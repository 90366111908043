import React from "react";

const Alert = ({
                   onClick,
                   isOpen,
                   info = "Click on a mat to change the color or delete it",
                   ...props
               }) => {
    return (
        <div className="tooltip bottom" role="tooltip">
            <div className="tooltip-arrow"></div>
            <div className="tooltip-inner">
                {info}
            </div>
        </div>
    );
};

export default Alert;