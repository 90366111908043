
// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import Swain_Designer from "./views/Swain_Designer";
import Wrestling_Designer from "./views/Wrestling_Designer";
import Flexi_Designer from "./views/Flexi_Designer";
import WallPad_Designer from "./views/WallPad_Designer";
import Puzzle_Designer from "./views/Puzzle_Designer";
import Turf_Designer from "./views/Turf_Designer"
import AdminLayout from "./views/Admin";
import HomePage from "./views/home";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: HomePage
  },
  {
    path: "/wrestling_mat_designer",
    layout: DefaultLayout,
    component: Wrestling_Designer
  },
  {
    path: "/wrestling_save_designer/:id",
    layout: DefaultLayout,
    component: Wrestling_Designer
  },
  {
    path: "/swain_mat_designer",
    layout: DefaultLayout,
    component: Swain_Designer
  },
  {
    path: "/swain_save_designer/:id",
    layout: DefaultLayout,
    component: Swain_Designer
  },
  {
    path: "/puzzle_save_designer/:id",
    layout: DefaultLayout,
    component: Puzzle_Designer
  },
  {
    path: "/wallpad_mat_designer",
    layout: DefaultLayout,
    component: WallPad_Designer
  },
  {
    path: "/wallpad_save_designer/:id",
    layout: DefaultLayout,
    component: WallPad_Designer
  },
  {
    path: "/flexi_mat_designer",
    layout: DefaultLayout,
    component: Flexi_Designer
  },
  {
    path: "/flexi_save_designer/:id",
    layout: DefaultLayout,
    component: Flexi_Designer
  },
  {
    path: "/admin",
    layout: DefaultLayout,
    component: AdminLayout
  },
  {
    path: "/puzzle_mat_designer",
    layout: DefaultLayout,
    component: Puzzle_Designer
  },
  {
    path: "/turf_mat_designer",
    layout: DefaultLayout,
    component: Turf_Designer
  },
  {
    path: "/turf_save_designer/:id",
    layout: DefaultLayout,
    component: Turf_Designer
  }
];
