import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import ColorPickerContainer from "./ColorPicker";
import {PaintColorHex} from "./layout/ColorCodeHelper";

class TextColorPane extends React.Component {
    state = {
        activeColor: PaintColorHex.Black,
        activeTab: 'custom',
        showColorPane: false,
        type: null,
        c: 0,
        m: 0,
        y: 0,
        k: 0,
    }

    componentDidMount() {
        console.log(this.props);
        this.setState({type: this.props.type})
        if (this.props.canvas) {

        } else {
            this.setState({activeColor: PaintColorHex.Black})
        }
        if(this.props.default){
            this.setState({activeColor: this.props.default})
        }
    }

    toggleColorPane = () => {
        const {showColorPane} = this.state;
        this.setState({showColorPane: !showColorPane})
    }
    setActiveTab = (tabName) => {
        this.setState({activeTab: tabName})
    }
    setBackgroundColor = (color) => {
        const {type} = this.state
        const canvas = this.props.canvas;
        if(type && type === 'turf'){
            this.props.changeColor(color);
            return;
        }
        if (type && canvas) {
            let activeObject = canvas.getActiveObject();
            if (activeObject && activeObject.type === "curvedText") {
                this.setState({activeColor: color})
                let obj = {};
                if (type === "fill") {
                    obj = {fill: color};
                    this.props.setActiveTextFill(color);
                }
                if (type === "stroke") {
                    obj = {stroke: color}
                    this.props.setActiveTextStroke(color);
                }
                activeObject.set(obj);
                canvas.renderAll();
            }
        }
    }
    setCyanColor = (e) => {
        let {value} = e.target;
        this.setState({c: value})
        this.setCMYKcolors()
    }
    setMagentaColor = (e) => {
        let {value} = e.target;
        this.setState({m: value})
        this.setCMYKcolors()
    }
    setYellowColor = (e) => {
        let {value} = e.target;
        this.setState({y: value})
        this.setCMYKcolors()
    }
    setKeyColor = (e) => {
        let {value} = e.target;
        this.setState({k: value})
        this.setCMYKcolors()
    }
    setCMYKcolors=()=>{
        const {c,m,y,k} = this.state;
        const cyan = c;
        const yellow = y;
        const black = k;
        const magenta = m;
        const red = 255 * (1 - cyan / 100) * (1 - black / 100);
        const green = 255 * (1 - magenta / 100) * (1 - black / 100);
        const blue = 255 * (1 - yellow / 100) * (1 - black / 100);
        let color = `rgb(${red}, ${green}, ${blue})`;
        this.setBackgroundColor(color);
    }

    render() {
        const {showColorPane, activeTab , c ,m , y , k} = this.state;
        let activeColor = '';
        let title ='Choose Color';
        if (this.props.type === "fill") {
            activeColor = this.props.activeTextFill
        }
        if (this.props.type === "stroke") {

            activeColor = this.props.activeTextStroke
        }
        if(this.props.type === "turf" && this.props.activeColor) {
            activeColor = this.props.activeColor;
        }
        if(this.props.title) title = this.props.title;
        return (
            <>
                <div className="col-md-12">
                    <div className="choose-clr-blk pt-10">
                        <div className="choos-clr-label"><span>{title}</span></div>
                        <div className="choos-clr-btn">
                            <button className="btn" style={{background: activeColor}}
                                    onClick={this.toggleColorPane}></button>
                        </div>
                    </div>
                </div>
                {showColorPane && (
                    <div className={`templatesmain color-lft-blk-menu ${showColorPane ? 'show-class' : ''}`}
                         id="animation-div">
                        <div className="row mb-3">
                            <div className="col-md-12">
                                <div
                                    className="col-md-4 custom-color active"
                                    onClick={() => this.setActiveTab("custom")}
                                    style={{
                                        cursor: 'pointer',
                                        background: activeTab === "custom" ? "#34405a" : "#263147"
                                    }}
                                >
                                        <span
                                            onClick={() => this.setActiveTab("custom")}
                                        >
                                          Custom
                                        </span>
                                </div>
                                <div
                                    className="col-md-4 rgb-color"
                                    onClick={() => this.setActiveTab("rgb")}
                                    style={{cursor: 'pointer', background: activeTab === "rgb" ? "#34405a" : "#263147"}}
                                >
                                        <span onClick={() => this.setActiveTab("rgb")}>
                                          RGB
                                        </span>
                                </div>
                                <div
                                    className="col-md-4 cmyk-color"
                                    onClick={() => this.setActiveTab("cmyk")}
                                    style={{
                                        cursor: 'pointer',
                                        background: activeTab === "cmyk" ? "#34405a" : "#263147"
                                    }}
                                >
                                        <span onClick={() => this.setActiveTab("cmyk")}>
                                          CMYK
                                        </span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="preset-color-row">

                                {activeTab === "custom" && (
                                    <div className="col-md-12">
                                        <div className="preset-color-row">
                                            <div
                                                className="pre-clr-box clr-pr1 tooltip"
                                                onClick={() =>
                                                    this.setBackgroundColor(PaintColorHex.White)
                                                }
                                                style={{background: PaintColorHex.White}}
                                            >
                                                <span className="tooltiptext">White</span>
                                            </div>
                                            <div
                                                className="pre-clr-box clr-pr2 tooltip"
                                                onClick={() =>
                                                    this.setBackgroundColor(PaintColorHex.MetallicSilver)
                                                }
                                                style={{background: PaintColorHex.MetallicSilver}}
                                            >
                                              <span className="tooltiptext">
                                                Metallic Silver
                                              </span>
                                            </div>
                                            <div
                                                className="pre-clr-box clr-pr3 tooltip"
                                                onClick={() =>
                                                    this.setBackgroundColor(PaintColorHex.LightGray)
                                                }
                                                style={{background: PaintColorHex.LightGray}}
                                            >
                                              <span className="tooltiptext">
                                                Light Gray
                                              </span>
                                            </div>
                                            <div
                                                className="pre-clr-box clr-pr4 tooltip"
                                                onClick={() =>
                                                    this.setBackgroundColor(PaintColorHex.VegasGold)
                                                }
                                                style={{background: PaintColorHex.VegasGold}}
                                            >
                                              <span className="tooltiptext">
                                                Vegas Gold
                                              </span>
                                            </div>
                                        </div>
                                        <div className="preset-color-row">
                                            <div
                                                className="pre-clr-box clr-pr5 tooltip"
                                                onClick={() =>
                                                    this.setBackgroundColor(PaintColorHex.Yellow)
                                                }
                                                style={{background: PaintColorHex.Yellow}}
                                            >
                                                <span className="tooltiptext">Yellow</span>
                                            </div>
                                            <div
                                                className="pre-clr-box clr-pr6 tooltip"
                                                onClick={() =>
                                                    this.setBackgroundColor(PaintColorHex.AthleticGold)
                                                }
                                                style={{background: PaintColorHex.AthleticGold}}
                                            >
                                              <span className="tooltiptext">
                                                Athletic Gold
                                              </span>
                                            </div>
                                            <div
                                                className="pre-clr-box clr-pr7 tooltip"
                                                onClick={() =>
                                                    this.setBackgroundColor(PaintColorHex.MatVinylGold)
                                                }
                                                style={{background: PaintColorHex.MatVinylGold}}
                                            >
                                              <span className="tooltiptext">
                                                Mat Vinyl Gold
                                              </span>
                                            </div>
                                            <div
                                                className="pre-clr-box clr-pr8 tooltip"
                                                onClick={() =>
                                                    this.setBackgroundColor(PaintColorHex.Orange)
                                                }
                                                style={{background: PaintColorHex.Orange}}
                                            >
                                                <span className="tooltiptext">Orange</span>
                                            </div>
                                        </div>
                                        <div className="preset-color-row">
                                            <div
                                                className="pre-clr-box clr-pr9 tooltip"
                                                onClick={() =>
                                                    this.setBackgroundColor(PaintColorHex.Red)
                                                }
                                                style={{background: PaintColorHex.Red}}
                                            >
                                                <span className="tooltiptext">Red</span>
                                            </div>
                                            <div
                                                className="pre-clr-box clr-pr10 tooltip"
                                                onClick={() =>
                                                    this.setBackgroundColor(PaintColorHex.Maroon)
                                                }
                                                style={{background: PaintColorHex.Maroon}}
                                            >
                                                <span className="tooltiptext">Maroon</span>
                                            </div>
                                            <div
                                                className="pre-clr-box clr-pr11 tooltip"
                                                onClick={() =>
                                                    this.setBackgroundColor(PaintColorHex.Purple)
                                                }
                                                style={{background: PaintColorHex.Purple}}
                                            >
                                                <span className="tooltiptext">Purple</span>
                                            </div>
                                            <div
                                                className="pre-clr-box clr-pr12 tooltip"
                                                onClick={() =>
                                                    this.setBackgroundColor(PaintColorHex.Navy)
                                                }
                                                style={{background: PaintColorHex.Navy}}
                                            >
                                                <span className="tooltiptext">Navy</span>
                                            </div>
                                        </div>
                                        <div className="preset-color-row">
                                            <div
                                                className="pre-clr-box clr-pr13 tooltip"
                                                onClick={() =>
                                                    this.setBackgroundColor(PaintColorHex.RoyalBlue)
                                                }
                                                style={{background: PaintColorHex.RoyalBlue}}
                                            >
                                              <span className="tooltiptext">
                                                Royal Blue
                                              </span>
                                            </div>
                                            <div
                                                className="pre-clr-box clr-pr14 tooltip"
                                                onClick={() =>
                                                    this.setBackgroundColor(PaintColorHex.CarolinaBlue)
                                                }
                                                style={{background: PaintColorHex.CarolinaBlue}}
                                            >
                                              <span className="tooltiptext">
                                                Carolina Blue
                                              </span>
                                            </div>
                                            <div
                                                className="pre-clr-box clr-pr15 tooltip"
                                                onClick={() =>
                                                    this.setBackgroundColor(PaintColorHex.Green)
                                                }
                                                style={{background: PaintColorHex.Green}}
                                            >
                                                <span className="tooltiptext">Green</span>
                                            </div>
                                            <div
                                                className="pre-clr-box clr-pr16 tooltip"
                                                onClick={() =>
                                                    this.setBackgroundColor(PaintColorHex.DarkGreen)
                                                }
                                                style={{background: PaintColorHex.DarkGreen}}
                                            >
                                                  <span className="tooltiptext">
                                                    Dark Green
                                                  </span>
                                            </div>
                                        </div>
                                        <div className="preset-color-row">
                                            <div
                                                className="pre-clr-box clr-pr17 tooltip"
                                                onClick={() =>
                                                    this.setBackgroundColor(PaintColorHex.Brown)
                                                }
                                                style={{background: PaintColorHex.Brown}}
                                            >
                                                <span className="tooltiptext">Brown</span>
                                            </div>
                                            <div
                                                className="pre-clr-box clr-pr18 tooltip"
                                                onClick={() =>
                                                    this.setBackgroundColor(PaintColorHex.CharcoalGray)
                                                }
                                                style={{background: PaintColorHex.CharcoalGray}}
                                            >
                                                  <span className="tooltiptext">
                                                    Charcoal Gray
                                                  </span>
                                            </div>
                                            <div
                                                className="pre-clr-box clr-pr19 tooltip"
                                                onClick={() =>
                                                    this.setBackgroundColor(PaintColorHex.Black)
                                                }
                                                style={{background: PaintColorHex.Black}}
                                            >
                                                <span className="tooltiptext">Black</span>
                                            </div>
                                            <div
                                                className="pre-clr-box clr-pr20 tooltip"
                                                onClick={() =>
                                                    this.setBackgroundColor(PaintColorHex.UWWMat)
                                                }
                                                style={{background: PaintColorHex.UWWMat}}
                                            >
                                                <span className="tooltiptext">UWW Mat</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {activeTab === "rgb" && (
                                    <div className="col-md-12">
                                        <ColorPickerContainer type={"rect"} subType={this.props.type}/>
                                    </div>
                                )}
                                {activeTab === "cmyk" && (
                                    <div className="col-md-12">
                                        <span></span>
                                        <div className="row">
                                            <div
                                                className="col-md-12"
                                                style={{background: "#34405a"}}
                                            >
                                                <div className="line-height-blk" style={{
                                                    marginBottom: '5px',
                                                    paddingTop: '15px'
                                                }}>
                                                    <div style={{width:'10%'}} className="line-height-label">
                                                        <span>C</span>
                                                    </div>
                                                    <div style={{width:'70%'}} className="line-height-label">
                                                        <div className="range-slidecontainer">
                                                            <input
                                                                type="range"
                                                                min="1"
                                                                max="100"
                                                                defaultValue="1"
                                                                className="range-slider"
                                                                value={c}
                                                                style={{
                                                                    background: `linear-gradient(to right, #DB2032 0%, #DB2032 ${c}%, #fff ${c}%, #fff 100%)`,
                                                                }}
                                                                onChange={this.setCyanColor}
                                                            ></input>
                                                        </div>
                                                    </div>
                                                    <div style={{width:'20%'}} className="line-height-value">
                                                        <input
                                                            type="number"
                                                            className="btn ln-ht-val-btn"
                                                            defaultValue="1"
                                                            min="1"
                                                            max="100"
                                                            value={c}
                                                            onChange={this.setCyanColor}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="line-height-blk" style={{
                                                    marginBottom: '5px'
                                                }}>
                                                    <div style={{width:'10%'}} className="line-height-label">
                                                        <span>M</span>
                                                    </div>
                                                    <div style={{width:'70%'}} className="line-height-label">
                                                        <div className="range-slidecontainer">
                                                            <input
                                                                type="range"
                                                                min="1"
                                                                max="100"
                                                                defaultValue="1"
                                                                className="range-slider"
                                                                value={m}
                                                                style={{
                                                                    background: `linear-gradient(to right, #DB2032 0%, #DB2032 ${m}%, #fff ${m}%, #fff 100%)`,
                                                                }}
                                                                onChange={this.setMagentaColor}
                                                            ></input>
                                                        </div>
                                                    </div>
                                                    <div style={{width:'20%'}} className="line-height-value">
                                                        <input
                                                            type="number"
                                                            value={m}
                                                            className="btn ln-ht-val-btn"
                                                            defaultValue="1"
                                                            min="1"
                                                            max="100"
                                                            onChange={this.setMagentaColor}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="line-height-blk" style={{
                                                    marginBottom: '5px'
                                                }}>
                                                    <div style={{width:'10%'}} className="line-height-label">
                                                        <span>Y</span>
                                                    </div>
                                                    <div style={{width:'70%'}} className="line-height-label">
                                                        <div className="range-slidecontainer">
                                                            <input
                                                                type="range"
                                                                min="1"
                                                                max="100"
                                                                defaultValue="1"
                                                                className="range-slider"
                                                                value={y}
                                                                style={{
                                                                    background: `linear-gradient(to right, #DB2032 0%, #DB2032 ${y}%, #fff ${y}%, #fff 100%)`,
                                                                }}
                                                                onChange={this.setYellowColor}
                                                            ></input>
                                                        </div>
                                                    </div>
                                                    <div style={{width:'20%'}} className="line-height-value">
                                                        <input
                                                            type="number"
                                                            value={y}
                                                            className="btn ln-ht-val-btn"
                                                            defaultValue="1"
                                                            min="1"
                                                            max="100"
                                                            onChange={this.setYellowColor}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="line-height-blk"
                                                    style={{marginBottom: "10px"}}
                                                >
                                                    <div style={{width:'10%'}} className="line-height-label">
                                                        <span>K</span>
                                                    </div>
                                                    <div style={{width:'70%'}} className="line-height-label">
                                                        <div className="range-slidecontainer">
                                                            <input
                                                                type="range"
                                                                min="1"
                                                                max="100"
                                                                defaultValue="1"
                                                                className="range-slider"
                                                                value={k}
                                                                style={{
                                                                    background: `linear-gradient(to right, #DB2032 0%, #DB2032 ${k}%, #fff ${k}%, #fff 100%)`,
                                                                }}
                                                                onChange={this.setKeyColor}
                                                            ></input>
                                                        </div>
                                                    </div>
                                                    <div style={{width:'20%'}} className="line-height-value">
                                                        <input
                                                            type="number"
                                                            value={k}
                                                            className="btn ln-ht-val-btn"
                                                            defaultValue="1"
                                                            min="1"
                                                            max="100"
                                                            onChange={this.setKeyColor}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='d-flex justify-content-center'>
                              <span className="btn col-md-2"
                                    style={{border: '1px solid white', margin: '10px 0px 0px 0px', padding: '2px'}}>
                                <a className='custom-side-nav1' onClick={() => this.toggleColorPane()}>
                                  <img src={require("./../assets/img/icon-collapse.png")} alt="del"
                                       style={{height: '30px', width: '30px'}}/>
                                </a>
                              </span>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }
}

const mapStateToProps = state => {

    return {
        canvas: state.canvas,
        activeTextFill: state.text_toolbar_config.fill,
        activeTextStroke: state.text_toolbar_config.stroke
    };
};

const setActiveTextFill = (color) => {
    return {
        type: "SET_ACTIVE_TEXT_FILL",
        fill: color,
    };
};
const setActiveTextStroke = (color) => {
    return {
        type: "SET_ACTIVE_TEXT_STROKE",
        stroke: color,
    };
};


const mapDispatchToProps = dispatch => {
    return bindActionCreators({setActiveTextFill, setActiveTextStroke}, dispatch);
};


const TextColorPaneConnect = connect(
    mapStateToProps,
    mapDispatchToProps
)(TextColorPane);

export default TextColorPaneConnect