import React from "react";
import "../assets/css/bootstrap.css";
import "../assets/css/fontawesome.css";
import "../assets/css/wrestling.css";
import "../assets/css/metisMenu.css";
import "../assets/css/onoffcanvas.css";
import "../assets/css/animate.css";
import { Container } from "shards-react";
import "fabric-webpack";
import DesignCanvas from "../components/layout/wrestling_mat_designer/DesignCanvas";
import OptionSetttingDialogue from "../components/layout/wrestling_mat_designer/Option_Settting_Dialogue";
import { connect } from "react-redux";
// import CurvedText from "../components/CurvedText";
import Image from "../components/Image";
import {
  Circle1,
  Circle2,
  Circle3,
  Circle4,
  Circle5,
  Circle6,
  Circle7,
  Circle8,
} from "../components/Circles";
import Artwork from "../components/Artwork";
import { bindActionCreators } from "redux";
import Sidebar from "../components/layout/wrestling_mat_designer/Sidebar";
import ExtraMenu from "../components/layout/wrestling_mat_designer/ExtraMenu";
import SidebarItems from "../components/layout/wrestling_mat_designer/SidebarItems";
import $ from "jquery";
import axios from "axios";

class Dashboard extends React.Component {
  state = {
    id: null,
    showAlert: true,
  };
  componentDidMount() {
    const { id } = this.props.match.params;
    if (id !== undefined) {
      this.props.setSaveDesignID(id);
    }
    $(".circle-color-pane").css({ display: "none" });
    $(".text-color-pane").css({ display: "none" });
    $(".artwork-color-pane").css({ display: "none" });
    document.getElementById("mySidenav11") && (document.getElementById("mySidenav11").style.width = "0");
    /*document.addEventListener('click',(e)=>{
      if(e.target.tagName !== 'CANVAS'){
        this.clearSelection();
      }
    })*/
  }
  handleCloseAlert = () => {
    this.setState({ showAlert: false });
  };

  updateCanvasState = () => {
    let tempConfig = this.props._config;
    if (tempConfig.undoStatus === false && tempConfig.redoStatus === false) {
      let jsonData = this.props.canvas.toJSON(['source','isCenterCircle']);
      let canvasAsJson = JSON.stringify(jsonData);
      if (tempConfig.currentStateIndex < tempConfig.canvasState.length - 1) {
        let indexToBeInserted = tempConfig.currentStateIndex + 1;
        tempConfig.canvasState[indexToBeInserted] = canvasAsJson;

        let numberOfElementsToRetain = indexToBeInserted + 1;
        tempConfig.canvasState = tempConfig.canvasState.splice(
          0,
          numberOfElementsToRetain
        );
      } else {
        tempConfig.canvasState.push(canvasAsJson);
      }

      tempConfig.currentStateIndex = tempConfig.canvasState.length - 1;

      if (
        tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
        tempConfig.currentStateIndex !== -1
      ) {
        tempConfig.redoButton = "disabled";
      }
    }
    this.props.setCanvasConfig(tempConfig);
  };

  showEditArtWorkDialogue = () => {
    $(".material-color-pane").css({ display: "none" });
    $(".circle-color-pane").css({ display: "none" });
    $(".text-color-pane").css({ display: "none" });
  };

  setArtWorkColor = (color) => {
    let activeObject = this.props.canvas._activeObject;
    activeObject.set("fill", color);
    this.props.canvas.renderAll();
    this.updateCanvasState();
  };

  deleteSelectedMaterial = () => {
    console.log(this.props.canvas);
    let activeObject = this.props.canvas._activeObject;
    if (activeObject) {
      this.props.canvas.remove(activeObject);
    }
    this.updateCanvasState();
  };

  duplicateSelectedMaterial = () => {
    let activeObject = this.props.canvas._activeObject;
    activeObject.clone((cloned) => {
      this.props.canvas.add(cloned);
    });
    this.props.canvas.renderAll();
    this.updateCanvasState();
  };

  clearCanvas = (e) => {
    e.preventDefault();
    this.props.canvas.clear();
    this.updateCanvasState();
    this.props.clearCanvas();
    //const t = new window.fabric.Text('', { fontFamily: 'Times New Roman',left:-10 })
    //this.props.canvas.add(t);
  };
  clearSelection = () => {
    this.props.canvas.deactivateAll().renderAll();
    // copying clear selection event data
    $('.deleteBtn').css('visibility', 'hidden')
    $(".material-color-pane").css({ display: "none" });
    $(".circle-color-pane").css({ display: "none" });
    $(".text-color-pane").css({ display: "none" });
    $(".artwork-color-pane").css({ display: "none" });
    $(".custom-colors").css("display", "none");
    $(".advanced_span").css("display", "none");
    $("#rgb").css("display", "none");
    $("#cmyk").css("display", "none");

    $("#bold").removeClass("active");
    $("#italic").removeClass("active");
    $("#underline").removeClass("active");
    $("#capital").removeClass("active");
    $("#left-align").removeClass("active");
    $("#center-align").removeClass("active");
    $("#right-align").removeClass("active");
    //$('.slct-font-size').val($('.slct-font-size option:first').val())
    //$('.slct-font-fam').val($('.slct-font-fam option:first').val())
    $("#line_ht_get").val(1);
    $("#line_ht_get").css({
      background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
    });
    $("#line_ht_set").val(1);
    $("#spacing_get").val(1);
    $("#spacing_get").css({
      background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
    });
    $("#spacing_set").val(1);
    $("#text-color-bind").css({ background: "black" });
    $("#size_get").val(0);
    $("#size_get").css({
      background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
    });
    $("#size_set").val(0);
    $("#outline-color-bind").css({ background: "black" });
    $("#selected_text").val("Add Text");
    $(".add-text-btn").text("Add Text");
  };

  setKeyColor = (e) => {
    let { value } = e.target;
    $("#key_get").val(value);
    $("#key_set").val(value);
    const black = value;
    const cyan = $("#cyan_get").val();
    const magenta = $("#magenta_get").val();
    const yellow = $("#yellow_get").val();

    const red = 255 * (1 - cyan / 100) * (1 - black / 100);
    const green = 255 * (1 - magenta / 100) * (1 - black / 100);
    const blue = 255 * (1 - yellow / 100) * (1 - black / 100);

    const activeObject = this.props.canvas._activeObject;
    if (!activeObject) return;
    if (activeObject.__proto__.type === "group") {
      switch (this.props.selectedGroupType) {
        case 0:
          activeObject._objects[1].set(
            "stroke",
            `rgb(${red}, ${green}, ${blue})`
          );
          activeObject._objects[3].set(
            "stroke",
            `rgb(${red}, ${green}, ${blue})`
          );
          $(".default-color-1").css(
            "background",
            `rgb(${red}, ${green}, ${blue})`
          );
          break;
        case 1:
          activeObject._objects[0].set(
            "stroke",
            `rgb(${red}, ${green}, ${blue})`
          );
          $(".default-color-2").css(
            "background",
            `rgb(${red}, ${green}, ${blue})`
          );
          break;
        case 2:
          activeObject._objects[2].set(
            "stroke",
            `rgb(${red}, ${green}, ${blue})`
          );
          $(".default-color-3").css(
            "background",
            `rgb(${red}, ${green}, ${blue})`
          );
          break;
        default:
          break;
      }
    } else if (activeObject.__proto__.type === "path-group") {
      for (let i = 0; i < activeObject.paths.length; i++) {
        let index = this.props.artworkPaths[
          this.props.selectedGroupType
        ].indexOf(i);
        if (index >= 0)
          activeObject.paths[i].setFill(`rgb(${red}, ${green}, ${blue})`);
      }
      $(
        `.artwork-color-pane .default-colors .default-color-${this.props
          .selectedGroupType + 1}`
      ).css("background", `rgb(${red}, ${green}, ${blue})`);
    } else {
      if (activeObject.get("fill") === "rgba(0,0,0,0)") {
        activeObject.set("stroke", `rgb(${red}, ${green}, ${blue})`);
        $(".circle-color-pane .default-color-2").css(
          "background",
          `rgb(${red}, ${green}, ${blue})`
        );
      } else {
        if (this.props.selectedGroupType === 0) {
          activeObject.set("fill", `rgb(${red}, ${green}, ${blue})`);
          $(".circle-color-pane .default-color-1").css(
            "background",
            `rgb(${red}, ${green}, ${blue})`
          );
        } else if (this.props.selectedGroupType === 1) {
          activeObject.set("stroke", `rgb(${red}, ${green}, ${blue})`);
          $(".circle-color-pane .default-color-2").css(
            "background",
            `rgb(${red}, ${green}, ${blue})`
          );
        }
      }
    }

    this.props.canvas.renderAll();
    this.updateCanvasState();
  };

  setMagentaColor = (e) => {
    let { value } = e.target;
    $("#magenta_get").val(value);
    $("#magenta_set").val(value);

    const magenta = value;
    const cyan = $("#cyan_get").val();
    const black = $("#key_get").val();
    const yellow = $("#yellow_get").val();

    const red = 255 * (1 - cyan / 100) * (1 - black / 100);
    const green = 255 * (1 - magenta / 100) * (1 - black / 100);
    const blue = 255 * (1 - yellow / 100) * (1 - black / 100);

    const activeObject = this.props.canvas._activeObject;
    if (!activeObject) return;
    if (activeObject.__proto__.type === "group") {
      switch (this.props.selectedGroupType) {
        case 0:
          activeObject._objects[0].set(
            "stroke",
            `rgb(${red}, ${green}, ${blue})`
          );
          activeObject._objects[2].set(
            "stroke",
            `rgb(${red}, ${green}, ${blue})`
          );
          $(".default-color-1").css(
            "background",
            `rgb(${red}, ${green}, ${blue})`
          );
          break;
        case 1:
          activeObject._objects[1].set(
            "stroke",
            `rgb(${red}, ${green}, ${blue})`
          );
          $(".default-color-2").css(
            "background",
            `rgb(${red}, ${green}, ${blue})`
          );
          break;
        case 2:
          activeObject._objects[3].set(
            "stroke",
            `rgb(${red}, ${green}, ${blue})`
          );
          $(".default-color-3").css(
            "background",
            `rgb(${red}, ${green}, ${blue})`
          );
          break;
        default:
          break;
      }
    } else if (activeObject.__proto__.type === "path-group") {
      for (let i = 0; i < activeObject.paths.length; i++) {
        let index = this.props.artworkPaths[
          this.props.selectedGroupType
        ].indexOf(i);
        if (index >= 0)
          activeObject.paths[i].setFill(`rgb(${red}, ${green}, ${blue})`);
      }
      $(
        `.artwork-color-pane .default-colors .default-color-${this.props
          .selectedGroupType + 1}`
      ).css("background", `rgb(${red}, ${green}, ${blue})`);
    } else {
      if (activeObject.get("fill") === "rgba(0,0,0,0)") {
        activeObject.set("stroke", `rgb(${red}, ${green}, ${blue})`);
        $(".circle-color-pane .default-color-2").css(
          "background",
          `rgb(${red}, ${green}, ${blue})`
        );
      } else {
        if (this.props.selectedGroupType === 0) {
          activeObject.set("fill", `rgb(${red}, ${green}, ${blue})`);
          $(".circle-color-pane .default-color-1").css(
            "background",
            `rgb(${red}, ${green}, ${blue})`
          );
        } else if (this.props.selectedGroupType === 1) {
          activeObject.set("stroke", `rgb(${red}, ${green}, ${blue})`);
          $(".circle-color-pane .default-color-2").css(
            "background",
            `rgb(${red}, ${green}, ${blue})`
          );
        }
      }
    }

    this.props.canvas.renderAll();
    this.updateCanvasState();
  };

  setYellowColor = (e) => {
    let { value } = e.target;
    $("#yellow_get").val(value);
    $("#yellow_set").val(value);

    const yellow = value;
    const cyan = $("#cyan_get").val();
    const black = $("#key_get").val();
    const magenta = $("#magenta_get").val();

    const red = 255 * (1 - cyan / 100) * (1 - black / 100);
    const green = 255 * (1 - magenta / 100) * (1 - black / 100);
    const blue = 255 * (1 - yellow / 100) * (1 - black / 100);

    const activeObject = this.props.canvas._activeObject;
    if (!activeObject) return;
    if (activeObject.__proto__.type === "group") {
      switch (this.props.selectedGroupType) {
        case 0:
          activeObject._objects[0].set(
            "stroke",
            `rgb(${red}, ${green}, ${blue})`
          );
          activeObject._objects[2].set(
            "stroke",
            `rgb(${red}, ${green}, ${blue})`
          );
          $(".default-color-1").css(
            "background",
            `rgb(${red}, ${green}, ${blue})`
          );
          break;
        case 1:
          activeObject._objects[1].set(
            "stroke",
            `rgb(${red}, ${green}, ${blue})`
          );
          $(".default-color-2").css(
            "background",
            `rgb(${red}, ${green}, ${blue})`
          );
          break;
        case 2:
          activeObject._objects[3].set(
            "stroke",
            `rgb(${red}, ${green}, ${blue})`
          );
          $(".default-color-3").css(
            "background",
            `rgb(${red}, ${green}, ${blue})`
          );
          break;
        default:
          break;
      }
    } else if (activeObject.__proto__.type === "path-group") {
      for (let i = 0; i < activeObject.paths.length; i++) {
        let index = this.props.artworkPaths[
          this.props.selectedGroupType
        ].indexOf(i);
        if (index >= 0)
          activeObject.paths[i].setFill(`rgb(${red}, ${green}, ${blue})`);
      }
      $(
        `.artwork-color-pane .default-colors .default-color-${this.props
          .selectedGroupType + 1}`
      ).css("background", `rgb(${red}, ${green}, ${blue})`);
    } else {
      if (activeObject.get("fill") === "rgba(0,0,0,0)") {
        activeObject.set("stroke", `rgb(${red}, ${green}, ${blue})`);
        $(".circle-color-pane .default-color-2").css(
          "background",
          `rgb(${red}, ${green}, ${blue})`
        );
      } else {
        if (this.props.selectedGroupType === 0) {
          activeObject.set("fill", `rgb(${red}, ${green}, ${blue})`);
          $(".circle-color-pane .default-color-1").css(
            "background",
            `rgb(${red}, ${green}, ${blue})`
          );
        } else if (this.props.selectedGroupType === 1) {
          activeObject.set("stroke", `rgb(${red}, ${green}, ${blue})`);
          $(".circle-color-pane .default-color-2").css(
            "background",
            `rgb(${red}, ${green}, ${blue})`
          );
        }
      }
    }

    this.props.canvas.renderAll();
    this.updateCanvasState();
  };

  setCyanColor = (e) => {
    console.log("hello");
    let { value } = e.target;
    $("#cyan_get").val(value);
    $("#cyan_set").val(value);

    const cyan = value;
    const yellow = $("#yellow_get").val();
    const black = $("#key_get").val();
    const magenta = $("#magenta_get").val();

    const red = 255 * (1 - cyan / 100) * (1 - black / 100);
    const green = 255 * (1 - magenta / 100) * (1 - black / 100);
    const blue = 255 * (1 - yellow / 100) * (1 - black / 100);

    const activeObject = this.props.canvas._activeObject;
    if (!activeObject) return;

    if (activeObject.__proto__.type === "group") {
      switch (this.props.selectedGroupType) {
        case 0:
          activeObject._objects[0].set(
            "stroke",
            `rgb(${red}, ${green}, ${blue})`
          );
          activeObject._objects[2].set(
            "stroke",
            `rgb(${red}, ${green}, ${blue})`
          );
          $(".default-color-1").css(
            "background",
            `rgb(${red}, ${green}, ${blue})`
          );
          break;
        case 1:
          activeObject._objects[1].set(
            "stroke",
            `rgb(${red}, ${green}, ${blue})`
          );
          $(".default-color-2").css(
            "background",
            `rgb(${red}, ${green}, ${blue})`
          );
          break;
        case 2:
          activeObject._objects[3].set(
            "stroke",
            `rgb(${red}, ${green}, ${blue})`
          );
          $(".default-color-3").css(
            "background",
            `rgb(${red}, ${green}, ${blue})`
          );
          break;
        default:
          break;
      }
    } else if (activeObject.__proto__.type === "path-group") {
      for (let i = 0; i < activeObject.paths.length; i++) {
        let index = this.props.artworkPaths[
          this.props.selectedGroupType
        ].indexOf(i);
        if (index >= 0)
          activeObject.paths[i].setFill(`rgb(${red}, ${green}, ${blue})`);
      }
      $(
        `.artwork-color-pane .default-colors .default-color-${this.props
          .selectedGroupType + 1}`
      ).css("background", `rgb(${red}, ${green}, ${blue})`);
    } else {
      if (activeObject.get("fill") === "rgba(0,0,0,0)") {
        activeObject.set("stroke", `rgb(${red}, ${green}, ${blue})`);
        $(".circle-color-pane .default-color-2").css(
          "background",
          `rgb(${red}, ${green}, ${blue})`
        );
      } else {
        if (this.props.selectedGroupType === 0) {
          activeObject.set("fill", `rgb(${red}, ${green}, ${blue})`);
          $(".circle-color-pane .default-color-1").css(
            "background",
            `rgb(${red}, ${green}, ${blue})`
          );
        } else if (this.props.selectedGroupType === 1) {
          activeObject.set("stroke", `rgb(${red}, ${green}, ${blue})`);
          $(".circle-color-pane .default-color-2").css(
            "background",
            `rgb(${red}, ${green}, ${blue})`
          );
        }
      }
    }

    this.props.canvas.renderAll();
    this.updateCanvasState();
  };

  setActiveTab = (id) => {
    $("#custom").css("display", "none");
    $("#rgb").css("display", "none");
    $("#cmyk").css("display", "none");
    $("#text-custom").css("display", "none");
    $("#text-rgb").css("display", "none");
    $("#text-cmyk").css("display", "none");
    $("#circle-custom").css("display", "none");
    $("#circle-rgb").css("display", "none");
    $("#circle-cmyk").css("display", "none");
    $("#artwork-custom").css("display", "none");
    $("#artwork-rgb").css("display", "none");
    $("#artwork-cmyk").css("display", "none");
    $(`#${id}`).css("display", "block");
    $(".custom-colors .active").removeClass("active");
    $(`.${id}-color`).addClass("active");
  };

  setBackgroundColor = (color) => {
    const activeObject = this.props.canvas._activeObject;
    if (!activeObject) return;
    if (activeObject.__proto__.type === "group") {
      switch (this.props.selectedGroupType) {
        case 0:
          activeObject._objects[0].set("stroke", color);
          activeObject._objects[2].set("stroke", color);
          $(".default-color-1").css("background", color);
          break;
        case 1:
          activeObject._objects[1].set("stroke", color);
          $(".default-color-2").css("background", color);
          break;
        case 2:
          activeObject._objects[3].set("stroke", color);
          $(".default-color-3").css("background", color);
          break;
        default:
          break;
      }
    } else if (activeObject.__proto__.type === "path-group") {
      for (let i = 0; i < activeObject.paths.length; i++) {
        let index = this.props.artworkPaths[
          this.props.selectedGroupType
        ].indexOf(i);
        if (index >= 0) activeObject.paths[i].setFill(color);
      }
      $(
        `.artwork-color-pane .default-colors .default-color-${this.props
          .selectedGroupType + 1}`
      ).css("background", color);
    } else {
      if (activeObject.get("fill") === "rgba(0,0,0,0)") {
        activeObject.set("stroke", color);
        $(".circle-color-pane .default-color-2").css("background", color);
      } else {
        if (this.props.selectedGroupType === 0) {
          activeObject.set("fill", color);
          $(".circle-color-pane .default-color-1").css("background", color);
        } else if (this.props.selectedGroupType === 1) {
          activeObject.set("stroke", color);
          $(".circle-color-pane .default-color-2").css("background", color);
        }
      }
    }
    this.props.canvas.renderAll();
    this.updateCanvasState();
  };

  setColorType = (type) => {
    this.props.setSelectedGroupType(type);
    $(".default-colors")
      .children()
      .removeClass("color-active");
    $(".default-colors")
      .children()
      .eq(type)
      .addClass("color-active");
    // console.log([0]);

    $(".custom-colors").css("display", "block");
    $("#custom").css("display", "block");
    $("#rgb").css("display", "none");
    $("#cmyk").css("display", "none");
    $("#text-custom").css("display", "block");
    $("#text-rgb").css("display", "none");
    $("#text-cmyk").css("display", "none");
    $("#artwork-custom").css("display", "block");
    $("#artwork-rgb").css("display", "none");
    $("#artwork-cmyk").css("display", "none");
    $(".advanced_span").css("display", "block");
    $(".custom-color").addClass("active");
    $(".rgb-color.active").removeClass("active");
    $(".cmyk-color.active").removeClass("active");
  };

  bindCircleColorBtn = () => {
    $(".custom-colors").css({ display: "block" });
    $("#material-custom").css({ display: "block" });
    $("#material-rgb").css({ display: "none" });
    $("#material-cmyk").css({ display: "none" });
  };

  setCircleColor = (e) => {
    let { value } = e.target;
    $("#circle-color-bind").css({ background: value });

    let activeObject = this.props.canvas._activeObject;
    if (activeObject == null) return;

    activeObject.set("stroke", value);
    this.props.canvas.renderAll();
    this.updateCanvasState();
  };

  sendQuote = ({ fullName: name, email, phone, zipcode, subject, message }) => {
    this.clearSelection();
    const thickness = $('input[name="mat-thickness"]:checked').val();
    const matData = [{}];
    // matData[0].matSize = (this.props.wrestling_config.width * 2) + " x " + (this.props.wrestling_config.height * 2);
    if (
      this.props.wrestling_config.width === "12" ||
      this.props.wrestling_config.width === 12
      // this.props.wrestling_config.width === 30
    ) {
      matData[0].matSize =
        this.props.wrestling_config.width +
        "m x " +
        this.props.wrestling_config.height +
        "m";
    } else {
      matData[0].matSize =
        this.props.wrestling_config.width +
        "ft x " +
        this.props.wrestling_config.height+"ft";
    }
    matData[0].matColor = this.props.mat_color;
    let matFonts = [];
    if (this.props.canvas.getObjects("curvedText").length > 0) {
      let curvedTexts = this.props.canvas.getObjects("curvedText");
      curvedTexts.forEach((item,index)=>{
        console.log({index,item});
        if(item.text !== ''){
          matFonts.push({
            text : item.text,
            fontFamily : item.fontFamily,
            fill : item.fill,
          })
        }
      })

      matData[0].text = this.props.text_style ? this.props.text_style.text : "";
      matData[0].font = this.props.text_style
        ? this.props.text_style.fontFamily
        : "";
      matData[0].fontColor = this.props.text_style.fill;
    }
    matData[0].curvedTexts = matFonts;
    if(this.props.canvas.getObjects('circle').length > 0){
      if (
          this.props.circle_outer_diameter === 260 &&
          (this.props.wrestling_config.width === "12" ||
              this.props.wrestling_config.width === 12 ||
              this.props.wrestling_config.width === 30)
      ) {
        matData[0].circle = "9m x 9m";
      } else {
        matData[0].circle =
            this.props.circle_outer_diameter +
            "ft x " +
            this.props.circle_inner_diameter+"ft";
      }
    }else{
      matData[0].circle = "";
    }

    if(matData[0].circle === "0ft x 0ft"){
      matData[0].circle = "";
    }

    console.log(matData[0].circle);
    matData[0].thickness = thickness;

    const fc = $('input[name="mat-flexi-connect"]:checked').val();
    matData[0].fc = fc ? "Yes" : null;

    matData[0].svg = this.props.canvas.toSVG();
    matData[0].png = this.props.canvas.toDataURL();
    matData[0].logos = this.props.uploadedLogos;

    const data = {
      name,
      email,
      phone,
      zipcode,
      subject,
      message,
      matData,
    };

    axios
      .post("/api/quote", data)
      .then((res) => {
        alert("Successfully saved!");
      })
      .catch((res) => {
        alert("An unexpected error occurred. Please contact Admin");
      });
  };

  setTextColor = (color) => {
    let activeObject = this.props.canvas._activeObject;
    if (activeObject == null) return;

    if (this.props.textColorOption === "fill") {
      activeObject.set("fill", color);
    } else {
      activeObject.set("stroke", color);
    }
    this.props.canvas.renderAll();
    this.updateCanvasState();
    this.props.setFontColorOption(color);
  };

  setTextKeyColor = (e) => {
    let { value } = e.target;
    $("#key_get").val(value);
    $("#key_set").val(value);
    const black = value;
    const cyan = $("#cyan_get").val();
    const magenta = $("#magenta_get").val();
    const yellow = $("#yellow_get").val();

    const red = 255 * (1 - cyan / 100) * (1 - black / 100);
    const green = 255 * (1 - magenta / 100) * (1 - black / 100);
    const blue = 255 * (1 - yellow / 100) * (1 - black / 100);

    $("#cmyk_color").css("background", `rgb(${red}, ${green}, ${blue})`);

    const activeObject = this.props.canvas._activeObject;
    if (!activeObject) return;
    if (this.props.textColorOption === "fill") {
      activeObject.set("fill", `rgb(${red}, ${green}, ${blue})`);
    } else {
      activeObject.set("stroke", `rgb(${red}, ${green}, ${blue})`);
    }

    this.props.canvas.renderAll();
    this.updateCanvasState();
  };

  setTextMagentaColor = (e) => {
    let { value } = e.target;
    $("#magenta_get").val(value);
    $("#magenta_set").val(value);

    const magenta = value;
    const cyan = $("#cyan_get").val();
    const black = $("#key_get").val();
    const yellow = $("#yellow_get").val();

    const red = 255 * (1 - cyan / 100) * (1 - black / 100);
    const green = 255 * (1 - magenta / 100) * (1 - black / 100);
    const blue = 255 * (1 - yellow / 100) * (1 - black / 100);

    $("#cmyk_color").css("background", `rgb(${red}, ${green}, ${blue})`);

    const activeObject = this.props.canvas._activeObject;
    if (!activeObject) return;
    if (this.props.textColorOption === "fill") {
      activeObject.set("fill", `rgb(${red}, ${green}, ${blue})`);
    } else {
      activeObject.set("stroke", `rgb(${red}, ${green}, ${blue})`);
    }

    this.props.canvas.renderAll();
    this.updateCanvasState();
  };

  setTextYellowColor = (e) => {
    let { value } = e.target;
    $("#yellow_get").val(value);
    $("#yellow_set").val(value);

    const yellow = value;
    const cyan = $("#cyan_get").val();
    const black = $("#key_get").val();
    const magenta = $("#magenta_get").val();

    const red = 255 * (1 - cyan / 100) * (1 - black / 100);
    const green = 255 * (1 - magenta / 100) * (1 - black / 100);
    const blue = 255 * (1 - yellow / 100) * (1 - black / 100);

    $("#cmyk_color").css("background", `rgb(${red}, ${green}, ${blue})`);

    const activeObject = this.props.canvas._activeObject;
    if (!activeObject) return;
    if (this.props.textColorOption === "fill") {
      activeObject.set("fill", `rgb(${red}, ${green}, ${blue})`);
    } else {
      activeObject.set("stroke", `rgb(${red}, ${green}, ${blue})`);
    }

    this.props.canvas.renderAll();
    this.updateCanvasState();
  };

  setTextCyanColor = (e) => {
    let { value } = e.target;
    $("#cyan_get").val(value);
    $("#cyan_set").val(value);

    const cyan = value;
    const yellow = $("#yellow_get").val();
    const black = $("#key_get").val();
    const magenta = $("#magenta_get").val();

    const red = 255 * (1 - cyan / 100) * (1 - black / 100);
    const green = 255 * (1 - magenta / 100) * (1 - black / 100);
    const blue = 255 * (1 - yellow / 100) * (1 - black / 100);

    $("#cmyk_color").css("background", `rgb(${red}, ${green}, ${blue})`);

    const activeObject = this.props.canvas._activeObject;
    if (!activeObject) return;

    if (this.props.textColorOption === "fill") {
      activeObject.set("fill", `rgb(${red}, ${green}, ${blue})`);
    } else {
      activeObject.set("stroke", `rgb(${red}, ${green}, ${blue})`);
    }

    this.props.canvas.renderAll();
    this.updateCanvasState();
  };

  setArtworkColor = (color) => {
    const activeObject = this.props.canvas._activeObject;
    if (!activeObject) return;

    for (let i = 0; i < activeObject.paths.length; i++) {
      let index = this.props.artworkPaths[this.props.selectedGroupType].indexOf(
        i
      );
      if (index >= 0) activeObject.paths[i].setFill(color);
    }
    this.props.canvas.renderAll();
    this.updateCanvasState();
  };

  emptyClick = () => {
    if (window.innerWidth < 768) {
      document.getElementById('second-custom-right').style.display = 'none'
      document.getElementById("mySidenav1").style.width = "0"
      document.getElementById("mySidenav2").style.width = "0"
      document.getElementById("mySidenav3").style.width = "0"
      document.getElementById("mySidenav11") && (document.getElementById("mySidenav11").style.width = "0")
      document.getElementById("mySidenav5").style.width = "0"
      document.getElementById("mySidenav6").style.width = "0"
      document.getElementById("mySidenav7").style.width = "0"
      document.getElementById("mySidenav8").style.width = "0"
    }
  }

  render() {
    const components_type = this.props.components_type;
    const wrestling_config = this.props.wrestling_config;

    if (!wrestling_config.option_setting) {
      return <OptionSetttingDialogue />;
    }
    const unit = Number(wrestling_config.width) === 12 ? 'm' : 'ft';

    return (
      <>
        <div id="second-custom-right" onClick={this.emptyClick} />
        <Sidebar onClearSelection={this.clearSelection} />
        <div className="col-md-4 col-sm-4 text-center pad0 leftpanel">
          <SidebarItems />
        </div>
        <div id="content">
          <div className="outer">
            <div className="inner bg-light lter">
              <ExtraMenu />
              <div className="row ml0 mar ">
                <div className="text-center pad0 main-content custom-main-content">
                  <div className="main-edit-content">
                    <p className="matsize">
                        <span>
                          MAT SIZE : {wrestling_config.width+unit} x{" "}
                          {wrestling_config.height+unit}
                        </span>
                    </p>
                    <div className="addcontdiv">
                      <Container fluid className="main-content-container px-4 scroll-wrestling-pad">
                        <DesignCanvas>
                          {components_type.map((type, idx) => {
                            switch (type) {
                              case "Image":
                                return <Image key={idx} />;
                              case "Artwork":
                                return <Artwork key={idx} />;
                              case "Circle1":
                                return <Circle1 key={idx} />;
                              case "Circle2":
                                return <Circle2 key={idx} />;
                              case "Circle3":
                                return <Circle3 key={idx} />;
                              case "Circle4":
                                return <Circle4 key={idx} />;
                              case "Circle5":
                                return <Circle5 key={idx} />;
                              case "Circle6":
                                return <Circle6 key={idx} />;
                              case "Circle7":
                                return <Circle7 key={idx} />;
                              case "Circle8":
                                return <Circle8 key={idx} />;
                              /*default:
                                return <CurvedText key={idx} />;*/
                            }
                          })}
                        </DesignCanvas>
                      </Container>
                    </div>
                  </div>
                  <div id="drag_image" />
                  <div id="drag_template" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const setSaveDesignID = (id) => {
  return {
    type: "SET_SAVED_ID",
    id: id,
  };
};

const setCanvasConfig = (_config) => {
  return {
    type: "SET_CONFIG",
    _config,
  };
};

const setSelectedGroupType = (type) => {
  return {
    type: "SET_GROUP_SELECT",
    groupType: type,
  };
};

const clearCanvas = () => {
  return {
    type: "CLAER_CANVAS",
    isCanvasCleared: true,
  };
};

const setFontColorOption = (option) => {
  return {
    type: "SET_FONT_COLOR_OPTION",
    option,
  };
};

const mapStateToProps = (state) => {
  return {
    _config: state._config,
    components_type: [...state.components_type],
    wrestling_config: state.wrestling_config,
    canvas: state.canvas,
    text_style: state.text_style,
    fontColor: state.fontColor,
    selectedGroupType: state.selectedGroupType,
    textColorOption: state.textColorOption,
    artworkPaths: state.artworkPaths,
    circle_outer_diameter: state.circle_outer_diameter,
    circle_inner_diameter: state.circle_inner_diameter,
    uploadedLogos: state.uploadedLogos,
    mat_color: state.mat_color,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setSaveDesignID,
      setCanvasConfig,
      setSelectedGroupType,
      clearCanvas,
      setFontColorOption,
    },
    dispatch
  );
};

const Wrestling_Designer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

export default Wrestling_Designer;
