import React, { useState } from "react";
import "../assets/css/bootstrap.css";
import "../assets/css/fontawesome.css";
import "../assets/css/wrestling.css";
import "../assets/css/metisMenu.css";
import "../assets/css/onoffcanvas.css";
import "../assets/css/animate.css";
import { Container } from "shards-react";
import "fabric-webpack";
import DesignCanvas from "../components/layout/wallpad_mat_designer/DesignCanvas";
import { connect } from "react-redux";
import CurvedText from "../components/CurvedText";
import Image from "../components/Image";
import { bindActionCreators } from "redux";
import Sidebar from "../components/layout/wallpad_mat_designer/Sidebar";
import Toolbar from "../components/layout/wallpad_mat_designer/Toolbar";
import ExtraMenu from "../components/layout/wallpad_mat_designer/ExtraMenu";
import SidebarItems from "../components/layout/wallpad_mat_designer/SidebarItems";
import OptionSettingDialogue from "../components/layout/wallpad_mat_designer/Option_Settting_Dialogue";
import QuoteModal from "./../components/layout/QuoteModal";
import ColorPickerContainer from "./../components/ColorPicker";
import axios from "axios";
import $ from "jquery";

class Dashboard extends React.Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    if (id !== undefined) {
      this.props.setSaveDesignID(id);
    }
    $(".text-color-pane").css({ display: "none" });
  }

  updateCanvasState = () => {
    let tempConfig = this.props._config;
    if (tempConfig.undoStatus === false && tempConfig.redoStatus === false) {
      let jsonData = this.props.canvas.toJSON();
      let canvasAsJson = JSON.stringify(jsonData);
      if (tempConfig.currentStateIndex < tempConfig.canvasState.length - 1) {
        let indexToBeInserted = tempConfig.currentStateIndex + 1;
        tempConfig.canvasState[indexToBeInserted] = canvasAsJson;

        let numberOfElementsToRetain = indexToBeInserted + 1;
        tempConfig.canvasState = tempConfig.canvasState.splice(
          0,
          numberOfElementsToRetain
        );
      } else {
        tempConfig.canvasState.push(canvasAsJson);
      }

      tempConfig.currentStateIndex = tempConfig.canvasState.length - 1;

      if (
        tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
        tempConfig.currentStateIndex !== -1
      ) {
        tempConfig.redoButton = "disabled";
      }
    }
    this.props.setCanvasConfig(tempConfig);
  };

  clearCanvas = (e) => {
    e.preventDefault();
    this.props.canvas.clear();
    this.updateCanvasState();
    this.props.clearCanvas();
    const t = new window.fabric.Text("", {
      fontFamily: "Times New Roman",
      left: -10,
    });
    this.props.canvas.add(t);
  };
  clearSelection = () => {
    this.props.canvas.deactivateAll().renderAll();
    $(".text-color-pane").css({ display: "none" });
    $(".deleteBtn").css("visibility", "hidden");

    $("#bold").removeClass("active");
    $("#italic").removeClass("active");
    $("#underline").removeClass("active");
    $("#capital").removeClass("active");
    $("#left-align").removeClass("active");
    $("#center-align").removeClass("active");
    $("#right-align").removeClass("active");
    //$('.slct-font-size').val($('.slct-font-size option:first').val())
    //$('.slct-font-fam').val($('.slct-font-fam option:first').val())
    $("#line_ht_get").val(1);
    $("#line_ht_get").css({
      background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
    });
    $("#line_ht_set").val(1);
    $("#spacing_get").val(1);
    $("#spacing_get").css({
      background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
    });
    $("#spacing_set").val(1);
    $("#text-color-bind").css({ background: "black" });
    $("#size_get").val(0);
    $("#size_get").css({
      background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
    });
    $("#size_set").val(0);
    $("#outline-color-bind").css({ background: "black" });
    $("#selected_text").val("sampletext");
    $(".add-text-btn").text("Add Text");
  };
  sendQuote = ({
    fullName: name,
    email,
    phone,
    zipcode,
    subject,
    message,
    sports: sport,
  }) => {
    this.clearSelection();
    const matData = [];
    const wallpad_config = this.props.wallpad_config.config;
    const unit = this.props.wallpad_config.length_unit === "FEET" ? "ft" : "m";
    for (let i = 0; i < wallpad_config.length; i++) {
      matData[i] = {};
      console.log(wallpad_config[i]);
      // 03/06
      // matData[i].matSize = (wallpad_config[i].width * 2) + " x " + (wallpad_config[i].height * 2);
      matData[i].matSize =
        wallpad_config[i].width +
        unit +
        " x " +
        wallpad_config[i].height +
        unit;
      matData[i].matColor = wallpad_config[i].color;
      matData[i].thickness = wallpad_config[i].thickness;
      let matFonts = [];
      console.log(
        i,
        "canvas here ",
        wallpad_config[i].canvas.getObjects("curvedText").length
      );
      if (wallpad_config[i].canvas.getObjects("curvedText").length > 0) {
        let curvedTexts = wallpad_config[i].canvas.getObjects("curvedText");
        curvedTexts.forEach((item, index) => {
          console.log({ index, item });
          let color = item.fill;
          if (color.indexOf("rgb") >= 0) {
            let a = color.split("(")[1].split(")")[0];
            a = a.split(",");
            let b = a.map(function(x) {
              //For each array element
              x = parseInt(x).toString(16); //Convert to a base16 string
              return x.length == 1 ? "0" + x : x; //Add zero if we get only one character
            });
            color = "#" + b.join("");
          }
          if (item.text !== "") {
            matFonts.push({
              text: item.text,
              fontFamily: item.fontFamily,
              fill: color,
            });
          }
        });

        matData[i].text = wallpad_config[i].text;
        matData[i].font = wallpad_config[i].fontFamily;
        matData[i].fontColor = wallpad_config[i].fontColor;
      }
      matData[i].curvedTexts = matFonts;

      matData[i].sport = sport;
      matData[i].logos = wallpad_config[i].logos;
      matData[i].svg = wallpad_config[i].canvas.toSVG();
      matData[i].png = wallpad_config[i].canvas.toDataURL();
    }

    const data = {
      name,
      email,
      phone,
      zipcode,
      subject,
      message,
      matData,
    };
    axios
      .post("/api/quote", data)
      .then((res) => {
        alert("Successfully saved!");
      })
      .catch((res) => {
        alert("An unexpected error occurred. Please contact Admin");
      });
  };

  setLeftWallPad = () => {
    let index = this.props.wallpad_config.current_wallpad / 1;
    if (index === 0) index = this.props.wallpad_config["config"].length;
    index = (index - 1) % 4;
    $(".wallpad_0").css("display", "none");
    $(".wallpad_1").css("display", "none");
    $(".wallpad_2").css("display", "none");
    $(".wallpad_3").css("display", "none");
    $(".wallpad_0")
      .closest("div.canvas-container")
      .css("display", "none");
    $(".wallpad_1")
      .closest("div.canvas-container")
      .css("display", "none");
    $(".wallpad_2")
      .closest("div.canvas-container")
      .css("display", "none");
    $(".wallpad_3")
      .closest("div.canvas-container")
      .css("display", "none");

    $(`.wallpad_${index}`)
      .closest("div.canvas-container")
      .css("display", "block");
    $(`.wallpad_${index}`).css("display", "block");
    this.props.setCurrentWallPad(index);
    this.props.canvas.discardActiveObject();
  };

  setRightWallPad = () => {
    let index = this.props.wallpad_config.current_wallpad / 1;
    index = (index + 1) % this.props.wallpad_config["config"].length;
    $(".wallpad_0").css("display", "none");
    $(".wallpad_1").css("display", "none");
    $(".wallpad_2").css("display", "none");
    $(".wallpad_3").css("display", "none");
    $(".wallpad_0")
      .closest("div.canvas-container")
      .css("display", "none");
    $(".wallpad_1")
      .closest("div.canvas-container")
      .css("display", "none");
    $(".wallpad_2")
      .closest("div.canvas-container")
      .css("display", "none");
    $(".wallpad_3")
      .closest("div.canvas-container")
      .css("display", "none");
    $(`.wallpad_${index}`)
      .closest("div.canvas-container")
      .css("display", "block");
    $(`.wallpad_${index}`).css("display", "block");

    this.props.setCurrentWallPad(index);
    this.props.canvas.discardActiveObject();
  };

  showEditArtWorkDialogue = () => {
    $(".material-color-pane").css({ display: "none" });
    $(".circle-color-pane").css({ display: "none" });
    $(".text-color-pane").css({ display: "none" });
  };

  setActiveTab = (id) => {
    $("#text-custom").css("display", "none");
    $("#text-rgb").css("display", "none");
    $("#text-cmyk").css("display", "none");
    $(`#${id}`).css("display", "block");
    $(".custom-colors .active").removeClass("active");
    $(`.${id}-color`).addClass("active");
  };

  setTextColor = (color) => {
    let activeObject = this.props.canvas._activeObject;
    if (activeObject == null) return;

    if (this.props.textColorOption === "fill") {
      activeObject.set("fill", color);
    } else {
      activeObject.set("stroke", color);
    }
    this.props.canvas.renderAll();
    this.updateCanvasState();

    this.props.setCurrentWallPadConfig(
      this.props.wallpad_config.current_wallpad,
      { fontColor: color }
    );
  };

  setTextKeyColor = (e) => {
    let { value } = e.target;
    $("#key_get").val(value);
    $("#key_set").val(value);
    const black = value;
    const cyan = $("#cyan_get").val();
    const magenta = $("#magenta_get").val();
    const yellow = $("#yellow_get").val();

    const red = 255 * (1 - cyan / 100) * (1 - black / 100);
    const green = 255 * (1 - magenta / 100) * (1 - black / 100);
    const blue = 255 * (1 - yellow / 100) * (1 - black / 100);

    $("#cmyk_color").css("background", `rgb(${red}, ${green}, ${blue})`);

    const activeObject = this.props.canvas._activeObject;
    if (!activeObject) return;
    if (this.props.textColorOption === "fill") {
      activeObject.set("fill", `rgb(${red}, ${green}, ${blue})`);
    } else {
      activeObject.set("stroke", `rgb(${red}, ${green}, ${blue})`);
    }

    this.props.canvas.renderAll();
    this.updateCanvasState();
  };

  setTextMagentaColor = (e) => {
    let { value } = e.target;
    $("#magenta_get").val(value);
    $("#magenta_set").val(value);

    const magenta = value;
    const cyan = $("#cyan_get").val();
    const black = $("#key_get").val();
    const yellow = $("#yellow_get").val();

    const red = 255 * (1 - cyan / 100) * (1 - black / 100);
    const green = 255 * (1 - magenta / 100) * (1 - black / 100);
    const blue = 255 * (1 - yellow / 100) * (1 - black / 100);

    $("#cmyk_color").css("background", `rgb(${red}, ${green}, ${blue})`);

    const activeObject = this.props.canvas._activeObject;
    if (!activeObject) return;
    if (this.props.textColorOption === "fill") {
      activeObject.set("fill", `rgb(${red}, ${green}, ${blue})`);
    } else {
      activeObject.set("stroke", `rgb(${red}, ${green}, ${blue})`);
    }

    this.props.canvas.renderAll();
    this.updateCanvasState();
  };

  setTextYellowColor = (e) => {
    let { value } = e.target;
    $("#yellow_get").val(value);
    $("#yellow_set").val(value);

    const yellow = value;
    const cyan = $("#cyan_get").val();
    const black = $("#key_get").val();
    const magenta = $("#magenta_get").val();

    const red = 255 * (1 - cyan / 100) * (1 - black / 100);
    const green = 255 * (1 - magenta / 100) * (1 - black / 100);
    const blue = 255 * (1 - yellow / 100) * (1 - black / 100);

    $("#cmyk_color").css("background", `rgb(${red}, ${green}, ${blue})`);

    const activeObject = this.props.canvas._activeObject;
    if (!activeObject) return;
    if (this.props.textColorOption === "fill") {
      activeObject.set("fill", `rgb(${red}, ${green}, ${blue})`);
    } else {
      activeObject.set("stroke", `rgb(${red}, ${green}, ${blue})`);
    }

    this.props.canvas.renderAll();
    this.updateCanvasState();
  };

  setTextCyanColor = (e) => {
    let { value } = e.target;
    $("#cyan_get").val(value);
    $("#cyan_set").val(value);

    const cyan = value;
    const yellow = $("#yellow_get").val();
    const black = $("#key_get").val();
    const magenta = $("#magenta_get").val();

    const red = 255 * (1 - cyan / 100) * (1 - black / 100);
    const green = 255 * (1 - magenta / 100) * (1 - black / 100);
    const blue = 255 * (1 - yellow / 100) * (1 - black / 100);

    $("#cmyk_color").css("background", `rgb(${red}, ${green}, ${blue})`);

    const activeObject = this.props.canvas._activeObject;
    if (!activeObject) return;

    if (this.props.textColorOption === "fill") {
      activeObject.set("fill", `rgb(${red}, ${green}, ${blue})`);
    } else {
      activeObject.set("stroke", `rgb(${red}, ${green}, ${blue})`);
    }

    this.props.canvas.renderAll();
    this.updateCanvasState();
  };

  deleteSelectedMaterial = () => {
    let activeObject = this.props.canvas._activeObject;
    if (activeObject) {
      this.props.canvas.remove(activeObject);
    }
    this.updateCanvasState();
  };

  emptyClick = () => {
    if (window.innerWidth < 768) {
      document.getElementById('second-custom-right').style.display = 'none'
      document.getElementById("mySidenav1").style.width = "0"
      document.getElementById("mySidenav3").style.width = "0"
      document.getElementById("mySidenav11") && (document.getElementById("mySidenav11").style.width = "0")
    }
  }

  render() {
    const components_type = this.props.components_type;
    const wallpad_config = this.props.wallpad_config;
    const current_wallpad = wallpad_config.current_wallpad;
    let width = null;
    let height = null;
    const unit = this.props.wallpad_config.length_unit === "FEET" ? "ft" : "m";
    if (!wallpad_config.option_setting) return <OptionSettingDialogue />;
    else {
      width =
        wallpad_config["config"][current_wallpad] === undefined
          ? 0
          : wallpad_config["config"][current_wallpad].width;
      height =
        wallpad_config["config"][current_wallpad] === undefined
          ? 0
          : wallpad_config["config"][current_wallpad].height;
    }

    return (
      <>
        <div id="second-custom-right" onClick={this.emptyClick} />
        <Sidebar />
        <div className="col-md-4 col-sm-4 text-center pad0 leftpanel">
          <SidebarItems />
        </div>
        <div id="content" className="wallpad-content">
          <div className="outer">
            <div className="inner bg-light lter">
              <ExtraMenu />
              <div
                className="row ml0 mar"
                style={{ backgroundColor: "#ebf6f8" }}
              >
                <div className="text-center pad0 main-content custom-main-content">
                  <div className="main-edit-content">
                    <p className="matsize">
                      <span style={{ cursor: "pointer" }}>
                        MAT SIZE : {width + unit} x {height + unit}
                      </span>
                    </p>
                    <div className="addcontdiv">
                      <Container
                          fluid
                          className="main-content-container px-4 scroll-wall-pad">
                        <DesignCanvas>
                          {components_type.map((type, idx) => {
                            switch (type) {
                              case "Image":
                                return <Image key={idx} />;
                              default:
                                return <CurvedText key={idx} />;
                            }
                          })}
                        </DesignCanvas>
                      </Container>
                      <div style={{ paddingTop: "15px" }}>
                        <img
                          src={require("./../assets/img/angle-left.png")}
                          style={{ cursor: "pointer", marginRight: "10px" }}
                          onClick={() => this.setLeftWallPad()}
                          alt="left arrow"
                        ></img>
                        <img
                          src={require("./../assets/img/angle-right.png")}
                          style={{ cursor: "pointer" }}
                          onClick={() => this.setRightWallPad()}
                          alt="right arrow"
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const setSaveDesignID = (id) => {
  return {
    type: "SET_SAVED_ID",
    id: id,
  };
};

const setCanvasConfig = (_config) => {
  return {
    type: "SET_CONFIG",
    _config,
  };
};

const setCurrentWallPad = (index) => {
  return {
    type: "SET_CURRENT_WALLPAD",
    index,
  };
};

const setCurrentWallPadConfig = (index, config) => {
  return {
    type: "SET_CURRENT_WALLPAD",
    index,
    config,
  };
};

const clearCanvas = () => {
  return {
    type: "CLAER_CANVAS",
    isCanvasCleared: true,
  };
};

const addNewWallPad = (length) => {
  return {
    type: "SET_WALLPAD",
    length,
  };
};

const saveWallpadCanvasData = (data) => {
  return {
    type: "SAVE_WALLPAD_CANVAS_DATA",
    data,
  };
};

const mapStateToProps = (state) => {
  return {
    _config: state._config,
    components_type: [...state.components_type],
    wallpad_config: state.wallpad_config,
    canvas: state.canvas,
    textColorOption: state.textColorOption,
    saved_id: state.saved_id,
    load_design: state.load_design,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setSaveDesignID,
      setCanvasConfig,
      setCurrentWallPad,
      setCurrentWallPadConfig,
      addNewWallPad,
      saveWallpadCanvasData,
      clearCanvas,
    },
    dispatch
  );
};

const WallPad_Designer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

export default WallPad_Designer;
