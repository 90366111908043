import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import $ from "jquery";
import {Popover} from "antd";
import "antd/dist/antd.css";
import axios from "axios";
import FontFaceObserver from "fontfaceobserver";
import {VinColorHex} from "../ColorCodeHelper";
import RubberMats from "./RubberMatArray";

class MatsToolbar extends React.Component {
	state = {
		x: 0,
		y: 0,
		visible: 'hidden',
		rubberPuzzleType: 'cornerMat_0',
		rubberPuzzleTitle: '3/8" Rubber Mat Colors',
	}
	addNewRect = (e, type, color, colorCode, classType) => {
		const {puzzle_config} = this.props;
		const data = {
			mat_type: type,
			mat_color: colorCode,
			mat_class: classType
		};
		let width = 0;
		let height = 0;
		const unit = puzzle_config.length_unit === "FEET" ? 3.333 : 1.01;

		switch (type) {
			case 1:
				width = this.props.canvas_grid * 2 * unit * this.props.zoom;
				height = this.props.canvas_grid * unit * this.props.zoom;
				break;
			case 2:
				width = this.props.canvas_grid * unit * this.props.zoom;
				height = this.props.canvas_grid * 2 * unit * this.props.zoom;
				break;
			case 3:
				width = this.props.canvas_grid * unit * this.props.zoom;
				height = this.props.canvas_grid * unit * this.props.zoom;
				break;
			default:
				break;
		}

		this.props.setNewRectConfig(data);
		this.props.setDragRect(true);

		let $img = $("<img />", {
			src: require("./../../../assets/img/" + color + ".png"),
			id: "dragged_image",
			width: width,
			height: height,
			display: "none"
		});
		$("#drag_image").html($img);

		const scrollTop = document.documentElement.scrollTop / 1
		const top = e.pageY - height / 2 - scrollTop;
		const left = e.pageX - width / 2;

		$("#dragged_image").css({
			position: "fixed",
			top: top,
			left: left
		});
		let previewed_image = $("<img />", {
			src: require("./../../../assets/img/preview.png"),
			id: "previewed_image",
			width: width,
			height: height,
		});
		$("#preview_image").html(previewed_image);
		$('#previewed_image').css({
			display: 'none',
		})
		document.getElementById("mySidenav1").style.width = "0";
		document.getElementById("mySidenav2").style.width = "0";
	};

	setRubberPuzzleType = (newType, title) => {
		let puzzleType = this.props.rubber_puzzle_type;
		/*
		* add conditions on
		* newType: "squareMat_0"
		* puzzleType: "inter-locking"
		**/
		if(newType === "squareMat_0" && puzzleType === "inter-locking") {
			let canvasObjects = this.props.canvas.getObjects('group');
			if(canvasObjects.length > 0) {
				alert('Cannot place with interlocking mats')
				return;
			}
			else {
				this.props.setRubberPuzzleType('straight')
			}
		}
		if(newType !== "squareMat_0" && puzzleType === "straight") {
			let canvasObjects = this.props.canvas.getObjects('group');
			if(canvasObjects.length > 0) {
				alert('Cannot place with straight mats')
				return;
			}
			else {
				this.props.setRubberPuzzleType('inter-locking')
			}
		}

		let rubberPuzzleType = this.state.rubberPuzzleType;
		if (rubberPuzzleType !== newType) {
			this.setState({rubberPuzzleType: newType, rubberPuzzleTitle: title});
		}
	}

	addNewRubberRect = (e, type, color, colorCode, classType) => {
		const {puzzle_config} = this.props;
		const data = {
			mat_type: type,
			mat_color: colorCode,
			mat_class: classType
		};
		let width = 0;
		let height = 0;
		const unit = puzzle_config.length_unit === "FEET" ? 1.96 : 1;

		switch (type) {
			case 1:
				width = this.props.canvas_grid * 2 * unit * this.props.zoom;
				height = this.props.canvas_grid * unit * this.props.zoom;
				break;
			case 2:
				width = this.props.canvas_grid * unit * this.props.zoom;
				height = this.props.canvas_grid * 2 * unit * this.props.zoom;
				break;
			case 3:
				width = this.props.canvas_grid * unit * this.props.zoom;
				height = this.props.canvas_grid * unit * this.props.zoom;
				break;
			default:
				break;
		}

		this.props.setNewRectConfig(data);
		this.props.setDragRect(true);

		let $img = $("<img />", {
			src: require("./../../../assets/img/rubberPuzzle/" + color + ".png"),
			id: "dragged_image",
			width: width,
			height: height,
			display: "none"
		});
		$("#drag_image").html($img);

		const scrollTop = document.documentElement.scrollTop / 1
		const top = e.pageY - height / 2 - scrollTop;
		const left = e.pageX - width / 2;

		$("#dragged_image").css({
			position: "fixed",
			top: top,
			left: left
		});
		let previewed_image = $("<img />", {
			src: require("./../../../assets/img/preview.png"),
			id: "previewed_image",
			width: width,
			height: height,
		});
		$("#preview_image").html(previewed_image);
		$('#previewed_image').css({
			display: 'none',
		})
		document.getElementById("mySidenav1").style.width = "0";
		document.getElementById("mySidenav2").style.width = "0";
	};
	checkBrowser = () => {
		let check = false;
		(function (a) {
			if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
		})(navigator.userAgent || navigator.vendor || window.opera);
		return check;
	};
	_mouseOver = (e) => {
		if (this.checkBrowser()) return;
		let boundingBox = e.target.getBoundingClientRect();
		$("#tooltipmat").css({
			top: boundingBox.top + boundingBox.height,
			left: boundingBox.left + boundingBox.width,
			visibility: 'visible'
		});
	}
	_mouseOut = () => {
		if (this.checkBrowser()) return;
		$("#tooltipmat").css({visibility: 'hidden'});
	}
	_mouseOverMat = (e,title) => {
		if (this.checkBrowser()) return;
		let boundingBox = e.target.getBoundingClientRect();
		$("#tooltiprubbermat").css({
			top: boundingBox.top + boundingBox.height,
			left: boundingBox.left + boundingBox.width,
			visibility: 'visible'
		});
		$("#tooltiprubbermat").text(title)
	}
	_mouseOutMat = () => {
		if (this.checkBrowser()) return;
		$("#tooltiprubbermat").css({visibility: 'hidden'});
	}
	_mouseOverRubberType = (e,title) => {
		if (this.checkBrowser()) return;
		let boundingBox = e.target.getBoundingClientRect();
		$("#tooltipmatrubberpuzzle").css({
			top: boundingBox.top + boundingBox.height,
			left: boundingBox.left + boundingBox.width,
			visibility: 'visible'
		});
	}
	_mouseOutRubberType = (e,title) => {
		if (this.checkBrowser()) return;
		$("#tooltipmatrubberpuzzle").css({visibility: 'hidden'});
	}

	render() {
		const puzzle_config = this.props.puzzle_config;
		const mat_type = puzzle_config.type;
		const {rubberPuzzleType} = this.state;
		return (
			<>
				<div className="adddiv">
					<h2>Mats</h2>
				</div>
				{
					mat_type === 'puzzle' && (
						<div className="matlist_main">
							<ul className="matlistul">
								<li>
									<span className="mat-caption mat-view-title">Black / Charcoal Gray</span>
									<span className="puzzle-mat-images">
                            <div
	                            className="tooltip"
                            >
                              <img
	                              src={require("./../../../assets/img/Black.png")}
	                              alt="Royal Blue"
	                              style={{height: '70px'}}
	                              onDragStart={e => this.addNewRect(e, 3, "Black", "Black", "Black+CharcoalGray")}
                              />
                              </div>
                            </span>
									<span className="puzzle-mat-images">
                            <div
	                            className="tooltip"
                            >
                              <img
	                              src={require("../../../assets/img/CharcoalGray.png")}
	                              alt="Royal Blue"
	                              style={{height: '70px'}}
	                              onDragStart={e => this.addNewRect(e, 3, "CharcoalGrey", "CharcoalGrey", "Black+CharcoalGray")}
                              />
                              </div>
                            </span>
								</li>
								<li>
									<span className="mat-caption mat-view-title">Royal Blue / Red</span>
									<span className="puzzle-mat-images">
                                <div className="tooltip">
                                  <img
	                                  src={require("./../../../assets/img/Royal_Blue.png")}
	                                  alt="RED"
	                                  style={{height: '70px'}}
	                                  onDragStart={e => this.addNewRect(e, 3, "RoyalBlue", "RoyalBlue", "RoyalBlue+Red")}
                                  />
                                  </div>
                            </span>
									<span className="puzzle-mat-images">
                                <div className="tooltip">
                                  <img
	                                  src={require("./../../../assets/img/Red.png")}
	                                  alt="RED"
	                                  style={{height: '70px'}}
	                                  onDragStart={e => this.addNewRect(e, 3, "Red", "Red", "RoyalBlue+Red")}
                                  />
                                  </div>
                            </span>
								</li>
								{/*<li>
									<span className="mat-caption mat-view-title">Wood Grain / Black Tatami</span>
									<span className="puzzle-mat-images">
                            <div className="tooltip">
                              <img
	                              src={require("./../../../assets/img/Wood_Grain.png")}
	                              alt="Gold"
	                              style={{height: '70px'}}
	                              onClick={e => this.addNewRect(e, 3, "WoodGrain", "WoodGrain", "WoodGrain+BlackTatami")}
	                              onMouseOver={this._mouseOver}
	                              onMouseOut={this._mouseOut}
                              />
                              </div>
                          </span>
									<span className="puzzle-mat-images">
                            <div className="tooltip">
                              <img
	                              src={require("./../../../assets/img/Black.png")}
	                              alt="Gold"
	                              style={{height: '70px'}}
	                              onClick={e => this.addNewRect(e, 3, "Black", "BlackTatami", "WoodGrain+BlackTatami")}
	                              onMouseOver={this._mouseOver}
	                              onMouseOut={this._mouseOut}
                              />
                              </div>
                          </span>
								</li>*/}
							</ul>
						</div>
					)
				}
				{
					mat_type === 'rubber-puzzle' && (
						<div className="matlist_main">
							<ul className="matlistul">
								<div>
									<span className="mat-caption mat-view-title">Mat Types:</span>
								</div>
								<div style={{display: 'flex', padding: '0px 10px'}}>
									{
										RubberMats.map(mat => {
											let classn = 'puzzle-mat-images';
											if(rubberPuzzleType === mat.newType) classn+= ' selected-rubber-puzzle-type';

											return (
												<section id="images" className={classn} key={mat.id}
												         style={{cursor: 'pointer', padding: mat.padding}}>
													<div className="tooltip wrapper">
														<div>
															<img
																src={require(`../../../assets/img/rubberPuzzle/${mat.newType}.png`)}
																alt={mat.newType}
																style={{height: '70px'}}
																onClick={e => this.setRubberPuzzleType(mat.newType, mat.title)}
																onMouseOver={this._mouseOverRubberType}
																onMouseOut={this._mouseOutRubberType}
															/>
															<span onClick={e => this.setRubberPuzzleType(mat.newType, mat.title)} style={{fontSize:'12px',pointerEvents:'none',color:'black'}} className="text">{mat.title}</span>
														</div>
													</div>
												</section>
											)
										})
									}
								</div>
								<div style={{paddingTop: '20px'}}>
									<span className="mat-caption mat-view-title">{this.state.rubberPuzzleTitle}</span>
									{
										RubberMats.map(mat => {
											return (
												mat.items.map(item => {
													const styles = {cursor: 'pointer',padding: '0px 2px 0px 0px'};
													if(rubberPuzzleType !== mat.newType) styles.display = 'none'
													return (
														<span className="puzzle-mat-images" key={item.id}
														      style={styles}>
					                                        <div
						                                        className="tooltip"
					                                        >
					                                          <img
						                                          src={require(`../../../assets/img/rubberPuzzle/${item.color}.png`)}
						                                          alt="Royal Blue"
						                                          style={{height: '70px'}}
						                                          onDragStart={e => this.addNewRubberRect(e, item.type, item.color, item.colorCode, item.classType)}
					                                          />
					                                          </div>
					                                    </span>
													)
												})
											)
										})
									}
								</div>
							</ul>
						</div>
					)
				}
			</>
		);
	}
}

const setNewRectConfig = data => {
	return {
		type: "NEW_RECT_CONFIG",
		data: data
	};
};

const setDragRect = drag_new_rect => {
	return {
		type: "DRAG_NEW_RECT",
		drag_new_rect: drag_new_rect
	};
};
const setRubberPuzzleType =newType =>{
	return {
		type:"SET_ACTIVE_RUBBER_TYPE",
		rubber_puzzle_type:newType
	}
}

const setActiveRubberPuzzle = (active_rubber_puzzle) =>{
	return{
		type:'SET_ACTIVE_PUZZLE',
		active_rubber_puzzle
	}
}

const mapStateToProps = state => {
	return {
		components: [...state.components],
		_config: state._config,
		canvas: state.canvas,
		canvas_grid: state.canvas_grid,
		templates: state.templates,
		logo_url: state.logo_url,
		templates_width: state.templates_width,
		puzzle_templates_width: state.puzzle_templates_width,
		rubber_puzzle_type: state.rubber_puzzle_type,
		puzzle_config: state.puzzle_config,
		rubber_puzzle_templates_width: state.rubber_puzzle_templates_width,
		zoom: state.zoom,
		selectedMaterialColor: state.selectedMaterialColor,
		active_rubber_object:state.active_rubber_puzzle.activeObject ,
		active_rubber_class:state.active_rubber_puzzle.matClass,
		active_rubber_img_src:state.active_rubber_puzzle.source ,
	};
};

const mapDispatchToPropsForRect = dispatch => {
	return bindActionCreators({
		setNewRectConfig,
		setDragRect,
		setCanvasConfig,
		setSelectedMaterialColor,
		removeRect,
		setRubberPuzzleType,
		setActiveRubberPuzzle
	}, dispatch);
};

const MatsToolbarConnect = connect(
	mapStateToProps,
	mapDispatchToPropsForRect
)(MatsToolbar);

class MatsToolSidebar extends React.Component {
	closeNav1 = () => {
		document.getElementById("mySidenav1").style.width = "0";
	};

	render() {
		return (
			<>
				<div id="mats" className="h100 second-menu fordeskview">
					<MatsToolbarConnect/>
				</div>
				<div
					className="text-center h100 pad0 mtopneg sidenav1 formobview wo"
					id="mySidenav1"
				>
					<a
						style={{color: "#fff"}}
						href="#"
						className="closebtn"
						onClick={() => this.closeNav1()}
					>
						&times;
					</a>
					<MatsToolbarConnect/>
				</div>
			</>
		);
	}
}

class EditArtWorkToolbar extends React.Component {
	state = {
		x: 0,
		y: 0,
		visible: 'hidden',
	}

	setArtWorkColor = (color, image = 'Black', mat_type) => {
		let activeObject = this.props.canvas._activeObject;
		let activeGroup = this.props.canvas.getActiveGroup();
		if (activeObject) {
			let img = new Image();
			img.onload = () => {
				let height = activeObject.height;
				let width = activeObject.width;
				activeObject.setElement(img);
				activeObject.set({height, width, mat_class: mat_type, mat_image: image});
				this.props.canvas.renderAll();
			}
			img.src = require(`./../../../assets/img/${image}.png`);
			if (activeObject.oldFill) {
				activeObject.set({fill: color});
			} else {
				activeObject.set({fill: color, oldFill: color});
			}
			this.props.canvas.renderAll();
		}
		if (activeGroup) {
			let objects = activeGroup._objects;
			objects.forEach(item => {
				let img = new Image();
				img.onload = () => {
					let height = item.height;
					let width = item.width;
					item.setElement(img);
					item.set({height, width, mat_class: mat_type, mat_image: image});
					this.props.canvas.renderAll();
				}
				img.src = require(`./../../../assets/img/${image}.png`);
			})
			this.props.canvas.renderAll();
		}
		this.props.setSelectedMaterialColor(color)
		this.updateCanvasState();
	};
	invertMatColors = (e) => {
		let checked = e.target.checked;
		let activeObject = this.props.canvas.getActiveObject();
		let activeGroup = this.props.canvas.getActiveGroup();
		if (activeObject) {
			let mat_image = activeObject.mat_image;
			let mat_class = activeObject.mat_class;
			let new_image = this.findInvertColor(mat_class, mat_image);
			this.setImage(activeObject, new_image);
		}
		if (activeGroup) {
			let objects = activeGroup._objects;
			objects.forEach(object => {
				let mat_image = object.mat_image;
				let mat_class = object.mat_class;
				let new_image = this.findInvertColor(mat_class, mat_image);
				this.setImage(object, new_image);
			})
		}
	}
	setImage = (object, image) => {
		let img = new Image();
		img.onload = () => {
			let height = object.height;
			let width = object.width;
			object.setElement(img);
			object.set({height, width, mat_image: image});
			this.props.canvas.renderAll();
		}
		img.src = require(`./../../../assets/img/${image}.png`);
	}
	findInvertColor = (mat_class, mat_image) => {
		let combinationList = mat_class.split('+');
		let index = combinationList.indexOf(mat_image);
		if (index === 0) {
			return combinationList[1]
		}
		if (index === 1) {
			return combinationList[0]
		} else {
			return combinationList[0];
		}
	}
	updateCanvasState = () => {
		let tempConfig = this.props._config;
		if (tempConfig.undoStatus === false && tempConfig.redoStatus === false) {
			let jsonData = this.props.canvas.toJSON();
			let canvasAsJson = JSON.stringify(jsonData);
			if (tempConfig.currentStateIndex < tempConfig.canvasState.length - 1) {
				let indexToBeInserted = tempConfig.currentStateIndex + 1;
				tempConfig.canvasState[indexToBeInserted] = canvasAsJson;

				let numberOfElementsToRetain = indexToBeInserted + 1;
				tempConfig.canvasState = tempConfig.canvasState.splice(
					0,
					numberOfElementsToRetain
				);
			} else {
				tempConfig.canvasState.push(canvasAsJson);
			}

			tempConfig.currentStateIndex = tempConfig.canvasState.length - 1;

			if (
				tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
				tempConfig.currentStateIndex !== -1
			) {
				tempConfig.redoButton = "disabled";
			}
		}
		this.props.setCanvasConfig(tempConfig);
	};
	clearSelection = () => {
		$('.deleteBtn').css('visibility', 'hidden')
		$('.material-color-pane').css({display: 'none'})
		$('.text-color-pane').css({display: 'none'})

		$('#bold').removeClass('active')
		$('#italic').removeClass('active')
		$('#underline').removeClass('active')
		$('#capital').removeClass('active')
		$('#left-align').removeClass('active')
		$('#center-align').removeClass('active')
		$('#right-align').removeClass('active')
		$('.slct-font-size').val($('.slct-font-size option:first').val())
		$('.slct-font-fam').val($('.slct-font-fam option:first').val())
		$('#line_ht_get').val(1)
		$('#line_ht_get').css({
			background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
		})
		$('#line_ht_set').val(1)
		$('#spacing_get').val(1)
		$('#spacing_get').css({
			background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
		})
		$('#spacing_set').val(1)
		$('#text-color-bind').css({background: 'black'})
		$('#size_get').val(0)
		$('#size_get').css({
			background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
		})
		$('#size_set').val(0)
		$('#outline-color-bind').css({background: 'black'})
		$('#selected_text').val('sampletext')
		$('.add-text-btn').text('Add Text')
	}
	deleteSelectedMaterial = () => {
		let activeObject = this.props.canvas._activeObject;
		if (activeObject) {
			this.props.canvas.remove(activeObject);
			this.props.removeRect(activeObject);
		}
		let activeGroup = this.props.canvas.getActiveGroup();
		if (activeGroup) {
			let objects = activeGroup._objects;
			this.props.canvas.deactivateAll().renderAll();
			objects.forEach(item => {
				this.props.removeRect(item);
				this.props.canvas.remove(item);
			})
		}
		this.updateCanvasState();
		this.clearSelection();
		$(".second-menu").hide();
		$("#mats").show();
		$(`#menu li.nav.active`).removeClass("active");
		$(`#menu li.nav.mats`).addClass("active");
	};
	rubberPuzzleRotateClockwise = () => {
		let activeObject = this.props.canvas.getActiveObject();
		if (activeObject) {
			let rubberAngle = activeObject.rubberAngle;
			let newRubberAngle = 0;
			if (rubberAngle === 0) {
				newRubberAngle = 90;
			} else if (rubberAngle === 90) {
				newRubberAngle = 180;
			} else if (rubberAngle === 180) {
				newRubberAngle = 270;
			} else if (rubberAngle === 270) {
				newRubberAngle = 0;
			}
			activeObject.rubberAngle = newRubberAngle;
			this.props.canvas.fire('rubber-puzzle:rotated');
		}

	}
	rubberPuzzleRotateAntiClockwise = () => {
		let activeObject = this.props.canvas.getActiveObject();
		if (activeObject) {
			let rubberAngle = activeObject.rubberAngle;
			let newRubberAngle = 0;
			if (rubberAngle === 0) {
				newRubberAngle = 270;
			} else if (rubberAngle === 90) {
				newRubberAngle = 0;
			} else if (rubberAngle === 180) {
				newRubberAngle = 90;
			} else if (rubberAngle === 270) {
				newRubberAngle = 180;
			}
			activeObject.rubberAngle = newRubberAngle;
			this.props.canvas.fire('rubber-puzzle:rotated');
		}
	}
	reStoreSelectedMaterial = () => {
		let activeObject = this.props.canvas._activeObject;
		let activeGroup = this.props.canvas.getActiveGroup();
		if (activeObject) {
			if (activeObject.oldFill) {
				let fill = activeObject.oldFill;
				delete activeObject.oldFill;
				activeObject.set("fill", fill);
			} else {
				activeObject.set("fill", this.props.selectedMaterialColor);
			}
			this.props.canvas.renderAll();
		}
		if (activeGroup) {
			let objects = activeGroup._objects;
			objects.forEach(item => {
				if (item.oldFill) {
					let fill = item.oldFill;
					delete item.oldFill;
					item.set({fill: fill});
				}
			})
			this.props.canvas.renderAll();
		}
		this.updateCanvasState();
	};

	render() {
		const activeColor = this.props.selectedMaterialColor;
		const puzzleConfig = this.props.puzzle_config;
		const puzzleType = this.props.puzzle_config.type// === 'rubber-puzzle'
		return (
			<>
				<div className="adddiv">
					<h2>EDIT ART WORK</h2>
				</div>
				<div className="templatesmain color-lft-blk-menu">
					<div className="row ">
						<div className="col-md-12">
							<div className="preset-color-row">
								<div
									className="pre-clr-box clr-pr1 tooltip"
									style={{backgroundColor: activeColor}}
								>
								</div>
							</div>
						</div>
						<div className="col-md-12 mt-15">
							<ul className="nav nav-tabs nav-justified select-color-tab">
								<li>
									<a data-toggle="tab" href="#presets">
										MAT COLORS
									</a>
								</li>
							</ul>
							<div className="tab-content slct-clr-tb-content">
								<div id="presets" className="tab-pane fade in active">
									<div className="preset-color-row">
										<div
											className="pre-clr-box clr-pr1 tooltip"
											onClick={() => this.setArtWorkColor(VinColorHex.RoyalBlue, 'RoyalBlue', 'RoyalBlue+Red')}
										>
											<span className="tooltiptext">Royal Blue</span>
										</div>
										<div
											className="pre-clr-box clr-pr2 tooltip"
											onClick={() => this.setArtWorkColor(VinColorHex.Red, 'Red', 'RoyalBlue+Red')}
										>
											<span className="tooltiptext">Red</span>
										</div>
										<div
											className="pre-clr-box sandalwood-4 tooltip"
											onClick={() => this.setArtWorkColor(VinColorHex.Black, 'WoodGrain', 'WoodGrain+BlackTatami')}
										>
											<span className="tooltiptext">Wood Grain</span>
										</div>
									</div>
									<div className="preset-color-row">
										<div
											className="pre-clr-box clr-pr4 tooltip"
											onClick={() => this.setArtWorkColor(VinColorHex.Black, 'Black', 'Black+CharcoalGray')}
										>
											<span className="tooltiptext">Black</span>
										</div>
										<div
											className="pre-clr-box clr-pr21 tooltip"
											onClick={() => this.setArtWorkColor(VinColorHex.Gray, 'CharcoalGrey', 'Black+CharcoalGray')}
										>
											<span className="tooltiptext">Charcoal Gray</span>
										</div>
										{/*<div className="pre-clr-box clr-pr1 tooltip" style="visibility: hidden;"></div>*/}
										<div
											className="pre-clr-box clr-pr4 tooltip"
											onClick={() => this.setArtWorkColor(VinColorHex.Black, 'Black', 'WoodGrain+BlackTatami')}
										>
											<span className="tooltiptext">Tatami Black</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{
						puzzleType === 'rubber-puzzle' && (
							<div className='d-flex justify-content-center'>
                                <span className="btn col-md-2"
                                      style={{border: '1px solid white', margin: '10px 0px 10px 0px'}}>
                                    <a className='custom-side-nav1'
                                       onClick={() => this.rubberPuzzleRotateAntiClockwise()}>
                                      <img src={require("./../../../assets/img/undo.png")} alt="redo"
                                           style={{height: '20px', width: '18px'}}/>
                                    </a>
                                </span>
								<span className="btn col-md-2"
								      style={{border: '1px solid white', margin: '10px 10px 10px 10px'}}>
                                    <a className='custom-side-nav1' onClick={() => this.rubberPuzzleRotateClockwise()}>
                                      <img src={require("./../../../assets/img/redo.png")} alt="redo"
                                           style={{height: '20px', width: '18px'}}/>
                                    </a>
                                </span>
							</div>
						)
					}
					<div className='d-flex justify-content-center'>
                        <span className="btn col-md-2"
                              style={{border: '1px solid white', margin: '10px 10px 10px 10px'}}>
                        <a className='custom-side-nav1' onClick={() => this.reStoreSelectedMaterial()}>
                          <img src={require("./../../../assets/img/redo.png")} alt="redo"
                               style={{height: '20px', width: '18px'}}/>
                        </a>
                      </span>
						<span className="btn col-md-2"
						      style={{border: '1px solid white', margin: '10px 10px 10px 0px'}}>
                            <a className='custom-side-nav1' onClick={() => this.deleteSelectedMaterial()}>
                              <img src={require("./../../../assets/img/del.png")} alt="del"
                                   style={{height: '20px', width: '18px'}}/>
                            </a>
                      </span>
					</div>
					<div className='d-flex justify-content-center' style={{marginTop: '20px'}}>
						<div>
							<input type="checkbox" id="mat-invert-color" name="mat-invert-color"
							       onChange={this.invertMatColors}/>
							<label htmlFor="mat-invert-color" style={{padding: '10px'}}>Reverse mats</label>
						</div>
					</div>
				</div>
			</>
		)
	}
}

const EditArtWorkToolbarConnect = connect(
	mapStateToProps,
	mapDispatchToPropsForRect
)(EditArtWorkToolbar);

class EditArtWorkSidebar extends React.Component {
	closeNav1 = () => {
		document.getElementById("mySidenav5").style.width = "0";
	};

	render() {
		return (
			<>
				<div id="artwork" className="h100 second-menu fordeskview">
					<EditArtWorkToolbarConnect/>
				</div>
				<div
					className="text-center h100 pad0 mtopneg sidenav1 formobview wo"
					id="mySidenav5"
				>
					<a
						style={{color: "#fff"}}
						href="#"
						className="closebtn"
						onClick={() => this.closeNav1()}
					>
						&times;
					</a>
					<EditArtWorkToolbarConnect/>
				</div>
			</>
		)
	}
}

class EditRubberArtWork extends React.Component {

	setRubberPuzzleColor=(mat_class,mat_color)=>{
		let activeObject = this.props.active_rubber_object;
		if(activeObject.name && activeObject.name==='activeSelection') {
			this.setRubberPuzzleSelection(mat_class,mat_color);
			return;
		}
		let img = new Image();
		// let activeObject = this.props.canvas.getActiveObject();
		// let activeSelection = this.props.canvas.getActiveObject();
		img.onload = ()=>{
			let zoom = this.props.canvas.getZoom();
			if(!activeObject.name) {
				let imageObj = activeObject.getObjects()[0];
				let scaledHeight = imageObj.height * imageObj.scaleY ,
					scaledWidth = imageObj.width * imageObj.scaleX;
				imageObj.setElement(img, () => {
					imageObj.set({mat_color, mat_class,height:scaledHeight,width:scaledWidth,mat_image:mat_color})
					let source = imageObj.getSrc();
					let matClass = imageObj.mat_class;
					this.props.setActiveRubberPuzzle({activeObject,matClass,source});
					this.props.canvas.renderAll();
				})
			}
		}
		img.src = require(`./../../../assets/img/rubberPuzzle/${mat_color}.png`);
	}
	setRubberPuzzleSelection=(mat_class,mat_color)=>{
		let activeSelection = this.props.active_rubber_object;
		let zoom = this.props.canvas.getZoom();
		let objects = activeSelection.getObjects();
		objects.forEach(activeObj=>{
			let imageObj = activeObj.getObjects()[0];
			let mat_color_org_arr = imageObj.mat_image.match(/([A-Z]?[^A-Z]*)/g).slice(0,-1);
			let mat_class_org_arr = imageObj.mat_class.match(/([A-Z]?[^A-Z]*)/g).slice(0,-1);
			let mat_color_arr = mat_color.match(/([A-Z]?[^A-Z]*)/g).slice(0,-1);
			let mat_class_arr = mat_class.match(/([A-Z]?[^A-Z]*)/g).slice(0,-1);
			let img = new Image();
			let final_mat_class = mat_class,
				final_mat_image = mat_color;
			img.onload = ()=> {
				let scaledHeight = imageObj.height * imageObj.scaleY ,
					scaledWidth = imageObj.width * imageObj.scaleX ;
				imageObj.setElement(img, () => {
					//imageObj.scaleToHeight(scaledHeight);
					//imageObj.scaleToWidth(scaledWidth);
					console.log({final_mat_class,final_mat_image})
					imageObj.set({mat_color:final_mat_image,height:scaledHeight,width:scaledWidth, mat_class:final_mat_class,mat_image:final_mat_image})
					let source = imageObj.getSrc();
					let matClass = imageObj.mat_class;
					this.props.setActiveRubberPuzzle({activeObject:activeSelection,matClass:mat_class,source});
					this.props.canvas.renderAll();
				})
			}
			mat_color_arr[0] = mat_color_org_arr[0];
			mat_class_arr[0] = mat_class_org_arr[0];
			final_mat_image = mat_color_arr.join('');
			final_mat_class = mat_class_arr.join('');
			img.src = require(`./../../../assets/img/rubberPuzzle/${final_mat_image}.png`);
		})
	}

	deleteSelectedMaterial = () => {
		let activeObject = this.props.canvas._activeObject;
		if (activeObject) {
			this.props.canvas.remove(activeObject);
			this.props.removeRect(activeObject);
		}
		let activeGroup = this.props.canvas.getActiveGroup();
		if (activeGroup) {
			let objects = activeGroup._objects;
			this.props.canvas.deactivateAll().renderAll();
			objects.forEach(item => {
				this.props.removeRect(item);
				this.props.canvas.remove(item);
			})
		}
		this.updateCanvasState();
		//this.clearSelection();
		$(".second-menu").hide();
		$("#mats").show();
		$(`#menu li.nav.active`).removeClass("active");
		$(`#menu li.nav.mats`).addClass("active");
	};
	rubberPuzzleRotateClockwise = () => {
		let activeObject = this.props.canvas.getActiveObject();
		if (activeObject) {
			let rubberAngle = activeObject.rubberAngle;
			let newRubberAngle = 0;
			if (rubberAngle === 0) {
				newRubberAngle = 90;
			} else if (rubberAngle === 90) {
				newRubberAngle = 180;
			} else if (rubberAngle === 180) {
				newRubberAngle = 270;
			} else if (rubberAngle === 270) {
				newRubberAngle = 0;
			}
			activeObject.rubberAngle = newRubberAngle;
			this.props.canvas.fire('rubber-puzzle:rotated');
		}

	}
	rubberPuzzleRotateAntiClockwise = () => {
		let activeObject = this.props.canvas.getActiveObject();
		if (activeObject) {
			let rubberAngle = activeObject.rubberAngle;
			let newRubberAngle = 0;
			if (rubberAngle === 0) {
				newRubberAngle = 270;
			} else if (rubberAngle === 90) {
				newRubberAngle = 0;
			} else if (rubberAngle === 180) {
				newRubberAngle = 90;
			} else if (rubberAngle === 270) {
				newRubberAngle = 180;
			}
			activeObject.rubberAngle = newRubberAngle;
			this.props.canvas.fire('rubber-puzzle:rotated');
		}
	}
	reStoreSelectedMaterial = () => {
		let activeObject = this.props.canvas._activeObject;
		let activeGroup = this.props.canvas.getActiveGroup();
		if (activeObject) {
			if (activeObject.oldFill) {
				let fill = activeObject.oldFill;
				delete activeObject.oldFill;
				activeObject.set("fill", fill);
			} else {
				activeObject.set("fill", this.props.selectedMaterialColor);
			}
			this.props.canvas.renderAll();
		}
		if (activeGroup) {
			let objects = activeGroup._objects;
			objects.forEach(item => {
				if (item.oldFill) {
					let fill = item.oldFill;
					delete item.oldFill;
					item.set({fill: fill});
				}
			})
			this.props.canvas.renderAll();
		}
		this.updateCanvasState();
	};
	updateCanvasState = () => {
		let tempConfig = this.props._config;
		if (tempConfig.undoStatus === false && tempConfig.redoStatus === false) {
			let jsonData = this.props.canvas.toJSON();
			let canvasAsJson = JSON.stringify(jsonData);
			if (tempConfig.currentStateIndex < tempConfig.canvasState.length - 1) {
				let indexToBeInserted = tempConfig.currentStateIndex + 1;
				tempConfig.canvasState[indexToBeInserted] = canvasAsJson;

				let numberOfElementsToRetain = indexToBeInserted + 1;
				tempConfig.canvasState = tempConfig.canvasState.splice(
					0,
					numberOfElementsToRetain
				);
			} else {
				tempConfig.canvasState.push(canvasAsJson);
			}

			tempConfig.currentStateIndex = tempConfig.canvasState.length - 1;

			if (
				tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
				tempConfig.currentStateIndex !== -1
			) {
				tempConfig.redoButton = "disabled";
			}
		}
		this.props.setCanvasConfig(tempConfig);
	};
	render() {
		const activeColor = this.props.selectedMaterialColor;
		const puzzleConfig = this.props.puzzle_config;
		const puzzleType = this.props.puzzle_config.type// === 'rubber-puzzle'
		const activeRubberImage = this.props.active_rubber_img_src;
		const activeRubberClass = this.props.active_rubber_class;

		let selectedMatImages = RubberMats.find(mat => mat.newType === activeRubberClass);
		if(!selectedMatImages) selectedMatImages = {items: []};
		let matTitle ='';
		if(this.props.canvas && this.props.canvas.getActiveObject()) {
			let activeObject = this.props.canvas.getActiveObject();
			let imageObject = activeObject.getObjects()[0],
				matClass = imageObject.mat_class,
				matData = imageObject.mat_image,
				matImage = imageObject.mat_image;
			matClass = matClass.split('_')[0];
			matTitle = matImage.split(matClass)[1];
			matTitle = matTitle.match(/[a-z]+|\d+/ig);
			matTitle = matTitle.join(' ')+'%';
		}

		return (
			<>
				<div className="adddiv">
					<h2>EDIT MATS</h2>
				</div>
				<div className="templatesmain color-lft-blk-menu">
					<div className="row ">
						<div className="col-md-12">
							<div className="preset-color-row">
								<span className={'mat-caption mat-view-title'}>{matTitle}</span>
							</div>
							<div className="preset-color-row">
								<div
									className="pre-clr-box clr-pr1 tooltip mat-image"
									style={{
										// background: `url(/static/media/WoodGrain.fad7495d.png)`,
										background: `url(${activeRubberImage})`,
									}}
								>
								</div>
							</div>
						</div>
						<div className="col-md-12 mt-15">
							<ul className="nav nav-tabs nav-justified select-color-tab" style={{backgroundColor:'#2a3f54'}}>
								<li>
									<a data-toggle="tab" href="#presets" style={{backgroundColor:'#2a3f54'}}>
										MAT COLORS
									</a>
								</li>
							</ul>
							<div className="tab-content slct-clr-tb-content">
								<div id="presets" className="tab-pane fade in active">
									<div className="preset-color-row wrap-rubber-puzzle">
										{
											selectedMatImages.items.map(item=>{
												const classes = `pre-clr-box clr-pr1 tooltip mat-image ${item.color}`;
												return (
													<div
														className={classes}
														key = {item.id}
														onClick={() => this.setRubberPuzzleColor(item.classType,item.color)}
													>
														<span className="tooltiptext">{item.title}</span>
													</div>
												)
											})
										}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='d-flex justify-content-center'>
						<span className="btn col-md-2" style={{border: '1px solid white', margin: '10px 0px 10px 0px'}}>
							<a className='custom-side-nav1' onClick={() => this.rubberPuzzleRotateAntiClockwise()}>
								<img src={require("./../../../assets/img/rotation_left.png")} alt="redo" />
							</a>
						</span>
						<span className="btn col-md-2" style={{border: '1px solid white', margin: '10px 10px 10px 10px'}}>
							<a className='custom-side-nav1' onClick={() => this.rubberPuzzleRotateClockwise()}>
								<img src={require("./../../../assets/img/rotation_right.png")} alt="redo" />
							</a>
						</span>
                        <span className="btn col-md-2"
                              style={{border: '1px solid white', margin: '10px 10px 10px 10px'}}>
                        <a className='custom-side-nav1' onClick={() => this.reStoreSelectedMaterial()}>
                          <img src={require("./../../../assets/img/redo.png")} alt="redo" />
                        </a>
                      </span>
						<span className="btn col-md-2"
						      style={{border: '1px solid white', margin: '10px 10px 10px 0px'}}>
                            <a className='custom-side-nav1' onClick={() => this.deleteSelectedMaterial()}>
                              <img src={require("./../../../assets/img/del.png")} alt="del" />
                            </a>
                      </span>
					</div>
				</div>
			</>
		)
	}
}
const EditRubberArtWorkConnect = connect(
	mapStateToProps,
	mapDispatchToPropsForRect
)(EditRubberArtWork);

class EditRubberArtWorkSidebar extends React.Component {
	closeNav1 = () => {
		document.getElementById("mySidenav5").style.width = "0";
	};

	render() {
		return (
			<>
				<div id="artwork" className="h100 second-menu fordeskview">
					<EditRubberArtWorkConnect/>
				</div>
				<div
					className="text-center h100 pad0 mtopneg sidenav1 formobview wo"
					id="mySidenav5"
				>
					<a
						style={{color: "#fff"}}
						href="#"
						className="closebtn"
						onClick={() => this.closeNav1()}
					>
						&times;
					</a>
					<EditRubberArtWorkConnect/>
				</div>
			</>
		)
	}
}

class TemplatesToolbar extends React.Component {
	state = {
		rubberPuzzleType: 'cornerMat_0',
		rubberPuzzleTitle: '3/8" Rubber Mat Colors',
	}
	addNewTemplate = (e, templateImage, mat_class, mat_image = "RoyalBlue",template_1_width,template_1_height) => {
		const {puzzle_config} = this.props;
		const unit = puzzle_config.length_unit === "FEET" ? 3.333 : 1.01;
		if(puzzle_config.width < template_1_width*unit || puzzle_config.height < template_1_height*unit){
			alert('Cannot place big design on smaller design area');
			return;
		}
		let template_type = e.target.alt;
		this.props.setNewTemplateConfig(template_type);
		this.props.setDragTemplate(true);
		this.props.setMatClass(mat_class);
		const templates_width = this.props.puzzle_templates_width;

		const width = templates_width[template_type].width * this.props.canvas_grid * unit * this.props.zoom;
		const height = templates_width[template_type].height * this.props.canvas_grid * unit * this.props.zoom;
		let img_template = $("<img />", {
			src: require("./../../../assets/img/" + templateImage + ".png"),
			id: "dragged_template",
			width,
			height,
			display: "none"
		});
		$("#drag_template").html(img_template);
		const top = e.pageY - height / 2;
		const left = e.pageX - width / 2;
		$("#dragged_template").css({
			position: "fixed",
			top: top,
			left: left
		});
		let previewed_image = $("<img />", {
			src: require("./../../../assets/img/preview.png"),
			id: "previewed_image",
			width: width,
			height: height,
			display: "none"
		});
		$("#preview_image").html(previewed_image);
		$('#previewed_image').css({
			display: 'none',
		})
		document.getElementById("mySidenav1").style.width = "0";
		document.getElementById("mySidenav2").style.width = "0";
		document.getElementById("mySidenav3") && (document.getElementById("mySidenav3").style.width = "0");
		document.getElementById("mySidenav4") && (document.getElementById("mySidenav4").style.width = "0");
	};

	addNewRubberTemplate = (e, templateImage, mat_class, mat_image = "RoyalBlue",template_1_width,template_1_height) => {
		const {puzzle_config} = this.props;
		const unit = puzzle_config.length_unit === "FEET" ? 2 : 1;
		if(puzzle_config.width < template_1_width*unit || puzzle_config.height < template_1_height*unit){
			alert('Cannot place big design on smaller design area');
			return;
		}
		let template_type = e.target.alt || mat_image;
		this.props.setNewTemplateConfig(template_type);
		this.props.setDragTemplate(true);
		this.props.setMatClass(mat_class);
		const templates_width = this.props.rubber_puzzle_templates_width;

		const width = templates_width[template_type].width * this.props.canvas_grid * unit * this.props.zoom;
		const height = templates_width[template_type].height * this.props.canvas_grid * unit * this.props.zoom;

		let img_template = $("<img />", {
			src: require("./../../../assets/img/" + templateImage + ".png"),
			id: "dragged_template",
			width,
			height,
			display: "none"
		});
		$("#drag_template").html(img_template);
		const top = e.pageY - height / 2;
		const left = e.pageX - width / 2;
		$("#dragged_template").css({
			position: "fixed",
			top: top,
			left: left
		});
		let previewed_image = $("<img />", {
			src: require("./../../../assets/img/preview.png"),
			id: "previewed_image",
			width: width,
			height: height,
			display: "none"
		});
		$("#preview_image").html(previewed_image);
		$('#previewed_image').css({
			display: 'none',
		})
		document.getElementById("mySidenav1").style.width = "0";
		document.getElementById("mySidenav2").style.width = "0";
		document.getElementById("mySidenav3") && (document.getElementById("mySidenav3").style.width = "0");
		document.getElementById("mySidenav4") && (document.getElementById("mySidenav4").style.width = "0");
	};


	checkBrowser = () => {
		let check = false;
		(function (a) {
			if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
		})(navigator.userAgent || navigator.vendor || window.opera);
		return check;
	};
	_mouseOver = (e) => {
		if (this.checkBrowser()) return;
		let boundingBox = e.target.getBoundingClientRect();
		$("#tooltiptemplate").css({
			top: boundingBox.top + boundingBox.height,
			left: boundingBox.left + boundingBox.width,
			visibility: 'visible'
		});
	}
	_mouseOut = () => {
		if (this.checkBrowser()) return;
		$("#tooltiptemplate").css({visibility: 'hidden'});
	}

	setRubberPuzzleType = (newType, title) => {
		let puzzleType = this.props.rubber_puzzle_type;
		/*
		* add conditions on
		* newType: "squareMat_0"
		* puzzleType: "inter-locking"
		**/
		if(newType === "squareMat_0" && puzzleType === "inter-locking") {
			let canvasObjects = this.props.canvas.getObjects('group');
			if(canvasObjects.length > 0) {
				alert('Cannot place with interlocking mats')
				return;
			}
			else {
				this.props.setRubberPuzzleType('straight')
			}
		}
		if(newType !== "squareMat_0" && puzzleType === "straight") {
			let canvasObjects = this.props.canvas.getObjects('group');
			if(canvasObjects.length > 0) {
				alert('Cannot place with straight mats')
				return;
			}
			else {
				this.props.setRubberPuzzleType('inter-locking')
			}
		}

		let rubberPuzzleType = this.state.rubberPuzzleType;
		if (rubberPuzzleType !== newType) {
			this.setState({rubberPuzzleType: newType, rubberPuzzleTitle: title});
		}
	}
	_mouseOverRubberType = (e,title) => {
		if (this.checkBrowser()) return;
		let boundingBox = e.target.getBoundingClientRect();
		$("#tooltipmatrubberpuzzle").css({
			top: boundingBox.top + boundingBox.height,
			left: boundingBox.left + boundingBox.width,
			visibility: 'visible'
		});
	}
	_mouseOutRubberType = (e,title) => {
		if (this.checkBrowser()) return;
		$("#tooltipmatrubberpuzzle").css({visibility: 'hidden'});
	}
	setRubberPuzzleType = (newType, title) => {
		let puzzleType = this.props.rubber_puzzle_type;
		/*
		* add conditions on
		* newType: "squareMat_0"
		* puzzleType: "inter-locking"
		**/
		if(newType === "squareMat_0" && puzzleType === "inter-locking") {
			let canvasObjects = this.props.canvas.getObjects('group');
			if(canvasObjects.length > 0) {
				alert('Cannot place with interlocking mats')
				return;
			}
			else {
				this.props.setRubberPuzzleType('straight')
			}
		}
		if(newType !== "squareMat_0" && puzzleType === "straight") {
			let canvasObjects = this.props.canvas.getObjects('group');
			if(canvasObjects.length > 0) {
				alert('Cannot place with straight mats')
				return;
			}
			else {
				this.props.setRubberPuzzleType('inter-locking')
			}
		}

		let rubberPuzzleType = this.state.rubberPuzzleType;
		if (rubberPuzzleType !== newType) {
			this.setState({rubberPuzzleType: newType, rubberPuzzleTitle: title});
		}
	}
	render() {

		const puzzle_config = this.props.puzzle_config;
		const mat_type = puzzle_config.type;
		const puzzle_type = this.props.rubber_puzzle_type;
		const title = mat_type === 'rubber-puzzle' ? 'Rubber Templates':'Templates'
		return (
			<>
				<div className="adddiv">
					<h2>{title}</h2>
				</div>
				{
					mat_type === 'puzzle' && (
						<div className="templatesmain">
							<div className="col-md-6 col-sm-6">
								<div className="temrows">
									<div>
										<img
											src={require("./../../../assets/img/1x3.png")}
											alt="template1"
											onDragStart={(e) => {
												this.addNewTemplate(e, "1x3", "RoyalBlue+Red","RoyalBlue",1,3)
											}}
											style={{height: "121px", width: "50px"}}
										/>
									</div>

								</div>
							</div>
							<div className="col-md-6 col-sm-6">
								<div className="temrows">
									<div>
										<img
											src={require("./../../../assets/img/3x1.png")}
											alt="template2"
											onDragStart={(e) => {
												this.addNewTemplate(e, "3x1", "RoyalBlue+Red","RoyalBlue",1,3)
											}}
											style={{width: "121px", height: "50px"}}
										/>
									</div>
								</div>
							</div>
							<div className="col-md-6 col-sm-6">
								<div className="temrows">
									<div>
										<img
											src={require("./../../../assets/img/3x2.png")}
											alt="template3"
											onDragStart={(e) => {
												this.addNewTemplate(e, "3x2", "RoyalBlue+Red","RoyalBlue",3,2)
											}}
											style={{width: "121px", height: "70px"}}
										/>
									</div>

								</div>
							</div>
							<div className="col-md-6 col-sm-6">
								<div className="temrows">
									<div>
										<img
											src={require("./../../../assets/img/2x3.png")}
											alt="template4"
											onDragStart={(e) => {
												this.addNewTemplate(e, "2x3", "RoyalBlue+Red","RoyalBlue",2,3)
											}}
											style={{width: "90px", height: "121px"}}
										/>
									</div>

								</div>
							</div>
							<div className="col-md-6 col-sm-6">
								<div className="temrows">
									<div>
										<img
											src={require("./../../../assets/img/3x3.png")}
											alt="template5"
											onDragStart={(e) => {
												this.addNewTemplate(e, "3x3", "RoyalBlue+Red","RoyalBlue",3,3)
											}}
											style={{width: "121px", height: "121px"}}
										/>
									</div>
								</div>
							</div>
						</div>
					)
				}
				{
					mat_type === 'rubber-puzzle' && (
						<div className="matlist_main">
							<ul>
								<div>
									<span className="mat-caption mat-view-title">Select a type:</span>
								</div>
								<div style={{display: 'flex', padding: '0px 10px'}}>
									{
										RubberMats.map(mat => {
											let classn = 'puzzle-mat-images';
											if(this.state.rubberPuzzleType === mat.newType) classn += ' selected-rubber-puzzle-type';

											return (
												<section id="images" className={classn} key={mat.id}
												      style={{cursor: 'pointer', padding: mat.padding}}>
			                                        <div className="tooltip wrapper">
			                                          <div>
				                                          <img
					                                          src={require(`../../../assets/img/rubberPuzzle/${mat.newType}.png`)}
					                                          alt={mat.newType}
					                                          style={{height: '70px'}}
					                                          onClick={e => this.setRubberPuzzleType(mat.newType, mat.title)}
					                                          onMouseOver={this._mouseOverRubberType}
					                                          onMouseOut={this._mouseOutRubberType}
				                                          />
				                                          <span onClick={e => this.setRubberPuzzleType(mat.newType, mat.title)} style={{fontSize:'12px',pointerEvents:'none',color:'black'}} className="text">{mat.title}</span>
			                                          </div>
			                                        </div>
			                                    </section>
											)
										})
									}
								</div>

							</ul>
							{
								mat_type === 'rubber-puzzle' && puzzle_type === 'inter-locking' && (
									<div style={{paddingTop:'20px'}}>
										<div className="col-md-6 col-sm-6">
											<section id={'images'} className="temrows">
												<div className={'wrapper'}>
													<div>
														<img
															src={require("./../../../assets/img/5x5-rubber.png")}
															alt="templateRubber1"
															onDragStart={(e) => {
																this.addNewRubberTemplate(e, "5x5-rubber", "RoyalBlue+Red","templateRubber1",5,5)
															}}
															style={{cursor: 'pointer',width: "121px", height: "121px"}}
														/>
														<span onClick={e => this.addNewRubberTemplate(e, "5x5-rubber", "RoyalBlue+Red","templateRubber1",5,5)} style={{fontSize:'12px',pointerEvents:'none'}} className="text">10ftx10ft template each 2ft INTER-LOCKING</span>
													</div>
												</div>
											</section>
										</div>

										<div className="col-md-6 col-sm-6" >
											<section id={'images'} className="temrows">
												<div className={'wrapper'}>
													<div>
														<img
															src={require("./../../../assets/img/7x7-rubber.png")}
															alt="templateRubber4"
															onDragStart={(e) => {
																this.addNewRubberTemplate(e, "7x7-rubber", "RoyalBlue+Red","templateRubber4",7,7)
															}}
															style={{cursor: 'pointer',width: "121px", height: "121px"}}
														/>
														<span onClick={e => this.addNewRubberTemplate(e, "7x7-rubber", "RoyalBlue+Red","templateRubber4",7,7)} style={{fontSize:'12px',pointerEvents:'none'}} className="text">14ftx14ft template each 2ft INTER-LOCKING</span>
													</div>
												</div>
											</section>
										</div>

										<div className="col-md-6 col-sm-6">
											<section id={'images'} className="temrows">
												<div className={'wrapper'}>
													<div>
														<img
															src={require("./../../../assets/img/9x9-rubber.png")}
															alt="templateRubber5"
															onDragStart={(e) => {
																this.addNewRubberTemplate(e, "9x9-rubber", "RoyalBlue+Red","templateRubber5",9,9)
															}}
															style={{cursor: 'pointer',width: "121px", height: "121px"}}
														/>
														<span onClick={e => this.addNewRubberTemplate(e, "9x9-rubber", "RoyalBlue+Red","templateRubber5",9,9)} style={{fontSize:'12px',pointerEvents:'none'}} className="text">18ftx18ft template each 2ft INTER-LOCKING</span>
													</div>
												</div>
											</section>
										</div>
										<div className="col-md-6 col-sm-6">
											<section id={'images'} className="temrows">
												<div className={'wrapper'}>
													<div>
														<img
															src={require("./../../../assets/img/9x9-rubber.png")}
															alt="templateRubber6"
															onDragStart={(e) => {
																this.addNewRubberTemplate(e, "10x10-rubber", "RoyalBlue+Red","templateRubber6",10,10)
															}}
															style={{cursor: 'pointer',width: "121px", height: "121px"}}
														/>
														<span onClick={e => this.addNewRubberTemplate(e, "10x10-rubber", "RoyalBlue+Red","templateRubber6",10,10)} style={{fontSize:'12px',pointerEvents:'none'}} className="text">20ftx20ft template each 2ft INTER-LOCKING</span>
													</div>
												</div>
											</section>
										</div>
									</div>
								)
							}
							{
								mat_type === 'rubber-puzzle' && puzzle_type !== 'inter-locking' && (
									<div style={{paddingTop:'20px'}}>
										<div className="col-md-6 col-sm-6">
											<section id={'images'} className="temrows"
											         onMouseOver={this._mouseOver}
											         onMouseOut={this._mouseOut}>
												<div className={'wrapper'}>
													<div>
														<img
															src={require("./../../../assets/img/12x12-3.png")}
															alt="templateRubber3"
															onClick={(e) => {
																this.addNewRubberTemplate(e, "12x12-3", "RoyalBlue+Red","templateRubber3",10,10)
															}}
															style={{cursor: 'pointer',width: "121px", height: "121px"}}
														/>
														<span onClick={e => this.addNewRubberTemplate(e, "12x12-3", "RoyalBlue+Red","templateRubber3",10,10)} style={{fontSize:'12px',pointerEvents:'none'}} className="text">20ftx20ft template each 2ft SQUARE</span>
													</div>
												</div>
											</section>
										</div>
										<div className="col-md-6 col-sm-6">
											<section id={'images'} className="temrows"
											         onMouseOver={this._mouseOver}
											         onMouseOut={this._mouseOut}>
												<div className={'wrapper'}>
													<div>
														<img
															src={require("./../../../assets/img/12x12-3.png")}
															alt="templateRubber2"
															onClick={(e) => {
																this.addNewRubberTemplate(e, "12x12-3", "RoyalBlue+Red","templateRubber2",5,5)
															}}
															style={{cursor: 'pointer',width: "121px", height: "121px"}}
														/>
														<span onClick={e => this.addNewRubberTemplate(e, "12x12-3", "RoyalBlue+Red","templateRubber2",5,5)} style={{fontSize:'12px',pointerEvents:'none'}} className="text">10ftx10ft template each 2ft SQUARE</span>
													</div>
												</div>
											</section>
										</div>
									</div>
								)
							}
						</div>
					)
				}


			</>
		);
	}
}

const setDragTemplate = drag_new_template => {
	return {
		type: "DRAG_NEW_TEMPLATE",
		drag_new_template: drag_new_template
	};
};

const setMatClass = (mat_class) => {
	return {
		type: "SET_TEMPLATE_CLASS",
		mat_class,
	}
}

const setNewTemplateConfig = template_type => {
	return {
		type: "NEW_TEMPLATE_CONFIG",
		template_type: template_type
	};
};

const mapDispatchToPropsForTemplate = dispatch => {
	return bindActionCreators(
		{setNewTemplateConfig, setDragTemplate, setMatClass,setRubberPuzzleType},
		dispatch
	);
};

const TemplatesToolbarConnect = connect(
	mapStateToProps,
	mapDispatchToPropsForTemplate
)(TemplatesToolbar);

class TemplateToolSidebar extends React.Component {
	closeNav2 = () => {
		document.getElementById("mySidenav2").style.width = "0";
	};

	render() {
		return (
			<>
				<div
					id="template"
					className="h100 second-menu fordeskview"
					style={{display: "none"}}
				>
					<TemplatesToolbarConnect/>
				</div>
				<div
					className="text-center h100 pad0 mtopneg sidenav2 formobview wo"
					id="mySidenav2"
				>
					<a
						style={{color: "#fff"}}
						href="#"
						className="closebtn"
						onClick={() => this.closeNav2()}
					>
						&times;
					</a>
					<TemplatesToolbarConnect/>
				</div>
			</>
		);
	}
}

const setCanvasConfig = _config => {
	return {
		type: "SET_CONFIG",
		_config
	};
};
const removeRect = (data) => {
	return {
		type: "REMOVE_RECT",
		data,
	};
};
const setSelectedMaterialColor = color => {
	return {
		type: 'SET_SELECTEED_MATERIAL_COLOR',
		color: color,
	}
}


class SidebarItemsDesktop extends React.Component {
	emptyClick = () => {
		document.getElementById('second-custom-right').style.display = 'none'
		document.getElementById("mySidenav1").style.width = "0";
		document.getElementById("mySidenav2").style.width = "0";
		document.getElementById("mySidenav5").style.width = "0";
		// document.getElementById("mySidenav6").style.width = "0";
	}

	render() {
		const templateType = this.props.puzzle_config.type;
		return (
			<>
				<MatsToolSidebar/>
				<TemplateToolSidebar/>
				{
					templateType !== 'rubber-puzzle' && (
						<EditArtWorkSidebar/>
					)
				}
				{
					templateType === 'rubber-puzzle' && (
						<EditRubberArtWorkSidebar/>
					)
				}

				<div id="second-custom-right" onClick={this.emptyClick}/>
			</>
		);
	}
}

const SidebarItemsDesktopConnect = connect(
	mapStateToProps
)(SidebarItemsDesktop);

export default SidebarItemsDesktopConnect;
