import React from "react";
import "../assets/css/bootstrap.css";
import "../assets/css/fontawesome.css";
import "../assets/css/swain.css";
import "../assets/css/metisMenu.css";
import "../assets/css/onoffcanvas.css";
import "../assets/css/animate.css";
import {Container} from "shards-react";
import "fabric-webpack";
import DesignCanvas from "../components/layout/turf_mat_designer/DesignCanvas";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import OptionSettingDialogue from "../components/layout/turf_mat_designer/Option_Setting_Dialogue";
import Sidebar from "../components/layout/turf_mat_designer/Sidebar";
import ExtraMenu from "../components/layout/turf_mat_designer/ExtraMenu";
import SidebarItems from "../components/layout/turf_mat_designer/SidebarItems";
import $ from "jquery";
import axios from "axios";
import Alert from "../components/Alert";
import {SwainColorHex} from "../components/layout/ColorCodeHelper";

class Dashboard extends React.Component {
	state = {
		showAlert: false,
		firstAlert: true, // added to show tooltip only once even if object is removed
	};

	componentDidMount() {
		const {id} = this.props.match.params;
		if (id !== undefined) {
			this.props.setSaveDesignID(id);
		}

		$(".material-color-pane").css({visibility: "hidden"});
		$(".text-color-pane").css({display: "none"});
	}

	handleCloseAlert = () => {
		this.setState({showAlert: false});
	};

	updateCanvasState = () => {
		let tempConfig = this.props._config;
		if (tempConfig.undoStatus === false && tempConfig.redoStatus === false) {
			let jsonData = this.props.canvas.toJSON();
			let canvasAsJson = JSON.stringify(jsonData);
			if (tempConfig.currentStateIndex < tempConfig.canvasState.length - 1) {
				let indexToBeInserted = tempConfig.currentStateIndex + 1;
				tempConfig.canvasState[indexToBeInserted] = canvasAsJson;

				let numberOfElementsToRetain = indexToBeInserted + 1;
				tempConfig.canvasState = tempConfig.canvasState.splice(
					0,
					numberOfElementsToRetain
				);
			} else {
				tempConfig.canvasState.push(canvasAsJson);
			}

			tempConfig.currentStateIndex = tempConfig.canvasState.length - 1;

			if (
				tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
				tempConfig.currentStateIndex !== -1
			) {
				tempConfig.redoButton = "disabled";
			}
		}
		this.props.setCanvasConfig(tempConfig);
	};

	showEditArtWorkDialogue = () => {
		$(".material-color-pane").css({visibility: "hidden"});
	};

	setArtWorkColor = (color) => {
		let activeObject = this.props.canvas._activeObject;
		let activeGroup = this.props.canvas.getActiveGroup();
		console.log(activeGroup);
		if (activeObject) {
			activeObject.set("fill", color);
			this.props.canvas.renderAll();
		}
		if (activeGroup) {
			let objects = activeGroup._objects;
			objects.forEach(item => {
				if (item.oldFill) {
					item.set({fill: color});
				} else {
					item.set({fill: color, oldFill: item.fill});
				}
			})
			this.props.canvas.renderAll();
		}
		this.updateCanvasState();
	};

	deleteSelectedMaterial = () => {
		let activeObject = this.props.canvas._activeObject;
		if (activeObject) {
			this.props.canvas.remove(activeObject);
			this.props.removeRect(activeObject);
		}
		let activeGroup = this.props.canvas.getActiveGroup();
		console.log('group ', activeGroup);
		if (activeGroup) {
			let objects = activeGroup._objects;
			this.props.canvas.deactivateAll().renderAll();
			objects.forEach(item => {
				this.props.removeRect(item);
				this.props.canvas.remove(item);
			})
		}
		this.updateCanvasState();
		this.clearSelection();
	};

	clearSelection = () => {
		$('.deleteBtn').css('visibility', 'hidden')
		$('.material-color-pane').css({display: 'none'})
		$('.text-color-pane').css({display: 'none'})

		$('#bold').removeClass('active')
		$('#italic').removeClass('active')
		$('#underline').removeClass('active')
		$('#capital').removeClass('active')
		$('#left-align').removeClass('active')
		$('#center-align').removeClass('active')
		$('#right-align').removeClass('active')
		$('.slct-font-size').val($('.slct-font-size option:first').val())
		$('.slct-font-fam').val($('.slct-font-fam option:first').val())
		$('#line_ht_get').val(1)
		$('#line_ht_get').css({
			background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
		})
		$('#line_ht_set').val(1)
		$('#spacing_get').val(1)
		$('#spacing_get').css({
			background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
		})
		$('#spacing_set').val(1)
		$('#text-color-bind').css({background: 'black'})
		$('#size_get').val(0)
		$('#size_get').css({
			background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
		})
		$('#size_set').val(0)
		$('#outline-color-bind').css({background: 'black'})
		$('#selected_text').val('sampletext')
		$('.add-text-btn').text('Add Text')
	}

	reStoreSelectedMaterial = () => {
		let activeObject = this.props.canvas._activeObject;
		let activeGroup = this.props.canvas.getActiveGroup();
		console.log('restore', activeGroup);
		if (activeObject) {
			activeObject.set("fill", this.props.selectedMaterialColor);
			this.props.canvas.renderAll();
		}
		if (activeGroup) {
			let objects = activeGroup._objects;
			objects.forEach(item => {
				if (item.oldFill) {
					let fill = item.oldFill;
					delete item.oldFill;
					item.set({fill: fill});
				}
			})
			this.props.canvas.renderAll();
		}
		this.updateCanvasState();
	};

	clearCanvas = (e) => {
		e.preventDefault();
		this.props.canvas.clear();
		window.scrollTo(0, 0);
		this.updateCanvasState();
		this.props.clearCanvas();
		clearTimeout(this.popupTimer);
		this.setState({showAlert: false, firstAlert: true});
	};

	tooltipHandler = (alert, first = false) => {
		if (alert) {
			this.setState({showAlert: true, firstAlert: false});
			this.popupTimer = setTimeout(() => {
				this.setState({showAlert: false});
			}, 5000);
		}
		if (alert === false) {
			this.setState({showAlert: false});
		}
	};

	clearSelection = () => {
		this.props.canvas.deactivateAll().renderAll();
		$(".deleteBtn").css("visibility", "hidden");
		$(".material-color-pane").css({display: "none"});
		$(".text-color-pane").css({display: "none"});

		$("#bold").removeClass("active");
		$("#italic").removeClass("active");
		$("#underline").removeClass("active");
		$("#capital").removeClass("active");
		$("#left-align").removeClass("active");
		$("#center-align").removeClass("active");
		$("#right-align").removeClass("active");
		//$('.slct-font-size').val($('.slct-font-size option:first').val())
		//$('.slct-font-fam').val($('.slct-font-fam option:first').val())
		$("#line_ht_get").val(1);
		$("#line_ht_get").css({
			background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
		});
		$("#line_ht_set").val(1);
		$("#spacing_get").val(1);
		$("#spacing_get").css({
			background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
		});
		$("#spacing_set").val(1);
		$("#text-color-bind").css({background: "black"});
		$("#size_get").val(0);
		$("#size_get").css({
			background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
		});
		$("#size_set").val(0);
		$("#outline-color-bind").css({background: "black"});
		$("#selected_text").val("sampletext");
		$(".add-text-btn").text("Add Text");
	};

	reduceDesignColor = (colorCode) => {
		if (colorCode.indexOf('rgb') >= 0) {
			let a = colorCode.split("(")[1].split(")")[0];
			a = a.split(",");
			let b = a.map(function (x) {                      //For each array element
				x = parseInt(x).toString(16);      //Convert to a base16 string
				return (x.length === 1) ? "0" + x : x; //Add zero if we get only one character
			});
			colorCode = "#" + b.join("");
		}
		let colorName = colorCode,
			colorNames = Object.keys(SwainColorHex);
		colorNames.forEach(color => {
			if (SwainColorHex[color] === colorName) {
				colorName = color;
			}
		})
		return colorName;
	}

	onOutOfCanvas = (activeObject) => {
		console.log("called");
		let out = false;
		activeObject.setCoords();
		let cords = activeObject.oCoords;
		let originalHeight = this.props.canvas.height / this.props.canvas.getZoom();
		let originalWidth = this.props.canvas.width / this.props.canvas.getZoom();
		//if is out from top
		if (cords.tl.y < 0 && cords.tr.y < 0 && cords.br.y < 5 && cords.bl.y < 5) {
			out = true;
		}
		//if is out from left
		else if (cords.tl.x < 5 && cords.tr.x < 5 && cords.br.x < 5 && cords.bl.x < 5) {
			out = true;
		}
		//if is out from bottom
		else if (cords.tl.x > originalWidth && cords.bl.x > originalWidth) {
			out = true;
		}
		//if is out from right
		else if (cords.tr.y > originalHeight && cords.tl.y > originalHeight) {
			out = true;

		}
		return out;
	}

	setActiveTab = (id) => {
		$("#text-custom").css("display", "none");
		$("#text-rgb").css("display", "none");
		$("#text-cmyk").css("display", "none");
		$(`#${id}`).css("display", "block");
		$(".custom-colors .active").removeClass("active");
		$(`.${id}-color`).addClass("active");
	};

	emptyClick = () => {
		if (window.innerWidth < 768) {
			document.getElementById('second-custom-right').style.display = 'none'
			document.getElementById("mySidenav1") && (document.getElementById("mySidenav1").style.width = "0");
			document.getElementById("mySidenav2") && (document.getElementById("mySidenav2").style.width = "0");
			document.getElementById("mySidenav3") && (document.getElementById("mySidenav3").style.width = "0");
			document.getElementById("mySidenav4") && (document.getElementById("mySidenav4").style.width = "0");
			document.getElementById("mySidenav11") && (document.getElementById("mySidenav11").style.width = "0");
			document.getElementById("mySidenav12") && (document.getElementById("mySidenav12").style.width = "0");
			document.getElementById("mySidenav13") && (document.getElementById("mySidenav13").style.width = "0");
			document.getElementById("mySidenav14") && (document.getElementById("mySidenav14").style.width = "0");
			document.getElementById("mySidenav15") && (document.getElementById("mySidenav15").style.width = "0");
			document.getElementById("mySidenav16") && (document.getElementById("mySidenav16").style.width = "0");
			document.getElementById("mySidenav17") && (document.getElementById("mySidenav17").style.width = "0");
			document.getElementById("mySidenav18") && (document.getElementById("mySidenav18").style.width = "0");
			document.getElementById("mySidenav19") && (document.getElementById("mySidenav19").style.width = "0");
		}
	}

	render() {
		const components = this.props.components;
		const turf_config = this.props.turf_config;
		console.log(turf_config.option_setting)
		if (!turf_config.option_setting) {
			return <OptionSettingDialogue/>;
		}
		const rubber_turf_config = this.props.rubber_turf_config;
		const design_mode = turf_config.design_mode;
		const turfHeight = turf_config.height-rubber_turf_config.paddingTop-rubber_turf_config.paddingBottom;
		const turfWidth = turf_config.width-rubber_turf_config.paddingLeft-rubber_turf_config.paddingRight;
		const unit = this.props.turf_config.length_unit === "FEET" ? "ft" : "m";
		const templateMessage = "TIP: Click on Template and Place on Grid"
		const matMessage = "TIP: Click on Mat and Place on Grid"
		return (
			<>
                <span
	                id="tooltiptemplate"
	                style={{
		                visibility: 'hidden',
		                width: 'fit-content',
		                backgroundColor: 'grey',
		                color: '#fff',
		                textAlign: 'center',
		                padding: '5px 5px',
		                borderRadius: '6px',
		                top: '45px',
		                /* Position the tooltip text - see examples below! */
		                position: 'absolute',
		                zIndex: '9999',
		                overflow: 'visible',
		                fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
		                fontSize: '14px',
		                fontStyle: 'normal',
		                fontWeight: 'normal',
	                }}>
	                {templateMessage}
                </span>
				<span
					id="tooltipmat"
					style={{
						visibility: 'hidden',
						width: 'fit-content',
						backgroundColor: 'grey',
						color: '#fff',
						textAlign: 'center',
						padding: '5px 5px',
						borderRadius: '6px',
						top: '45px',
						/* Position the tooltip text - see examples below! */
						position: 'absolute',
						zIndex: '9999',
						overflow: 'visible',
						fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
						fontSize: '14px',
						fontStyle: 'normal',
						fontWeight: 'normal',
					}}>
					{matMessage}
				</span>
				<div id="second-custom-right" onClick={this.emptyClick}/>
				<Sidebar/>
				<div className="col-md-4 col-sm-4 text-center pad0 leftpanel">
					<SidebarItems/>
				</div>
				<div id="content">
					<div className="outer">
						<div className="inner bg-light lter">
							<ExtraMenu/>
							<div className="row ml0 mar" style={{background: "#ebf6f8"}}>
								<div className="text-center main-content custom-main-content">
									<div className="main-edit-content">
										<p className="matsize">
											<span>
				                            MAT SIZE : {turf_config.width}
													{unit} x {turf_config.height}
													{unit}
				                            </span>
											{
												design_mode === '2' && (
													<span>
							                            TURF SIZE : {turfWidth}
																	{unit} x {turfHeight}
																	{unit}
							                            </span>
												)
											}
										</p>
										<div className="addcontdiv">
											<div className="inneraddcontdiv">
												<Container
													fluid
													className="main-content-container px-4 scroll-pad"
												>
													<DesignCanvas
														//showAlert={this.state.showAlert}
														tooltipHandler={this.tooltipHandler}
													>

													</DesignCanvas>
													{this.state.showAlert && !this.state.firstAlert && (
														<Alert
															onClick={this.handleCloseAlert}
															isOpen={true}
														/>
													)}
												</Container>
											</div>
										</div>
										<div id="drag_image"/>
										<div id="drag_template"/>
										<div id="preview_image"/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const setSaveDesignID = (id) => {
	return {
		type: "SET_SAVED_ID",
		id: id,
	};
};

const setCanvasConfig = (_config) => {
	return {
		type: "SET_CONFIG",
		_config,
	};
};

const clearCanvas = () => {
	return {
		type: "CLAER_CANVAS",
		isCanvasCleared: true,
	};
};

const removeRect = (data) => {
	return {
		type: "REMOVE_RECT",
		data,
	};
};

const setFontColorOption = (option) => {
	return {
		type: "SET_FONT_COLOR_OPTION",
		option,
	};
};

const mapStateToProps = (state) => {
	return {
		_config: state._config,
		components: [...state.components],
		components_type: [...state.components_type],
		turf_config: state.turf_config,
		rubber_turf_config: state.rubber_turf_config,
		canvas: state.canvas,
		selectedMaterialColor: state.selectedMaterialColor,
		textColorOption: state.textColorOption,
		rects: state.rects,
		text_style: state.text_style,
		fontColor: state.fontColor,
		uploadedLogos: state.uploadedLogos,
		canvas_grid: state.canvas_grid,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setSaveDesignID,
			setCanvasConfig,
			clearCanvas,
			removeRect,
			setFontColorOption,
		},
		dispatch
	);
};

const Swain_Designer = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default Swain_Designer;
