import React from "react";
import {TurfColorHex, VinColorHex} from "../../ColorCodeHelper";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

class TurfColorToolbar extends React.Component {
	constructor() {
		super();
		this.state = {}
	}

	componentDidMount() {

	}

	setTurfColor(color, colorName) {
		this.props.canvas.backgroundColor = color;
		this.props.canvas.renderAll();
		let config = this.props.turf_config;
		config.turf_color = colorName;
		config.color = color;
		this.props.setTurfConfig(config);
		this.updateCanvasState();
		//this.props.setCurrentWallPad(this.props.wallpad_config.current_wallpad, {color: colorName})
	}

	setThickness(value) {
		let config = this.props.turf_config;
		config.thickness = value;
		this.props.setTurfConfig(config);
	}

	updateCanvasState = () => {
		let tempConfig = this.props._config;
		if (tempConfig.undoStatus === false && tempConfig.redoStatus === false) {
			let jsonData = this.props.canvas.toJSON(['custom','name']);
			let canvasAsJson = JSON.stringify(jsonData);
			if (tempConfig.currentStateIndex < tempConfig.canvasState.length - 1) {
				let indexToBeInserted = tempConfig.currentStateIndex + 1;
				tempConfig.canvasState[indexToBeInserted] = canvasAsJson;

				let numberOfElementsToRetain = indexToBeInserted + 1;
				tempConfig.canvasState = tempConfig.canvasState.splice(
					0,
					numberOfElementsToRetain
				);
			} else {
				tempConfig.canvasState.push(canvasAsJson);
			}

			tempConfig.currentStateIndex = tempConfig.canvasState.length - 1;

			if (
				tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
				tempConfig.currentStateIndex !== -1
			) {
				tempConfig.redoButton = "disabled";
			}
		}
		this.props.setCanvasConfig(tempConfig);
	};

	render() {
		return (
			<>
				<div className="row adddiv">
					<h2>Turf Color</h2>
				</div>
				<div className="templatesmain mat-lft-blk-menu">
					<div className="col-md-12 mt-15 pb-15">
						<div className="row row-border-btm">
			              <span style={{color: 'white', fontStyle: 'italic'}}>
			                Select Color
			              </span>
							<div className="preset-color-row">
								<div
									className="pre-clr-box clr-pr1 tooltip"
									style={{backgroundColor: TurfColorHex.Red}}
									onClick={() => this.setTurfColor(TurfColorHex.Red, 'Red')}
								>
									<span className="tooltiptext">Red</span>
								</div>
								<div
									className="pre-clr-box clr-pr2 tooltip"
									style={{backgroundColor: TurfColorHex.Green}}
									onClick={() => this.setTurfColor(TurfColorHex.Green, 'Green')}
								>
									<span className="tooltiptext">Green</span>
								</div>
								<div
									className="pre-clr-box clr-pr3 tooltip"
									style={{backgroundColor: TurfColorHex.RoyalBlue}}
									onClick={() => this.setTurfColor(TurfColorHex.RoyalBlue, 'Royal Blue')}
								>
									<span className="tooltiptext">Royal Blue</span>
								</div>
								<div
									className="pre-clr-box clr-pr4 tooltip"
									style={{backgroundColor: TurfColorHex.Black}}
									onClick={() => this.setTurfColor(TurfColorHex.Black, 'Black')}
								>
									<span className="tooltiptext">Black</span>
								</div>
								<div
									className="pre-clr-box clr-pr5 tooltip"
									style={{backgroundColor: TurfColorHex.White}}
									onClick={() => this.setTurfColor(TurfColorHex.White, 'White')}
								>
									<span className="tooltiptext">White</span>
								</div>
							</div>
							<div className="preset-color-row">
								<div
									className="pre-clr-box clr-pr5 tooltip"
									style={{backgroundColor: TurfColorHex.Gold}}
									onClick={() => this.setTurfColor(TurfColorHex.Gold, 'Gold')}
								>
									<span className="tooltiptext">Gold</span>
								</div>
								<div
									className="pre-clr-box clr-pr6 tooltip"
									style={{backgroundColor: TurfColorHex.Brown}}
									onClick={() => this.setTurfColor(TurfColorHex.Brown, 'Brown')}
								>
									<span className="tooltiptext">Brown</span>
								</div>
								<div
									className="pre-clr-box clr-pr7 tooltip"
									style={{backgroundColor: TurfColorHex.Clay}}
									onClick={() => this.setTurfColor(TurfColorHex.Clay, 'Clay')}
								>
									<span className="tooltiptext">Clay</span>
								</div>
								<div
									className="pre-clr-box clr-pr8 tooltip"
									style={{backgroundColor: TurfColorHex.TanBlack}}
									onClick={() => this.setTurfColor(TurfColorHex.TanBlack, 'Tan w/Black')}
								>
									<span className="tooltiptext">Tan w/Black</span>
								</div>
								<div
									className="pre-clr-box clr-pr1 tooltip"
									style={{backgroundColor: TurfColorHex.TanWhite}}
									onClick={() => this.setTurfColor(TurfColorHex.TanWhite, 'Tan w/White')}
								>
									<span className="tooltiptext">Tan w/White</span>
								</div>
							</div>
							<div className="preset-color-row">
								<div
									className="pre-clr-box clr-pr1 tooltip"
									style={{backgroundColor: TurfColorHex.Maroon}}
									onClick={() => this.setTurfColor(TurfColorHex.Maroon, 'Maroon')}
								>
									<span className="tooltiptext">Maroon</span>
								</div>
								<div
									className="pre-clr-box clr-pr9 tooltip"
									style={{backgroundColor: TurfColorHex.IndyBlue}}
									onClick={() => this.setTurfColor(TurfColorHex.IndyBlue, 'Indy Blue')}
								>
									<span className="tooltiptext">Indy Blue</span>
								</div>
								<div
									className="pre-clr-box clr-pr10 tooltip"
									style={{backgroundColor: TurfColorHex.Orange}}
									onClick={() => this.setTurfColor(TurfColorHex.Orange, 'Orange')}
								>
									<span className="tooltiptext">Orange</span>
								</div>
								<div
									className="pre-clr-box clr-pr11 tooltip"
									style={{backgroundColor: TurfColorHex.Navy}}
									onClick={() => this.setTurfColor(TurfColorHex.Navy, 'Navy')}
								>
									<span className="tooltiptext">Navy</span>
								</div>
								<div
									className="pre-clr-box clr-pr11 tooltip"
									style={{backgroundColor: TurfColorHex.Silver}}
									onClick={() => this.setTurfColor(TurfColorHex.Silver, 'Silver')}
								>
									<span className="tooltiptext">Silver</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}

const mapStateToProps = state => {
	return {
		_config:state._config,
		canvas: state.canvas,
		turf_config:state.turf_config,
	}
}
const setTurfConfig = _config => {
	return {
		type:'SET_TURF_CONFIG',
		_config
	}
}

const setCanvasConfig = (_config) => {
	return {
		type: "SET_CONFIG",
		_config,
	};
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ setTurfConfig,setCanvasConfig }, dispatch);
}

const TurfColorToolbarConnect = connect(
	mapStateToProps,mapDispatchToProps
)(TurfColorToolbar)

class TurfColorToolbarSidebar extends React.Component {
	closeNav12 = () => {
		document.getElementById("mySidenav12").style.width = "0";
	};

	render() {
		return (
			<>
				<div
					id="turf-color"
					className="h100 second-menu fordeskview"
				>
					<TurfColorToolbarConnect/>
				</div>
				<div
					className="text-center h100 pad0 mtopneg sidenav4 formobview wo"
					id="mySidenav12"
				>
					<a
						style={{color: "#fff"}}
						href="#"
						className="closebtn"
						onClick={() => this.closeNav12()}
					>
						&times;
					</a>
					<TurfColorToolbarConnect/>
				</div>
			</>
		);
	}
}

export default TurfColorToolbarSidebar