import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import $ from 'jquery'
import {VinColorHex} from "../../ColorCodeHelper";

class WallPadToolbar extends React.Component {
  state = {
    page: 0,
  }

  componentDidMount() {
    this.props.setCurrentWallPad(this.props.wallpad_config.current_wallpad, {thickness: '1"'});
  }

  setWallPadColor = (color, colorName) => {
    this.props.canvas.backgroundColor = color
    this.props.canvas.renderAll()
    this.props.setCurrentWallPad(this.props.wallpad_config.current_wallpad, {color: colorName})
  }

  setWallPad = e => {
    const { value } = e.target
    const index = value / 1 - 1
    this.setState({ page: value })
    $('.wallpad_0').css('display', 'none')
    $('.wallpad_1').css('display', 'none')
    $('.wallpad_2').css('display', 'none')
    $('.wallpad_3').css('display', 'none')
    $('.wallpad_0')
      .closest('div.canvas-container')
      .css('display', 'none')
    $('.wallpad_1')
      .closest('div.canvas-container')
      .css('display', 'none')
    $('.wallpad_2')
      .closest('div.canvas-container')
      .css('display', 'none')
    $('.wallpad_3')
      .closest('div.canvas-container')
      .css('display', 'none')

    $(`.wallpad_${index}`)
      .closest('div.canvas-container')
      .css('display', 'block')
    $(`.wallpad_${index}`).css('display', 'block')

    this.props.setCurrentWallPad(index)
    this.props.canvas.discardActiveObject()
  }
  render() {
    if(this.props.wallpad_config.current_wallpad+1 !== this.state.page){
      this.setState({page:this.props.wallpad_config.current_wallpad+1})
    }
    const wallpad_configs = this.props.wallpad_config['config']
    return (
      <>
        <div className="adddiv">
          <h2>Wall Pads</h2>
        </div>
        <div className="templatesmain mat-lft-blk-menu">
          <div className="col-md-12 mt-15 pb-15">
            <div className="row row-border-btm">
              <div className="col-md-6 vrtcl-dot-line">
                <div className="choos-clr-label">
                  <span>WALL Pad</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <select
                    className="form-control slct-font-size"
                    onChange={this.setWallPad}
                    value={this.state.page}
                  >
                    {wallpad_configs.map((config, index) => (
                      <option key={index}>{index + 1}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row row-border-btm">
              <span style={{ color: 'white', fontStyle: 'italic' }}>
                Select Color
              </span>
              <div className="preset-color-row">
                <div
                    className="pre-clr-box clr-pr1 tooltip"
                    style={{ backgroundColor: VinColorHex.RoyalBlue }}
                    onClick={() => this.setWallPadColor(VinColorHex.RoyalBlue, 'Royal Blue')}
                >
                  <span className="tooltiptext">Royal Blue</span>
                </div>
                <div
                    className="pre-clr-box clr-pr2 tooltip"
                    style={{ backgroundColor: VinColorHex.Red }}
                    onClick={() => this.setWallPadColor(VinColorHex.Red, 'Red')}
                >
                  <span className="tooltiptext">Red</span>
                </div>
                <div
                    className="pre-clr-box clr-pr3 tooltip"
                    style={{ backgroundColor: VinColorHex.Gold }}
                    onClick={() => this.setWallPadColor(VinColorHex.Gold, 'Gold')}
                >
                  <span className="tooltiptext">Gold</span>
                </div>
                <div
                    className="pre-clr-box clr-pr4 tooltip"
                    style={{ backgroundColor: VinColorHex.Black }}
                    onClick={() => this.setWallPadColor(VinColorHex.Black, 'Black')}
                >
                  <span className="tooltiptext">Black</span>
                </div>
              </div>
              <div className="preset-color-row">
                <div
                    className="pre-clr-box clr-pr5 tooltip"
                    style={{ backgroundColor: VinColorHex.Purple }}
                    onClick={() => this.setWallPadColor(VinColorHex.Purple, 'Purple')}
                >
                  <span className="tooltiptext">Purple</span>
                </div>
                <div
                    className="pre-clr-box clr-pr6 tooltip"
                    style={{ backgroundColor: VinColorHex.Orange }}
                    onClick={() => this.setWallPadColor(VinColorHex.Orange, 'Orange')}
                >
                  <span className="tooltiptext">Orange</span>
                </div>
                <div
                    className="pre-clr-box clr-pr7 tooltip"
                    style={{ backgroundColor: VinColorHex.DarkGreen }}
                    onClick={() => this.setWallPadColor(VinColorHex.DarkGreen, 'Dark Green')}
                >
                  <span className="tooltiptext">Dark Green</span>
                </div>
                <div
                    className="pre-clr-box clr-pr8 tooltip"
                    style={{ backgroundColor: VinColorHex.LightGrey }}
                    onClick={() => this.setWallPadColor(VinColorHex.LightGrey, 'Light Grey')}
                >
                  <span className="tooltiptext">Light Grey</span>
                </div>
              </div>
              <div className="preset-color-row">
                <div
                    className="pre-clr-box clr-pr1 tooltip"
                    style={{ backgroundColor: VinColorHex.Navy }}
                    onClick={() => this.setWallPadColor(VinColorHex.Navy, 'Navy')}
                >
                  <span className="tooltiptext">Navy</span>
                </div>
                <div
                    className="pre-clr-box clr-pr9 tooltip"
                    style={{ backgroundColor: VinColorHex.White }}
                    onClick={() => this.setWallPadColor(VinColorHex.White, 'White')}
                >
                  <span className="tooltiptext">White</span>
                </div>
                <div
                    className="pre-clr-box clr-pr10 tooltip"
                    style={{ backgroundColor: VinColorHex.Brown }}
                    onClick={() => this.setWallPadColor(VinColorHex.Brown, 'Brown')}
                >
                  <span className="tooltiptext">Brown</span>
                </div>
                <div
                    className="pre-clr-box clr-pr11 tooltip"
                    style={{ backgroundColor: VinColorHex.CharcoalGrey }}
                    onClick={() => this.setWallPadColor(VinColorHex.CharcoalGrey, 'Charcoal Grey')}
                >
                  <span className="tooltiptext">Charcoal Grey</span>
                </div>
              </div>
              <div className="preset-color-row">
                <div
                    className="pre-clr-box clr-pr12 tooltip"
                    style={{ backgroundColor: VinColorHex.Maroon }}
                    onClick={() => this.setWallPadColor(VinColorHex.Maroon, 'Maroon')}
                >
                  <span className="tooltiptext">Maroon</span>
                </div>
                <div
                    className="pre-clr-box clr-pr6 tooltip"
                    style={{ backgroundColor: VinColorHex.CarolinaBlue }}
                    onClick={() => this.setWallPadColor(VinColorHex.CarolinaBlue, 'Carolina Blue')}
                >
                  <span className="tooltiptext">Carolina Blue</span>
                </div>
                <div
                    className="pre-clr-box tooltip"
                    style={{ visibility: 'hidden' }}
                >
                  <span className="tooltiptext">{''}</span>
                </div>
                <div
                    className="pre-clr-box tooltip"
                    style={{ visibility: 'hidden' }}
                >
                  <span className="tooltiptext">{''}</span>
                </div>
              </div>
            </div>
            <div className="row row-border-btm">
              <div className="col-md-12">
                <div
                  className="thickness col-md-12"
                  style={{
                    background: '#2a3f54',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    paddingTop: '15px',
                  }}
                >
                  <span
                    className="col-md-6"
                    style={{
                      color: 'white',
                      float: 'left',
                      paddingLeft: '0px',
                      paddingTop: '10px',
                      textAlign: 'left',
                    }}
                  >
                    Thickness
                  </span>
                  <select
                    className="form-control slct-font-fam"
                    onChange={(e) => this.props.setCurrentWallPad(this.props.wallpad_config.current_wallpad, {thickness: e.target.value})}
                    style={{ width: '50%', float: ' right' }}
                  >
                    <option>1"</option>
                    <option>1 1/4"</option>
                    <option>1 1/2"</option>
                    <option>1 5/8"</option>
                    <option>2"</option>
                  </select>
                </div>
              </div>              
            </div>
          </div>
        </div>
      </>
    )
  }
}

const setCurrentWallPad = (index, config) => {
  return {
    type: 'SET_CURRENT_WALLPAD',
    index,
    config
  }
}

const mapStateToProps = state => {
  return {
    canvas: state.canvas,
    _config: state._config,
    wallpad_config: state.wallpad_config,
  }
}

const mapDispatchToPropsForRect = dispatch => {
  return bindActionCreators({ setCurrentWallPad }, dispatch)
}

const WallPadToolbarConnect = connect(
  mapStateToProps,
  mapDispatchToPropsForRect,
)(WallPadToolbar)

class WallPadToolSidebar extends React.Component {
  closeNav = () => {
    document.getElementById('mySidenav1').style.width = '0'
  }

  render() {
    return (
      <>
        <div id="wallpad" className="h100 second-menu fordeskview">
          <WallPadToolbarConnect />
        </div>
        <div
          className="text-center h100 pad0 mtopneg sidenav2 formobview wo"
          id="mySidenav1"
        >
          <a
            style={{ color: '#fff' }}
            href="#"
            className="closebtn"
            onClick={() => this.closeNav()}
          >
            &times;
          </a>
          <WallPadToolbarConnect />
        </div>
      </>
    )
  }
}

export default WallPadToolSidebar
