import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import "antd/dist/antd.css";
import {
	HashMarksTurfToolbarSidebar,
	TurfColorToolbarSidebar,
	LogoToolSidebar,
	NumberMarksTurfToolbarSidebar,
	TurfShapesToolbarSidebar,
	TurfRubberColorToolbarSidebar,
	RubberTurfColorToolbarSidebar,
	RubberHashMarksTurfToolbarSidebar,
	RubberNumberMarksTurfToolbarSidebar,
	RubberShapesToolbarSidebar
} from './items'


class SidebarItemsDesktop extends React.Component {
	emptyClick = () => {
		document.getElementById('second-custom-right').style.display = 'none'
		document.getElementById("mySidenav1") && (document.getElementById("mySidenav1").style.width = "0");
		document.getElementById("mySidenav2") && (document.getElementById("mySidenav2").style.width = "0");
		document.getElementById("mySidenav3") && (document.getElementById("mySidenav3").style.width = "0");
		document.getElementById("mySidenav4") && (document.getElementById("mySidenav4").style.width = "0");
		document.getElementById("mySidenav11") && (document.getElementById("mySidenav11").style.width = "0");
		document.getElementById("mySidenav12") && (document.getElementById("mySidenav12").style.width = "0");
		document.getElementById("mySidenav13") && (document.getElementById("mySidenav13").style.width = "0");
		document.getElementById("mySidenav14") && (document.getElementById("mySidenav14").style.width = "0");
		document.getElementById("mySidenav15") && (document.getElementById("mySidenav15").style.width = "0");
		document.getElementById("mySidenav16") && (document.getElementById("mySidenav16").style.width = "0");
		document.getElementById("mySidenav17") && (document.getElementById("mySidenav17").style.width = "0");
		document.getElementById("mySidenav18") && (document.getElementById("mySidenav18").style.width = "0");
		document.getElementById("mySidenav19") && (document.getElementById("mySidenav19").style.width = "0");

	}

	render() {
		console.log(this.props.turf_config)
		const designMode = this.props.turf_config.design_mode;
		return (
			<>
				{
					designMode === '3' && <TurfRubberColorToolbarSidebar />
				}
				{
					designMode === '2' && <TurfRubberColorToolbarSidebar />
				}
				{
					designMode === '2' && <RubberTurfColorToolbarSidebar />
				}
				{
					designMode === '2' && <RubberHashMarksTurfToolbarSidebar />
				}
				{
					designMode === '2' && <RubberNumberMarksTurfToolbarSidebar />
				}
				{
					designMode === '2' && <RubberShapesToolbarSidebar />
				}
				{
					designMode === '1' && <TurfColorToolbarSidebar />

				}
				{
					designMode === '1' && <HashMarksTurfToolbarSidebar />
				}
				{
					designMode === '1' && <NumberMarksTurfToolbarSidebar />
				}
				{
					designMode === '1' && <TurfShapesToolbarSidebar />
				}
				<LogoToolSidebar/>

				<div id="second-custom-right" onClick={this.emptyClick}/>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		turf_config: state.turf_config,
	}
}


export default connect(
	mapStateToProps,
)(SidebarItemsDesktop)
