import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Footer from '../Footer'

class OptionSettingDialogue extends React.Component {
  state = {
  }

  onBuild = () => {
    const { wrestling_config, setWrestingSettingConfig } = this.props
    setWrestingSettingConfig({
      ...wrestling_config,
      option_setting: true
    })
  }

  render() {
    return (
      <>
        <div className="see-instruction int-matbuild default-left wrestling">
          <div className="container">
            <div className="innerint default-left text-center">
              <h2>Interactive Mat Builder</h2>
              <p className="wrestling-subtitle">Wrestling Competition Mats and Practice Rooms, <span>UWW Mats, Octagon Mats</span></p>
            </div>
            <div className="default-left back-fwd-btns wresting-btn">
              <div className="fw">
                <a className='btn btn-danger' style={{padding:'0px'}} href="#" onClick={this.onBuild}>
                  <span>Build it</span>
                  <img
                    src={require('./../../../assets/img/fwarw.png')}
                    alt="Forward Arrow"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

const setWrestingSettingConfig = (_config) => {
  return {
    type: 'SET_WRESTLING_SETTING_CONFIG',
    _config
  }
}

const mapStateToProps = state => {
  return {
    wrestling_config: state.wrestling_config,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ setWrestingSettingConfig }, dispatch)
}

const OptionSettingConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OptionSettingDialogue)

export default OptionSettingConnect
