import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./../../assets/css/fontawesome.css";
import { useForm } from "react-hook-form";

const SaveModal = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { register, errors, handleSubmit } = useForm();

  const onSubmit = (data) => {
    const { save } = props;
    save(data.name, data.email);
    handleClose();
  };
  const toolbar =  props.toolbar ? props.toolbar : false;
  return (
    <>
      {
        toolbar && (
            <a className="toolbar-btn" onClick={handleShow}><img src={require("./../../assets/img/save.png")} style={{marginTop:'-3px'}}  alt="Save" /><span className="hide-on-smaller-screen">Save</span></a>
        )
      }
      {
        !toolbar && (
            <a href="#" onClick={handleShow}>
              <img src={require("./../../assets/img/save.png")} style={{marginTop:'-3px'}} alt="Save" />
              <span>Save Design</span>
            </a>
        )
      }

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="modalRegisterForm"
      >
        <div className="modal-header text-center">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 className="modal-title w-100 font-weight-bold">
            SAVE YOUR DESIGN
          </h4>
          <p className="modal-title w-100 font-weight-bold">
            Save your design to use later, then share it with via email.
          </p>
        </div>
        <div className="modal-body mx-3">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`md-form mb-5 ${errors.name ? "has-error" : ""}`}>
              {" "}
              <input
                type="text"
                id="orangeForm-name"
                className="form-control validate"
                placeholder="Name of your design *"
                name="name"
                ref={register({ required: true })}
              />
              <i className="far fa-edit"></i>
              <p>{errors.name && "This field is required."}</p>
            </div>
            <div className={`md-form mb-5 ${errors.email ? "has-error" : ""}`}>
              {" "}
              <input
                type="email"
                id="orangeForm-email"
                className="form-control validate"
                placeholder="Your Email Address *"
                name="email"
                ref={register({
                  required: true,
                  pattern: {
                    value: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Your email address is not correct.",
                  },
                })}
              />
              <i className="far fa-envelope" aria-hidden="true"></i>
              <p>{errors.email && errors.email.message}</p>
              <p>
                {errors.email &&
                  errors.email.type === "required" &&
                  "This field is required."}
              </p>
            </div>
            <button
              className="btn btn-danger custom-button"
              type="submit"
              style={{ marginTop: "15px" }}
            >
              Submit
            </button>
          </form>
        </div>
        <div className="modal-footer d-flex justify-content-center"></div>
      </Modal>
    </>
  );
};
export default SaveModal;
