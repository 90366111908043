import React from 'react'
import '../assets/css/w3.css'
import '../assets/css/confirm.css'
import QuoteTable from '../components/Quote/QuoteTable';

class AdminLayout extends React.Component {
  state = {}

  render() {
    return (
        <QuoteTable/>
    )
  }
}

export default AdminLayout
