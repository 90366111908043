import React from 'react'
import { connect } from 'react-redux'
import $ from 'jquery'
import SaveModal from "../SaveModal";
import EmailModal from "../EmailModal";
import QuoteModal from "../QuoteModal";
import CanvasZoomConnect from "../../zoomTool/ZoomTool";
import axios from "axios";
import {bindActionCreators} from "redux";

class ExtraMenu extends React.Component {

  state = {
		submittingQuote: false,
		quoteModal: false,
	}

  componentDidMount = () => {}
  download = e => {
    this.clearSelection();
    e.preventDefault()
    let download = document.createElement('a')
    download.href = this.props.canvas.toDataURL()
    download.download = 'Dollamur_mat.png'
    download.click()
  }
  updateCanvasState = () => {
    let tempConfig = this.props._config;
    if (tempConfig.undoStatus === false && tempConfig.redoStatus === false) {
      let jsonData = this.props.canvas.toJSON();
      let canvasAsJson = JSON.stringify(jsonData);
      if (tempConfig.currentStateIndex < tempConfig.canvasState.length - 1) {
        let indexToBeInserted = tempConfig.currentStateIndex + 1;
        tempConfig.canvasState[indexToBeInserted] = canvasAsJson;

        let numberOfElementsToRetain = indexToBeInserted + 1;
        tempConfig.canvasState = tempConfig.canvasState.splice(
            0,
            numberOfElementsToRetain
        );
      } else {
        tempConfig.canvasState.push(canvasAsJson);
      }

      tempConfig.currentStateIndex = tempConfig.canvasState.length - 1;

      if (
          tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
          tempConfig.currentStateIndex !== -1
      ) {
        tempConfig.redoButton = "disabled";
      }
    }
    this.props.setCanvasConfig(tempConfig);
  };
  reduceDesignColor=(colorCode)=>{
    if(colorCode.indexOf('rgb') >= 0){
      let a = colorCode.split("(")[1].split(")")[0];
      a = a.split(",");
      let b = a.map(function(x){                      //For each array element
        x = parseInt(x).toString(16);      //Convert to a base16 string
        return (x.length===1) ? "0"+x : x; //Add zero if we get only one character
      });
      colorCode = "#"+b.join("");
    }
    return colorCode;
  }
  sendQuote = ({
                 fullName: name,
                 email,
                 phone,
                 zipcode,
                 subject,
                 message,
                 sports: sport,
               }) => {
    this.clearSelection();
    this.setState({submittingQuote:true});
    const matData = [];
    const wallpad_config = this.props.wallpad_config.config;
    const unit = this.props.wallpad_config.length_unit === "FEET" ? "ft" : "m";
    for (let i = 0; i < wallpad_config.length; i++) {
      matData[i] = {};
      let allLogos = [];
      console.log(wallpad_config[i])
      // 03/06
      // matData[i].matSize = (wallpad_config[i].width * 2) + " x " + (wallpad_config[i].height * 2);
      matData[i].matSize =
          wallpad_config[i].width+unit + " x " + wallpad_config[i].height+unit;
      matData[i].matColor = wallpad_config[i].color;
      matData[i].thickness = wallpad_config[i].thickness;
      let matFonts = [];
      let matShapes = [];
      if (this.props.canvas.getObjects("path-group").length > 0){
        let shapes = this.props.canvas.getObjects("path-group");
        shapes.forEach((item,index)=>{
          console.log({index,item});
          let paths = item.paths;
          let colors =[];
          paths.forEach(path=>{
            let hex = this.reduceDesignColor(path.fill)
            if(!colors.includes(hex)){
              colors.push(hex);
            }
          })
          matShapes.push({
            colors : colors,
          })
        })
        matData[0].shapes = matShapes;
      }
      console.log(i,'canvas here ',wallpad_config[i].canvas.getObjects("curvedText").length)
      if (wallpad_config[i].canvas.getObjects("curvedText").length > 0) {
        let curvedTexts = wallpad_config[i].canvas.getObjects("curvedText");
        curvedTexts.forEach((item,index)=>{
          console.log({index,item});
          let color = item.fill;
          if(color.indexOf('rgb') >= 0){
            let a = color.split("(")[1].split(")")[0];
            a = a.split(",");
            let b = a.map(function(x){                      //For each array element
              x = parseInt(x).toString(16);      //Convert to a base16 string
              return (x.length===1) ? "0"+x : x; //Add zero if we get only one character
            });
            color = "#"+b.join("");
          }
          if(item.text !== ''){
            matFonts.push({
              text : item.text,
              fontFamily : item.fontFamily,
              fill : item.fill,
              outline: item.stroke,
              outlineSize: item.strokeWidth,
            })
          }
        })

        matData[i].text = wallpad_config[i].text;
        matData[i].font = wallpad_config[i].fontFamily;
        matData[i].fontColor = wallpad_config[i].fontColor;
      }
      if(wallpad_config[i].canvas.getObjects('image').length > 0){
        let images = wallpad_config[i].canvas.getObjects("image");
        images.forEach((image)=>{
          console.log(image.source)
          allLogos.push(image.source)
        })

      }
      matData[i].logos = allLogos;
      matData[i].curvedTexts = matFonts;

      matData[i].sport = sport;
      //matData[i].logos = wallpad_config[i].logos;
      matData[i].svg = wallpad_config[i].canvas.toSVG();
      matData[i].png = wallpad_config[i].canvas.toDataURL();
    }

    const data = {
      name,
      email,
      phone,
      zipcode,
      subject:`WALL PAD MAT - ${subject}`,
      message,
      matData,
    };
    axios
        .post("/api/quote", data)
        .then((res) => {
          this.setState({quoteModal: false, submittingQuote: false});
          alert("Successfully saved!");
        })
        .catch((err) => {
          this.setState({quoteModal: false, submittingQuote: false});
          alert("An unexpected error occurred. Please contact Admin");
        });
  };

  clearSelection = () =>{
    this.props.canvas.deactivateAll().renderAll()
    $('.text-color-pane').css({ display: 'none' })
    $('.deleteBtn').css('visibility', 'hidden')

    $('#bold').removeClass('active')
    $('#italic').removeClass('active')
    $('#underline').removeClass('active')
    $('#capital').removeClass('active')
    $('#left-align').removeClass('active')
    $('#center-align').removeClass('active')
    $('#right-align').removeClass('active')
    //$('.slct-font-size').val($('.slct-font-size option:first').val())
    //$('.slct-font-fam').val($('.slct-font-fam option:first').val())
    $('#line_ht_get').val(1)
    $('#line_ht_get').css({
      background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
    })
    $('#line_ht_set').val(1)
    $('#spacing_get').val(1)
    $('#spacing_get').css({
      background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
    })
    $('#spacing_set').val(1)
    $('#text-color-bind').css({ background: 'black' })
    $('#size_get').val(0)
    $('#size_get').css({
      background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
    })
    $('#size_set').val(0)
    $('#outline-color-bind').css({ background: 'black' })
    $('#selected_text').val('sampletext')
    $('.add-text-btn').text('Add Text')
  }
  clearCanvas = (e) => {
    e.preventDefault();
    this.props.canvas.clear();
    this.updateCanvasState();
    this.props.clearCanvas();
    const t = new window.fabric.Text('', { fontFamily: 'Times New Roman',left:-10 })
    this.props.canvas.add(t);
  };
  undo = e => {
    e.preventDefault();
    let tempConfig = this.props._config;
    if (tempConfig.undoFinishedStatus) {
      if (tempConfig.currentStateIndex === 0) {
        tempConfig.undoStatus = false;
      } else {
        if (tempConfig.canvasState.length >= 0) {
          tempConfig.undoFinishedStatus = 0;
          if (tempConfig.currentStateIndex !== 0) {
            tempConfig.undoStatus = true;
            this.props.canvas.loadFromJSON(
                tempConfig.canvasState[tempConfig.currentStateIndex - 1],
                () => {
                  this.props.canvas.renderAll();
                  tempConfig.undoStatus = false;
                  tempConfig.currentStateIndex -= 1;
                  tempConfig.undoButton = "remove disabled";
                  if (
                      tempConfig.currentStateIndex !==
                      tempConfig.canvasState.length - 1
                  ) {
                    tempConfig.redoButton = "remove disabled";
                  }
                  tempConfig.undoFinishedStatus = 1;
                }
            );
          } else if (tempConfig.currentStateIndex === 0) {
            this.props.canvas.clear();
            tempConfig.undoFinishedStatus = 1;
            tempConfig.undoButton = "disabled";
            tempConfig.redoButton = "remove disabled";
            tempConfig.currentStateIndex -= 1;
          }
        }
      }
    }

    this.props.setCanvasConfig(tempConfig);
  };

  redo = e => {
    e.preventDefault();
    let tempConfig = this.props._config;
    if (tempConfig.redoFinishedStatus) {
      if (
          tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
          tempConfig.currentStateIndex !== -1
      ) {
        tempConfig.redoButton = "disabled";
      } else {
        if (
            tempConfig.canvasState.length > tempConfig.currentStateIndex &&
            tempConfig.canvasState.length !== 0
        ) {
          tempConfig.redoFinishedStatus = 0;
          tempConfig.redoStatus = true;
          this.props.canvas.loadFromJSON(
              tempConfig.canvasState[tempConfig.currentStateIndex + 1],
              () => {
                this.props.canvas.renderAll();
                tempConfig.redoStatus = false;
                tempConfig.currentStateIndex += 1;
                if (tempConfig.currentStateIndex !== -1) {
                  tempConfig.undoButton = "remove disabled";
                }
                tempConfig.redoFinishedStatus = 1;
                if (
                    tempConfig.currentStateIndex ===
                    tempConfig.canvasState.length - 1 &&
                    tempConfig.currentStateIndex !== -1
                ) {
                  tempConfig.redoButton = "disabled";
                }
              }
          );
        }
      }
    }

    this.props.setCanvasConfig(tempConfig);
  };

  save = (title, email) => {
    const length = this.props.wallpad_config["config"].length;
    let canvas_data = [];
    for (let i = 0; i < length; i++)
      canvas_data.push({
        data: JSON.stringify(this.props.wallpad_config["config"][i].canvas),
        config: {
          height: this.props.wallpad_config["config"][i].height,
          width: this.props.wallpad_config["config"][i].width,
          grid: this.props.wallpad_config["config"][i].canvas_grid
        }
      });
    const data = {
      data: canvas_data,
      email,
      title,
      url: `${window.location.origin}/wallpad_save_designer/`
    };
    axios.post('/api/save_design', data).then(res => {
      alert('Successfully saved!');
    }).catch(res => {
      alert('An unexpected error occurred. Please contact Admin');
    })
  };

  email_design = (title, email) => {
    const length = this.props.wallpad_config["config"].length;
    let canvas_data = [];
    for (let i = 0; i < length; i++)
      canvas_data.push({
        data: JSON.stringify(this.props.wallpad_config["config"][i].canvas),
        config: {
          height: this.props.wallpad_config["config"][i].height,
          width: this.props.wallpad_config["config"][i].width,
          grid: this.props.wallpad_config["config"][i].canvas_grid
        }
      });
    const data = {
      data: canvas_data,
      email,
      title,
      url: `${window.location.origin}/wallpad_save_designer/`
    };
    axios.post("/api/save_design", data)
        .then(res => {
          alert('We have sent you an email with details.');
        }).catch(res => {
      alert('An unexpected error occurred. Please contact Admin');
    })
  };
  menuToggle = () => {
    let bodyClass = $('body').attr('class')
    if (
      bodyClass === undefined ||
      bodyClass.indexOf('sidebar-left-opened') < 0
    ) {
      $('body').addClass('sidebar-left-opened')
    } else {
      $('body').removeClass('sidebar-left-opened')
    }
  }
  render() {
    return (
      <>
        <div className="row mar">
          <div className="col-md-12 thrd-nav">
            <div className="col-md-12 pl-0 pr-0 mobile-menu">
              <QuoteModal  
                toolbar={true} 
                designer={'wallpad'} 
                sendQuote={this.sendQuote} 
                message={'WALL PAD '}
                show={this.state.quoteModal}
                submitting={this.state.submittingQuote}
                toggleModal={(val) => this.setState({quoteModal: val})} 
              />
              <SaveModal toolbar={true} save={this.save} />
              <EmailModal toolbar={true} email_design={this.email_design} />
              <a className="toolbar-btn" onClick={this.download}>
                <img
                    className="fas"
                    src={require("./../../../assets/img/download.png")}
                    style={{marginTop:'-3px'}}
                    alt="UNDO"
                />
                <span className="hide-on-smaller-screen">Download</span></a>
              <a className="toolbar-btn" href='/'>
                <img
                    className="fas"
                    src={require("./../../../assets/img/info.png")}
                    style={{marginTop:'-3px'}}
                    alt="INFO"
                />
                <span className="hide-on-smaller-screen">Info</span></a>
              <a className="toolbar-btn" onClick={this.undo}><img
                  className="fas"
                  src={require("./../../../assets/img/undo.png")}
                  style={{marginTop:'-3px'}}
                  alt="UNDO"
              /><span className="hide-on-smaller-screen">Undo</span></a>
              <a className="toolbar-btn" onClick={this.redo}><img
                  src={require("./../../../assets/img/redo.png")}
                  style={{marginTop:'-3px'}}
                  alt="REDO"
              /><span className="hide-on-smaller-screen">Redo</span></a>

              <a className="toolbar-btn" onClick={this.clearCanvas}><img
                  src={require("./../../../assets/img/delete.png")}
                  style={{marginTop:'-3px'}}
                  alt="REDO"
              /><span className="hide-on-smaller-screen">Clear</span></a>


              <div className="nav navbar-nav navbar-right">
                <CanvasZoomConnect/>
              </div>
            </div>
            <ul className="nav navbar-nav navbar-right formobview mobul">
              <li>
                <a onClick={this.download}>
                  <img
                      src={require('./../../../assets/img/download.png')}
                      alt="DOWNLOAD"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                      src={require('./../../../assets/img/info.png')}
                      alt="INFO"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row mar sectogglebtn formobview">
          <a
              data-placement="bottom"
              data-original-title="Show/Hide"
              data-toggle="tooltip"
              className="btn btn-sm toggle-left"
              id="menu-toggle"
              onClick={() => this.menuToggle()}
          >
            <img src={require('./../../../assets/img/menu.png')} alt="MENU" />
          </a>
        </div>
      </>
    )
  }
}

const clearCanvas = () => {
  return {
    type: "CLAER_CANVAS",
    isCanvasCleared: true,
  };
};
const setCanvasConfig = _config => {
  return {
    type: "WALLPAD_SET_CONFIG",
    _config
  };
};

const mapStateToProps = state => {
  return {
    canvas: state.canvas,
    _config: state._config,
    wallpad_config:state.wallpad_config,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
      {
        clearCanvas,
        setCanvasConfig
      },
      dispatch
  );
};

const ExtraMenuConnect = connect(mapStateToProps,mapDispatchToProps)(ExtraMenu)

export default ExtraMenuConnect
