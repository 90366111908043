import React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import $ from 'jquery'

import InfoModal from '../InfoModal';
import SaveModal from "../SaveModal";
import EmailModal from "../EmailModal";
import QuoteModal from "../QuoteModal";
import CanvasZoomConnect from "../../zoomTool/ZoomTool";
import axios from "axios";
import {bindActionCreators} from "redux";
import {PaintColorHex, PaintColorName, SwainColorHex} from "../ColorCodeHelper";

class ExtraMenu extends React.Component {
  state = {
    submittingQuote: false,
    quoteModal: false,
  }
  componentDidMount = () => {}

  undo = e => {
    e.preventDefault();
    let tempConfig = this.props._config;
    if (tempConfig.undoFinishedStatus) {
      if (tempConfig.currentStateIndex === 0) {
        tempConfig.undoStatus = false;
      } else {
        if (tempConfig.canvasState.length >= 0) {
          tempConfig.undoFinishedStatus = 0;
          if (tempConfig.currentStateIndex !== 0) {
            tempConfig.undoStatus = true;
            this.props.canvas.loadFromJSON(
                tempConfig.canvasState[tempConfig.currentStateIndex - 1],
                () => {
                  this.props.canvas.renderAll();
                  tempConfig.undoStatus = false;
                  tempConfig.currentStateIndex -= 1;
                  tempConfig.undoButton = "remove disabled";
                  if (
                      tempConfig.currentStateIndex !==
                      tempConfig.canvasState.length - 1
                  ) {
                    tempConfig.redoButton = "remove disabled";
                  }
                  tempConfig.undoFinishedStatus = 1;
                }
            );
          } else if (tempConfig.currentStateIndex === 0) {
            this.props.canvas.clear();
            tempConfig.undoFinishedStatus = 1;
            tempConfig.undoButton = "disabled";
            tempConfig.redoButton = "remove disabled";
            tempConfig.currentStateIndex -= 1;
          }
        }
      }
    }

    this.props.setCanvasConfig(tempConfig);
  };

  redo = e => {
    e.preventDefault();
    let tempConfig = this.props._config;
    if (tempConfig.redoFinishedStatus) {
      if (
          tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
          tempConfig.currentStateIndex !== -1
      ) {
        tempConfig.redoButton = "disabled";
      } else {
        if (
            tempConfig.canvasState.length > tempConfig.currentStateIndex &&
            tempConfig.canvasState.length !== 0
        ) {
          tempConfig.redoFinishedStatus = 0;
          tempConfig.redoStatus = true;
          this.props.canvas.loadFromJSON(
              tempConfig.canvasState[tempConfig.currentStateIndex + 1],
              () => {
                this.props.canvas.renderAll();
                tempConfig.redoStatus = false;
                tempConfig.currentStateIndex += 1;
                if (tempConfig.currentStateIndex !== -1) {
                  tempConfig.undoButton = "remove disabled";
                }
                tempConfig.redoFinishedStatus = 1;
                if (
                    tempConfig.currentStateIndex ===
                    tempConfig.canvasState.length - 1 &&
                    tempConfig.currentStateIndex !== -1
                ) {
                  tempConfig.redoButton = "disabled";
                }
              }
          );
        }
      }
    }

    this.props.setCanvasConfig(tempConfig);
  };

  save = (title, email) => {
    const bgSvg = this.props.canvas.backgroundImage.toSVG();
    const bg = this.props.canvas.backgroundImage;
    this.props.canvas.backgroundImage = null;
    const cnvPng = this.props.canvas.toDataURL('png');
    this.props.canvas.setBackgroundImage(bg);
    this.props.canvas.renderAll();
    let jsonData = this.props.canvas.toJSON();
    jsonData.wrestling_config = this.props.wrestling_config;
    const data = {
      data: JSON.stringify(jsonData),
      bgSvg: bgSvg,
      cnvPng: cnvPng,
      email,
      title,
      url: `${window.location.origin}/wrestling_save_designer/`
    };
    axios.post("/api/save_design", data).then(res => {
      alert("Your Design Has been saved! We have sent you an email with details.");
    });
  };

  email_design = (title, email) => {
    let jsonData = this.props.canvas.toJSON();
    jsonData.wrestling_config = this.props.wrestling_config;
    const data = {
      data: JSON.stringify(jsonData),
      email,
      title,
      url: `${window.location.origin}/wrestling_save_designer/`
    };

    axios.post("/api/save_design", data)
        .then(res => {
          alert('We have sent you an email with details.');
        }).catch(res => {
      alert('An unexpected error occurred. Please contact Admin');
    })
  };

  download = e => {
    this.clearSelection();
    e.preventDefault()
    let download = document.createElement('a')
    download.href = this.props.canvas.toDataURL()
    download.download = 'Dollamur_mat.png'
    download.click()
  }
  clearSelection = () =>{
    this.props.canvas.deactivateAll().renderAll()
    // copying clear selection event data
    //$('.deleteBtn').css('visibility', 'hidden')
    $('.material-color-pane').css({ display: 'none' })
    $('.circle-color-pane').css({ display: 'none' })
    $('.text-color-pane').css({ display: 'none' })
    $('.artwork-color-pane').css({ display: 'none' })
    $('.custom-colors').css('display', 'none')
    $('.advanced_span').css('display', 'none')
    $('#rgb').css('display', 'none')
    $('#cmyk').css('display', 'none')

    $('#bold').removeClass('active')
    $('#italic').removeClass('active')
    $('#underline').removeClass('active')
    $('#capital').removeClass('active')
    $('#left-align').removeClass('active')
    $('#center-align').removeClass('active')
    $('#right-align').removeClass('active')
    //$('.slct-font-size').val($('.slct-font-size option:first').val())
    //$('.slct-font-fam').val($('.slct-font-fam option:first').val())
    $('#line_ht_get').val(1)
    $('#line_ht_get').css({
      background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
    })
    $('#line_ht_set').val(1)
    $('#spacing_get').val(1)
    $('#spacing_get').css({
      background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
    })
    $('#spacing_set').val(1)
    $('#text-color-bind').css({ background: 'black' })
    $('#size_get').val(0)
    $('#size_get').css({
      background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
    })
    $('#size_set').val(0)
    $('#outline-color-bind').css({ background: 'black' })
    $('#selected_text').val('Add Text')
    $('.add-text-btn').text('Add Text')
  }
  findOpaqueOuterCircle=(size)=>{
    let circles = this.props.canvas.getObjects('circle'),
        outerCircles=[];
    let finalOuterCircle = {
      fill:null,
      stroke:null,
      size
    }
    // seperate circles by types
    // @todo: use for loop
    circles.forEach(circle=>{
      if(circle.isOuterCircle){
        outerCircles.push({
          size : this.props.circle_outer_diameter,
          stroke: circle.strokeWidth>0 ? this.reduceDesignColor(circle.stroke):{hex:'transparent', name:'Transparent'},
          fill:circle.fill === 'rgba(0,0,0,0)'?{hex:'transparent', name:'Transparent'}:this.reduceDesignColor(circle.fill)
        })
      }
    })
    //find outer opaque circle
    outerCircles.forEach((outerCircle,index)=>{
      if(outerCircle.fill.hex !== 'transparent')finalOuterCircle.fill = outerCircle.fill;
      if(outerCircle.stroke.hex !== 'transparent')finalOuterCircle.stroke = outerCircle.stroke;
    })
    if(finalOuterCircle.fill === null && finalOuterCircle.stroke === null) return null
    return(finalOuterCircle)
  }
  findOpaqueCenterCircle=(size)=>{
    let circles = this.props.canvas.getObjects('circle'),
        allCircles=[];
    let finalOuterCircles = []
    // seperate circles by types
    // @todo: use for loop
    circles.forEach((circle)=>{
      if(circle.isCenterCircle){
        allCircles.push({
          type:'center',
          stroke: circle.strokeWidth>0 ? this.reduceDesignColor(circle.stroke):{hex:'transparent', name:'Transparent'},
          fill:circle.fill === 'rgba(0,0,0,0)'?{hex:'transparent', name:'Transparent'}:this.reduceDesignColor(circle.fill)
        })
      }else if(circle.isOuterCircle){
        allCircles.push({
          type:'outer',
          stroke: circle.strokeWidth>0 ? this.reduceDesignColor(circle.stroke):{hex:'transparent', name:'Transparent'},
          fill:circle.fill === 'rgba(0,0,0,0)'?{hex:'transparent', name:'Transparent'}:this.reduceDesignColor(circle.fill)
        })
      }
    })
    //find outer opaque circle
    let lastCenterCircle = {
      fill:null,
      stroke:null,
      size,
    };
    allCircles.forEach((circle,index)=>{
      let finalOuterCircle = {
        fill:null,
        stroke:null,
        type:circle.type
      }
      if(circle.fill.hex !== 'transparent')finalOuterCircle.fill = circle.fill;
      if(circle.stroke.hex !== 'transparent')finalOuterCircle.stroke = circle.stroke;
      finalOuterCircles.push(finalOuterCircle);
    })
    for(let i = finalOuterCircles.length-1 ; i>=0 ; i-- ){
      if(finalOuterCircles[i].type === 'center'){
        lastCenterCircle.stroke  = finalOuterCircles[i].stroke;
        break;
      }
    }
    for(let i = finalOuterCircles.length-1 ; i>=0 ; i-- ){
      if(finalOuterCircles[i].fill){
        lastCenterCircle.fill  = finalOuterCircles[i].fill;
        break;
      }
    }
    if(lastCenterCircle.fill === null && lastCenterCircle.stroke === null) return null
    return lastCenterCircle;
  }
  sendQuote = ({ fullName: name, email, phone, zipcode, subject, message }) => {
    this.clearSelection();
    this.setState({submittingQuote:true})
    const thickness = $('input[name="mat-thickness"]:checked').val();
    const matData = [{}];
    let allLogos = [];
    // matData[0].matSize = (this.props.wrestling_config.width * 2) + " x " + (this.props.wrestling_config.height * 2);
    if (
        this.props.wrestling_config.width === "12" ||
        this.props.wrestling_config.width === 12
        // this.props.wrestling_config.width === 30
    ) {
      matData[0].matSize =
          this.props.wrestling_config.width +
          "m x " +
          this.props.wrestling_config.height +
          "m";
    } else {
      matData[0].matSize =
          this.props.wrestling_config.width +
          "ft x " +
          this.props.wrestling_config.height+"ft";
    }
    matData[0].matColor = this.props.mat_color;
    let matFonts = [];
    let matShapes = [];
    if (this.props.canvas.getObjects("path-group").length > 0){
      let shapes = this.props.canvas.getObjects("path-group");
      shapes.forEach((item,index)=>{
        console.log({index,item});
        let paths = item.paths;
        let colors =[];
        paths.forEach(path=>{
          let hex = this.reduceDesignColorMin(path.fill)
          if(!colors.includes(hex)){
            colors.push(hex);
          }
        })
        matShapes.push({
          colors : colors,
        })
      })
      matData[0].shapes = matShapes;
    }
    if (this.props.canvas.getObjects("curvedText").length > 0) {
      let curvedTexts = this.props.canvas.getObjects("curvedText");
      curvedTexts.forEach((item,index)=>{
        console.log({index,item});
        if(item && item.text !== ''){
          matFonts.push({
            text : item.text,
            fontFamily : item.fontFamily,
            fill : item.fill,
            outline: item.stroke,
            outlineSize: item.strokeWidth,
          })
        }
      })

      matData[0].text = this.props.text_style ? this.props.text_style.text : "";
      matData[0].font = this.props.text_style
          ? this.props.text_toolbar_config.fontFamily
          : "";
      matData[0].fontColor = this.props.text_toolbar_config.fill;
    }
    matData[0].curvedTexts = matFonts;
    if(this.props.canvas.getObjects('circle').length > 0){
      if (
          this.props.circle_outer_diameter === 260 &&
          (this.props.wrestling_config.width === "12" ||
              this.props.wrestling_config.width === 12 ||
              this.props.wrestling_config.width === 30)
      ) {
        matData[0].circle = "9m x 9m";
      } else {
        matData[0].circle =
            this.props.circle_outer_diameter +
            "ft x " +
            this.props.circle_inner_diameter+"ft";
      }
    }else{
      matData[0].circle = "";
    }

    if(matData[0].circle == "0ft x 0ft"){
      matData[0].circle = "";
    }
    if(matData[0].circle && this.props.canvas.getObjects('circle').length > 0){
      matData[0].color_fill = this.reduceDesignColor(this.props.canvas.getObjects('circle')[0].fill);
      matData[0].color_stroke = this.reduceDesignColor(this.props.canvas.getObjects('circle')[0].stroke);
    }
    //adding practice circles
    if(this.props.canvas.getObjects('circle').length > 0){
      let practiceCircles = [];
      this.props.canvas.getObjects('circle').forEach(circle=>{
        //let
        //let newCircle = `${circle.strokeWidth>0 ? 'OUTLINE : '+circle.stroke:''} ${circle.fill === 'rgba(0,0,0,0)'?'':'FILL : '+circle.fill}`
        let newCircle = this.reduceDesignColor(circle.stroke);
        if(circle.radius === 43 && !circle.isCenterCircle){
          practiceCircles.push(newCircle)
        }
      })
      matData[0].practrice_circles = practiceCircles;
    }
    matData[0].outer_circle = this.findOpaqueOuterCircle(this.props.circle_outer_diameter);
    matData[0].center_circle = this.findOpaqueCenterCircle(this.props.circle_inner_diameter);
    if(this.props.canvas.getObjects('group').length > 0){
      this.props.canvas.getObjects('group').forEach(rect=>{
        if(rect.isStartMark){
          matData[0].start_marks = [
            this.reduceDesignColor(rect._objects[1].stroke),
            this.reduceDesignColor(rect._objects[2].stroke),
            this.reduceDesignColor(rect._objects[3].stroke),
          ]
        }
      })
    }
    if(this.props.canvas.getObjects('image').length > 0){
      let images = this.props.canvas.getObjects("image");
      images.forEach((image)=>{
        console.log(image.source)
        allLogos.push(image.source)
      })
    }
    matData[0].logos = allLogos;
    console.log(matData[0].circle);
    matData[0].thickness = thickness;

    const fc = $('input[name="mat-flexi-connect"]:checked').val();
    matData[0].fc = fc ? "Yes" : null;

    matData[0].svg = this.props.canvas.toSVG();
    matData[0].png = this.props.canvas.toDataURL();
    //matData[0].logos = this.props.uploadedLogos;

    const data = {
      name,
      email,
      phone,
      zipcode,
      subject:`${subject}`,
      message,
      matData,
    };
    console.log(data);
    axios
        .post("/api/quote", data)
        .then((res) => {
          this.setState({quoteModal: false, submittingQuote: false})
          alert("Successfully saved!");
        })
        .catch((err) => {
          this.setState({quoteModal: false, submittingQuote: false})
          alert("An unexpected error occurred. Please contact Admin");
        });
  };
  menuToggle = () => {
    let bodyClass = $('body').attr('class')
    if (
      bodyClass === undefined ||
      bodyClass.indexOf('sidebar-left-opened') < 0
    ) {
      $('body').addClass('sidebar-left-opened')
    } else {
      $('body').removeClass('sidebar-left-opened')
    }
  }
  clearCanvas = (e) => {
    e.preventDefault();
    this.props.canvas.clear();
    this.updateCanvasState();
    this.props.clearCanvas();
    const t = new window.fabric.Text('', { fontFamily: 'Times New Roman',left:-10 })
    this.props.canvas.add(t);
  };
  updateCanvasState = () => {
    let tempConfig = this.props._config;
    if (tempConfig.undoStatus === false && tempConfig.redoStatus === false) {
      let jsonData = this.props.canvas.toJSON(['source','isCenterCircle']);
      let canvasAsJson = JSON.stringify(jsonData);
      if (tempConfig.currentStateIndex < tempConfig.canvasState.length - 1) {
        let indexToBeInserted = tempConfig.currentStateIndex + 1;
        tempConfig.canvasState[indexToBeInserted] = canvasAsJson;

        let numberOfElementsToRetain = indexToBeInserted + 1;
        tempConfig.canvasState = tempConfig.canvasState.splice(
            0,
            numberOfElementsToRetain
        );
      } else {
        tempConfig.canvasState.push(canvasAsJson);
      }

      tempConfig.currentStateIndex = tempConfig.canvasState.length - 1;

      if (
          tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
          tempConfig.currentStateIndex !== -1
      ) {
        tempConfig.redoButton = "disabled";
      }
    }
    this.props.setCanvasConfig(tempConfig);
  };
  reduceDesignColorMin=(colorCode)=>{
    if(colorCode.indexOf('rgb') >= 0){
      let a = colorCode.split("(")[1].split(")")[0];
      a = a.split(",");
      let b = a.map(function(x){                      //For each array element
        x = parseInt(x).toString(16);      //Convert to a base16 string
        return (x.length==1) ? "0"+x : x; //Add zero if we get only one character
      });
      colorCode = "#"+b.join("");
    }
    return colorCode;
  }
  reduceDesignColor=(colorCode)=>{
    if(colorCode === 'rgba(0,0,0,0)'){
      return 'transparent';
    }
    if(colorCode.indexOf('rgb') >= 0){
      let a = colorCode.split("(")[1].split(")")[0];
      a = a.split(",");
      let b = a.map(function(x){                      //For each array element
        x = parseInt(x).toString(16);      //Convert to a base16 string
        return (x.length==1) ? "0"+x : x; //Add zero if we get only one character
      });
      colorCode = "#"+b.join("");
    }
    let colorName  = {hex:colorCode, name:colorCode}, // send color code and name to server
        colorNames = Object.keys(PaintColorHex);
    colorNames.forEach(color=>{
      if(PaintColorHex[color] === colorName.hex){
        colorName.name = PaintColorName[color]
      }
    })
    return colorName;
  }

  render() {
    return (
      <>
        <div className="row mar">
          <div className="col-md-12 thrd-nav">
            <div className="col-md-12 pl-0 pr-0 mobile-menu">
              <QuoteModal 
                toolbar={true}
                show={this.state.quoteModal}
                submitting={this.state.submittingQuote}
                toggleModal={(val) => this.setState({quoteModal: val})} 
                sendQuote={this.sendQuote}  
                designer={'wrestling'} 
                message={'WRESTLING MAT'} 
              />
              <SaveModal toolbar={true} save={this.save} />
              <EmailModal toolbar={true} email_design={this.email_design} />

              <a className="toolbar-btn" onClick={this.download}>
                <img
                    className="fas"
                    src={require("./../../../assets/img/download.png")}
                    style={{marginTop:'-3px'}}
                    alt="UNDO"
                />
                <span className="hide-on-smaller-screen">Download</span></a>
              <InfoModal toolbar={true} />

              <a className="toolbar-btn" onClick={this.undo}><img
                  className="fas"
                  src={require("./../../../assets/img/undo.png")}
                  style={{marginTop:'-3px'}}
                  alt="UNDO"
              /><span className="hide-on-smaller-screen">Undo</span></a>

              <a className="toolbar-btn" onClick={this.redo}><img
                  src={require("./../../../assets/img/redo.png")}
                  style={{marginTop:'-3px'}}
                  alt="REDO"
              /><span className="hide-on-smaller-screen">Redo</span></a>

              <a className="toolbar-btn" onClick={this.clearCanvas}>
                <img
                    src={require("./../../../assets/img/delete.png")}
                    style={{marginTop:'-3px'}}
                    alt="REDO"
                />
                <span className="hide-on-smaller-screen">Clear</span></a>


              <div className="nav navbar-nav navbar-right">
                <CanvasZoomConnect/>
              </div>

            </div>
            <ul className="nav navbar-nav navbar-right formobview mobul">
              <li>
                <a onClick={this.download}>
                  <img
                      style={{marginTop:'-3px'}}
                    src={require('./../../../assets/img/download.png')}
                    alt="DOWNLOAD"
                  />
                </a>
              </li>
              <li>
                <InfoModal />
              </li>
            </ul>
          </div>
        </div>
        <div className="row mar sectogglebtn formobview">
          <a
            data-placement="bottom"
            data-original-title="Show/Hide"
            data-toggle="tooltip"
            className="btn btn-sm toggle-left"
            id="menu-toggle"
            onClick={() => this.menuToggle()}
          >
            <img src={require('./../../../assets/img/menu.png')} alt="MENU" />
          </a>
        </div>
      </>
    )
  }
}

const clearCanvas = () => {
  return {
    type: "CLAER_CANVAS",
    isCanvasCleared: true,
  };
};

const setCanvasConfig = (_config) => {
  return {
    type: "SET_CONFIG",
    _config,
  };
};

const mapStateToProps = state => {
  return {
    canvas: state.canvas,
    _config: state._config,
    wrestling_config: state.wrestling_config,
    circle_outer_diameter: state.circle_outer_diameter,
    circle_inner_diameter: state.circle_inner_diameter,
    mat_color: state.mat_color,
    text_toolbar_config:state.text_toolbar_config
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
      {
        clearCanvas,
        setCanvasConfig
      },
      dispatch
  );
};

const ExtraMenuConnect = connect(mapStateToProps,mapDispatchToProps)(ExtraMenu)

export default ExtraMenuConnect
