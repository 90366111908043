export const VinColorName = {
    RoyalBlue:      'Royal Blue',
    Red:            'Red',
    Gold:           'Gold',
    Black:          'Black',
    Purple:         'Purple',
    Orange:         'Orange',
    DarkGreen:      'Dark Green',
    LightGrey:      'Light Grey',
    Navy:           'Navy',
    White:          'White',
    Brown:          'Brown',
    CharcoalGrey:   'Charcoal Grey',
    Maroon:         'Maroon',
    CarolinaBlue:   'Carolina Blue',
    UWWMats:        'UWW Mats',
    LightGreen:     'Light Green',
    Green:          'Green',
    Teal:           'Teal',
};

export const VinColorStandard = {
    RoyalBlue:      'PMS 293C',
    Red:            'PMS 200C',
    Gold:           'PMS 110C',
    Black:          'Process Black',
    Purple:         'PMS 269C',
    Orange:         'PMS 151C',
    DarkGreen:      'PMS 350C',
    LightGrey:      'PMS 428C',
    Navy:           'PMS 534C',
    White:          'Unknown',
    Brown:          'PMS 4695C',
    CharcoalGrey:   'PMS Cool Grey11C',
    Maroon:         'PMS 216C',
    CarolinaBlue:   'PMS 140',
    UWWMats:        'Unknown',
    LightGreen:     'PMS 7494C',
    Green:          'PMS 3425C',
    Teal:           'PMS 321C',
};

export const VinColorRGB = {
    RoyalBlue:      'rgb(0,61,167)',
    Red:            'rgb(194,4,48)',
    Gold:           'rgb(219,170,0)',
    Black:          'rgb(13,0,0)',
    Purple:         'rgb(82,45,109)',
    Orange:         'rgb(255,132,0)',
    DarkGreen:      'rgb(42,81,53)',
    LightGrey:      'rgb(194,198,201)',
    Navy:           'rgb(28,53,94)',
    White:          'rgb(255,255,255)',
    Brown:          'rgb(93,52,40)',
    CharcoalGrey:   'rgb(77,79,83)',
    Maroon:         'rgb(127,35,70)',
    CarolinaBlue:   'rgb(100,160,200)',
    UWWMats:        'rgb(0,44,95)',
    LightGreen:     'rgb(165,177,140)',
    Green:          'rgb(97,35,29)',
    Teal:           'rgb(0,137,150)',
};

export const VinColorHex = {
    RoyalBlue:      '#003da7',
    Red:            '#c20430',
    Gold:           '#dbaa00',
    Black:          '#0d0000',
    Purple:         '#522d6d',
    Orange:         '#ff5800',
    DarkGreen:      '#2a5135',
    LightGrey:      '#c2c6c9',
    Navy:           '#1c355e',
    White:          '#ffffff',
    Brown:          '#5d3428',
    CharcoalGrey:   '#4d4f53',
    Maroon:         '#7f2346',
    CarolinaBlue:   '#64a0c8',
    UWWMats:        '#002c5f',
    LightGreen:     '#a5b18c',
    Green:          '#006241',
    Teal:           '#008996',
};

export const PaintColorName = {
    White:              'White',
    MetallicSilver :    'Metallic Silver',
    LightGray:          'Light Gray',
    VegasGold:          'Vegas Gold',
    Yellow:             'Yellow',
    AthleticGold:       'Athletic Gold',
    MatVinylGold:       'MatVinyl Gold',
    Orange:             'Orange',
    Red:                'Red',
    Maroon:             'Maroon',
    Purple:             'Purple',
    Navy:               'Navy',
    RoyalBlue:          'Royal Blue',
    CarolinaBlue:       'Carolina Blue',
    Green:              'Green',
    DarkGreen:          'Dark Green',
    Brown:              'Brown',
    CharcoalGray:       'Charcoal Gray',
    Black:              'Black',
    UWWMat:            'UWW Mat',
};

export const PaintColorStandard = {
    White:              'Unknown',
    MetallicSilver :    'PMS 7543',
    LightGray:          'PMS 428',
    VegasGold:          'PMS 871',
    Yellow:             'PMS Yellow C',
    AthleticGold:       'PMS 1235',
    MatVinylGold:       'PMS 110',
    Orange:             'PMS 151',
    Red:                'PMS 200 ',
    Maroon:             'PMS 216',
    Purple:             'PMS 269',
    Navy:               'PMS 534',
    RoyalBlue:          'PMS 293',
    CarolinaBlue:       'PMS 542',
    Green:              'PMS 7727',
    DarkGreen:          'PMS 350',
    Brown:              'PMS 4695',
    CharcoalGray:       'Cool Gray 11',
    Black:              'Rich Black',
    UWWMat:            'Unknown',
};

export const PaintColorRGB = {
    White:              'rgb(255,255,255)',
    MetallicSilver :    'rgb(164,174,181)',
    LightGray:          'rgb(195,200,200)',
    VegasGold:          'rgb(134,117,79)',
    Yellow:             'rgb(254,223,0)',
    AthleticGold:       'rgb(251,173,74)',
    MatVinylGold:       'rgb(215,169,0)',
    Orange:             'rgb(255,121,0)',
    Red:                'rgb(183,18,52)',
    Maroon:             'rgb(124,35,72)',
    Purple:             'rgb(75,48,106)',
    Navy:               'rgb(38,63,106)',
    RoyalBlue:          'rgb(0,70,173)',
    CarolinaBlue:       'rgb(100,160,200)',
    Green:              'rgb(0,107,54)',
    DarkGreen:          'rgb(40,78,54)',
    Brown:              'rgb(82,45,36)',
    CharcoalGray:       'rgb(77,79,83)',
    Black:              'rgb(13,0,0)',
    UWWMat:            'rgb(0,44,95)',
};

export const PaintColorHex = {
    White:              '#ffffff',
    MetallicSilver :    '#a4aeb5',
    LightGray:          '#c3c8c8',
    VegasGold:          '#86754f',
    Yellow:             '#fedf00',
    AthleticGold:       '#ffae00',
    MatVinylGold:       '#d7a900',
    Orange:             '#ff5800',
    Red:                '#b71234',
    Maroon:             '#7c2348',
    Purple:             '#4b306a',
    Navy:               '#263f6a',
    RoyalBlue:          '#0046ad',
    CarolinaBlue:       '#64a0c8',
    Green:              '#006b36',
    DarkGreen:          '#284e36',
    Brown:              '#522d24',
    CharcoalGray:       '#4d4f53',
    Black:              '#0d0000',
    UWWMat:            '#002c5f',
};

export const SwainColorName = {
    RoyalBlue:  'Royal Blue',
    Red:        'Red',
    Gold:       'Gold',
    Black:      'Black',
    Gray:       'Gray',
    LightGreen: 'Light Green',
};

export const SwainColorStandard = {
    RoyalBlue:  'PMS 293',
    Red:        'PMS 200',
    Gold:       'PMS 110',
    Black:      'Rich Black ',
    Gray:       'Cool Gray 11',
    LightGreen: 'PMS 5783',
};

export const SwainColorHex = {
    RoyalBlue:  '#0046ad',
    Red:        '#b71234',
    Gold:       '#d7a900',
    Black:      '#0d0000',
    Gray:       '#4d4f53',
    LightGreen: '#a5b18c',
};


export const SwainColorRGB = {
    RoyalBlue:  'rgb(0,70,173)',
    Red:        'rgb(183,18,52)',
    Gold:       'rgb(215,169,0)',
    Black:      'rgb(13,0,0)',
    Gray:       'rgb(77,79,83)',
    LightGreen: 'rgb(165,177,140)',
};

export const TurfColorName = {
    Red:         'Red',
    Green:       'Green',
    RoyalBlue:   'Royal Blue',
    Black:       'Black',
    White:       'White',
    Gold:        'Gold',
    Brown:       'Brown',
    Clay:        'Clay',
    TanBlack:    'Tan w/ Black',
    TanWhite:    'Tan w/ White',
    Maroon:      'Marron',
    IndyBlue:    'Indy Blue',
    Orange:      'Orange',
    Navy:        'Navy',
    Silver:      'Silver',
};

export const TurfColorStandard = {
    Red:        'PMS 185',
    Green:      'PMS 575',
    RoyalBlue:  'PMS 293',
    Black:      'PMS Black',
    White:      'PMS White',
    Gold:       'PMS 143',
    Brown:      'PMS 4625',
    Clay:       'PMS 7526',
    TanBlack:   'PMS 728',
    TanWhite:   'PMS 728',
    Maroon:     'PMS 229',
    IndyBlue:   'PMS 280',
    Orange:     'PMS 166',
    Navy:       'PMS 282',
    Silver:     'PMS 423',
};

export const TurfColorHex = {
    Red:        '#e4002b',
    Green:      '#67823a',
    RoyalBlue:  '#003da5',
    Black:      '#0d0000',
    White:      '#ffffff',
    Gold:       '#f1b434',
    Brown:      '#4f2c1d',
    Clay:       '#8a391b',
    TanBlack:   '#cda177',
    TanWhite:   '#cda177',
    Maroon:     '#672146',
    IndyBlue:   '#012169',
    Orange:     '#e35205',
    Navy:       '#041e42',
    Silver:     '#898d8d',
};

export const TurfColorRGB = {
    Red:        'rgb(228,0,43)',
    Green:      'rgb(103,130,58)',
    RoyalBlue:  'rgb(0,61,165)',
    Black:      'rgb(13,0,0)',
    White:      'rgb(255,255,255)',
    Gold:       'rgb(241,180,52)',
    Brown:      'rgb(79,44,29)',
    Clay:       'rgb(138,57,27)',
    TanBlack:   'rgb(205,161,119)',
    TanWhite:   'rgb(205,161,119)',
    Maroon:     'rgb(103,33,70)',
    IndyBlue:   'rgb(1,33,105)',
    Orange:     'rgb(227,82,5)',
    Navy:       'rgb(4,30,66)',
    Silver:     'rgb(137,141,141)',
};
