import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { VinColorHex } from "./layout/ColorCodeHelper";

class PuzzleMat extends React.Component {
    componentDidMount() {
        if(this.props.puzzle_config.type === 'rubber-puzzle') {
            this.addRubberPuzzle();
            return;
        }
        const canvas_grid = this.props.canvas_grid
        const mat_type = this.props.mat_type
        const mat_color = this.props.mat_color
        const mat_class = this.props.mat_class;
        const mat_top = this.props.mat_top
        const mat_left = this.props.mat_left
        let width = 0
        let height = 0
        const unit = this.props.puzzle_config.length_unit === "FEET"? 3.28 : 1;
        console.log(mat_type);
        switch (mat_type) {
            case 1:
                width = canvas_grid * 2
                height = canvas_grid
                break
            case 2:
                width = canvas_grid
                height = canvas_grid * 2
                break
            case 3:
                width = canvas_grid
                height = canvas_grid
                break
            default:
                break
        }

        const colors = VinColorHex;

        let img = new Image();
        img.onload = ()=>{
            console.log('adding..................');
            let rect = new window.fabric.Image(img);
            let widthRatio = width * unit;
            let unitRatio = this.props.puzzle_config.length_unit === "FEET"? 3.17 : 1;
            let unitScale = this.props.puzzle_config.length_unit === "FEET"? 3.28 : 1.035;
            let lockRotation = true;
            if(this.props.puzzle_config.type === 'rubber-puzzle') {
                unitRatio = this.props.puzzle_config.length_unit === "FEET"? 3.17 : 1;
                unitScale = this.props.puzzle_config.length_unit === "FEET"? 3.31 : 1.035;
                lockRotation=false;
            }

            console.log(unitRatio);
            rect.set({
                left: Math.round(mat_left / canvas_grid / unitRatio) * canvas_grid * unitRatio,
                top: Math.round(mat_top / canvas_grid / unitRatio) * canvas_grid * unitRatio,
                width: width * unitScale,
                height: height * unitScale,
                //padding:(widthRatio*0.9)-widthRatio,
                mat_class:mat_class,
                mat_image:mat_color,
                lockRotation:lockRotation,
            });
            if(!lockRotation) {

                rect.set({
                    rubberAngle:0,
                });
            }
            setTimeout( () =>{
                rect['setControlVisible']('ml', false)
                rect['setControlVisible']('mb', false)
                rect['setControlVisible']('mr', false)
                rect['setControlVisible']('mt', false)
                rect['cornerSize'] = 6
                //rect['lockRotation'] = lockRotation;
                this.props.canvas.add(rect)
                this.props.canvas.renderAll()
                this.updateCanvasState()
            },0)
        }
        console.log(mat_color);
        img.src = require(`./../assets/img/${mat_color}.png`);

        /*const rect = new window.fabric.Rect({
            left: Math.round(mat_left / canvas_grid / unit) * canvas_grid * unit,
            top: Math.round(mat_top / canvas_grid / unit) * canvas_grid * unit,
            width: width * unit,
            height: height * unit,
            //fill: colors[mat_color],
        })*/
    }

    addRubberPuzzle = () =>{
        const canvas_grid = this.props.canvas_grid
        const mat_type = this.props.mat_type
        const mat_color = this.props.mat_color
        const mat_class = this.props.mat_class;
        const mat_top = this.props.mat_top
        const mat_left = this.props.mat_left
        let width = 0
        let height = 0
        const unit = this.props.puzzle_config.length_unit === "FEET"? 3.28 : 1;
        console.log(mat_type);
        switch (mat_type) {
            case 1:
                width = canvas_grid * 2
                height = canvas_grid
                break
            case 2:
                width = canvas_grid
                height = canvas_grid * 2
                break
            case 3:
                width = canvas_grid
                height = canvas_grid
                break
            default:
                break
        }

        const colors = VinColorHex;

        let img = new Image();
        img.onload = ()=>{
            console.log('adding..................rubber puzzle');
            let rect = new window.fabric.Image(img),
                widthRatio = width * unit,
                // unitRatio = this.props.puzzle_config.length_unit === "FEET"? 3.17 : 1,
                // unitScale = this.props.puzzle_config.length_unit === "FEET"? 3.31 : 1.035,
                unitRatio = this.props.puzzle_config.length_unit === "FEET"? 1.91 : 1,
                unitScale = this.props.puzzle_config.length_unit === "FEET"? 2.09 : 1.035,
                lockRotation = true,
                left=Math.round(mat_left / canvas_grid / unitRatio) * canvas_grid * unitRatio,
                top =Math.round(mat_top / canvas_grid / unitRatio) * canvas_grid * unitRatio;

            if(this.props.rubber_puzzle_type === 'straight') {
                unitScale = this.props.puzzle_config.length_unit === "FEET"? 2.0 : 1.0;
                unitRatio = this.props.puzzle_config.length_unit === "FEET"? 2 : 1;
            }
            rect.set({
                left:0,
                top:0,
                width: width * unitScale,
                height: height * unitScale,
                //padding:(widthRatio*0.9)-widthRatio,
                mat_class:mat_class,
                mat_image:mat_color,
                //lockRotation:lockRotation,
                rubberAngle:0,
            });
            let colorRect = new window.fabric.Rect({
                left:0,
                top:0,
                width: width * unitScale,
                height: height * unitScale,
                fill:'transparent',
                //padding:(widthRatio*0.9)-widthRatio,
                //lockRotation:lockRotation,

            });
            let group = new window.fabric.Group([rect,colorRect],{
                top,
                left,
                lockRotation,
                rubberAngle:0,
                // hasControls:false,
                // globalCompositeOperation:'destination-atop'
            });


            setTimeout( () =>{
                rect['setControlVisible']('ml', false)
                rect['setControlVisible']('mb', false)
                rect['setControlVisible']('mr', false)
                rect['setControlVisible']('mt', false)
                group['cornerSize'] = 6
                this.props.canvas.add(group)
                this.props.canvas.renderAll()
                this.updateCanvasState()
            },0)
        }
        console.log(mat_color);
        img.src = require(`./../assets/img/rubberPuzzle/${mat_color}.png`);
    }

    updateCanvasState = () => {
        let tempConfig = this.props._config
        if (tempConfig.undoStatus === false && tempConfig.redoStatus === false) {
            let jsonData = this.props.canvas.toJSON(['mat_class','mat_image'])
            let canvasAsJson = JSON.stringify(jsonData)
            if (tempConfig.currentStateIndex < tempConfig.canvasState.length - 1) {
                let indexToBeInserted = tempConfig.currentStateIndex + 1
                tempConfig.canvasState[indexToBeInserted] = canvasAsJson

                let numberOfElementsToRetain = indexToBeInserted + 1
                tempConfig.canvasState = tempConfig.canvasState.splice(
                    0,
                    numberOfElementsToRetain,
                )
            } else {
                tempConfig.canvasState.push(canvasAsJson)
            }

            tempConfig.currentStateIndex = tempConfig.canvasState.length - 1

            if (
                tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
                tempConfig.currentStateIndex !== -1
            ) {
                tempConfig.redoButton = 'disabled'
            }
        }
        this.props.setCanvasConfig(tempConfig)
    }

    render() {
        return null
    }
}

const setCanvasConfig = _config => {
    return {
        type: 'SET_CONFIG',
        _config,
    }
}

const addRectToList = data => {
    return {
        type: "ADD_RECT_TO_LIST",
        data: data
    };
};

const mapStateToProps = state => {
    return {
        _config: state._config,
        canvas_grid: state.canvas_grid,
        mat_color: state.mat_color,
        mat_class: state.mat_class,
        mat_type: state.mat_type,
        mat_top: state.mat_top,
        mat_left: state.mat_left,
        puzzle_config: state.puzzle_config,
        rubber_puzzle_type: state.rubber_puzzle_type,
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            setCanvasConfig,
            addRectToList
        },
        dispatch,
    )
}

const PuzzleMatConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PuzzleMat)

export default PuzzleMatConnect
