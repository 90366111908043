import React from "react";
import * as RubberImages from "../../../../assets/img/rubbercolors";
import {VinColorHex} from "../../ColorCodeHelper";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

const setTurfConfig = _config => {
	return {
		type: 'SET_TURF_CONFIG',
		_config
	}
}

const setRubberTurfConfig = _config =>{
	return {
		type:'SET_RUBBER_TURF_CONFIG',
		_config
	}
}

class TurfRubberColorToolbar extends React.Component {
	constructor() {
		super();
		this.state = {
			rubberColor: 'blueSmall10',
			thickness:'1/4"'
		};
	}

	setThickness(value) {
		let config = this.props.rubber_turf_config;
		config.thickness = value;
		this.props.setRubberTurfConfig(config);
		this.updateCanvasState();
	}

	setRubberMat(name, image,thumbnail,thickness=null) {
		console.log(thickness)
		window.fabric.Image.fromURL(image, (img) => {
			img.scaleToWidth(img.width*0.5);
			let patternSourceCanvas = new window.fabric.StaticCanvas();
			patternSourceCanvas.add(img);

			this.props.canvas.backgroundColor = new window.fabric.Pattern({
				source: function() {
					patternSourceCanvas.setDimensions({
						width: img.height*img.scaleY-50,
						height: img.width*img.scaleX-50
					});
					return patternSourceCanvas.getElement();
				},
				repeat: 'repeat'
			});
			this.props.canvas.renderAll();
		})
		this.setState({rubberColor: thumbnail,thickness:thickness});
		let rubberConfig = this.props.rubber_turf_config;
		rubberConfig.rubberColor = name;
		this.props.setRubberTurfConfig(rubberConfig);
		this.updateCanvasState();
		if(thickness) this.setThickness(thickness);
	}

	updateCanvasState (){
		let tempConfig = this.props._config;
		if (tempConfig.undoStatus === false && tempConfig.redoStatus === false) {
			let jsonData = this.props.canvas.toJSON(['custom','name']);
			let canvasAsJson = JSON.stringify(jsonData);
			if (tempConfig.currentStateIndex < tempConfig.canvasState.length - 1) {
				let indexToBeInserted = tempConfig.currentStateIndex + 1;
				tempConfig.canvasState[indexToBeInserted] = canvasAsJson;

				let numberOfElementsToRetain = indexToBeInserted + 1;
				tempConfig.canvasState = tempConfig.canvasState.splice(
					0,
					numberOfElementsToRetain
				);
			} else {
				tempConfig.canvasState.push(canvasAsJson);
			}

			tempConfig.currentStateIndex = tempConfig.canvasState.length - 1;

			if (
				tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
				tempConfig.currentStateIndex !== -1
			) {
				tempConfig.redoButton = "disabled";
			}
		}
		this.props.setCanvasConfig(tempConfig);
	};

	render() {
		const {rubberColor} = this.state;
		const rubberThickness = this.state.thickness;
		const {thickness} = this.props.rubber_turf_config;
		const borderColor = 'gainsboro';
		return (
			<>
				<div className="row adddiv">
					<h2>Roll Rubber Color</h2>
				</div>
				<div className="templatesmain mat-lft-blk-menu">
					<div className="row">
						<div className="col-md-12 mt-15 pb-15">
							<div className="row row-border-btm" style={{marginTop:'10px'}}>
								<span style={{color: 'white', fontStyle: 'italic'}}>
				                Color Density 10%
				              </span>
							</div>
							<div className="row">
								<span style={{color: 'white', fontStyle: 'italic',display:'block'}}>
									1/4"
								</span>
							</div>
							<div className="row">
								<div style={{marginRight:'10%',marginLeft:'10%'}}>
									<div className="preset-color-row">
										<div
											className="pre-clr-box clr-pr4 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.blackSmall10})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'blackSmall10' && rubberThickness==='1/4"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Black 10%', RubberImages.black10,'blackSmall10','1/4"')}
										>
											<span className="tooltiptext">Black 10%</span>
										</div>
										<div
											className="pre-clr-box clr-pr4 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.gerySmall10})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'gerySmall10' && rubberThickness==='1/4"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Grey 10%', RubberImages.grey10,'gerySmall10','1/4"')}
										>
											<span className="tooltiptext">Grey 10%</span>
										</div>
										<div
											className="pre-clr-box clr-pr1 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.blueSmall10})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'blueSmall10' && rubberThickness==='1/4"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Blue 10%', RubberImages.blue10,'blueSmall10','1/4"')}
										>
											<span className="tooltiptext">Blue 10%</span>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<span style={{color: 'white', fontStyle: 'italic',display:'block'}}>
									5/16"
								</span>
							</div>
							<div className="row">
								<div style={{marginRight:'10%',marginLeft:'10%'}}>
									<div className="preset-color-row">
										<div
											className="pre-clr-box clr-pr4 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.blackSmall10})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'blackSmall10' && rubberThickness==='5/16"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Black 10%', RubberImages.black10,'blackSmall10','5/16"')}
										>
											<span className="tooltiptext">Black 10%</span>
										</div>
										<div
											className="pre-clr-box clr-pr4 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.gerySmall10})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'gerySmall10' && rubberThickness==='5/16"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Grey 10%', RubberImages.grey10,'gerySmall10','5/16"')}
										>
											<span className="tooltiptext">Grey 10%</span>
										</div>
										<div
											className="pre-clr-box clr-pr1 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.blueSmall10})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'blueSmall10' && rubberThickness==='5/16"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Blue 10%', RubberImages.blue10,'blueSmall10','5/16"')}
										>
											<span className="tooltiptext">Blue 10%</span>
										</div>
									</div>
									<div className="preset-color-row">
										<div
											className="pre-clr-box clr-pr2 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.blueGreySmall10})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'blueGreySmall10' && rubberThickness==='5/16"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Blue Grey 10%', RubberImages.blueGrey10,'blueGreySmall10','5/16"')}
										>
											<span className="tooltiptext">Blue Grey 10%</span>
										</div>
										<div
											className="pre-clr-box clr-pr3 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.tanSmall10})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'tanSmall10' && rubberThickness==='5/16"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Tan 10%', RubberImages.tanSmall10,'tanSmall10','5/16"')}
										>
											<span className="tooltiptext">Tan 10%</span>
										</div>
										<div
											className="pre-clr-box clr-pr3 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.brickSmall10})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'brickSmall10' && rubberThickness==='5/16"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Brick 10%', RubberImages.brick10,'brickSmall10','5/16"')}
										>
											<span className="tooltiptext">Brick 10%</span>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<span style={{color: 'white', fontStyle: 'italic',display:'block'}}>
									3/8"
								</span>
							</div>
							<div className="row">
								<div style={{marginRight:'10%',marginLeft:'10%'}}>
									<div className="preset-color-row">
										<div
											className="pre-clr-box clr-pr4 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.blackSmall10})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'blackSmall10' && rubberThickness==='3/8"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Black 10%', RubberImages.black10,'blackSmall10','3/8"')}
										>
											<span className="tooltiptext">Black 10%</span>
										</div>
										<div
											className="pre-clr-box clr-pr4 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.gerySmall10})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'gerySmall10' && rubberThickness==='3/8"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Grey 10%', RubberImages.grey10,'gerySmall10','3/8"')}
										>
											<span className="tooltiptext">Grey 10%</span>
										</div>
										<div
											className="pre-clr-box clr-pr1 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.blueSmall10})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'blueSmall10' && rubberThickness==='3/8"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Blue 10%', RubberImages.blue10,'blueSmall10','3/8"')}
										>
											<span className="tooltiptext">Blue 10%</span>
										</div>
									</div>
									<div className="preset-color-row">
										<div
											className="pre-clr-box clr-pr2 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.blueGreySmall10})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'blueGreySmall10' && rubberThickness==='3/8"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Blue Grey 10%', RubberImages.blueGrey10,'blueGreySmall10','3/8"')}
										>
											<span className="tooltiptext">Blue Grey 10%</span>
										</div>
										<div
											className="pre-clr-box clr-pr3 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.tanSmall10})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'tanSmall10' && rubberThickness==='3/8"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Tan 10%', RubberImages.tanSmall10,'tanSmall10','3/8"')}
										>
											<span className="tooltiptext">Tan 10%</span>
										</div>
										<div
											className="pre-clr-box clr-pr3 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.brickSmall10})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'brickSmall10' && rubberThickness==='3/8"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Brick 10%', RubberImages.brick10,'brickSmall10','3/8"')}
										>
											<span className="tooltiptext">Brick 10%</span>
										</div>
									</div>
									<div className="preset-color-row">
										<div
											className="pre-clr-box clr-pr4 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.greenSmall10})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'greenSmall10' && rubberThickness==='3/8"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Green 10%', RubberImages.green10,'greenSmall10','3/8"')}
										>
											<span className="tooltiptext">Green 10%</span>
										</div>
										<div
											className="pre-clr-box clr-pr4 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.yellowSmall10})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'yellowSmall10' && rubberThickness==='3/8"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Yellow 10%', RubberImages.yellow10,'yellowSmall10','3/8"')}
										>
											<span className="tooltiptext">Yellow 10%</span>
										</div>
										<div
											className="pre-clr-box clr-pr4 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.purpleSmall10})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'purpleSmall10' && rubberThickness==='3/8"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Purple 10%', RubberImages.purple10,'purpleSmall10','3/8"')}
										>
											<span className="tooltiptext">Purple 10%</span>
										</div>
									</div>
								</div>
							</div>

							{/* START COLOR DENSITY 20 */}
							<div className="row row-border-btm" style={{marginTop:'10px'}}>
								<span style={{color: 'white', fontStyle: 'italic'}}>
				                Color Density 20%
				              </span>
							</div>
							<div className="row">
								<span style={{color: 'white', fontStyle: 'italic',display:'block'}}>
									3/8"
								</span>
							</div>
							<div className="row ">
								<div style={{marginRight:'10%',marginLeft:'10%'}}>
									<div className="preset-color-row">
										<div
											className="pre-clr-box clr-pr9 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.greySmall20})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'greySmall20' && rubberThickness==='3/8"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Grey 20%', RubberImages.grey20,'greySmall20','3/8"')}
										>
											<span className="tooltiptext">Grey 20%</span>
										</div>
										<div
											className="pre-clr-box clr-pr7 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.blueSmall20})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'blueSmall20' && rubberThickness==='3/8"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Blue 20%', RubberImages.blue20,'blueSmall20','3/8"')}
										>
											<span className="tooltiptext">Blue 20%</span>
										</div>
										<div
											className="pre-clr-box clr-pr10 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.redSmall20})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'redSmall20' && rubberThickness==='3/8"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Red 20%', RubberImages.red20,'redSmall20','3/8"')}
										>
											<span className="tooltiptext">Red 20%</span>
										</div>
									</div>
									<div className="preset-color-row">
										<div
											className="pre-clr-box clr-pr8 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.brownSmall20})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'brownSmall20' && rubberThickness==='3/8"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Brown 20%', RubberImages.brown20,'brownSmall20','3/8"')}
										>
											<span className="tooltiptext">Brown 20%</span>
										</div>
										<div
											className="pre-clr-box clr-pr1 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.goldSmall20})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'goldSmall20' && rubberThickness==='3/8"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Gold 20%', RubberImages.gold20,'goldSmall20','3/8"')}
										>
											<span className="tooltiptext">Gold 20%</span>
										</div>
										<div
											className="pre-clr-box clr-pr11 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.whiteSmall20})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'whiteSmall20' && rubberThickness==='3/8"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('White 20%', RubberImages.white20,'whiteSmall20','3/8"')}
										>
											<span className="tooltiptext">White 20%</span>
										</div>
									</div>
									<div className="preset-color-row">
										<div
											className="pre-clr-box clr-pr12 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.blueWhiteGreySmall20})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'blueWhiteGreySmall20' && rubberThickness==='3/8"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Gery White Blue 20%', RubberImages.blueWhiteGrey20,'blueWhiteGreySmall20','3/8"')}
										>
											<span className="tooltiptext">Gery White Blue 20%</span>
										</div>
										<div className="pre-clr-box tooltip" style={{visibility:'hidden'}}>
											<span className="tooltiptext"></span>
										</div>
										<div className="pre-clr-box tooltip" style={{visibility:'hidden'}}>
											<span className="tooltiptext"></span>
										</div>
									</div>
								</div>
							</div>

							{/* END COLOR DENSITY 20*/}



							{/* START COLOR DENSITY 35 */}
							<div className="row row-border-btm" style={{marginTop:'10px'}}>
								<span style={{color: 'white', fontStyle: 'italic'}}>
				                Color Density 35%
				              </span>
							</div>
							<div className="row">
								<span style={{color: 'white', fontStyle: 'italic',display:'block'}}>
									3/8"
								</span>
							</div>
							<div className="row">
								<div style={{marginRight:'10%',marginLeft:'10%'}}>
									<div className="preset-color-row">
										<div
											className="pre-clr-box clr-pr11 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.gerySmall35})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'gerySmall35' && rubberThickness==='3/8"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Gery 35%', RubberImages.gery35,'gerySmall35','3/8"')}
										>
											<span className="tooltiptext">Gery 35%</span>
										</div>
										<div
											className="pre-clr-box clr-pr11 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.blueSmall35})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'blueSmall35' && rubberThickness==='3/8"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Blue 35%', RubberImages.blue35,'blueSmall35','3/8"')}
										>
											<span className="tooltiptext">Blue 35%</span>
										</div>
										<div
											className="pre-clr-box clr-pr11 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.greenSmall35})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'greenSmall35' && rubberThickness==='3/8"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Green 35%', RubberImages.green35,'greenSmall35','3/8"')}
										>
											<span className="tooltiptext">Green 35%</span>
										</div>
									</div>
									<div className="preset-color-row">
										<div
											className="pre-clr-box clr-pr11 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.redSmall35})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'redSmall35' && rubberThickness==='3/8"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Red 35%', RubberImages.red35,'redSmall35','3/8"')}
										>
											<span className="tooltiptext">Red 35%</span>
										</div>
										<div
											className="pre-clr-box clr-pr11 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.tanSmall35})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'tanSmall35' && rubberThickness==='3/8"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Tan 35%', RubberImages.tan35,'tanSmall35','3/8"')}
										>
											<span className="tooltiptext">Tan 35%</span>
										</div>
										<div className="pre-clr-box tooltip" style={{visibility:'hidden'}}>
											<span className="tooltiptext"></span>
										</div>
									</div>
								</div>
							</div>

							{/* END COLOR DENSITY 35*/}


							{/* START COLOR DENSITY 95 */}
							<div className="row row-border-btm" style={{marginTop:'10px'}}>
								<span style={{color: 'white', fontStyle: 'italic'}}>
				                Color Density 95%
				              </span>
							</div>
							<div className="row">
								<span style={{color: 'white', fontStyle: 'italic',display:'block'}}>
									5/32"
								</span>
							</div>
							<div className="row">
								<div style={{marginRight:'10%',marginLeft:'10%'}}>
									<div className="preset-color-row">
										<div
											className="pre-clr-box clr-pr11 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.brown95})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'brown95' && rubberThickness==='5/32"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Brown 95%', RubberImages.brown95,'brown95','5/32"')}
										>
											<span className="tooltiptext">Brown 95%</span>
										</div>
										<div
											className="pre-clr-box clr-pr11 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.geryTan95})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'geryTan95' && rubberThickness==='5/32"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Gery Tan 95%', RubberImages.geryTan95,'geryTan95','5/32"')}
										>
											<span className="tooltiptext">Gery Tan 95%</span>
										</div>
										<div
											className="pre-clr-box clr-pr11 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.charcoalGery95})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'charcoalGery95' && rubberThickness==='5/32"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Charcoal Gery 95%', RubberImages.charcoalGery95,'charcoalGery95','5/32"')}
										>
											<span className="tooltiptext">Charcoal Gery 95%</span>
										</div>
									</div>
									<div className="preset-color-row">
										<div
											className="pre-clr-box clr-pr11 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.red95})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'red95' && rubberThickness==='5/32"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('RED 95%', RubberImages.red95,'red95','5/32"')}
										>
											<span className="tooltiptext">Red 95%</span>
										</div>
										<div
											className="pre-clr-box clr-pr11 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.tan95})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'tan95' && rubberThickness==='5/32"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Tan 95%', RubberImages.tan95,'tan95','5/32"')}
										>
											<span className="tooltiptext">Tan 95%</span>
										</div>
										<div
											className="pre-clr-box clr-pr11 tooltip"
											style={{
												backgroundImage: `url(${RubberImages.gery95})`,
												backgroundSize: 'contain',
												border : (rubberColor === 'gery95' && rubberThickness==='5/32"')?`2px solid ${borderColor}`:`0px solid ${borderColor}`
											}}
											onClick={() => this.setRubberMat('Gery 95%', RubberImages.gery95,'gery95','5/32"')}
										>
											<span className="tooltiptext">Gery 95%</span>
										</div>
									</div>
								</div>
							</div>
							{/* END DENSITY 95 */}

						</div>
					</div>
				</div>
			</>
		)
	}
}

const mapStateToPropsForRubberTurf = state => {
	return {
		_config:state._config,
		canvas: state.canvas,
		canvas_grid: state.canvas_grid,
		turf_config: state.turf_config,
		rubber_turf_config: state.rubber_turf_config,
	}
}
const setCanvasConfig = (_config) => {
	return {
		type: "SET_CONFIG",
		_config,
	};
}

const mapDispatchToPropsForRubberTurf = dispatch => {
	return bindActionCreators({setTurfConfig,setRubberTurfConfig,setCanvasConfig}, dispatch);
}
const TurfRubberColorToolbarConnect = connect(
	mapStateToPropsForRubberTurf, mapDispatchToPropsForRubberTurf
)(TurfRubberColorToolbar)

class TurfRubberColorToolbarSidebar extends React.Component {
	closeNav16 = () => {
		document.getElementById("mySidenav16").style.width = "0";
	};

	render() {
		return (
			<>
				<div
					id="turf-rubber-color"
					className="h100 second-menu fordeskview"
				>
					<TurfRubberColorToolbarConnect/>
				</div>
				<div
					className="text-center h100 pad0 mtopneg sidenav4 formobview wo"
					id="mySidenav16"
				>
					<a
						style={{color: "#fff"}}
						href="#"
						className="closebtn"
						onClick={() => this.closeNav16()}
					>
						&times;
					</a>
					<TurfRubberColorToolbarConnect/>
				</div>
			</>
		);
	}
}

export default TurfRubberColorToolbarSidebar;
