import Circle1 from './Circle1'
import Circle2 from './Circle2'
import Circle3 from './Circle3'
import Circle4 from './Circle4'
import Circle5 from './Circle5'
import Circle6 from './Circle6'
import Circle7 from './Circle7'
import Circle8 from './Circle8'

export {
  Circle1,
  Circle2,
  Circle3,
  Circle4,
  Circle5,
  Circle6,
  Circle7,
  Circle8,
}
