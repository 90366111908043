import React from "react";
import $ from "jquery";
import {connect} from "react-redux";

class Sidebar extends React.Component {
	componentDidMount = () => {
		const designMode = this.props.turf_config.design_mode;
		if(designMode === "1"){
			$("#turf-color").show();
			$("#menu li.nav.mats").addClass("active");
			$(`#menu li.nav.turf-color`).addClass("active");
		}
		else {
			$("#turf-rubber-color").show();
			$(`#menu li.nav.turf-rubber-color`).addClass("active");
		}

	};

	setActiveToolbar = e => {
		e.preventDefault();
		let target = $(e.target).attr("data-target");
		$(".second-menu").hide();
		$("#" + target).show();
		$(`#menu li.nav.active`).removeClass("active");
		$(`#menu li.nav.${target}`).addClass("active");
	};

	showSecondCustom = (width) => {
		document.getElementById("second-custom-right").style.display = "block";
		document.getElementById("second-custom-right").style.width = `calc(100% - ${width}px)`;
	}

	openNav1 = e => {
		document.getElementById("mySidenav1").style.width = "300px";
		this.showSecondCustom(300)
	};

	openNav2 = () => {
		document.getElementById("mySidenav2").style.width = "300px";
		this.showSecondCustom(300)
	};

	openNav3 = () => {
		document.getElementById("mySidenav3").style.width = "300px";
		this.showSecondCustom(300)
	};

	openNav4 = () => {
		document.getElementById("mySidenav4").style.width = "300px";
		this.showSecondCustom(300)
	};

	openNav12 = () => {
		document.getElementById("mySidenav12").style.width = "300px";
		this.showSecondCustom(300)
	};
	openNav13 = () => {
		document.getElementById("mySidenav13").style.width = "300px";
		this.showSecondCustom(300)
	};
	openNav16 = () => {
		document.getElementById("mySidenav16").style.width = "300px";
		this.showSecondCustom(300)
	};

	openNav =(index)=>{
		document.getElementById(`mySidenav${index}`).style.width = "300px";
		this.showSecondCustom(300)
	}

	handleClose = () => {
		$('body').removeClass('sidebar-left-opened')
	}

	render() {
		const designMode = this.props.turf_config.design_mode;
		return (
			<>
				<div id="left" className="custom-left">
					{
						designMode === "1" && (
							<ul id="menu" className="bg-blue dker fordeskview">
								<li className="nav turf-color active">
									<a
										href="#"
										data-target="turf-color"
										className="leftMenu"
										onClick={this.setActiveToolbar}
									>
										<img
											className="blk"
											src={require("./../../../assets/img/mats.png")}
											data-target="turf-color"
											onClick={this.setActiveToolbar}
											alt="Turf Color"
										/>
										<span
											className="link-title"
											data-target="turf-color"
											onClick={this.setActiveToolbar}
										>
				                  Turf Color
				                </span>
									</a>
								</li>

								<li className="nav hash-color">
									<a
										href="#"
										data-target="hash-color"
										className="leftMenu"
										onClick={this.setActiveToolbar}
									>
										<img
											className="blk"
											src={require("./../../../assets/img/templates.png")}
											data-target="hash-color"
											onClick={this.setActiveToolbar}
											alt="Templates"
										/>
										<span
											className="link-title"
											data-target="hash-color"
											onClick={this.setActiveToolbar}
										>
									Add Hashmarks
				                </span>
									</a>
								</li>

								<li className="nav text-mark">
									<a
										href="#"
										data-target="text-mark"
										className="leftMenu"
										onClick={this.setActiveToolbar}
									>
										<img
											className="blk"
											src={require("./../../../assets/img/addtext.png")}
											data-target="text-mark"
											onClick={this.setActiveToolbar}
											alt="Templates"
										/>
										<span
											className="link-title"
											data-target="text-mark"
											onClick={this.setActiveToolbar}
										>
									Add Number
				                </span>
									</a>
								</li>
								<li className="nav turf-shape">
									<a
										href="#"
										data-target="turf-shape"
										className="leftMenu"
										onClick={this.setActiveToolbar}
									>
										<img
											className="blk"
											src={require("./../../../assets/img/addlogo.png")}
											data-target="turf-shape"
											onClick={this.setActiveToolbar}
											alt="Add Logo"
										/>
										<span
											className="link-title"
											data-target="turf-shape"
											onClick={this.setActiveToolbar}
										>
				                  Add Shape
				                </span>
									</a>
								</li>
								<li className="nav logo">
									<a
										href="#"
										data-target="logo"
										className="leftMenu"
										onClick={this.setActiveToolbar}
									>
										<img
											className="blk"
											src={require("./../../../assets/img/addlogo.png")}
											data-target="logo"
											onClick={this.setActiveToolbar}
											alt="Add Logo"
										/>
										<span
											className="link-title"
											data-target="logo"
											onClick={this.setActiveToolbar}
										>
				                  Add Logo
				                </span>
									</a>
								</li>
							</ul>
						)
					}
					{
						designMode === "2" && (
							<ul id="menu" className="bg-blue dker fordeskview">
								<li className="nav turf-rubber-color active">
									<a
										href="#"
										data-target="turf-rubber-color"
										className="leftMenu"
										onClick={this.setActiveToolbar}
									>
										<img
											className="blk"
											src={require("./../../../assets/img/mats.png")}
											data-target="turf-rubber-color"
											onClick={this.setActiveToolbar}
											alt="Turf Color"
										/>
										<span
											className="link-title"
											data-target="turf-rubber-color"
											onClick={this.setActiveToolbar}
										>
				                  Rubber Color
				                </span>
									</a>
								</li>
								<li className="nav rubber-turf-color">
									<a
										href="#"
										data-target="rubber-turf-color"
										className="leftMenu"
										onClick={this.setActiveToolbar}
									>
										<img
											className="blk"
											src={require("./../../../assets/img/mats.png")}
											data-target="rubber-turf-color"
											onClick={this.setActiveToolbar}
											alt="Turf Color"
										/>
										<span
											className="link-title"
											data-target="rubber-turf-color"
											onClick={this.setActiveToolbar}
										>
				                  Turf Color
				                </span>
									</a>
								</li>
								<li className="nav rubber-hash-color">
									<a
										href="#"
										data-target="rubber-hash-color"
										className="leftMenu"
										onClick={this.setActiveToolbar}
									>
										<img
											className="blk"
											src={require("./../../../assets/img/templates.png")}
											data-target="rubber-hash-color"
											onClick={this.setActiveToolbar}
											alt="Templates"
										/>
										<span
											className="link-title"
											data-target="rubber-hash-color"
											onClick={this.setActiveToolbar}
										>
									Add Hashmarks
				                </span>
									</a>
								</li>
								<li className="nav rubber-text-mark">
									<a
										href="#"
										data-target="rubber-text-mark"
										className="leftMenu"
										onClick={this.setActiveToolbar}
									>
										<img
											className="blk"
											src={require("./../../../assets/img/addtext.png")}
											data-target="rubber-text-mark"
											onClick={this.setActiveToolbar}
											alt="Templates"
										/>
										<span
											className="link-title"
											data-target="rubber-text-mark"
											onClick={this.setActiveToolbar}
										>
									Add Number
				                </span>
									</a>
								</li>
								<li className="nav rubber-turf-shape">
									<a
										href="#"
										data-target="rubber-turf-shape"
										className="leftMenu"
										onClick={this.setActiveToolbar}
									>
										<img
											className="blk"
											src={require("./../../../assets/img/addlogo.png")}
											data-target="rubber-turf-shape"
											onClick={this.setActiveToolbar}
											alt="Add Logo"
										/>
										<span
											className="link-title"
											data-target="rubber-turf-shape"
											onClick={this.setActiveToolbar}
										>
				                  Add Shape
				                </span>
									</a>
								</li>
								<li className="nav logo">
									<a
										href="#"
										data-target="logo"
										className="leftMenu"
										onClick={this.setActiveToolbar}
									>
										<img
											className="blk"
											src={require("./../../../assets/img/addlogo.png")}
											data-target="logo"
											onClick={this.setActiveToolbar}
											alt="Add Logo"
										/>
										<span
											className="link-title"
											data-target="logo"
											onClick={this.setActiveToolbar}
										>
				                  Add Logo
				                </span>
									</a>
								</li>
							</ul>
						)
					}
					{
						designMode === "3" && (
							<ul id="menu" className="bg-blue dker fordeskview">
								<li className="nav turf-rubber-color">
									<a
										href="#"
										data-target="turf-rubber-color"
										className="leftMenu"
										onClick={this.setActiveToolbar}
									>
										<img
											className="blk"
											src={require("./../../../assets/img/mats.png")}
											data-target="turf-rubber-color"
											onClick={this.setActiveToolbar}
											alt="Turf Color"
										/>
										<span
											className="link-title"
											data-target="turf-rubber-color"
											onClick={this.setActiveToolbar}
										>
				                  Rubber Color
				                </span>
									</a>
								</li>
								<li className="nav logo">
									<a
										href="#"
										data-target="logo"
										className="leftMenu"
										onClick={this.setActiveToolbar}
									>
										<img
											className="blk"
											src={require("./../../../assets/img/addlogo.png")}
											data-target="logo"
											onClick={this.setActiveToolbar}
											alt="Add Logo"
										/>
										<span
											className="link-title"
											data-target="logo"
											onClick={this.setActiveToolbar}
										>
				                  Add Logo
				                </span>
									</a>
								</li>
							</ul>
						)
					}

					{
						designMode === "1" && (
							<ul id="menu" className="bg-blue dker formobview">
								<li className="nav-divider"></li>

								<li onClick={()=>{this.openNav(12)}}>
									<a href="#" data-target="mats" className="leftMenu">
										<img
											className="blk"
											src={require("./../../../assets/img/mats.png")}
											alt="Mats"
										/>
										<span className="link-title">&nbsp;Turf Color</span>
									</a>
								</li>
								<li onClick={()=>{this.openNav(13)}}>
									<a href="#" data-target="mats" className="leftMenu">
										<img
											className="blk"
											src={require("./../../../assets/img/templates.png")}
											alt="Mats"
										/>
										<span className="link-title">&nbsp;Add Hashmarks</span>
									</a>
								</li>
								<li onClick={()=>{this.openNav(14)}}>
									<a href="#" data-target="mats" className="leftMenu">
										<img
											className="blk"
											src={require("./../../../assets/img/addtext.png")}
											alt="Mats"
										/>
										<span className="link-title">&nbsp;Add Number</span>
									</a>
								</li>
								<li onClick={()=>{this.openNav(15)}}>
									<a href="#" data-target="mats" className="leftMenu">
										<img
											className="blk"
											src={require("./../../../assets/img/addlogo.png")}
											alt="Mats"
										/>
										<span className="link-title">&nbsp;Add Shape</span>
									</a>
								</li>
								<li onClick={()=>{this.openNav(4)}}>
									<a href="#" data-target="mats" className="leftMenu">
										<img
											className="blk"
											src={require("./../../../assets/img/addlogo.png")}
											alt="Mats"
										/>
										<span className="link-title">&nbsp;Add Logo</span>
									</a>
								</li>

							</ul>
						)
					}
					{
						designMode === "2" && (
							<ul id="menu" className="bg-blue dker formobview">
								<li className="nav-divider"></li>

								<li onClick={()=>{this.openNav(16)}}>
									<a href="#" data-target="mats" className="leftMenu">
										<img
											className="blk"
											src={require("./../../../assets/img/mats.png")}
											alt="Mats"
										/>
										<span className="link-title">&nbsp;Rubber Color</span>
									</a>
								</li>
								<li onClick={()=>{this.openNav(11)}}>
									<a href="#" data-target="mats" className="leftMenu">
										<img
											className="blk"
											src={require("./../../../assets/img/mats.png")}
											alt="Mats"
										/>
										<span className="link-title">&nbsp;Turf Color</span>
									</a>
								</li>
								<li onClick={()=>{this.openNav(17)}}>
									<a href="#" data-target="mats" className="leftMenu">
										<img
											className="blk"
											src={require("./../../../assets/img/addtext.png")}
											alt="Mats"
										/>
										<span className="link-title">&nbsp;Add Hashmarks</span>
									</a>
								</li>
								<li onClick={()=>{this.openNav(18)}}>
									<a href="#" data-target="mats" className="leftMenu">
										<img
											className="blk"
											src={require("./../../../assets/img/addtext.png")}
											alt="Mats"
										/>
										<span className="link-title">&nbsp;Add Number</span>
									</a>
								</li>
								<li onClick={()=>{this.openNav(19)}}>
									<a href="#" data-target="mats" className="leftMenu">
										<img
											className="blk"
											src={require("./../../../assets/img/addlogo.png")}
											alt="Mats"
										/>
										<span className="link-title">&nbsp;Add Shape</span>
									</a>
								</li>
								<li onClick={()=>{this.openNav(4)}}>
									<a href="#" data-target="mats" className="leftMenu">
										<img
											className="blk"
											src={require("./../../../assets/img/addlogo.png")}
											alt="Mats"
										/>
										<span className="link-title">&nbsp;Add Logo</span>
									</a>
								</li>

							</ul>
						)
					}
					{
						designMode === "3" && (
							<ul id="menu" className="bg-blue dker formobview">
								<li className="nav-divider"></li>

								<li onClick={()=>{this.openNav(16)}}>
									<a href="#" data-target="mats" className="leftMenu">
										<img
											className="blk"
											src={require("./../../../assets/img/addlogo.png")}
											alt="Mats"
										/>
										<span className="link-title">&nbsp;Rubber Color</span>
									</a>
								</li>
								<li onClick={()=>{this.openNav(4)}}>
									<a href="#" data-target="mats" className="leftMenu">
										<img
											className="blk"
											src={require("./../../../assets/img/addlogo.png")}
											alt="Mats"
										/>
										<span className="link-title">&nbsp;Add Logo</span>
									</a>
								</li>

							</ul>
						)
					}
				</div>
				<div className="custom-right" onClick={this.handleClose}/>
			</>
		);
	}
}
const mapStateToProps = state => {
	return {
		turf_config: state.turf_config,
	}
}


export default connect(
	mapStateToProps,
)(Sidebar)
