import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import $ from "jquery";
import "../assets/css/rubberPuzzle.css";
import {VinColorHex} from "../components/layout/ColorCodeHelper";
import OptionSettingDialogue from "../components/layout/puzzle_mat_designer/OptionSettingDialogue";
import Sidebar from "../components/layout/puzzle_mat_designer/Sidebar";
import SidebarItems from "../components/layout/puzzle_mat_designer/SidebarItems";
import ExtraMenu from "../components/layout/puzzle_mat_designer/ExtraMenu";
import {Container} from "shards-react";
import DesignCanvas from "../components/layout/puzzle_mat_designer/DesignCanvas";
import Rect from "../components/PuzzleMat";
import Image from "../components/Image";
import Text from "../components/Text";
import Template1 from "../components/PuzzleTemplates/Template1";
import Template2 from "../components/PuzzleTemplates/Template2";
import Template3 from "../components/PuzzleTemplates/Template3";
import Template4 from "../components/PuzzleTemplates/Template4";
import Template5 from "../components/PuzzleTemplates/Template5";
import CurvedText from "../components/CurvedText";
import Alert from "../components/Alert";
import TemplateRubber1 from "../components/RubberPuzzleTemplates/templateRubber1";

class Dashboard extends Component {
	state = {
		showAlert: false,
		firstAlert: true, // added to show tooltip only once even if object is removed
	};

	componentDidMount() {
		const {id} = this.props.match.params;
		console.log(id)
		if (id !== undefined) {
			this.props.setSaveDesignID(id);
		}

		$(".material-color-pane").css({visibility: "hidden"});
		$(".text-color-pane").css({display: "none"});
	}

	handleCloseAlert = () => {
		this.setState({showAlert: false});
	};

	updateCanvasState = () => {
		//@todo: need to be reimplemented
		return;
		let tempConfig = this.props._config;
		if (tempConfig.undoStatus === false && tempConfig.redoStatus === false) {
			let jsonData = this.props.canvas.toJSON();
			let canvasAsJson = JSON.stringify(jsonData);
			if (tempConfig.currentStateIndex < tempConfig.canvasState.length - 1) {
				let indexToBeInserted = tempConfig.currentStateIndex + 1;
				tempConfig.canvasState[indexToBeInserted] = canvasAsJson;

				let numberOfElementsToRetain = indexToBeInserted + 1;
				tempConfig.canvasState = tempConfig.canvasState.splice(
					0,
					numberOfElementsToRetain
				);
			} else {
				tempConfig.canvasState.push(canvasAsJson);
			}

			tempConfig.currentStateIndex = tempConfig.canvasState.length - 1;

			if (
				tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
				tempConfig.currentStateIndex !== -1
			) {
				tempConfig.redoButton = "disabled";
			}
		}
		this.props.setCanvasConfig(tempConfig);
	};

	showEditArtWorkDialogue = () => {
		$(".material-color-pane").css({visibility: "hidden"});
	};

	deleteSelectedMaterial = () => {
		let activeObject = this.props.canvas._activeObject;
		if (activeObject) {
			this.props.canvas.remove(activeObject);
			this.props.removeRect(activeObject);
		}
		let activeGroup = this.props.canvas.getActiveGroup();
		console.log('group ', activeGroup);
		if (activeGroup) {
			let objects = activeGroup._objects;
			this.props.canvas.deactivateAll().renderAll();
			objects.forEach(item => {
				this.props.removeRect(item);
				this.props.canvas.remove(item);
			})
		}
		this.updateCanvasState();
		this.clearSelection();
	};

	clearSelection = () => {
		$('.deleteBtn').css('visibility', 'hidden')
		$('.material-color-pane').css({display: 'none'})
		$('.text-color-pane').css({display: 'none'})

		$('#bold').removeClass('active')
		$('#italic').removeClass('active')
		$('#underline').removeClass('active')
		$('#capital').removeClass('active')
		$('#left-align').removeClass('active')
		$('#center-align').removeClass('active')
		$('#right-align').removeClass('active')
		$('.slct-font-size').val($('.slct-font-size option:first').val())
		$('.slct-font-fam').val($('.slct-font-fam option:first').val())
		$('#line_ht_get').val(1)
		$('#line_ht_get').css({
			background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
		})
		$('#line_ht_set').val(1)
		$('#spacing_get').val(1)
		$('#spacing_get').css({
			background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
		})
		$('#spacing_set').val(1)
		$('#text-color-bind').css({background: 'black'})
		$('#size_get').val(0)
		$('#size_get').css({
			background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
		})
		$('#size_set').val(0)
		$('#outline-color-bind').css({background: 'black'})
		$('#selected_text').val('sampletext')
		$('.add-text-btn').text('Add Text')
	}

	clearCanvas = (e) => {
		e.preventDefault();
		this.props.canvas.clear();
		window.scrollTo(0, 0);
		this.updateCanvasState();
		this.props.clearCanvas();
		clearTimeout(this.popupTimer);
		this.setState({showAlert: false, firstAlert: true});
	};

	tooltipHandler = (alert, first = false) => {
		if (alert) {
			this.setState({showAlert: true, firstAlert: false});
			this.popupTimer = setTimeout(() => {
				this.setState({showAlert: false});
			}, 5000);
		}
		if (alert === false) {
			this.setState({showAlert: false});
		}
	};
	clearSelection = () => {
		this.props.canvas.deactivateAll().renderAll();
		$(".deleteBtn").css("visibility", "hidden");
		$(".material-color-pane").css({display: "none"});
		$(".text-color-pane").css({display: "none"});

		$("#bold").removeClass("active");
		$("#italic").removeClass("active");
		$("#underline").removeClass("active");
		$("#capital").removeClass("active");
		$("#left-align").removeClass("active");
		$("#center-align").removeClass("active");
		$("#right-align").removeClass("active");
		//$('.slct-font-size').val($('.slct-font-size option:first').val())
		//$('.slct-font-fam').val($('.slct-font-fam option:first').val())
		$("#line_ht_get").val(1);
		$("#line_ht_get").css({
			background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
		});
		$("#line_ht_set").val(1);
		$("#spacing_get").val(1);
		$("#spacing_get").css({
			background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
		});
		$("#spacing_set").val(1);
		$("#text-color-bind").css({background: "black"});
		$("#size_get").val(0);
		$("#size_get").css({
			background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
		});
		$("#size_set").val(0);
		$("#outline-color-bind").css({background: "black"});
		$("#selected_text").val("sampletext");
		$(".add-text-btn").text("Add Text");
	};
	reduceDesignColor = (colorCode) => {
		if (colorCode.indexOf('rgb') >= 0) {
			let a = colorCode.split("(")[1].split(")")[0];
			a = a.split(",");
			let b = a.map(function (x) {                      //For each array element
				x = parseInt(x).toString(16);      //Convert to a base16 string
				return (x.length === 1) ? "0" + x : x; //Add zero if we get only one character
			});
			colorCode = "#" + b.join("");
		}
		let colorName = colorCode,
			colorNames = Object.keys(VinColorHex);
		colorNames.forEach(color => {
			if (VinColorHex[color] === colorName) {
				colorName = color;
			}
		})
		return colorName;
	}
	onOutOfCanvas = (activeObject) => {
		console.log("called");
		let out = false;
		activeObject.setCoords();
		let cords = activeObject.oCoords;
		let originalHeight = this.props.canvas.height / this.props.canvas.getZoom();
		let originalWidth = this.props.canvas.width / this.props.canvas.getZoom();
		//if is out from top
		if (cords.tl.y < 0 && cords.tr.y < 0 && cords.br.y < 5 && cords.bl.y < 5) {
			out = true;
		}
		//if is out from left
		else if (cords.tl.x < 5 && cords.tr.x < 5 && cords.br.x < 5 && cords.bl.x < 5) {
			out = true;
		}
		//if is out from bottom
		else if (cords.tl.x > originalWidth && cords.bl.x > originalWidth) {
			out = true;
		}
		//if is out from right
		else if (cords.tr.y > originalHeight && cords.tl.y > originalHeight) {
			out = true;

		}
		return out;
	}

	emptyClick = () => {
		if (window.innerWidth < 768) {
			document.getElementById('second-custom-right').style.display = 'none'
			document.getElementById("mySidenav1") && (document.getElementById("mySidenav1").style.width = "0");
			document.getElementById("mySidenav2") && (document.getElementById("mySidenav2").style.width = "0");
			document.getElementById("mySidenav3") && (document.getElementById("mySidenav3").style.width = "0");
			document.getElementById("mySidenav4") && (document.getElementById("mySidenav4").style.width = "0");
			document.getElementById("mySidenav5") && (document.getElementById("mySidenav5").style.width = "0");
		}
	}

	render() {
		const components = this.props.components;
		const puzzle_config = this.props.puzzle_config;
		if (!puzzle_config.option_setting) {
			return <OptionSettingDialogue/>;
		}
		const unit = this.props.puzzle_config.length_unit === "FEET" ? "ft" : "m";
		const templateMessage = "TIP: Click on Template and Place on Grid"
		const matMessage = "TIP: Click on Mat and Place on Grid"
		return (
			<>
                <span id="tooltiptemplate" style={{visibility: 'hidden', width: 'fit-content', backgroundColor: 'grey', color: '#fff', textAlign: 'center', padding: '5px 5px', borderRadius: '6px', top: '45px', /* Position the tooltip text - see examples below! */position: 'absolute', zIndex: '9999', overflow: 'visible', fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif", fontSize: '14px', fontStyle: 'normal', fontWeight: 'normal',}}>{templateMessage}</span>
				<span id="tooltipmat" style={{visibility: 'hidden', width: 'fit-content', backgroundColor: 'grey', color: '#fff', textAlign: 'center', padding: '5px 5px', borderRadius: '6px', top: '45px', /* Position the tooltip text - see examples below! */position: 'absolute', zIndex: '9999', overflow: 'visible', fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif", fontSize: '14px', fontStyle: 'normal', fontWeight: 'normal',}}>{matMessage}</span>
				<span id="tooltipmatrubberpuzzle" style={{visibility: 'hidden', width: 'fit-content', backgroundColor: 'grey', color: '#fff', textAlign: 'center', padding: '5px 5px', borderRadius: '6px', top: '45px', /* Position the tooltip text - see examples below! */position: 'absolute', zIndex: '9999', overflow: 'visible', fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif", fontSize: '14px', fontStyle: 'normal', fontWeight: 'normal',}}>Please select a mat type</span>
				<span id="tooltiprubbermat" style={{visibility: 'hidden', width: 'fit-content', backgroundColor: 'grey', color: '#fff', textAlign: 'center', padding: '5px 5px', borderRadius: '6px', top: '45px', /* Position the tooltip text - see examples below! */position: 'absolute', zIndex: '9999', overflow: 'visible', fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif", fontSize: '14px', fontStyle: 'normal', fontWeight: 'normal',}}>Please select a mat type</span>
				<div id="second-custom-right" onClick={this.emptyClick}/>
				<Sidebar/>
				<div className="col-md-4 col-sm-4 text-center pad0 leftpanel">
					<SidebarItems/>
				</div>
				<div id="content">
					<div className="outer">
						<div className="inner bg-light lter">
							<ExtraMenu/>
							<div className="row ml0 mar" style={{background: "#ebf6f8"}}>
								<div className="text-center main-content custom-main-content">
									<div className="main-edit-content">
										<p className="matsize">
                                            <span> MAT SIZE : {puzzle_config.width} {unit} x {puzzle_config.height} {unit} </span>
										</p>
										<div className="addcontdiv">
											<div className="inneraddcontdiv">
												<Container
													fluid
													className="main-content-container px-4 scroll-pad"
												>
													<DesignCanvas
														//showAlert={this.state.showAlert}
														tooltipHandler={this.tooltipHandler}
													>
														{components.map((component, idx) => {
															switch (component.type) {
																case "rectangle":
																	return <Rect key={idx}/>;
																case "image":
																	return <Image key={idx}/>;
																case "text":
																	return <Text key={idx}/>;
																case "template1":
																	return <Template1 key={idx}/>;
																case "template2":
																	return <Template2 key={idx}/>;
																case "template3":
																	return <Template3 key={idx}/>;
																case "template4":
																	return <Template4 key={idx}/>;
																case "template5":
																	return <Template5 key={idx}/>;
																case "rubberTemplate1":
																	return <TemplateRubber1 key={idx} />
																default:
																	return <CurvedText key={idx}/>;
															}
														})}
													</DesignCanvas>
													{this.state.showAlert && !this.state.firstAlert && (
														<Alert
															onClick={this.handleCloseAlert}
															isOpen={true}
														/>
													)}
												</Container>
											</div>
										</div>
										<div id="drag_image"/>
										<div id="drag_template"/>
										<div id="drag_template"/>
										<div id="preview_image"/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}

const setSaveDesignID = (id) => {
	return {
		type: "SET_SAVED_ID",
		id: id,
	};
};

const setCanvasConfig = (_config) => {
	return {
		type: "SET_CONFIG",
		_config,
	};
};

const clearCanvas = () => {
	return {
		type: "CLAER_CANVAS",
		isCanvasCleared: true,
	};
};

const removeRect = (data) => {
	return {
		type: "REMOVE_RECT",
		data,
	};
};

const setFontColorOption = (option) => {
	return {
		type: "SET_FONT_COLOR_OPTION",
		option,
	};
};

const mapStateToProps = (state) => {
	return {
		_config: state._config,
		components: [...state.components],
		components_type: [...state.components_type],
		puzzle_config: state.puzzle_config,
		canvas: state.canvas,
		selectedMaterialColor: state.selectedMaterialColor,
		textColorOption: state.textColorOption,
		rects: state.rects,
		text_style: state.text_style,
		fontColor: state.fontColor,
		uploadedLogos: state.uploadedLogos,
		canvas_grid: state.canvas_grid,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setSaveDesignID,
			setCanvasConfig,
			clearCanvas,
			removeRect,
			setFontColorOption,
		},
		dispatch
	);
};

const PuzzleDesigner = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default PuzzleDesigner;