import React from 'react'
import {WallPadToolSidebar, LogoToolSidebar} from './Items'
import TextToolBar from "../../TextTool";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

class SidebarItemsDesktop extends React.Component {
    emptyClick = () => {
        document.getElementById('second-custom-right').style.display = 'none'
        document.getElementById("mySidenav1").style.width = "0";
        document.getElementById("mySidenav3").style.width = "0";
        document.getElementById("mySidenav11") && (document.getElementById("mySidenav11").style.width = "0");
        setTimeout(() => {
            this.props.setTextConfigPanel(false)
        }, 300)
    }

    render() {
        return (
            <>
                <WallPadToolSidebar/>
                {this.props.is_text_toolbar_active && (
                    <TextToolBar/>
                )}
                <LogoToolSidebar/>
                <div id="second-custom-right" onClick={this.emptyClick}/>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        is_text_toolbar_active: state.is_text_toolbar_active,
    }
}
const setTextConfigPanel = (status) => {
    return {
        type: 'TEXT_TOOLBAR_CONFIG',
        is_text_toolbar_active: status,
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            setTextConfigPanel,
        },
        dispatch,
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SidebarItemsDesktop)
