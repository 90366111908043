import React, {useState, useEffect} from 'react'
import {useTable, useSortBy, useRowSelect} from 'react-table'
import axios from "axios";
import {Form, Modal, Dropdown} from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import LoginForm from "../User/LoginForm";
import ChangePasswordForm from '../User/ChangePasswordForm';

/**
 * Checkbox for rows selection.
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{readonly indeterminate?: *}> & React.RefAttributes<unknown>>}
 */
const IndeterminateCheckbox = React.forwardRef(
    ({indeterminate, ...rest}, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)

/**
 * Table functional component.
 * @param columns - List of columns.
 * @param data - Arrays of data.
 * @param onChangeSort - Functions for sorting.
 * @param onSelect - Function for select row.
 * @returns {*}
 * @constructor
 */
function Table({columns, data, onChangeSort, onSelect}) {
    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state: {sortBy, selectedRowIds}} = useTable(
        {columns, data, manualSortBy: true, initialState: {sortBy: [{id: 'created_at', desc: true}]}},
        useSortBy,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => {
                return [
                    {
                        id: 'selection',
                        groupByBoundary: true,
                        Header: ({getToggleAllRowsSelectedProps}) => (
                            <div>
                                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                            </div>
                        ),
                        Cell: ({row}) => (
                            <div>
                                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                            </div>
                        ),
                    },
                    ...columns,
                ]
            })
        }
    )

    useEffect(() => {
        onChangeSort(sortBy);
    }, [onChangeSort, sortBy]);

    useEffect(() => {
        onSelect(selectedRowIds);
    }, [selectedRowIds]);


    return (
        <div>
            <table {...getTableProps()} className="w3-table-all w3-card-4">
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>

                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                                {column.isSorted
                                    ? column.isSortedDesc
                                        ? ' ↓'
                                        : ' ↑'
                                    : ''}
                              </span>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>

    )
}

export default function QuoteTable() {
    const [quotes, setQuotes] = useState([]);
    const [per_page, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(null);
    const [search, setSearch] = useState('');
    const [total, setTotal] = useState(null);
    const [desc, setDesc] = useState(null);
    const [orderBy, setOrderBy] = useState(null);
    const [selectedIds, setSelectedIds] = useState([]);
    const [deleteCount, setDeleteCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isLogged, setIsLogged] = useState(false);
    const [username, setUsername] = useState(false);

    /**
     * Description modal.
     * @param props
     * @returns {*}
     * @constructor
     */
    const DescModal = props => {
        const [show, setShow] = useState(false)

        const handleClose = () => setShow(false)
        const handleShow = () => setShow(true)

        return (
            <>
                <a href="#!" onClick={handleShow}>View</a>
                <Modal
                    show={show}
                    onHide={handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="modalDesc"
                >
                    <div className="modal-body" dangerouslySetInnerHTML={{__html: props.desc.cell.value}}>
                    </div>
                </Modal>
            </>
        )
    }

    /**
     * Confirm delete modal.
     * @param props - Properties object
     * @param props.onDelete - Function for delete selected object after confirm.
     * @returns {*}
     * @constructor
     */
    const DeleteModal = props => {
        const [show, setShow] = useState(false)

        const handleClose = () => setShow(false)
        const handleShow = () => setShow(true)

        return (
            <>
                <a onClick={handleShow}><i className="fas fa-trash-alt"></i></a>
                <Modal
                    show={show}
                    onHide={handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="modalDesc"
                >
                    <div className="modal-body">
                        <p>
                            Are you sure want to delete?
                        </p>
                    </div>

                    <div className="modal-footer">
                        <div className="row">
                            <div className="col-md-6">
                                <button type="button" className="btn btn-danger btn-block" onClick={props.onDelete}>Yes</button>
                            </div>
                            <div className="col-md-6">
                                <button type="button" className="btn btn-secondary btn-block" onClick={handleClose}>No</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        )
    }


    /**
     * Table columns.
     */
    const columns = React.useMemo(
        () => [
            {
                Header: 'No',
                accessor: 'order_id',
                disableSortBy: true
            },
            {
                Header: 'Full Name',
                accessor: 'name',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Phone Number',
                accessor: 'phone',
            },
            {
                Header: 'Delivery ZipCode',
                accessor: 'zipcode',
            },
            {
                Header: 'Subject',
                accessor: 'subject',
            },
            {
                Header: 'Description',
                accessor: 'description',
                Cell: props => <DescModal desc={props}/>
            },
            {
                Header: 'Message',
                accessor: 'message',
            },
            {
                Header: 'Created at',
                accessor: 'created_at',
            }
        ],
        []
    )

    /**
     * Load user data from API.
     */
    const loadUser = () => {
        axios.get(`/api/user`).then(success => {
            console.log('loadUser', success)
            setIsLoading(false);
            setIsLogged(true);
            setUsername(success.data.username)

        }, error => {
            setIsLoading(false);
            setIsLogged(false);
        })
    }

    useEffect(() => {
        loadUser();
    }, []);


    /**
     * Loading quotes from API with server-side pagination, filtering, sorting.
     * @returns {Promise<void>}
     */
    const fetchData = async () => {
        axios.get(`/api/quotes`, {
            params: {
                'per_page': per_page,
                'page': page,
                'search': search,
                'order_by': orderBy,
                'desc': desc
            }
        }).then(res => {
            let quotes = res.data.data;
            for (let idx in quotes) {
                let date = new Date(quotes[idx].created_at)
                quotes[idx].created_at = date.toLocaleString('en-US', {timeZone: "America/New_York"}) + ' EST'
                quotes[idx].order_id = (parseInt(idx)+1)+((page-1)*per_page)
                quotes[idx].message = quotes[idx].message.replace(/\\n/g, '')
            }

            setTotal(res.data.total)
            setQuotes(res.data.data);
            setPages(res.data.pages)
        })
    };

    useEffect(() => {
        fetchData();
    }, [search, per_page, page, desc, orderBy, deleteCount, isLogged]);

    /**
     * Change sorting handler.
     * @param data.id - Selected field for sorting.
     * @param data.desc - Is descending sort.
     */
    const handleChangeSort = data => {
        if (data.length > 0) {
            setOrderBy(data[0].id)
            setDesc(data[0].desc)
        } else {
            setOrderBy(null)
            setDesc(null)
        }
    }

    /**
     * Handling select rows in table.
     * @param rows - List of selected rows.
     */
    const handleSelect = rows => {
        let array_of_ids = []
        for (let row in rows) {
            array_of_ids.push(quotes[row].id)
        }
        setSelectedIds(array_of_ids)
    }

    useEffect((rows) => {
        let array_of_ids = []
        for (let row in rows) {
            array_of_ids.push(quotes[row].id)
        }
    }, [handleSelect]);


    /**
     * Function for delete each row in array.
     */
    const handleDelete = () => {
        for (let selectedId in selectedIds) {
            axios.delete(`/api/quote/${selectedIds[selectedId]}`)
        }
        setSelectedIds([]);
        setDeleteCount(deleteCount + 1)
    }

    /**
     * Load user after login.
     * @param data
     */
    const handleSubmitLogin = (data) => {
        loadUser()
    }

    /**
     * Call logout and clear state.
     */
    const handleLogout = () => {
        axios.get(`/api/logout`).then(success => {
            setIsLogged(false);
            setPage(1);
            setSearch('');
            setOrderBy(null);
            setDesc(null);
            setPerPage(10);
        })
    }

    if (isLoading) {
        return <div>
            Loading ...
        </div>
    }

    return (
        <div className="int-matbuild">
            {
                !isLogged ?
                    <LoginForm handleSubmit={handleSubmitLogin}/> :
                    <>
                        <div className="profile">
                            <Dropdown>
                                <Dropdown.Toggle as="div" drop="left">
                                    {username} <span><i className="fas fa-user-circle"></i></span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item><ChangePasswordForm handleSubmit={d=>{}}/></Dropdown.Item>
                                    <Dropdown.Item><span onClick={handleLogout}>Logout</span></Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="col-md-12 admin-table">
                            <div className="row" style={{marginTop:'15px'}}>
                                <div className="col-md-1">
                                    <Form>
                                        <Form.Group>
                                            <Form.Label>Show entries</Form.Label>
                                            <Form.Control as="select" onChange={(event) => {
                                                setPerPage(event.target.value);
                                                setPage(1)
                                            }}>
                                                <option>10</option>
                                                <option>20</option>
                                                <option>30</option>
                                                <option>40</option>
                                                <option>50</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form>

                                </div>
                                <div className="col-md-8">
                                    {
                                        selectedIds.length > 0 ?
                                            <span className="delete">
                                                Selected: {selectedIds.length}  <DeleteModal onDelete={handleDelete}/>
                                            </span> : <></>
                                    }


                                </div>
                                <div className="col-md-3">
                                    <Form>
                                        <Form.Label>Search</Form.Label>
                                        <Form.Group controlId="formSearch">
                                            <Form.Control type="text" placeholder="" onChange={event => {
                                                setSearch(event.target.value);
                                                setPage(1)
                                            }}/>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </div>
                            <Table columns={columns} data={quotes} onChangeSort={handleChangeSort} onSelect={handleSelect}/>
                            <div className="row">
                                <div className="col-md-6">
                                    Showing {page * per_page - per_page + 1} to { page*per_page < total ? page * per_page : total} of {total} entries
                                </div>
                                <div className="col-md-6">
                                    <Pagination className="float-right">
                                        <Pagination.Prev onClick={() => page <= 1 ? {} : setPage(page - 1)}
                                                         disabled={page <= 1}/>
                                        {
                                            page > 1 ? <Pagination.Item key={page - 1} onClick={() => setPage(page - 1)}>
                                                {page - 1}
                                            </Pagination.Item> : <span></span>
                                        }
                                        <Pagination.Item key={page} active={true}>
                                            {page}
                                        </Pagination.Item>
                                        {
                                            page < pages ?
                                                <Pagination.Item key={page + 1} onClick={() => setPage(page + 1)}>
                                                    {page + 1}
                                                </Pagination.Item> : <span></span>
                                        }
                                        <Pagination.Next onClick={() => page >= pages ? {} : setPage(page + 1)}
                                                         disabled={page >= pages}/>

                                    </Pagination>
                                </div>
                            </div>
                        </div>
                    </>
            }
        </div>

    )
}
