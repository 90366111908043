import React from "react";
import {TurfColorHex, VinColorHex} from "../../ColorCodeHelper";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

class TurfColorToolbar extends React.Component {
	constructor() {
		super();
		this.state = {
			paddingTop:2,
			paddingBottom:2,
			paddingLeft:2,
			paddingRight:2,
			color:VinColorHex.RoyalBlue,
		}
	}

	componentDidMount() {
		//this.addTurfMat();
	}

	addTurfMat(newColor) {
		let {paddingTop,paddingBottom,paddingLeft,paddingRight,color} = this.props.rubber_turf_config;
		//check if the mat already there
		let objects = this.props.canvas.getObjects('rect');
		objects.forEach(obj=>{
			if(obj.name === 'rubber-turf') {
				obj.set({fill:newColor})
				this.props.canvas.renderAll();
				this.setState({color:newColor});
			}
		})
		if(objects.length > 0) return;

		// add mat with color
		const  grid = this.props.canvas_grid,
			canvasWidth = this.props.canvas.width,
			canvasHeight = this.props.canvas.height;

		let top = 	grid * paddingTop,
			left = grid * paddingLeft,
			bottom = grid * paddingBottom,
			right = grid * paddingRight,
			height = canvasHeight-top-bottom,
			width = canvasWidth-left-right;

		let rect = new window.fabric.Rect({
			top,
			left,
			fill:color,
			width,
			height,
			//selectable:false,
			name:'rubber-turf'
		})
		this.props.canvas.add(rect);
		this.props.canvas.renderAll();
		this.setState({color:newColor});
		this.updateCanvasState();
	}

	updateRubberTurfMat(){
		let objects = this.props.canvas.getObjects();
		let index = -1;
		objects.forEach((obj,i)=>{
			if(obj.name === 'rubber-turf') {
				index = i;
			}
		})
		if(index !== -1) {
			let {paddingTop,paddingBottom,paddingLeft,paddingRight,color} = this.props.rubber_turf_config;
			// add mat with color
			const  grid = this.props.canvas_grid,
				canvasWidth = this.props.canvas.width,
				canvasHeight = this.props.canvas.height;

			let top = 	grid * paddingTop,
				left = grid * paddingLeft,
				bottom = grid * paddingBottom,
				right = grid * paddingRight,
				height = canvasHeight-top-bottom,
				width = canvasWidth-left-right;

			let rect = this.props.canvas.getObjects()[index];
			rect.set({top,left,height,width});
			/*let rect = new window.fabric.Rect({
				top,
				left,
				fill:color,
				width,
				height,
				//selectable:false,
				name:'rubber-turf'
			});*/
			//this.props.canvas._objects[index] = rect;
			this.props.canvas.renderAll();
			this.updateCanvasState();
		}
	}

	setTurfColor(color, colorName) {
		this.addTurfMat(color);
		let config = this.props.turf_config;
		config.turf_color = colorName;
		this.props.setTurfConfig(config);
		// this.props.canvas.backgroundColor = color;
		// this.props.canvas.renderAll();
		// this.updateCanvasState();
		//this.props.setCurrentWallPad(this.props.wallpad_config.current_wallpad, {color: colorName})
	}

	setPaddingLeft=(e)=> {
		let value = parseInt(e.target.value);
		let config = this.props.rubber_turf_config;
		config.paddingLeft = value;
		this.props.setRubberTurfConfig(config)
		this.updateRubberTurfMat();
		this.updateCanvasState();
	}

	setPaddingRight=(e)=> {
		let value = parseInt(e.target.value);
		let config = this.props.rubber_turf_config;
		config.paddingRight = value;
		this.props.setRubberTurfConfig(config);
		this.updateRubberTurfMat();
		this.updateCanvasState();
	}

	setPaddingTop=(e)=>{
		let value = parseInt(e.target.value);
		let config = this.props.rubber_turf_config;
		config.paddingTop = value;
		this.props.setRubberTurfConfig(config);
		this.updateRubberTurfMat();
		this.updateCanvasState();
	}
	setPaddingBottom=(e)=>{
		let value = parseInt(e.target.value);
		let config = this.props.rubber_turf_config;
		config.paddingBottom = value;
		this.props.setRubberTurfConfig(config);
		this.updateRubberTurfMat();
		this.updateCanvasState();
	}


	setThickness(value) {
		let config = this.props.turf_config;
		config.turf_thickness = value;
		this.props.setTurfConfig(config);
		this.updateCanvasState();
	}

	updateCanvasState (){
		let tempConfig = this.props._config;
		if (tempConfig.undoStatus === false && tempConfig.redoStatus === false) {
			let jsonData = this.props.canvas.toJSON(['custom','name']);
			let canvasAsJson = JSON.stringify(jsonData);
			if (tempConfig.currentStateIndex < tempConfig.canvasState.length - 1) {
				let indexToBeInserted = tempConfig.currentStateIndex + 1;
				tempConfig.canvasState[indexToBeInserted] = canvasAsJson;

				let numberOfElementsToRetain = indexToBeInserted + 1;
				tempConfig.canvasState = tempConfig.canvasState.splice(
					0,
					numberOfElementsToRetain
				);
			} else {
				tempConfig.canvasState.push(canvasAsJson);
			}

			tempConfig.currentStateIndex = tempConfig.canvasState.length - 1;

			if (
				tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
				tempConfig.currentStateIndex !== -1
			) {
				tempConfig.redoButton = "disabled";
			}
		}
		this.props.setCanvasConfig(tempConfig);
	};


	render() {
		const paddingLeft = this.props.paddingLeft;
		const paddingRight = this.props.paddingRight;
		const paddingTop = this.props.paddingTop;
		const paddingBottom = this.props.paddingBottom;
		let paddingLimit = [];
		for(let i=0;i<= 40; i++){
			paddingLimit.push(i)
		}
		return (
			<>
				<div className="row adddiv">
					<h2>Turf Color</h2>
				</div>
				<div className="templatesmain mat-lft-blk-menu">
					<div className="col-md-12 mt-15 pb-15">
						{/*<div className="row" style={{display:'inline-flex',width:'100%',alignItems:'center',paddingBottom:'10px'}}>
							<span style={{paddingRight:'5px'}}>Turf&nbsp;Size</span>
							<select className="form-control clipart-selct slct-font-fam choose-your-size-drop">
								<option value="10">10 %</option>
								<option value="20">20 %</option>
								<option value="35">35 %</option>
								<option value="95">95 %</option>
							</select>
							<span style={{paddingRight:'5px',paddingLeft:'5px'}}>X</span>
							<select className="form-control clipart-selct slct-font-fam choose-your-size-drop">
								<option value="10">10 %</option>
								<option value="20">20 %</option>
								<option value="35">35 %</option>
								<option value="95">95 %</option>
							</select>
						</div>*/}
						<div className="row mb-3">
							<span style={{color: 'white', fontStyle: 'italic'}}>
				                Padding
				              </span>
						</div>
						<div className="row" style={{display:'inline-flex',width:'100%',alignItems:'center',paddingBottom:'10px'}}>
							<span style={{paddingRight:'5px'}}>Left</span>
							<select value={paddingLeft} onChange={this.setPaddingLeft} className="form-control clipart-selct slct-font-fam choose-your-size-drop">
								{
									paddingLimit.map(value=>{
										return <option value={`${value}`}>{value} ft</option>
									})
								}
							</select>
							<span style={{paddingRight:'5px',paddingLeft:'5px'}}>Right</span>
							<select value={paddingRight} onChange={this.setPaddingRight} className="form-control clipart-selct slct-font-fam choose-your-size-drop">
								{
									paddingLimit.map(value=>{
										return <option value={`${value}`}>{value} ft</option>
									})
								}
							</select>
						</div>
						<div className="row" style={{display:'inline-flex',width:'100%',alignItems:'center',paddingBottom:'10px'}}>
							<span style={{paddingRight:'5px'}}>Top</span>
							<select value={paddingTop} onChange={this.setPaddingTop} className="form-control clipart-selct slct-font-fam choose-your-size-drop">
								{
									paddingLimit.map(value=>{
										return <option value={`${value}`}>{value} ft</option>
									})
								}
							</select>
							<span style={{paddingRight:'5px',paddingLeft:'5px'}}>Bottom</span>
							<select value={paddingBottom} onChange={this.setPaddingBottom} className="form-control clipart-selct slct-font-fam choose-your-size-drop">
								{
									paddingLimit.map(value=>{
										return <option value={`${value}`}>{value} ft</option>
									})
								}
							</select>
						</div>

						<div className="row row-border-btm">
				              <span style={{color: 'white', fontStyle: 'italic'}}>
				                Select Color
				              </span>
							<div className="preset-color-row">
								<div
									className="pre-clr-box clr-pr1 tooltip"
									style={{backgroundColor: TurfColorHex.Red}}
									onClick={() => this.setTurfColor(TurfColorHex.Red, 'Red')}
								>
									<span className="tooltiptext">Red</span>
								</div>
								<div
									className="pre-clr-box clr-pr2 tooltip"
									style={{backgroundColor: TurfColorHex.Green}}
									onClick={() => this.setTurfColor(TurfColorHex.Green, 'Green')}
								>
									<span className="tooltiptext">Green</span>
								</div>
								<div
									className="pre-clr-box clr-pr3 tooltip"
									style={{backgroundColor: TurfColorHex.RoyalBlue}}
									onClick={() => this.setTurfColor(TurfColorHex.RoyalBlue, 'Royal Blue')}
								>
									<span className="tooltiptext">Royal Blue</span>
								</div>
								<div
									className="pre-clr-box clr-pr4 tooltip"
									style={{backgroundColor: TurfColorHex.Black}}
									onClick={() => this.setTurfColor(TurfColorHex.Black, 'Black')}
								>
									<span className="tooltiptext">Black</span>
								</div>
								<div
									className="pre-clr-box clr-pr5 tooltip"
									style={{backgroundColor: TurfColorHex.White}}
									onClick={() => this.setTurfColor(TurfColorHex.White, 'White')}
								>
									<span className="tooltiptext">White</span>
								</div>
							</div>
							<div className="preset-color-row">
								<div
									className="pre-clr-box clr-pr5 tooltip"
									style={{backgroundColor: TurfColorHex.Gold}}
									onClick={() => this.setTurfColor(TurfColorHex.Gold, 'Gold')}
								>
									<span className="tooltiptext">Gold</span>
								</div>
								<div
									className="pre-clr-box clr-pr6 tooltip"
									style={{backgroundColor: TurfColorHex.Brown}}
									onClick={() => this.setTurfColor(TurfColorHex.Brown, 'Brown')}
								>
									<span className="tooltiptext">Brown</span>
								</div>
								<div
									className="pre-clr-box clr-pr7 tooltip"
									style={{backgroundColor: TurfColorHex.Clay}}
									onClick={() => this.setTurfColor(TurfColorHex.Clay, 'Clay')}
								>
									<span className="tooltiptext">Clay</span>
								</div>
								<div
									className="pre-clr-box clr-pr8 tooltip"
									style={{backgroundColor: TurfColorHex.TanBlack}}
									onClick={() => this.setTurfColor(TurfColorHex.TanBlack, 'Tan w/Black')}
								>
									<span className="tooltiptext">Tan w/Black</span>
								</div>
								<div
									className="pre-clr-box clr-pr1 tooltip"
									style={{backgroundColor: TurfColorHex.TanWhite}}
									onClick={() => this.setTurfColor(TurfColorHex.TanWhite, 'Tan w/White')}
								>
									<span className="tooltiptext">Tan w/White</span>
								</div>
							</div>
							<div className="preset-color-row">
								<div
									className="pre-clr-box clr-pr1 tooltip"
									style={{backgroundColor: TurfColorHex.Maroon}}
									onClick={() => this.setTurfColor(TurfColorHex.Maroon, 'Maroon')}
								>
									<span className="tooltiptext">Maroon</span>
								</div>
								<div
									className="pre-clr-box clr-pr9 tooltip"
									style={{backgroundColor: TurfColorHex.IndyBlue}}
									onClick={() => this.setTurfColor(TurfColorHex.IndyBlue, 'Indy Blue')}
								>
									<span className="tooltiptext">Indy Blue</span>
								</div>
								<div
									className="pre-clr-box clr-pr10 tooltip"
									style={{backgroundColor: TurfColorHex.Orange}}
									onClick={() => this.setTurfColor(TurfColorHex.Orange, 'Orange')}
								>
									<span className="tooltiptext">Orange</span>
								</div>
								<div
									className="pre-clr-box clr-pr11 tooltip"
									style={{backgroundColor: TurfColorHex.Navy}}
									onClick={() => this.setTurfColor(TurfColorHex.Navy, 'Navy')}
								>
									<span className="tooltiptext">Navy</span>
								</div>
								<div
									className="pre-clr-box clr-pr11 tooltip"
									style={{backgroundColor: TurfColorHex.Silver}}
									onClick={() => this.setTurfColor(TurfColorHex.Silver, 'Silver')}
								>
									<span className="tooltiptext">Silver</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}

const mapStateToProps = state => {
	console.log(state.rubber_turf_config);
	return {
		_config:state._config,
		canvas: state.canvas,
		turf_config:state.turf_config,
		canvas_grid:state.canvas_grid,
		rubber_turf_config:state.rubber_turf_config,
		paddingLeft:state.rubber_turf_config['paddingLeft'],
		paddingRight:state.rubber_turf_config['paddingRight'],
		paddingTop:state.rubber_turf_config['paddingTop'],
		paddingBottom:state.rubber_turf_config['paddingBottom'],
	}
}
const setCanvasConfig = (_config) => {
	return {
		type: "SET_CONFIG",
		_config,
	};
}
const setTurfConfig = _config => {
	return {
		type:'SET_TURF_CONFIG',
		_config
	}
}

const setRubberTurfConfig = _config =>{
	return {
		type:'SET_RUBBER_TURF_CONFIG',
		_config
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ setTurfConfig,setRubberTurfConfig , setCanvasConfig}, dispatch);
}

const TurfColorToolbarConnect = connect(
	mapStateToProps,mapDispatchToProps
)(TurfColorToolbar)

class RubberTurfColorToolbarSidebar extends React.Component {
	closeNav17 = () => {
		document.getElementById("mySidenav11").style.width = "0";
	};

	render() {
		return (
			<>
				<div
					id="rubber-turf-color"
					className="h100 second-menu fordeskview"
					style={{display: "none"}}
				>
					<TurfColorToolbarConnect/>
				</div>
				<div
					className="text-center h100 pad0 mtopneg sidenav4 formobview wo"
					id="mySidenav11"
				>
					<a
						style={{color: "#fff"}}
						href="#"
						className="closebtn"
						onClick={() => this.closeNav17()}
					>
						&times;
					</a>
					<TurfColorToolbarConnect/>
				</div>
			</>
		);
	}
}

export default RubberTurfColorToolbarSidebar