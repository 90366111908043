import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import {Modal} from "react-bootstrap";
import '../../assets/css/common.css';

export default function ChangePasswordForm(props) {
    const [message, setMessage] = useState(null);
    const [show, setShow] = useState(false)

    const { register, handleSubmit, watch, errors } = useForm();
    const onSubmit = data => {
        props.handleSubmit(data);

        axios.post(`/api/change_password`, data).then(success => {
            setMessage(success.data.message)
            setShow(false)
        }).catch(error => {
            setMessage(error.response.data.error)
        })
    }
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    return (
        <div>
            <span onClick={handleShow}>Change password</span>

            <Modal className="change-password" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Change password</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <form onSubmit={handleSubmit(onSubmit)} className="form-change-password">
                        <div className={`md-form mb-5 ${errors.oldPassword ? "has-error" : ""}`}>
                            <input
                                name="oldPassword"
                                type="password"
                                className="form-control"
                                placeholder="Old password"
                                ref={register({ required: true })}
                            />
                            <p>{errors.oldPassword && "This field required"}</p>
                        </div>

                        <div className={`md-form mb-5 ${errors.password ? "has-error" : ""}`}>
                            <input
                                name="password"
                                type="password"
                                className="form-control"
                                placeholder="New password"
                                ref={register({ required: true })}
                            />
                            <p>{errors.password && "This field required"}</p>
                        </div>

                        <div className={`md-form mb-5 ${errors.repeatPassword ? "has-error" : ""}`}>
                            <input
                                name="repeatPassword"
                                type="password"
                                className="form-control"
                                placeholder="Repeat password"
                                ref={register({required: true, validate: (value) => value === watch('password') })}
                            />
                            <p>{errors.repeatPassword && "This field required and must match"}</p>
                        </div>

                        <div>
                            {message && <small className="form-text">{message}</small>}
                        </div>

                    </form>

                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" onClick={handleSubmit(onSubmit)} className="btn btn-danger custom-button">Change password</button>
                </Modal.Footer>
            </Modal>
        </div>
    )

}
