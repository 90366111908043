import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { createStore } from "redux";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";

import { fabric } from 'fabric-webpack';
import {PaintColorName, TurfColorHex, VinColorHex} from "./components/layout/ColorCodeHelper";

window.fabric = fabric;

const initialState = {
  components: [],
  components_type: [],
  _config: {
    canvasState: [],
    currentStateIndex: -1,
    undoStatus: false,
    redoStatus: false,
    undoFinishedStatus: 1,
    redoFinishedStatus: 1,
    undoButton: "",
    redoButton: ""
  },
  update_status:false,
  saved_id: "",
  zoom:1,
  load_design: false,
  wrestling_config: {
    width: 42,
    height: 42,
    length_unit: "METER",
    isNewMaterial: false,
    file: "42x42.png"
  },
  flexi_config: {
    width: 20,
    height: 20,
    option_setting: false,
    length_unit: "FEET"
  },
  swain_config: {
    width: 20,
    height: 20,
    option_setting: false,
    design_mode:'1', // 1 for Turf Track Only
    length_unit: "FEET"
  },
  turf_config: {
    width: 100,
    height: 15,
    option_setting: false,
    thickness:'1"',
    turf_color:'Green',
    color:TurfColorHex.Green, // turf color
    length_unit: "FEET"
  },
  rubber_turf_config:{
    paddingTop:2,
    paddingBottom:2,
    paddingLeft:2,
    paddingRight:2,
    colorDensity:'10',
    thickness:'1/4"',
    rubberColor:'Blue 10%' // rubber mat color
  },
  active_shape_turf:{
    fill:'#000',
    stroke:'#000',
  },
  puzzle_config: {
    width: 20,
    height: 20,
    option_setting: false,
    length_unit: "FEET",
    type:'puzzle'     // puzzle or rubber-puzzle;
  },
  active_rubber_puzzle:{},
  rubber_puzzle_type:'inter-locking',  // inter-locking or straight
  wallpad_config: {
    config: [],
    option_setting: false,
    current_wallpad: 0,
    length_unit: "FEET"
  },
  wallpad_canvasData: [],
  canvas: null,
  first_canvas: null,
  second_canvas: null,
  third_canvas: null,
  forth_canvas: null,
  canvasWidth:null,
  canvasHeight:null,
  canvas_grid: 0,
  mat_type: 0,
  mat_color: "",
  mat_class:"",
  mat_top: 0,
  mat_left: 0,
  circle_outer_diameter: 0,
  circle_inner_diameter: 0,
  circle_type: "1",
  selectedMaterialColor: null,
  drag_new_rect: false,
  letterSpacingOption: false,
  image_url: "",
  selected_text: "Sample Text",
  template_type: "",
  template_top: 0,
  template_left: 0,
  drag_new_template: false,
  is_text_toolbar_active:false,
  text_toolbar_config:{
    update:false,
    text:'Your Text',
    arc:{value:1,},
    outline:{value:1,},
    letterSpace:{value:1,},
    lineHeight:{value:1,},
    fontWeight: '',
    fontStyle: '',
    fill:'#000000',
    stroke:'#000000',
    textDecoration: '',
    text_align:'center',
    fontFamily:'Times New Roman',
    fontSize:20,
    effect:"STRAIGHT"
  },
  templates_width: {
    template1: { width: 4, height: 3 },
    template2: { width: 4, height: 4 },
    template3: { width: 2, height: 3 },
    template4: { width: 6, height: 6 },
    template5: { width: 15, height: 8 },
    template6: { width: 8, height: 8 },
    template7: { width: 14, height: 14 },
    template8: { width: 4, height: 4 },
    template9: { width: 6, height: 6 },
    template10: { width: 4, height: 4 },
    template11: { width: 6, height: 6 },
    template12: { width: 5, height: 6 },
    template13: { width: 3, height: 4 },
    template14: { width: 8, height: 8 },
    template15: { width: 26, height: 14 }
  },
  puzzle_templates_width: {
    template1: { width: 1, height: 3 },
    template2: { width: 3, height: 1 },
    template3: { width: 3, height: 2 },
    template4: { width: 2, height: 3 },
    template5: { width: 3, height: 3 }
  },
  rubber_puzzle_templates_width:{
    templateRubber1: { width: 5, height: 5 },
    templateRubber2: { width: 5, height: 5 },
    templateRubber3: { width: 10, height: 10 },
    templateRubber4: { width: 7, height: 7 },
    templateRubber5: { width: 9, height: 9 },
    templateRubber6: { width: 10, height: 10 },
  },
  isCanvasCleared: false,
  text_style: null,
  thickness: '1"',
  fontColor: null,
  textColorOption: "fill",
  selectedGroupType: 0,
  flexi_type: "smooth",
  flexi_material_changed: false,
  flexi_smooth_index: 2,
  flexi_tatami_index: 2,
  flexi_carpet_index: 2,
  flexi_color: PaintColorName.AthleticGold,
  flexi_smooth_colors: [
    ["#d4d2ca", "#ffffff"],
    ["#99968d", "#c2c6c9"],
    ["#b7930d", "#dbaa00"],
    ["#9f4d03", "#ff8400"],
    ["#66081c", "#c20430"],
    ["#112718", "#2a5135"],
    ["#4a122a", "#7f2346"],
    ["#311914", "#5d3428"],
    ["#000000", "#0d0000"],
    ["#241634", "#522d6d"],
    ["#222326", "#4d4f53"],
    ["#16243d", "#1c355e"],
    ["#022252", "#003da7"],
    ["#3e657f", "#64a0c8"],
    ["#2a5737", "#65c883"]
  ],
  flexi_tatami_colors: [
    ["#022252", "#003da7"],
    ["#66081c", "#b71234"],
    ["#b7930d", "#e5c65c"],
    ["#000000", "#0e0000"],
    ["#636f5c", "#9db291"],
    ["#d4d2ca", "#ffffff"],
    ["#222326", "#4e4f53"],
    ["#99968d", "#c2c6c9"]
  ],
  flexi_carpet_colors: [
    ["#66081c", "#b71234"],
    ["#16243d", "#263e6a"],
    ["#241634", "#4c306b"],
    ["#112718", "#284e35"],
    ["#99968d", "#c4c8c9"],
    ["#222326", "#4e4f53"],
    ["#000000", "#0e0000"],
    ["#4a122a", "#7d2349"],
    ["#053A3E", "#008b95"]
  ],
  circles_mat_info: [
    [
      [1, 1, 1, 1],
      [1, 1, 1, 1],
      [1, 1, 1, 1]
    ],
    [
      [0, 0, 0, 0],
      [1, 1, 1, 0],
      [1, 1, 1, 0]
    ],
    [
      [0, 0, 0, 0],
      [1, 1, 0, 0],
      [1, 1, 0, 0]
    ],
    [
      [0, 0, 0, 0],
      [1, 1, 1, 1],
      [1, 1, 0, 0]
    ],
    [
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [1, 1, 0, 0]
    ],
    [
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [1, 1, 0, 0]
    ]
  ],
  oct_mat_info: [
    [
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 0]
    ],
    [
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 0]
    ],
    [
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 0]
    ],
    [
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 0]
    ],
    [
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 0]
    ],
    [
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 0]
    ]
  ],
  artworkPaths: [],
  rects: [],
  uploadedLogos: [],
  activeTextFill:'',
  activeTextStroke:'',
  text_line_height:1,
  text_letter_spacing:1,
  text_letter_outline:0,
  text_letter_radius:1,
  mat_style_flexi_conenct:false,
  mat_style_flexi_roll_mats:false,
  mat_style_non_flexi_roll_mats:false,
  text_arc_radius:0,
};

function reducer(state = [], action) {
  switch (action.type) {
    case "SET_STYLE_NON_FLEXI_ROLL_MATS":
      return {
        ...state,
        mat_style_non_flexi_roll_mats:action.value,
      }
    case 'SET_ARC_TEXT_LETTER_RADIUS':
      return {
        ...state,
        text_arc_radius:action.value,
      }
    case "SET_STYLE_FLEXI_ROLL_MATS":
      return {
        ...state,
        mat_style_flexi_roll_mats:action.value,
      }
    case "SET_STYLE_FLEXI_CONNECT":
      return {
        ...state,
        mat_style_flexi_conenct:action.value,
      }
    case "SET_TEXT_LETTER_RADIUS":
      return {
        ...state,
        text_letter_radius: action.value,
      }
    case "SET_TEXT_LETTER_OUTLINE":
      return {
        ...state,
        text_letter_outline: action.value,
      }
    case "SET_TEXT_LETTER_SPACING":
      return {
        ...state,
        text_letter_spacing: action.value,
      }
    case "SET_TEXT_LINE_HEIGHT":
      return {
        ...state,
        text_line_height: action.value,
      }
    case "SET_TEXT_PANEL_FONT_FAMILY":
      let text_toolbar_font_family = state.text_toolbar_config;
      text_toolbar_font_family.fontFamily = action.fontFamily;
      return {
        ...state,
        text_toolbar_config: text_toolbar_font_family
      }
    case "SET_TEXT_PANEL_TEXT":
      let text_toolbar_text = state.text_toolbar_config;
      text_toolbar_text.text = action.text;
      return {
        ...state,
        text_toolbar_config: text_toolbar_text
      }
    case "TEXT_TOOLBAR_CONFIG":
      let text_toolbar_config = {};
      let text_line_height = 1;
      let text_letter_spacing = 1;
      let text_letter_outline = 0;
      let text_letter_radius = 1;
      let activeObject = state.canvas.getActiveObject();
      let text_arc_radius=0;
      if (activeObject && (
          activeObject.__proto__.type === 'text' ||
          activeObject.__proto__.type === 'curvedText'))
      {
        text_toolbar_config = {
          update: true,
          text: activeObject.text,
          arc: {value: (500 - activeObject.get("radius")) / 20},
          outline: {value: activeObject.get("strokeWidth") / 1},
          letterSpace: {value: activeObject.get("spacing")},
          lineHeight: {value: activeObject.get("lineHeight")},
          text_align: activeObject.get('textAlign'),
          fontWeight: activeObject.get("fontWeight"),
          fontStyle: activeObject.get("fontStyle"),
          textDecoration: activeObject.get("textDecoration"),
          fill: activeObject.get("fill"),
          stroke: activeObject.get("stroke"),
          fontFamily: activeObject.get('fontFamily'),
          fontSize: activeObject.get('fontSize'),
          effect: activeObject.get('effect'),
        }

        text_line_height = activeObject.get("lineHeight");
        text_letter_spacing = activeObject.get("spacing");
        text_letter_outline = activeObject.get("strokeWidth") / 1;
        text_letter_radius = (500 - activeObject.get("radius")) / 20;
        text_arc_radius=(500-activeObject.get('radius'))/8;
      } else {
        text_toolbar_config = {
          update: false,
          text: 'Your Text',
          arc: {value: 1,},
          outline: {value: 0},
          letterSpace: {value: 1,},
          lineHeight: {value: 1,},
          fontWeight: '',
          fontStyle: '',
          fill: '#000000',
          stroke: '#000000',
          textDecoration: '',
          text_align: 'center',
          fontFamily: 'Times New Roman',
          fontSize: 20,
          effect: "STRAIGHT",
        }
      }

      return {
        ...state,
        is_text_toolbar_active: action.is_text_toolbar_active,
        text_toolbar_config: text_toolbar_config,
        //text_arc_radius,
        text_line_height,
        text_letter_spacing,
        text_letter_outline,
        text_letter_radius,
      }
    case "SET_ACTIVE_TEXT_STROKE":
      let text_toolbar_text_stroke = state.text_toolbar_config;
      text_toolbar_text_stroke.stroke = action.stroke;
      return {
        ...state,
        text_toolbar_config: text_toolbar_text_stroke
      }
    case "SET_ACTIVE_TEXT_FILL":
      let text_toolbar_text_fill = state.text_toolbar_config;
      text_toolbar_text_fill.fill = action.fill;
      return {
        ...state,
        text_toolbar_config: text_toolbar_text_fill
      }
    case "SET_CANVAS_HEIGHT_WIDTH":
      return {
        ...state,
        canvasWidth: action.dimensions.width,
        canvasHeight: action.dimensions.height,
      }
    case "SET_CANVAS":
      return {
        ...state,
        canvas: action.canvas
      };

    case "NEW_TEXT":
      state.components[state.components.length] = action.component;
      return {
        ...state,
        components: state.components
      };

    case "NEW_RECT":
      state.components[state.components.length] = action.component;
      return {
        ...state,
        components: state.components
      };

    case "NEW_RECT_IMAGE":
      return {
        ...state,
        isRectImageCreated: true
      };

    case "NEW_IMAGE":
      state.components[state.components.length] = action.component;
      state.swain_config["option_setting"] = true;
      return {
        ...state,
        components: state.components,
        swain_config: state.swain_config
      };

    case "NEW_TEMPLATE":
      state.components[state.components.length] = action.component;
      return {
        ...state,
        components: state.components
      };

    case "NEW_CURVED_TEXT":
      state.components[state.components.length] = action.component;
      return {
        ...state,
        components: state.components,
        text_style: action.data
      };

    case "SET_CURVEDTEXT_STLYE":
      return {
        ...state,
        text_style: action.data
      };
    case "SET_TEMPLATE_CLASS":
      return {
        ...state,
        mat_class:action.mat_class,
      }
    case "NEW_RECT_CONFIG":
      console.log(action);
      return {
        ...state,
        mat_color: action.data.mat_color,
        mat_type: action.data.mat_type,
        mat_class:action.data.mat_class
      };

    case "ADD_RECT_TO_LIST":
      return {
        ...state,
        rects: [...state.rects, action.data]
      };

    case "REMOVE_RECT":
      const pos = state.rects.findIndex(r => {
            return (
                r.fill === action.data.fill &&
                r.height === action.data.height &&
                r.width === action.data.width)
          }
      );

      state.rects.splice(pos, 1);

      return {
        ...state,
        rects: [...state.rects]
      }

    case "SET_SELECTED_TEXT":
      return {
        ...state,
        selected_text: action.text
      };

    case "DRAG_NEW_RECT":
      return {
        ...state,
        drag_new_rect: action.drag_new_rect
      };

    case "DRAG_NEW_TEMPLATE":
      return {
        ...state,
        drag_new_template: action.drag_new_template
      };

    case "NEW_TEMPLATE_CONFIG":
      return {
        ...state,
        template_type: action.template_type
      };

    case "SET_MATERIAL_POSITION":
      return {
        ...state,
        mat_top: action.data.top,
        mat_left: action.data.left
      };
    case "SET_THICKNESS":
      return {
        ...state,
        thickness: action.thickness
      }

    case "SET_TEMPLATE_POSITION":
      return {
        ...state,
        template_top: action.data.top,
        template_left: action.data.left
      };

    case "SET_CONFIG":
      return {
        ...state,
        _config: action._config
      };

    case "WALLPAD_SET_CONFIG":
      state.wallpad_config["config"][
          state.wallpad_config.current_wallpad
          ].canvas_config = action._config;
      return {
        ...state,
        _config: action._config,
        wallpad_config: state.wallpad_config
      };

    case "SET_IMAGE_URL":
      return {
        ...state,
        image_url: action.image_url
      };

    case "SET_SAVED_ID":
      state.flexi_config.option_setting = true;
      state.swain_config.option_setting = true;
      state.wallpad_config.option_setting = true;
      state.wrestling_config.option_setting = true;
      state.puzzle_config.option_setting = true;
      state.turf_config.option_setting = true;
      return {
        ...state,
        saved_id: action.id,
        load_design: true,
        flexi_config: state.flexi_config,
        swain_config: state.swain_config,
        wallpad_config: state.wallpad_config,
        puzzle_config: state.puzzle_config,
        turf_config: state.turf_config
      };

    case "DISABLE_DESIGN_LOAD":
      return {
        ...state,
        load_design: false
      };

    case "SET_WRESTLING_CONFIG":
      switch (action._config.file) {
        case "oct24.png":
        case "oct30.png":
          state.circle_type = "0-0";
          break;
        case "42x42.png":
          state.circle_type = "1";
          break;
        case "36x36.png":
        case "30x30.png":
          state.circle_type = "2";
          break;
        default:
          state.circle_type = "1";
          break;
      }
      return {
        ...state,
        wrestling_config: {
          ...state.wrestling_config,
          ...action._config
        },
        circle_type: state.circle_type
      };
    case "SET_WRESTLING_SETTING_CONFIG":
      return {
        ...state,
        wrestling_config: action._config,
      };
    case "SET_SWAIN_CONFIG":
      return {
        ...state,
        swain_config: action._config
      };
    case 'SET_TURF_CONFIG':
      let config = {...state.turf_config , ...action._config};

      return {
        ...state,
        turf_config: config
      };
    case 'SET_RUBBER_TURF_CONFIG':
      console.log(action._config)
      return {
        ...state,
        rubber_turf_config:action._config,
      }
    case 'SET_TURF_SHAPE_COLOR':
      return {
        ...state,
        active_shape_turf:action._config,
      }
    case 'SET_PUZZLE_CONFIG':
      return {
        ...state,
        puzzle_config: action._config
      };
    case "SET_FLEXI_CONFIG":
      return {
        ...state,
        flexi_config: action._config
      };

    case "SET_CANVAS_GRID":
      return {
        ...state,
        canvas_grid: action.grid
      };

    case "SET_SELECTEED_MATERIAL_COLOR":
      return {
        ...state,
        selectedMaterialColor: action.color
      };

    case "SET_LETTER_SPACING_OPTION":
      return {
        ...state,
        letterSpacingOption: action.option
      };

    case "SET_COMPONENT_TYPE":
      state.components_type[state.components_type.length] =
          action.component_type;
      return {
        ...state,
        components_type: state.components_type
      };

    case "SET_FLEXI_TYPE":
      return {
        ...state,
        flexi_type: action.flexi_type
      };

    case "SET_GROUP_SELECT":
      return {
        ...state,
        selectedGroupType: action.groupType
      };

    case "SET_FLEXI_MATERIAL_CHANGED":
      return {
        ...state,
        flexi_material_changed: action.changed
      };

    case "SET_FLEXI_SMOOTH_COLOR_INDEX":
      return {
        ...state,
        flexi_smooth_index: action.index
      };

    case "SET_FLEXI_TATAMI_COLOR_INDEX":
      return {
        ...state,
        flexi_tatami_index: action.index
      };

    case "SET_FLEXI_CARPET_COLOR_INDEX":
      return {
        ...state,
        flexi_carpet_index: action.index
      };

    case "SET_FLEXI_COLOR":
      return {
        ...state,
        flexi_color: action.color
      };

    case "SET_SELECTED_CIRCLE_TYPE":
      return {
        ...state,
        circle_type: action.id
      };
    case "SET_WALL_PAD_COLOR":
      let wallpad_config = state.wallpad_config;
      wallpad_config["config"][action.index].color = action.color;
      return {
        ...state,
        wallpad_config:wallpad_config,
      }
    case "SET_CURRENT_WALLPAD":
      state.wallpad_config.current_wallpad = action.index;
      state.wallpad_config["config"][action.index] = {...state.wallpad_config["config"][action.index], ...action.config}
      state.canvas = state.wallpad_config["config"][action.index].canvas;
      if (state.canvas) {
        const canvasHeight = state.canvasHeight ? state.canvasHeight : 400;
        const canvasWidth = state.canvasWidth ? state.canvasWidth : 400;
        let tempWidth = canvasWidth * state.zoom;
        let tempHeight = canvasHeight * state.zoom;
        state.canvas.setWidth(tempWidth);
        state.canvas.setHeight(tempHeight);
        state.canvas.zoomToPoint(new window.fabric.Point(0, 0), state.zoom)
      }
      state._config =
          state.wallpad_config["config"][action.index].canvas_config;
      return {
        ...state,
        wallpad_config: {...state.wallpad_config},
        canvas: state.canvas,
        _config: state._config,
      };

    case "ARTWRORK_PATH":
      return {
        ...state,
        artworkPaths: action.paths,
        selectedGroupType: 0,
      };

    case "CLAER_CANVAS":
      if (action.isCanvasCleared) {
        let new_config = {
          canvasState: [],
          currentStateIndex: -1,
          undoStatus: false,
          redoStatus: false,
          undoFinishedStatus: 1,
          redoFinishedStatus: 1,
          undoButton: "",
          redoButton: ""
        };
        return {
          ...state,
          rects: [],
          circle_outer_diameter: 0,
          circle_inner_diameter: 0,
          fontColor: null,
          text_style: null,
          isCanvasCleared: action.isCanvasCleared,
          _config: new_config
        };
      }

      return {
        ...state,
        isCanvasCleared: action.isCanvasCleared
      };

    case "SET_BACKGROUND_COLOR":
      return {
        ...state,
        mat_color: action.color
      };
    case "SET_ACTIVE_PUZZLE":
      return {
        ...state,
        active_rubber_puzzle: action.active_rubber_puzzle
      };
    case "SET_ACTIVE_RUBBER_TYPE":
      return {
        ...state,
        rubber_puzzle_type: action.rubber_puzzle_type
      };
    case "SET_WALLPAD_CANVAS":
      state.wallpad_config["config"][action.index].canvas = action.canvas;
      return {
        ...state,
        wallpad_config: state.wallpad_config
      };

    case "SET_WALLPAD_CONFIG":
      state.wallpad_config.option_setting = true;
      return {
        ...state,
        wallpad_config: state.wallpad_config
      };

    case "SET_WALLPAD":
      state.wallpad_config["config"][state.wallpad_config["config"].length] = {
        width: action.length / 1,
        height: 6,
        canvas: null,
        canvas_grid: null,
        canvas_config: {
          canvasState: [],
          currentStateIndex: -1,
          undoStatus: false,
          redoStatus: false,
          undoFinishedStatus: 1,
          redoFinishedStatus: 1,
          undoButton: "",
          redoButton: ""
        }
      };
      return {
        ...state,
        wallpad_config: state.wallpad_config
      };

    case "SET_SAVED_WALLPAD":
      console.log(action.idx);
      state.wallpad_config["config"][action.idx] = {
        width: action.config.width / 1,
        height: 6,
        canvas: action.canvas,
        canvas_grid: null,
        canvas_config: {
          canvasState: [],
          currentStateIndex: -1,
          undoStatus: false,
          redoStatus: false,
          undoFinishedStatus: 1,
          redoFinishedStatus: 1,
          undoButton: "",
          redoButton: ""
        }
      };
      console.log(state.wallpad_config);
      return {
        ...state,
        wallpad_config: state.wallpad_config
      };

    case "SAVE_WALLPAD_CANVAS_DATA":
      action.data.map(data => {
        state.wallpad_config["config"][
            state.wallpad_config["config"].length
            ] = {
          width: data.config.width / 1,
          height: 6,
          canvas: null,
          canvas_grid: null,
          canvas_config: {
            canvasState: [],
            currentStateIndex: -1,
            undoStatus: false,
            redoStatus: false,
            undoFinishedStatus: 1,
            redoFinishedStatus: 1,
            undoButton: "",
            redoButton: ""
          }
        };
        state.wallpad_canvasData[state.wallpad_canvasData.length] =
            action.data.data;
      });

      return {
        ...state,
        wallpad_canvasData: state.wallpad_canvasData
      };

    case "SET_TEXT_COLOR_OPTION":
      return {
        ...state,
        textColorOption: action.option
      };

    case "SET_FONT_COLOR_OPTION":
      return {
        ...state,
        fontColor: action.option
      };

    case "SET_CIRCLE_SIZE":
      return {
        ...state,
        circle_outer_diameter: action.circle_outer_diameter,
        circle_inner_diameter: action.circle_inner_diameter,
      };

    case "SET_UPLOADED_LOGOS":
      return {
        ...state,
        uploadedLogos: [...state.uploadedLogos, ...action.logos],
      };
    case "SET_ZOOM":
      return {
        ...state,
        zoom: action.option
      };
    default:
      return state;
  }
}

const store = createStore(reducer, initialState);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
