import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Footer from '../Footer'

class OptionSettingDialogue extends React.Component {
    state = {
        width: 10,
        height: 10,
        unit: 'FEET',
        type:'puzzle'
    }

    onBuild = () => {
        if (this.state.width > 100 || this.state.height > 100) {
            return alert("Width or height should be maximum 100")
        }
        this.props.setPuzzleConfig(this.state)
    }

    _handleWidthChange = e => {
        let { value } = e.target
        this.setState({
            width: value,
        })
    }

    _handleHeightChange = e => {
        let { value } = e.target
        this.setState({
            height: value,
        })
    }

    _handleUnitChange = e => {
        let { value } = e.target
        if (value === 1) {
            this.setState({
                unit: 'FEET',
            })
        } else {
            this.setState({
                unit: 'METERS',
            })
        }
    }
    _handleTypeChange = e => {
        this.setState({type:e.target.value });
    }

    render() {
        return (
            <>
                <div className="see-instruction int-matbuild default-left puzzle">
                    <div className="container">
                        <div className="innerint default-left text-center">
                            <h2>Interactive Mat Builder</h2>
                            <p>Design interlocking or square tile mats.  For 10x10 RBU mats, please visit Shop Now/Martial Arts. All RBU mats include finished edges on all 4 sides.</p>

                            <div className="selectboxes default-left">
                                <div className="row innerselect_box">
                                    <div className="col-md-3 col-sm-12 selectcol col_3">
                                        <div className="height-ft" style={{marginLeft:'-20px'}}>
                                            Interlocking/Tile&nbsp;Options:
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 selectcol col_3" style={{paddingRight:'0px'}} >
                                        <select
                                            className="mdb-select md-form colorful-select dropdown-primary"
                                            onChange={this._handleTypeChange}
                                            style={{width:'100%'}}
                                            value={this.state.type}
                                        >
                                            <option value="puzzle">RBU Interlocking Puzzle</option>
                                            <option value="rubber-puzzle">Rubber Interlocking or Square Tiles</option>
                                        </select>
                                    </div>
                                    <div className="col-md-3 col-sm-12 selectcol col_3">

                                    </div>
                                </div>
                                <div className="row innerselect_box">
                                    <div className="col-md-3 col-sm-12 selectcol col_1">
                                        <input
                                            type="number"
                                            className="mdb-select md-form colorful-select dropdown-primary"
                                            min="1"
                                            max="75"
                                            defaultValue="10"
                                            onChange={this._handleWidthChange}
                                        />
                                    </div>
                                    <div className="col-md-3 col-sm-12 selectcol col_2">
                                        <input
                                            type="number"
                                            className="mdb-select md-form colorful-select dropdown-primary"
                                            min="1"
                                            max="75"
                                            defaultValue="10"
                                            onChange={this._handleHeightChange}
                                        />
                                    </div>
                                    <div className="col-md-3 col-sm-12 selectcol col_3">
                                        {/*<p style={{padding:'0px'}}>{this.state.unit}</p>*/}
                                        <select
                                            className="mdb-select md-form colorful-select dropdown-primary"
                                            onChange={this._handleUnitChange}
                                        >
                                            <option value="1">Feet</option>
                                            <option value="2">Meters</option>
                                        </select>
                                    </div>
                                    <div className="col-md-3 col-sm-12 selectcol col_3">
                                        <div className="fw">
                                            <a className='btn btn-danger' style={{padding:'0px'}} href="#" onClick={this.onBuild}>
                                                <span>Build it</span>
                                                <img
                                                    src={require('./../../../assets/img/fwarw.png')}
                                                    alt="Forward Arrow"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="default-left back-fwd-btns">
                            <div className="row">
                                <div className="default-left entertxt text-center">
                                    <p className="font-oswald">
                                        Enter your room size. For odd shaped rooms, put in your best<br />
                                        guess of the area & speak to one of our mat experts for a<br />
                                        more accurate idea.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}

const setPuzzleConfig = config => {
    const _config = {
        width: config.width,
        height: config.height,
        option_setting: true,
        length_unit: config.unit,
        type:config.type
    }

    return {
        type: 'SET_PUZZLE_CONFIG',
        _config: _config,
    }
}

const mapStateToProps = state => {
    return {
        puzzle_config: state.puzzle_config,
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ setPuzzleConfig }, dispatch)
}

const OptionSettingConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)(OptionSettingDialogue)

export default OptionSettingConnect
