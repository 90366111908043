import React, {useState} from "react";
import {useForm} from "react-hook-form";
import axios from "axios";
import '../../assets/css/common.css';

export default function LoginForm(props) {
    const {register, handleSubmit, errors} = useForm();
    const [message, setMessage] = useState(null);

    const onSubmit = data => {
        axios.post(`/api/login`, data).then(success => {
            setMessage(success.data.message)
            props.handleSubmit(data);
        }).catch(error => {
            setMessage(error.response.data.error)
        })
    }

    return (
        <div className="card" style={{maxHeight:'290px'}}>
            <h4 className="modal-title w-100 font-weight-bold">LOG IN</h4>

            <form onSubmit={handleSubmit(onSubmit)} className="form-login">
                <div className={`md-form mb-5 ${errors.username ? "has-error" : ""}`}>
                    <input
                        name="username"
                        type="text"
                        className="form-control"
                        placeholder="Username"
                        aria-describedby="usernameHelp"
                        ref={register({required: true})}
                    />
                    <p>{errors.username && "This field required"}</p>
                </div>
                <div className={`md-form mb-5 ${errors.password ? "has-error" : ""}`}>
                    <input
                        name="password"
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        ref={register({required: true})}
                    />
                    <p>{errors.password && "This field required"}</p>
                    <p>{message}</p>
                </div>
                <button type="submit" onClick={handleSubmit(onSubmit)} className="btn btn-lg btn-block btn-danger">Log in
                </button>
            </form>

        </div>

    );
}
