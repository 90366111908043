import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import './../../assets/css/fontawesome.css'

const InfoModal = props => {
    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false)
    const isToolBar = props.toolbar? props.toolbar : false;
    const handleShow = () => setShow(true)
  return (
    <>
        {
            isToolBar && (
                <a className="toolbar-btn" onClick={handleShow}>
                    <img src={require("./../../assets/img/info.png")} alt="Save" style={{marginTop:'-3px'}} />
                    <span className="hide-on-smaller-screen">Info</span></a>
            )
        }
        {
            !isToolBar && (
                <ul className="nav navbar-nav navbar-right fordeskview">
                    <li>
                        <a onClick={handleShow}>Info</a>
                    </li>
                </ul>
            )
        }
        <ul className="nav navbar-nav navbar-right formobview mobul">
            <li>
                <a onClick={handleShow}>
                    <img src={require("./../../assets/img/info.png")} alt="Save" style={{marginTop:'-3px'}} />
                </a>
            </li>
        </ul>
        <Modal
            show={show}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            scrollable
            id="modalRegisterForm"
        >
            <div className="modal-header">
                <h4>Thank you for using the Dollamur Mat Designers. Here are a few helpful instructions.</h4>
            </div>
            <div className="modal-body">
            <p>Step 1: In the LEFT menu, select your MAT SIZE from either the Dropdown or the MAT images</p>
            <p>Step 2: Select the color for the base mat</p>
            <p>Step 3: Add Circles to your Mat. Based on the size mat you are designing, use the Drop Down Menu to select Circle Sizes.</p>
            <p>Step 4: Add Text, if needed, to your mat.</p>
            <p>Step 5: Add Artwork, if needed. Select from our One, Two, Three or Four Color stock logos and change the colors by selecting the graphics once it is on your Drawing area. Or, you can Upload Your Own logo.</p>
            <p>Step 6: Select Your Mat Thickness and Add FLEXI-Connect, built mat connection and Go Tapeless!</p>
            <p>Step 7: Select GET A QUOTE and one of our Sales Team members will contact you as soon as possible with your FREE Quote.</p>
            </div>
        </Modal>
    </>
  )
}
export default InfoModal
