import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import TextColorPaneConnect from "../../../TextColorPane";
import CustomColorPane from "../../../customColorPane";
import {TurfColorHex, TurfColorName} from "../../ColorCodeHelper";

//      Hash Marks Toolbar      //
class RubberHashMarksToolbar extends React.Component {
	constructor() {
		super();
		this.state ={
			gridColor : '#0d0000'
		};
	}

	componentDidMount() {

	}

	checkGridMarks() {
		return this.props.canvas.getObjects('line').length > 0;
	}

	setGridMarkColor(color) {
		this.setState({gridColor:color});
		if (this.checkGridMarks()) {
			let allLines = this.props.canvas.getObjects('line');
			allLines.forEach(line => {
				if (line.name && (line.name === 'fullMark' || line.name === 'subMark')) {
					line.set({stroke: color});
				}
			})
			this.props.canvas.renderAll();
			this.updateCanvasState();
		}
	}

	removeGridMarks() {
		let allLines = this.props.canvas.getObjects('line');
		allLines.forEach(line => {
			if (line.custom && (line.custom.type === 'fullMark' || line.custom.type === 'subMark')) {
				this.props.canvas.remove(line);
			}
		});
		this.updateCanvasState();
	}

	addHashMarks() {
		// make sure grid not exist already
		if (this.checkGridMarks()) {
			// remove all grid marks first
			let allLines = this.props.canvas.getObjects('line');
			allLines.forEach(line => {
				if (line.name && (line.name === 'fullMark' || line.name === 'subMark')) {
					this.props.canvas.remove(line);
				}
			});
			this.updateCanvasState();
			return;
		}

		//let grid = 49.85;   // get dynamic grid from props
		let grid = this.props.canvas_grid;   // get dynamic grid from props
		let width = this.props.canvas.width;
		let height = this.props.canvas.height;
		let {paddingTop,paddingBottom,paddingLeft,paddingRight} = this.props.rubber_turf_config;

		let top = 	grid * paddingTop,
			left = grid * paddingLeft,
			bottom = grid * paddingBottom,
			right = grid * paddingRight,
			zoom = this.props.canvas.getZoom();

		let startDistance = left // distance from start
		let gridColor = this.state.gridColor;
		for (let i = startDistance; i < width-right; i += (grid * 15)) {
			//let line = new window.fabric.Line({});

			this.props.canvas.add(new window.fabric.Line([0, 0, 0, height/zoom-bottom-top], {
				left: i,
				top,
				strokeWidth: grid * 0.333,
				stroke: gridColor,
				evented:false,
				name:'fullMark'

			}));
			// check condition if add submakrs too
			if (true) {
				let subMarkLimit = i + (grid * 15) > width-right ? width-right : i + (grid * 15);
				for (let j = i + (grid * 3); j < subMarkLimit; j += (grid * 3)) {
					this.props.canvas.add(new window.fabric.Line([0, 0, 0, grid * 1.5], {
						left: j,
						top:top,
						strokeWidth: (grid * 0.333),
						stroke: gridColor,
						evented:false,
						name:'subMark'
					}));
					this.props.canvas.add(new window.fabric.Line([0, 0, 0, grid * 1.5], {
						left: j,
						top: height/zoom - bottom - (grid * 1.5),
						//top: width/2,
						strokeWidth: (grid * 0.333),
						stroke: gridColor,
						evented:false,
						name:'subMark'
					}));
				}
			}
		}
		this.updateCanvasState();
	}

	updateCanvasState (){
		let tempConfig = this.props._config;
		if (tempConfig.undoStatus === false && tempConfig.redoStatus === false) {
			let jsonData = this.props.canvas.toJSON(['custom','name']);
			let canvasAsJson = JSON.stringify(jsonData);
			if (tempConfig.currentStateIndex < tempConfig.canvasState.length - 1) {
				let indexToBeInserted = tempConfig.currentStateIndex + 1;
				tempConfig.canvasState[indexToBeInserted] = canvasAsJson;

				let numberOfElementsToRetain = indexToBeInserted + 1;
				tempConfig.canvasState = tempConfig.canvasState.splice(
					0,
					numberOfElementsToRetain
				);
			} else {
				tempConfig.canvasState.push(canvasAsJson);
			}

			tempConfig.currentStateIndex = tempConfig.canvasState.length - 1;

			if (
				tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
				tempConfig.currentStateIndex !== -1
			) {
				tempConfig.redoButton = "disabled";
			}
		}
		this.props.setCanvasConfig(tempConfig);
	};


	render() {
		return (
			<>
				<div className="row adddiv">
					<h2>Hash Color</h2>
				</div>
				<div className="templatesmain">
					<div className="add-new-txt">
						<div className="row row-border-btm pd-15" style={{width: '100%'}}>
							<button
								className="btn btn-danger add-text-btn"
								onClick={() => this.addHashMarks()}
								style={{width: '100%'}}
							>
								Toggle Hash Marks
							</button>
						</div>
					</div>
					<div className="add-text-blk-hmenu">
						<CustomColorPane activeColor={this.state.gridColor} title={'Choose Colors'} colors ={TurfColorHex} colorNames={TurfColorName} changeColor={(color) => {
							this.setGridMarkColor(color);
						}}/>
					</div>
					{/*<div className="add-text-blk-hmenu">
						<TextColorPaneConnect activeColor={this.state.gridColor} type={'turf'} changeColor={(color) => {
							this.setGridMarkColor(color)
						}}/>
					</div>*/}

				</div>
			</>
		)
	}
}

const mapStateToProps = state => {
	return {
		_config:state._config,
		canvas: state.canvas,
		canvas_grid: state.canvas_grid,
		rubber_turf_config:state.rubber_turf_config
	}
}
const setCanvasConfig = (_config) => {
	return {
		type: "SET_CONFIG",
		_config,
	};
}
const mapDispatchToProps = dispatch => {
	return bindActionCreators({ setCanvasConfig }, dispatch);
}

const RubberHashMarksToolbarConnect = connect(
	mapStateToProps,mapDispatchToProps
)(RubberHashMarksToolbar)

class RubberHashMarksTurfToolbarSidebar extends React.Component {
	closeNav13 = () => {
		document.getElementById("mySidenav17").style.width = "0";
	};

	render() {
		return (
			<>
				<div
					id="rubber-hash-color"
					className="h100 second-menu fordeskview"
					style={{display: "none"}}
				>
					<RubberHashMarksToolbarConnect/>
				</div>
				<div
					className="text-center h100 pad0 mtopneg sidenav4 formobview wo"
					id="mySidenav17"
				>
					<a
						style={{color: "#fff"}}
						href="#"
						className="closebtn"
						onClick={() => this.closeNav17()}
					>
						&times;
					</a>
					<RubberHashMarksToolbarConnect/>
				</div>
			</>
		);
	}
}

export default RubberHashMarksTurfToolbarSidebar;