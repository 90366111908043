import React from 'react'
// import MainMenu from '../components/layout/MainMenu'
import Header from '../components/layout/Header'

import './styles.css'

const DefaultLayout = ({ children }) => (
  <div className="bg-dark dk wallpad" id="wrap">
    {/* <MainMenu /> */}
    <Header />
    <div className="content-wrapper">
      {children}
    </div>
  </div>
)

export default DefaultLayout
