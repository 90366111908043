import React from "react";
import { connect } from "react-redux";
import {bindActionCreators} from "redux";
import $ from 'jquery';
import Styles from "../../assets/css/zoomTool.module.css";

class ZoomTool extends React.Component{
    state={
        zoom:1,
    }

    zoomIn=()=>{
        let {zoom} = this.state;
        if(zoom >= 5.0)return;
        const newZoom = zoom+0.1;
        this.setZoom(newZoom);
    }

    zoomOut=()=>{
        let {zoom} = this.state;
        if(zoom <= 0.3)return;
        const newZoom = zoom-0.1;
        this.setZoom(newZoom);
    }
    setZoom=(zoom)=>{

        if (zoom === 1) //Hide scrollbars if zoom level is 1
            $('#canvas-area').css('overflow', 'hidden');
        //$('#canvas-area').css('width', 'auto').css('height', 'auto').css('overflow', 'hidden');
        this.setState({
            zoom
        });
        const canvasHeight = this.props.canvasHeight ? this.props.canvasHeight : 400;
        const canvasWidth = this.props.canvasWidth ? this.props.canvasWidth : 400;
        this.props.setZoom(zoom);
        let tempWidth = canvasWidth * zoom;
        let tempHeight = canvasHeight * zoom;

        this.props.canvas.setWidth(tempWidth);

        this.props.canvas.setHeight(tempHeight);

        //$('#canvas-area').css('width', '600px').css('height', '600px').css('overflow', 'auto');

        this.props.canvas.deactivateAllWithDispatch();

        this.props.canvas.renderAll();

        this.props.canvas.zoomToPoint(new window.fabric.Point(0, 0), zoom);

        //$('#canvas-area').css('width', '600px').css('height', '600px').css('overflow', 'auto');
        //let scrollToMiddle = ($('#canvas-area')[0].scrollHeight - $('#canvas-area').height()) / 2;
        //let scrollToMiddle1 = ($('#canvas-area')[0].scrollWidth - $('#canvas-area').width()) / 2;
        //$('#canvas-area').scrollTop(scrollToMiddle);
        //$('#canvas-area').scrollLeft(scrollToMiddle1);

        console.log(window.innerWidth)
        const width = $('.custom-main-content').css('width');
        const height = $('.custom-main-content').css('height');
        console.log({height,width})
        $('.main-edit-content').css({width:`${width*zoom}px`})
        $('.main-edit-content').css({height:`${height*zoom}px`})
    }

    render() {
        const { zoom } = this.state;
        return(
            <li className={Styles.container+' custom-zoom-wrapper'} style={{marginTop:'13px'}}>
                <ul>
                    <li style={{borderRadius:'20px 0 0 20px',cursor:'pointer',paddingTop:'0px'}} onClick={this.zoomOut}>
                        <img src={require("./../../assets/img/zoom_minus.png")} style={{top:0,left:0}} alt="zoom" />
                    </li>
                    <li style={{paddingTop:'0px',paddingBottom:'3px'}}>
                        <span style={{fontSize:'12px'}}>{parseInt(zoom*100)}%</span>
                    </li>
                    <li style={{borderRadius:'0 20px 20px 0',cursor:'pointer',paddingTop:'0px'}} onClick={this.zoomIn}>
                        <img src={require("./../../assets/img/zoom_plus.png")} style={{top:0,left:0}} alt="zoom" />
                    </li>
                </ul>
            </li>
        )
    }
}

const mapStateToProps = state => {
    return {
        canvas: state.canvas,
        canvasWidth:state.canvasWidth,
        canvasHeight: state.canvasHeight,
    };
};
const setZoom = (option) => {
    return {
        type: "SET_ZOOM",
        option,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            setZoom,
        },
        dispatch
    );
};

const CanvasZoomConnect = connect(mapStateToProps,mapDispatchToProps)(ZoomTool);

export default CanvasZoomConnect;