/** @format */

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm, FormContext, useFormContext } from "react-hook-form";
import "./../../assets/css/fontawesome.css";
import $ from "jquery";

const CheckedInput = () => {
  const { register, getValues, errors, clearError } = useFormContext();

  const validate = () => {
    return ["sports[0]", "sports[1]", "sports[2]", "sports[3]"].some(
      (name) => getValues()[name]
    );
  };
  return (
    <div className="well">
      <div>Sport *</div>
      <div>
        <label>
          <input
            type="checkbox"
            name="sports[0]"
            value="Fitness/Yoga"
            ref={register({ validate })}
          />{" "}
          Fitness/Yoga
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            name="sports[1]"
            value="Wrestling"
            ref={register({ validate })}
          />{" "}
          Wrestling
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            name="sports[2]"
            value="Cheer/Gym"
            ref={register({ validate })}
          />{" "}
          Cheer/Gym
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            name="sports[3]"
            value="Martial Arts"
            ref={register({ validate })}
          />{" "}
          Martial Arts
        </label>
      </div>
      {errors.sports &&
        errors.sports[0] &&
        errors.sports[0].type === "validate" && (
          <p>Please select at least one option.</p>
        )}
    </div>
  );
};

const QuoteModal = (props) => {
  // const [show, setShow] = useState(false);
  const handleClose = () => props.toggleModal(false);
  const handleShow = () => {
    if(props.designer && props.designer === 'wrestling'){
      const thickness = $('input[name="mat-thickness"]:checked').map(function() { return $(this).val(); }).get().toString();
      if (!thickness) {
        alert("Please Select Mat Thickness");
        props.toggleModal(false)
      }else{
        props.toggleModal(true)
      }
    }
    else if(props.designer && props.designer === 'flexi'){
      const matstyle = $('input[name="mat-style"]:checked').val();
      console.log(matstyle);
      if ( !matstyle) {
        alert("Please Select Foam Thickness and Mat Style");
        props.toggleModal(false)
      }else{
        props.toggleModal(true)
      }
    }
    else{
      props.toggleModal(true)
    }

  };
  const { ...methods } = useForm({
    defaultValues: {
      subject:`${props.message}`
    }
  });

  const errors = methods.errors;
  const register = methods.register;

  const onSubmit = (data) => {

    if(data.sports){
      let sports = data.sports;
      let newSports =[];
      sports.forEach(item=>{
        if(item  !== false)
          newSports.push(item);
      })
      data.sports = newSports;
    }
    const { sendQuote } = props;
    sendQuote(data);
    // handleClose();
  };
  const toolbar =  props.toolbar ? props.toolbar : false;
  return (
    <>
      {
        toolbar && (
            <a className="toolbar-btn" onClick={handleShow}><img src={require("./../../assets/img/quote.png")} style={{marginTop:'-3px'}} alt="GET QUOTE" />
              <span className="hide-on-smaller-screen">Get a Quote</span>
            </a>
        )
      }
      {
        !toolbar && (
            <span className="gq">
              <button className='btn btn-danger custom-button' style={{
                marginLeft:'5px',
                marginRight:'5px'
              }} onClick={handleShow}>
                <img src={require("./../../assets/img/quote.png")} style={{marginTop:'-3px'}} alt="GET QUOTE" />{" "}
                Get a Quote
              </button>
            </span>
        )

      }
      <Modal
        show={props.show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        id="modalRegisterForm"
        dialogClassName="modal-custom"
        centered
      >
        <div className="modal-header text-center">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 className="modal-title w-100 font-weight-bold">GET A QUOTE</h4>
          <p className="modal-title w-100 font-weight-bold">
            If you have question, please call us or email us.
          </p>
        </div>
        <FormContext {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="modal-body mx-3">
              <div
                className={`md-form mb-5 ${errors.fullName ? "has-error" : ""}`}
              >
                <input
                  type="text"
                  id="orangeForm-name"
                  className="form-control validate"
                  placeholder="Your Full Name *"
                  name="fullName"
                  ref={register({ required: true })}
                />
                <i className="far fa-edit"></i>
                <p>{errors.fullName && "This field is required."}</p>
              </div>
              <div
                className={`md-form mb-5 ${errors.email ? "has-error" : ""}`}
              >
                <input
                  type="email"
                  id="orangeForm-email"
                  className="form-control validate"
                  placeholder="Your Email Address *"
                  name="email"
                  ref={register({
                    required: true,
                    pattern: {
                      value: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Your email address is not correct",
                    },
                  })}
                />
                <i className="far fa-envelope" aria-hidden="true"></i>
                <p>{errors.email && errors.email.message}</p>
                <p>
                  {errors.email &&
                    errors.email.type === "required" &&
                    "This field is required."}
                </p>
              </div>

              <div
                className={`md-form mb-5 ${errors.phone ? "has-error" : ""}`}
              >
                <input
                  type="tel"
                  id="orangeForm-phone"
                  className="form-control validate"
                  placeholder="Your Phone Number *"
                  name="phone"
                  ref={register({
                    required: true,
                  })}
                />
                <i className="fa fa-phone" aria-hidden="true"></i>
                <p>
                  {errors.phone &&
                    errors.phone.type === "required" &&
                    "This field is required."}
                </p>
              </div>

              <div
                className={`md-form mb-5 ${errors.zipcode ? "has-error" : ""}`}
              >
                <input
                  type="text"
                  id="orangeForm-zipcode"
                  className="form-control validate"
                  placeholder="Delivery Zip Code *"
                  name="zipcode"
                  ref={register({
                    required: true,
                    pattern: {
                      value: /^[0-9]{5}(?:-[0-9]{4})?$/,
                      message: "Your zip code is invalid.",
                    },
                  })}
                />
                <i className="fa fa-braille" aria-hidden="true"></i>
                <p>{errors.zipcode && errors.zipcode.message}</p>
                <p>
                  {errors.zipcode &&
                    errors.zipcode.type === "required" &&
                    "This field is required."}
                </p>
              </div>

              <div
                className={`md-form mb-5 ${errors.subject ? "has-error" : ""}`}
              >
                <input
                  type="text"
                  id="orangeForm-subject"
                  className="form-control validate"
                  placeholder="Your Subject *"
                  name="subject"
                  ref={register({
                    required: true,
                  })}
                />
                <i className="fas fa-edit" aria-hidden="true"></i>
                <p>{errors.subject && "This filed is required."}</p>
              </div>

              <div className="md-form mb-5">
                <textarea
                  className="form-control validate"
                  name="message"
                  rows="3"
                  col="30"
                  placeholder="Your Message"
                  id="orangeForm-message"
                  ref={register()}
                ></textarea>
                <i className="far fa-comments" aria-hidden="true"></i>
              </div>

              <div
                className={`md-form mb-5 ${errors.sports ? "has-error" : ""}`}
              >
                {props.designer==='turf'|| props.designer==='flexi'||props.designer==='wallpad' || props.designer==='puzzle' || props.designer==='rubber-puzzle' ? <CheckedInput /> : null}
              </div>

              <button
                className="btn btn-danger custom-button"
                type="submit"
                disabled={props.submitting}
                style={{ marginTop: "15px" }}
              >
                {props.submitting ? 'Submitting': 'Submit'}
              </button>
            </div>
          </form>
        </FormContext>

        <div className="modal-footer d-flex justify-content-center flex-direction-column">
          <p className="modal-title w-100 mb-3" style={{ textAlign: "center" }}>
            Thank you for contacting Dollamur Sport Surfaces with your Request
            for Quote. We value your business and will contact you as soon as
            possible with a FREE Design Quote. Please include any specific
            information in the Message section below.
          </p>

          <p className="modal-title w-100" style={{ textAlign: "center" }}>
            if you have questions, please call U.S.:{" "}
            <a href="tel:800-520-7647">800-520-7647</a>, I Intl:{" "}
            <a href="+1 954 643 0850">+1 954 643 0850</a> or <br />
            email us <a href="mailto:sales@dollamur.com">sales@dollamur.com</a>.
          </p>
        </div>
      </Modal>
    </>
  );
};
export default QuoteModal;
