import React from 'react'
import { connect } from 'react-redux'
import $ from 'jquery'
import CanvasZoomConnect from "../../zoomTool/ZoomTool";
import SaveModal from "../SaveModal";
import {bindActionCreators} from "redux";
import QuoteModal from "../QuoteModal";
import axios from "axios";
import EmailModal from "../EmailModal";
import {VinColorHex} from "../ColorCodeHelper";

class ExtraMenu extends React.Component {
    
    state = {
		submittingQuote: false,
		quoteModal: false,
	}
    componentDidMount = () => {}
    download = e => {
        this.clearSelection();
        e.preventDefault()
        let download = document.createElement('a')
        download.href = this.props.canvas.toDataURL()
        download.download = 'Dollamur_mat.png'
        download.click()
    }
    undo = e => {
        e.preventDefault();
        let tempConfig = this.props._config;
        if (tempConfig.undoFinishedStatus) {
            if (tempConfig.currentStateIndex === 0) {
                tempConfig.undoStatus = false;
            } else {
                if (tempConfig.canvasState.length >= 0) {
                    tempConfig.undoFinishedStatus = 0;
                    if (tempConfig.currentStateIndex !== 0) {
                        tempConfig.undoStatus = true;
                        this.props.canvas.loadFromJSON(
                            tempConfig.canvasState[tempConfig.currentStateIndex - 1],
                            () => {
                                this.props.canvas.renderAll();
                                tempConfig.undoStatus = false;
                                tempConfig.currentStateIndex -= 1;
                                tempConfig.undoButton = "remove disabled";
                                if (
                                    tempConfig.currentStateIndex !==
                                    tempConfig.canvasState.length - 1
                                ) {
                                    tempConfig.redoButton = "remove disabled";
                                }
                                tempConfig.undoFinishedStatus = 1;
                            }
                        );
                    } else if (tempConfig.currentStateIndex === 0) {
                        this.props.canvas.clear();
                        tempConfig.undoFinishedStatus = 1;
                        tempConfig.undoButton = "disabled";
                        tempConfig.redoButton = "remove disabled";
                        tempConfig.currentStateIndex -= 1;
                    }
                }
            }
        }
        this.props.canvas.getObjects('line').forEach(item=>{
            item.set({selectable:false});
        })
        this.props.canvas.renderAll()
        this.props.setCanvasConfig(tempConfig);
    };

    redo = e => {
        e.preventDefault();
        let tempConfig = this.props._config;
        if (tempConfig.redoFinishedStatus) {
            if (
                tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
                tempConfig.currentStateIndex !== -1
            ) {
                tempConfig.redoButton = "disabled";
            } else {
                if (
                    tempConfig.canvasState.length > tempConfig.currentStateIndex &&
                    tempConfig.canvasState.length !== 0
                ) {
                    tempConfig.redoFinishedStatus = 0;
                    tempConfig.redoStatus = true;
                    this.props.canvas.loadFromJSON(
                        tempConfig.canvasState[tempConfig.currentStateIndex + 1],
                        () => {
                            this.props.canvas.renderAll();
                            tempConfig.redoStatus = false;
                            tempConfig.currentStateIndex += 1;
                            if (tempConfig.currentStateIndex !== -1) {
                                tempConfig.undoButton = "remove disabled";
                            }
                            tempConfig.redoFinishedStatus = 1;
                            if (
                                tempConfig.currentStateIndex ===
                                tempConfig.canvasState.length - 1 &&
                                tempConfig.currentStateIndex !== -1
                            ) {
                                tempConfig.redoButton = "disabled";
                            }
                        }
                    );
                }
            }
            this.props.canvas.getObjects('line').forEach(item=>{
                item.set({selectable:false});
            })
            this.props.canvas.renderAll()
        }

        this.props.setCanvasConfig(tempConfig);
    };

    save = (title, email) => {
        let mat_type = 'puzzle';
        if(this.props.puzzle_config.type === 'rubber-puzzle') {
            mat_type = 'rubber-puzzle';
        }
        let jsonData = this.props.canvas.toDatalessJSON(['mat_class','mat_image']);
        const finalJson = JSON.stringify({
            puzzle_config:this.props.puzzle_config,
            rubber_puzzle_type:this.props.rubber_puzzle_type,
            active_rubber_puzzle:this.props.active_rubber_puzzle,
            canvas_grid:this.props.canvas_grid,
            json:jsonData
        })

        const data = {
            data: finalJson,
            email,
            title,
            mat_type,
            url: `${window.location.origin}/puzzle_save_designer/`
        };
        axios.post("/api/save_design", data).then(res => {
            alert("Your Design Has been saved! We have sent you an email with details.");
        });
    };

    email_design = (title, email) => {
        let mat_type = 'puzzle';
        if(this.props.puzzle_config.type === 'rubber-puzzle') {
            mat_type = 'rubber-puzzle';
        }
        let jsonData = this.props.canvas.toDatalessJSON(['mat_class','mat_image']);
        const finalJson = JSON.stringify({
            puzzle_config:this.props.puzzle_config,
            rubber_puzzle_type:this.props.rubber_puzzle_type,
            active_rubber_puzzle:this.props.active_rubber_puzzle,
            canvas_grid:this.props.canvas_grid,
            json:jsonData
        })

        const data = {
            data: finalJson,
            email,
            title,
            mat_type,
            url: `${window.location.origin}/puzzle_save_designer/`
        };
        axios.post("/api/save_design", data)
            .then(res => {
                alert('We have sent you an email with details.');
            }).catch(res => {
            alert('An unexpected error occurred. Please contact Admin');
        })
    };

    clearCanvas = (e) => {
        e.preventDefault();
        this.props.canvas.clear();
        window.scrollTo(0, 0);
        this.updateCanvasState();
        this.props.clearCanvas();
        clearTimeout(this.popupTimer);
        this.setState({ showAlert: false, firstAlert: true });
    };

    updateCanvasState = () => {
        let tempConfig = this.props._config;
        if (tempConfig.undoStatus === false && tempConfig.redoStatus === false) {
            let jsonData = this.props.canvas.toJSON(['mat_type']);
            let canvasAsJson = JSON.stringify(jsonData);
            if (tempConfig.currentStateIndex < tempConfig.canvasState.length - 1) {
                let indexToBeInserted = tempConfig.currentStateIndex + 1;
                tempConfig.canvasState[indexToBeInserted] = canvasAsJson;

                let numberOfElementsToRetain = indexToBeInserted + 1;
                tempConfig.canvasState = tempConfig.canvasState.splice(
                    0,
                    numberOfElementsToRetain
                );
            } else {
                tempConfig.canvasState.push(canvasAsJson);
            }

            tempConfig.currentStateIndex = tempConfig.canvasState.length - 1;

            if (
                tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
                tempConfig.currentStateIndex !== -1
            ) {
                tempConfig.redoButton = "disabled";
            }
        }
        this.props.setCanvasConfig(tempConfig);
    };

    clearSelection = () =>{
        this.props.canvas.deactivateAll().renderAll()
        $('.deleteBtn').css('visibility', 'hidden')
        $('.material-color-pane').css({ display: 'none' })
        $('.text-color-pane').css({ display: 'none' })

        $('#bold').removeClass('active')
        $('#italic').removeClass('active')
        $('#underline').removeClass('active')
        $('#capital').removeClass('active')
        $('#left-align').removeClass('active')
        $('#center-align').removeClass('active')
        $('#right-align').removeClass('active')
        $('#line_ht_get').val(1)
        $('#line_ht_get').css({
            background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
        })
        $('#line_ht_set').val(1)
        $('#spacing_get').val(1)
        $('#spacing_get').css({
            background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
        })
        $('#spacing_set').val(1)
        $('#text-color-bind').css({ background: 'black' })
        $('#size_get').val(0)
        $('#size_get').css({
            background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
        })
        $('#size_set').val(0)
        $('#outline-color-bind').css({ background: 'black' })
        $('#selected_text').val('sampletext')
        $('.add-text-btn').text('Add Text')
    }

    setQuoteRubber =(name, email, phone, zipcode, subject, message,sport)=>{
        const matData = [{}];
        const unit = this.props.puzzle_config.length_unit === "FEET" ? "ft" : "m";
        let unit1 = this.props.puzzle_config.length_unit === "FEET" ? 1.96 : 1;
        if(this.props.rubber_puzzle_type === 'straight')
            unit1 = this.props.puzzle_config.length_unit === "FEET"? 2 : 1;
        const canvas_grid = this.props.canvas_grid;
        matData[0].matSize =
            this.props.puzzle_config.width +
            unit +
            " x " +
            this.props.puzzle_config.height+unit;
        matData[0].designer ='rubber-puzzle';
        matData[0].sport =sport;
        matData[0].isStraight = this.props.rubber_puzzle_type === 'straight';

        // all mats
        const mats = [];
        let rubberMats = {};
        const allMats = this.props.canvas.getObjects('group');
        for (let i = 0; i < allMats.length; i++) {
            let size;
            let object = allMats[i];
            if(this.onOutOfCanvas(allMats[i])){
                continue;
            }
            if(object.getObjects()[0].type !== 'image') {
                continue;
            }
            let imageObject = object.getObjects()[0],
                matClass = imageObject.mat_class,
                matData = imageObject.mat_image,
                matImage = imageObject.mat_image,
                matTitle = '',
                matSize = '3/8"',
                matAngle = 0;
            //@todo: add checks for undefined values later

            matClass = matClass.split('_')[0];
            matImage = matImage.split(matClass)[1];
            matTitle = matImage.match(/[a-z]+|\d+/ig);
            matAngle = imageObject.angle;
            if(matData.includes('square')) {
                matSize = '1" SQUARE';
            }
            if(matData.includes('corner')) {
                matSize = '3/8" CORNER';
            }
            if(matData.includes('center')) {
                matSize = '3/8" CENTER';
            }
            if(matData.includes('side')) {
                matSize = '3/8" EDGE';
            }
            // mats.push({matImage,matTitle,matAngle,matSize,matData});

            /*
            * matAngle: 0
matData: "cornerMatBlue10"
matImage: "Blue10"
matSize: "3/8" CORNER"
matTitle: (2) ["Blue", "10"]
            * */

            let rubberMatTitle = matSize+" "+matImage;
            if(! rubberMats[rubberMatTitle] ) {
                rubberMats[rubberMatTitle] = 1;
            }else {
                rubberMats[rubberMatTitle] +=1;
            }

        }
        matData[0].rubberMats = rubberMats;
        matData[0].svg = this.props.canvas.toSVG();
        matData[0].png = this.props.canvas.toDataURL();

        const data = {
            name,
            email,
            phone,
            zipcode,
            subject:`${subject}`,
            message,
            matData,
        };
        axios
            .post("/api/quote", data)
            .then((res) => {
                alert("Successfully saved!");
            })
            .catch((res) => {
                alert("An unexpected error occurred. Please contact Admin");
            });
    }

    sendQuote = ({ fullName: name, email, phone, zipcode, subject, message,sports: sport}) => {
        this.clearSelection();
        this.setState({submittingQuote:true});
        if(this.props.puzzle_config.type === 'rubber-puzzle') {
            this.setQuoteRubber(name, email, phone, zipcode, subject, message,sport)
            return;
        }
        const matData = [{}];
        const unit = this.props.puzzle_config.length_unit === "FEET" ? "ft" : "m";
        const unit1 = this.props.puzzle_config.length_unit === "FEET" ? 3.28 : 1;
        const canvas_grid = this.props.canvas_grid;
        matData[0].matSize =
            this.props.puzzle_config.width +
            unit +
            " x " +
            this.props.puzzle_config.height+unit;

        const rects = {};
        const allrects = this.props.canvas.getObjects('image');
        let countInside = 0
        for (let i = 0; i < allrects.length; i++) {
            //setting all horizontals as vertivals
            let size;
            if(this.onOutOfCanvas(allrects[i])){
                continue;
            }
            countInside++
            console.log(countInside)
            const rectWidth = Math.round(allrects[i].width / canvas_grid / unit1);
            const rectHeight = Math.round(allrects[i].height / canvas_grid / unit1);

            if (
                rectWidth > rectHeight
            ) {
                size =
                    rectHeight + " x " + rectWidth;
            } else {
                size =
                    rectWidth + " x " + rectHeight;
            }
            let color = allrects[i].mat_class;
            if (rects[size + "##" + color]) {
                rects[size + "##" + color].count += 1;
            } else {
                rects[size + "##" + color] = {
                    count: 1,
                };
            }
        }
        const colors = [];
        Object.keys(rects).forEach((key, i) => {
            const strs = key.split("##");
            colors[i] = {
                count: rects[key].count,
                color: strs[1],
                size: strs[0],
            };
        });
        colors.forEach(item=>{
            item.color = item.color.replace('+','/');
        })

        matData[0].colors = colors;
        matData[0].sport = sport;
        matData[0].logos = this.props.uploadedLogos;
        matData[0].svg = this.props.canvas.toSVG();
        matData[0].png = this.props.canvas.toDataURL();

        matData[0].text = this.props.text_style ? this.props.text_style.text : "";
        matData[0].font = this.props.text_style
            ? this.props.text_style.fontFamily
            : "";
        matData[0].fontColor = this.props.fontColor;

        const data = {
            name,
            email,
            phone,
            zipcode,
            subject:`${subject}`,
            message,
            matData,
        };

        axios
            .post("/api/quote", data)
            .then((res) => {
                this.setState({quoteModal: false, submittingQuote: false});
                alert("Successfully saved!");
            })
            .catch((err) => {
                this.setState({quoteModal: false, submittingQuote: false});
                alert("An unexpected error occurred. Please contact Admin");
            });
    };

    reduceDesignColor=(colorCode)=>{
        if(colorCode.indexOf('rgb') >= 0){
            let a = colorCode.split("(")[1].split(")")[0];
            a = a.split(",");
            let b = a.map(function(x){                      //For each array element
                x = parseInt(x).toString(16);      //Convert to a base16 string
                return (x.length===1) ? "0"+x : x; //Add zero if we get only one character
            });
            colorCode = "#"+b.join("");
        }
        let colorName  = colorCode,
            colorNames = Object.keys(VinColorHex);
        colorNames.forEach(color=>{
            if(VinColorHex[color] === colorName){
                colorName = color;
            }
        })
        return colorName;
    }
    onOutOfCanvas = (activeObject) => {
        let out = false;
        activeObject.setCoords();
        let cords = activeObject.oCoords;
        let originalHeight = this.props.canvas.height;
        let originalWidth = this.props.canvas.width;
        //if is out from top
        if (cords.tl.y < 0 && cords.tr.y < 0 && cords.br.y < 5 && cords.bl.y < 5) {
            out = true;
        }
        //if is out from left
        else if (cords.tl.x < 5 && cords.tr.x < 5 && cords.br.x < 5 && cords.bl.x < 5) {
            out = true;
        }
        //if is out from bottom
        else if (cords.tl.x > originalWidth && cords.bl.x > originalWidth) {
            out = true;
        }
        //if is out from right
        else if (cords.tr.y > originalHeight && cords.tl.y > originalHeight) {
            out = true;

        }
        return out;
    }

    menuToggle = () => {
        let bodyClass = $('body').attr('class')
        if (
            bodyClass === undefined ||
            bodyClass.indexOf('sidebar-left-opened') < 0
        ) {
            $('body').addClass('sidebar-left-opened')
        } else {
            $('body').removeClass('sidebar-left-opened')
        }
    }
    render() {
        let designer = this.props.puzzle_config.type === 'rubber-puzzle' ? 'rubber-puzzle' : 'puzzle'
        return (
            <>
                <div className="row mar">
                    <div className="col-md-12 thrd-nav">
                        <div className="col-md-12 pl-0 pr-0 mobile-menu">
                            <QuoteModal 
                                toolbar={true} 
                                designer={designer} 
                                sendQuote={this.sendQuote} 
                                message={'PUZZLE MAT'}
                                show={this.state.quoteModal}
                				submitting={this.state.submittingQuote}
                				toggleModal={(val) => this.setState({quoteModal: val})} 
                            />
                            <SaveModal toolbar={true} save={this.save} />
                            <EmailModal toolbar={true} email_design={this.email_design} />
                            <a className="toolbar-btn" onClick={this.download}><img
                                className="fas"
                                src={require("./../../../assets/img/download.png")}
                                style={{marginTop:'-3px'}}
                                alt="DOWNLOAD"
                            /><span className="hide-on-smaller-screen">Download</span></a>
                            <a className="toolbar-btn" href='/'><img
                                className="fas"
                                src={require("./../../../assets/img/info.png")}
                                style={{marginTop:'-3px'}}
                                alt="INFO"
                            /><span className="hide-on-smaller-screen">Info</span></a>
                            <a className="toolbar-btn" onClick={this.undo}><img
                                className="fas"
                                src={require("./../../../assets/img/undo.png")}
                                style={{marginTop:'-3px'}}
                                alt="UNDO"
                            /><span className="hide-on-smaller-screen">Undo</span></a>
                            <a className="toolbar-btn" onClick={this.redo}><img
                                src={require("./../../../assets/img/redo.png")}
                                style={{marginTop:'-3px'}}
                                alt="REDO"
                            /><span className="hide-on-smaller-screen">Redo</span></a>
                            <a className="toolbar-btn" onClick={this.clearCanvas}><img
                                src={require("./../../../assets/img/delete.png")}
                                style={{marginTop:'-3px'}}
                                alt="REDO"
                            /><span className="hide-on-smaller-screen">Clear</span></a>
                            <div className="nav navbar-nav navbar-right">
                                <CanvasZoomConnect/>
                            </div>
                        </div>
                        <ul className="nav navbar-nav navbar-right formobview mobul">
                            <li>
                                <a onClick={this.download}>
                                    <img
                                        src={require('./../../../assets/img/download.png')}
                                        alt="DOWNLOAD"
                                    />
                                </a>
                            </li>
                            <li>
                                <a href="/">
                                    <img
                                        src={require('./../../../assets/img/info.png')}
                                        alt="INFO"
                                    />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row mar sectogglebtn formobview">
                    <a
                        data-placement="bottom"
                        data-original-title="Show/Hide"
                        data-toggle="tooltip"
                        className="btn btn-sm toggle-left"
                        id="menu-toggle"
                        onClick={() => this.menuToggle()}
                    >
                        <img src={require('./../../../assets/img/menu.png')} alt="MENU" />
                    </a>
                </div>
            </>
        )
    }
}

const clearCanvas = () => {
    return {
        type: "CLAER_CANVAS",
        isCanvasCleared: true,
    };
};

const setCanvasConfig = (_config) => {
    return {
        type: "SET_CONFIG",
        _config,
    };
};


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            clearCanvas,
            setCanvasConfig
        },
        dispatch
    );
};

const mapStateToProps = state => {
    return {
        _config: state._config,
        canvas: state.canvas,
        puzzle_config:state.puzzle_config,
        canvas_grid:state.canvas_grid,
        rubber_puzzle_type: state.rubber_puzzle_type,
        active_rubber_puzzle: state.active_rubber_puzzle,
    }
}

const ExtraMenuConnect = connect(mapStateToProps,mapDispatchToProps)(ExtraMenu)

export default ExtraMenuConnect
