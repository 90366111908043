// implemented for mat designer. extends window.fabric.CurvedText to render text vertically
(function (global){

	"use strict";

	var fabric=global.fabric||(global.fabric={}),
			extend=fabric.util.object.extend,
			clone=fabric.util.object.clone;

	if(fabric.StraightText){
		fabric.warn('fabric.StraightText is already defined');
		return;
	}
	
	fabric.StraightText = global.fabric.util.createClass(global.fabric.CurvedText, {
		// type: 'straightText',

		_render: function (ctx) {
			this.callSuper('_render');		
			var width = 0;

			for (var i = 0, len = this.text.length; i < len; i++) {
				this.letters.item(i).set('left', (width));
				width += this.letters.item(i).get('width') + +this.spacing;
			}

			var scaleX=this.letters.get('scaleX');
			var scaleY=this.letters.get('scaleY');
			var angle=this.letters.get('angle');

			this.letters.set('scaleX', 1);
			this.letters.set('scaleY', 1);
			this.letters.set('angle', 0);

			// Update group coords
			this.letters._calcBounds();
			this.letters._updateObjectsCoords();

			this.letters.set('scaleX', scaleX);
			this.letters.set('scaleY', scaleY);
			this.letters.set('angle', angle);

			this.width=this.letters.width;
			this.height=this.letters.height;
			this.letters.left=-(this.letters.width/2);
			this.letters.top=-(this.letters.height/2);		
		}
	})
})(global);
