import blue10 from './blue10.png';
import blueGrey10 from './blue-grey10.png';
import brick10 from './brick10.png';
import green10 from './green10.png';
import grey10 from './grey10.png';
import purple10 from './purple-10.png';
import yellow10 from './yellow10.png';
import black10 from './black10.png';
import tan10 from './tan10.png';
// small icons
import blueSmall10 from './blue-small-10.png';
import blueGreySmall10 from './blue-grey-s-10.png';
import brickSmall10 from './brick-s-10.png';
import greenSmall10 from './green-s-10.png';
import gerySmall10 from './grey-s-10.png';
import purpleSmall10 from './purple-s-10.png';
import yellowSmall10 from './yellow-s-10.png';
import blackSmall10 from './blacks10.png';
import tanSmall10 from './tan-s-10.png';

import whiteSmall20 from './white-s-20.png';
import redSmall20 from './red-s-20.png';
import greySmall20 from './grey-s-20.png';
import brownSmall20 from './brown-s-20.png';
import blueSmall20 from './blues20.png';
import goldSmall20 from './gold-s-20.png';
import blueWhiteGreySmall20 from './blue-white-gery-s-20.png';

import white20 from './white20.png';
import red20 from './red20.png';
import grey20 from './grey20.png';
import brown20 from './brown20.png';
import blue20 from './blue20.png';
import gold20 from './gold20.png';
import blueWhiteGrey20 from './gery-white-blue-20.png';

import blueSmall35 from './blue-s-35.png';
import gerySmall35 from './gery-s-35.png';
import greenSmall35 from './green-s-35.png';
import redSmall35 from './red-s-35.png';
import tanSmall35 from './tan-s-35.png';

import blue35 from './blue35.png';
import green35 from './GREEN35.jpeg';
import gery35 from './GREY35.jpeg';
import red35 from './RED35.jpeg';
import tan35 from './TAN35.jpeg';

import brown95 from './brown-95.jpg';
import charcoalGery95 from './charcoal-gery-95.jpg';
import geryTan95 from './gery-tan-95.jpg';
import tan95 from './tan-95.jpg';
import red95 from './red-95.jpg';
import gery95 from './gery-95.jpg';

export {
	// 10 %
	blue10,blueGrey10,brick10,green10,grey10,purple10,yellow10,black10,tan10,
	blueSmall10,blueGreySmall10,brickSmall10,greenSmall10,gerySmall10,purpleSmall10,yellowSmall10,blackSmall10,tanSmall10,
	// 20 %
	whiteSmall20,redSmall20,greySmall20,brownSmall20,blueSmall20,goldSmall20,blueWhiteGreySmall20,
	white20,red20,grey20,brown20,blue20,gold20,blueWhiteGrey20,
	//35 %
	blueSmall35,gerySmall35,greenSmall35,redSmall35,tanSmall35,
	blue35,green35,gery35,tan35,red35,
	//95 %
	brown95,charcoalGery95,geryTan95,tan95,red95,gery95
}