import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import axios from 'axios'

class Footer extends React.Component {
  undo = e => {
    e.preventDefault()
    let tempConfig = this.props._config
    if (tempConfig.undoFinishedStatus) {
      if (tempConfig.currentStateIndex === 0) {
        tempConfig.undoStatus = false
      } else {
        if (tempConfig.canvasState.length >= 1) {
          tempConfig.undoFinishedStatus = 0
          if (tempConfig.currentStateIndex !== 0) {
            tempConfig.undoStatus = true
            this.props.canvas.loadFromJSON(
              tempConfig.canvasState[tempConfig.currentStateIndex - 1],
              () => {
                this.props.canvas.renderAll()
                tempConfig.undoStatus = false
                tempConfig.currentStateIndex -= 1
                tempConfig.undoButton = 'remove disabled'
                if (
                  tempConfig.currentStateIndex !==
                  tempConfig.canvasState.length - 1
                ) {
                  tempConfig.redoButton = 'remove disabled'
                }
                tempConfig.undoFinishedStatus = 1
              },
            )
          } else if (tempConfig.currentStateIndex === 0) {
            this.props.canvas.clear()
            tempConfig.undoFinishedStatus = 1
            tempConfig.undoButton = 'disabled'
            tempConfig.redoButton = 'remove disabled'
            tempConfig.currentStateIndex -= 1
          }
        }
      }
    }

    this.props.setCanvasConfig(tempConfig)
  }

  redo = e => {
    e.preventDefault()
    let tempConfig = this.props._config
    if (tempConfig.redoFinishedStatus) {
      if (
        tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
        tempConfig.currentStateIndex !== -1
      ) {
        tempConfig.redoButton = 'disabled'
      } else {
        if (
          tempConfig.canvasState.length > tempConfig.currentStateIndex &&
          tempConfig.canvasState.length !== 0
        ) {
          tempConfig.redoFinishedStatus = 0
          tempConfig.redoStatus = true
          this.props.canvas.loadFromJSON(
            tempConfig.canvasState[tempConfig.currentStateIndex + 1],
            () => {
              this.props.canvas.renderAll()
              tempConfig.redoStatus = false
              tempConfig.currentStateIndex += 1
              if (tempConfig.currentStateIndex !== -1) {
                tempConfig.undoButton = 'remove disabled'
              }
              tempConfig.redoFinishedStatus = 1
              if (
                tempConfig.currentStateIndex ===
                  tempConfig.canvasState.length - 1 &&
                tempConfig.currentStateIndex !== -1
              ) {
                tempConfig.redoButton = 'disabled'
              }
            },
          )
        }
      }
    }

    this.props.setCanvasConfig(tempConfig)
  }

  save = e => {
    e.preventDefault()
    const data = {
      data: JSON.stringify(this.props._config),
    }
    axios.post('/api/save_design', data).then(res => {
      console.log(this.props._config)
    })
  }
  render() {
    return (
      <>
        <footer className="Footer bg-dark lter-new">
          <p>&copy; 2020 Dollamur Sport Surfaces. All Rights Reserved.</p>
          {/* <ul className="formobview btmnav">
            <li>
              <a href="#" onClick={this.undo}>
                <img
                  src={require('./../../assets/img/undobk.png')}
                  alt="UNDO"
                />
              </a>
            </li>
            <li>
              <a href="#" onClick={this.redo}>
                <img
                  src={require('./../../assets/img/redobk.png')}
                  alt="REDO"
                />
              </a>
            </li>
            <li>
              <a href="#" onClick={this.save}>
                <img
                  src={require('./../../assets/img/savebk.png')}
                  alt="SAVE"
                />
              </a>
            </li>
            <li>
              <a href="#">
                <img src={require('./../../assets/img/delbk2.png')} alt="DEL" />
              </a>
            </li>
            <li>
              <a href="#">
                <img
                  src={require('./../../assets/img/mailbk.png')}
                  alt="MAIL"
                />
              </a>
            </li>
          </ul> */}
        </footer>
      </>
    )
  }
}

const setCanvasConfig = _config => {
  return {
    type: 'SET_CONFIG',
    _config,
  }
}

const mapStateToProps = state => {
  return {
    _config: state._config,
    canvas: state.canvas,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ setCanvasConfig }, dispatch)
}

const FooterConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Footer)

export default FooterConnect
