import React from "react";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenMenu: false,
    }
  }

  openNav = () => {
    document.getElementById("mySidenav").style.width = "250px";
    this.setState({ isOpenMenu: true });
  };

  closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
  };

  handleClose = () => {
    document.getElementById("mySidenav").style.width = "0";
    this.setState({ isOpenMenu: false });
  }

  render() {
    return (
        <>
        <div className="see-instruction fordeskview">
          <div className="d-flex align-items-center justify-content-center h-75">
            <div className="left-cap">
              <h1>
                <img
                    className="fl"
                    src={require("./../../assets/img/logo-new.png")}
                    alt="LOGO"
                />
              </h1>
            </div>
            <ul className="nav navbar-nav navbar-center">
              <li className="active">
                <a href="/">HOME</a>
              </li>
              <li className="active">
                <a href="/wrestling_mat_designer">WRESTLING</a>
              </li>
              <li>
                <a href="/swain_mat_designer">SWAIN HYBRID</a>
              </li>
              <li>
                <a href="/flexi_mat_designer"> FLEXI-ROLL</a>
              </li>
              <li>
                <a href="/turf_mat_designer">TURF/RUBBER</a>
              </li>
              <li>
                <a href="/wallpad_mat_designer">WALL PADS</a>
              </li>
              <li>
                <a href="/puzzle_mat_designer">PUZZLE</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="see-instruction formobview">
          <div id="mySidenav" className="sidenav">
            {/* <a href="#" className="closebtn" onClick={() => this.closeNav()}>
              &times;
            </a> */}
            <a href="/">HOME</a>
            <a href="/wrestling_mat_designer">WRESTLING</a>
            <a href="/swain_mat_designer">SWAIN HYBRID</a>
            <a href="/flexi_mat_designer"> FLEXI-ROLL</a>
            <a href="/turf_mat_designer">TURF/RUBBER</a>
            <a href="/wallpad_mat_designer">WALL PADS</a>
            <a href="/puzzle_mat_designer">PUZZLE</a>
          </div>
          <span
            style={{ fontSize: 30, cursor: "pointer" }}
            onClick={() => this.openNav()}
          >
            <img src={require("./../../assets/img/menu.png")} alt="Menu" />
          </span>
          <img
            className="fr moblogo"
            src={require("./../../assets/img/logo-new.png")}
            alt="LOGO"
          />
        </div>
        <div className={`custom-right-sidenav ${this.state.isOpenMenu ? 'right-sidenav-active' : ''}`} onClick={this.handleClose} />
      </>
    );
  }
}

export default Header;
