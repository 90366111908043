import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Footer from '../Footer'

class OptionSettingDialogue extends React.Component {
  state = {
    width: 10,
    height: 10,
    unit: 'FEET',
  }

  onBuild = () => {
    if (this.state.width > 75 || this.state.height > 75) {
      return alert("Width or height should be maximum 75")
    }
    if (parseInt(this.state.height) > parseInt(this.state.width)) {
      let { width, height, unit } = this.state
      let newState = {
        width: height,
        height: width,
        unit: unit
      }
      console.log(newState)
      this.props.setFlexiConfig(newState)
    } else {
      this.props.setFlexiConfig(this.state)
    }

  }

  _handleWidthChange = e => {
    let { value } = e.target
    this.setState({
      width: value,
    })
  }

  _handleHeightChange = e => {
    let { value } = e.target
    this.setState({
      height: value,
    })
  }

  _handleUnitChange = e => {
    let { value } = e.target
    if (value === 1) {
      this.setState({
        unit: 'FEET',
      })
    } else {
      this.setState({
        unit: 'METERS',
      })
    }
  }

  render() {
    return (
      <>
        <div className="see-instruction int-matbuild default-left flexi">
          <div className="container">
            <div className="innerint default-left text-center">
              <h2>Interactive Mat Builder</h2>
              <h3>SMOOTH, TATAMI and CARPET MATS</h3>
              <p>What kind of mat would you like? For mats under 12 x 18 please visit our Store.</p>

              <div className=" selectboxes default-left">
                <div className="row innerselect_box">
                  <div className="col-md-3 col-sm-12 selectcol col_1">
                    <input
                      type="number"
                      className="mdb-select md-form colorful-select dropdown-primary"
                      min="1"
                      max="75"
                      defaultValue="10"
                      onChange={this._handleWidthChange}
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 selectcol col_2">
                    <input
                      type="number"
                      className="mdb-select md-form colorful-select dropdown-primary"
                      min="1"
                      max="75"
                      defaultValue="10"
                      onChange={this._handleHeightChange}
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 selectcol col_3">
                    <select
                      className="mdb-select md-form colorful-select dropdown-primary"
                      onChange={this._handleUnitChange}
                    >
                      <option value="1">Feet</option>
                    </select>
                  </div>
                  <div className="col-md-3 col-sm-12 selectcol col_3">
                    <div className="fw">
                      <a className='btn btn-danger' style={{padding:'0px'}} href="#" onClick={() => this.onBuild()}>
                        <span>Build it</span>
                        <img
                          src={require('./../../../assets/img/fwarw.png')}
                          alt="Forward Arrow"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="default-left back-fwd-btns">
              <div className="row">
                <div className="default-left entertxt text-center">
                  <p className="font-oswald">
                    Enter your room size. For odd shaped rooms, put in your best<br />
                  guess of the area & speak to one of our mat experts for a<br />
                   more accurate idea.
                </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

const setFlexiConfig = config => {
  const _config = {
    width: config.width,
    height: config.height,
    option_setting: true,
    length_unit: config.unit,
  }

  return {
    type: 'SET_FLEXI_CONFIG',
    _config: _config,
  }
}

const mapStateToProps = state => {
  return {
    flexi_config: state.flexi_config,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ setFlexiConfig }, dispatch)
}

const OptionSettingConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OptionSettingDialogue)

export default OptionSettingConnect
