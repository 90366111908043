import React from "react";
import "../assets/css/bootstrap.css";
import "../assets/css/fontawesome.css";
import "../assets/css/wrestling.css";
import "../assets/css/metisMenu.css";
import "../assets/css/onoffcanvas.css";
import "../assets/css/animate.css";
import { Container } from "shards-react";
import "fabric-webpack";
import DesignCanvas from "../components/layout/flexi_mat_designer/DesignCanvas";
import { connect } from "react-redux";
import CurvedText from "../components/CurvedText";
import Image from "../components/Image";
import { bindActionCreators } from "redux";
import Sidebar from "../components/layout/flexi_mat_designer/Sidebar";
import ExtraMenu from "../components/layout/flexi_mat_designer/ExtraMenu";
import SidebarItems from "../components/layout/flexi_mat_designer/SidebarItems";
import OptionSettingDialogue from "../components/layout/flexi_mat_designer/Option_Settting_Dialogue";
import ColorPickerContainer from "./../components/ColorPicker";
import axios from "axios";
import $ from "jquery";
import { PaintColorHex } from "../components/layout/ColorCodeHelper";

class Dashboard extends React.Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    if (id !== undefined) {
      this.props.setSaveDesignID(id);
    }
    $(".text-color-pane").css({ display: "none" });
  }

  updateCanvasState = () => {
    let tempConfig = this.props._config;
    if (tempConfig.undoStatus === false && tempConfig.redoStatus === false) {
      let jsonData = this.props.canvas.toJSON();
      let canvasAsJson = JSON.stringify(jsonData);
      if (tempConfig.currentStateIndex < tempConfig.canvasState.length - 1) {
        let indexToBeInserted = tempConfig.currentStateIndex + 1;
        tempConfig.canvasState[indexToBeInserted] = canvasAsJson;

        let numberOfElementsToRetain = indexToBeInserted + 1;
        tempConfig.canvasState = tempConfig.canvasState.splice(
          0,
          numberOfElementsToRetain
        );
      } else {
        tempConfig.canvasState.push(canvasAsJson);
      }

      tempConfig.currentStateIndex = tempConfig.canvasState.length - 1;

      if (
        tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
        tempConfig.currentStateIndex !== -1
      ) {
        tempConfig.redoButton = "disabled";
      }
    }
    this.props.setCanvasConfig(tempConfig);
  };

  clearCanvas = (e) => {
    e.preventDefault();
    //this.props.canvas.clear();


    let curvedText = this.props.canvas.getObjects('curvedText');
    let image = this.props.canvas.getObjects('image');
    curvedText.forEach((item)=>{
      this.props.canvas.remove(item);
    })
    image.forEach((item)=>{
      this.props.canvas.remove(item);
    })
    const t = new window.fabric.Text('', { fontFamily: 'Times New Roman',left:-10 })
    this.props.canvas.add(t);
    this.props.canvas.sendToBack(t);
    this.props.canvas.renderAll();
    this.updateCanvasState();

  };

  clearCanvas1 = (e) => {
    e.preventDefault();
    this.updateCanvasState();

    let objects = this.props.canvas.getObjects();
    console.log(objects);
    objects.forEach((item)=>{
      if(item.subType && item.subType === 'mat'){
      }else{
        this.props.canvas.remove(item);
      }
    })
    const t = new window.fabric.Text('', { fontFamily: 'Times New Roman',left:-10 })
    this.props.canvas.add(t);
    this.props.canvas.sendToBack(t);
    this.props.canvas.renderAll();

  };
  clearSelection = () => {
    this.props.canvas.deactivateAll().renderAll();
    $(".deleteBtn").css("visibility", "hidden");

    $(".text-color-pane").css({ display: "none" });
    $("#bold").removeClass("active");
    $("#italic").removeClass("active");
    $("#underline").removeClass("active");
    $("#capital").removeClass("active");
    $("#left-align").removeClass("active");
    $("#center-align").removeClass("active");
    $("#right-align").removeClass("active");
    //$('.slct-font-size').val($('.slct-font-size option:first').val())
    //$('.slct-font-fam').val($('.slct-font-fam option:first').val())
    $("#line_ht_get").val(1);
    $("#line_ht_get").css({
      background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
    });
    $("#line_ht_set").val(1);
    $("#spacing_get").val(1);
    $("#spacing_get").css({
      background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
    });
    $("#spacing_set").val(1);
    $("#text-color-bind").css({ background: "black" });
    $("#size_get").val(0);
    $("#size_get").css({
      background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
    });
    $("#size_set").val(0);
    $("#outline-color-bind").css({ background: "black" });
    $("#selected_text").val("sampletext");
    $(".add-text-btn").text("Add Text");
  };

  sendQuote = ({
    fullName: name,
    email,
    phone,
    zipcode,
    subject,
    message,
    sports: sport,
  }) => {
    this.clearSelection();
    const thickness = this.props.thickness;
    const matData = [{}];
    const unit = this.props.flexi_config.length_unit === "FEET" ? "ft" : "m";
    matData[0].matSize =
      this.props.flexi_config.width +
      unit +
      " x " +
      this.props.flexi_config.height +
      unit;
    matData[0].matColor = this.props.flexi_color;
    matData[0].thickness = thickness;

    let matFonts = [];
    if (this.props.canvas.getObjects("curvedText").length > 0) {
      let curvedTexts = this.props.canvas.getObjects("curvedText");
      curvedTexts.forEach((item,index)=>{
        console.log({index,item});
        let color = item.fill;
        if(color.indexOf('rgb') >= 0){
          let a = color.split("(")[1].split(")")[0];
          a = a.split(",");
          let b = a.map(function(x){           //For each array element
            x = parseInt(x).toString(16);      //Convert to a base16 string
            return (x.length===1) ? "0"+x : x; //Add zero if we get only one character
          });
          color = "#"+b.join("");
        }
        if(item.text !== ''){
          matFonts.push({
            text : item.text,
            fontFamily : item.fontFamily,
            fill : color,
          })
        }
      })

      matData[0].text = this.props.text_style ? this.props.text_style.text : "";
      matData[0].font = this.props.text_style
          ? this.props.text_style.fontFamily
          : "";
      matData[0].fontColor = this.props.fontColor
    }
    matData[0].curvedTexts = matFonts;

    matData[0].flexiType = this.props.flexi_type;
    matData[0].sport = sport;

    const fc = $('.flexi-connect input[name="mat-style"]:checked').val();
    matData[0].fc = fc ? "Yes" : null;

    const fr = $('.flexi-roll-mats input[name="mat-style"]:checked').val();
    matData[0].fr = fr ? "Yes" : null;

    const nonFr = $(
      '.non-flexi-roll-mats input[name="mat-style"]:checked'
    ).val();
    matData[0].nonFr = nonFr ? "Yes" : null;

    matData[0].logos = this.props.uploadedLogos;
    matData[0].svg = this.props.canvas.toSVG();
    matData[0].png = this.props.canvas.toDataURL();

    const data = {
      name,
      email,
      phone,
      zipcode,
      subject,
      message,
      thickness,
      matData,
    };
    axios
      .post("/api/quote", data)
      .then((res) => {
        alert("Successfully saved!");
      })
      .catch((err) => {
        console.log(err);
        alert("An unexpected error occurred. Please contact Admin");
      });
  };

  setActiveTab = (id) => {
    $("#text-custom").css("display", "none");
    $("#text-rgb").css("display", "none");
    $("#text-cmyk").css("display", "none");
    $(`#text-${id}`).css("display", "block");
    $(".custom-colors .active").removeClass("active");
    $(`.${id}-color`).addClass("active");
  };

  setTextColor = (color) => {
    let activeObject = this.props.canvas._activeObject;
    if (activeObject == null) return;

    if (this.props.textColorOption === "fill") {
      activeObject.set("fill", color);
    } else {
      activeObject.set("stroke", color);
    }
    this.props.canvas.renderAll();
    this.updateCanvasState();
    this.props.setFontColorOption(color);
  };

  setTextKeyColor = (e) => {
    let { value } = e.target;
    $("#key_get").val(value);
    $("#key_set").val(value);
    const black = value;
    const cyan = $("#cyan_get").val();
    const magenta = $("#magenta_get").val();
    const yellow = $("#yellow_get").val();

    const red = 255 * (1 - cyan / 100) * (1 - black / 100);
    const green = 255 * (1 - magenta / 100) * (1 - black / 100);
    const blue = 255 * (1 - yellow / 100) * (1 - black / 100);

    $("#cmyk_color").css("background", `rgb(${red}, ${green}, ${blue})`);

    const activeObject = this.props.canvas._activeObject;
    if (!activeObject) return;
    if (this.props.textColorOption === "fill") {
      activeObject.set("fill", `rgb(${red}, ${green}, ${blue})`);
    } else {
      activeObject.set("stroke", `rgb(${red}, ${green}, ${blue})`);
    }

    this.props.canvas.renderAll();
    this.updateCanvasState();
  };

  setTextMagentaColor = (e) => {
    let { value } = e.target;
    $("#magenta_get").val(value);
    $("#magenta_set").val(value);

    const magenta = value;
    const cyan = $("#cyan_get").val();
    const black = $("#key_get").val();
    const yellow = $("#yellow_get").val();

    const red = 255 * (1 - cyan / 100) * (1 - black / 100);
    const green = 255 * (1 - magenta / 100) * (1 - black / 100);
    const blue = 255 * (1 - yellow / 100) * (1 - black / 100);

    $("#cmyk_color").css("background", `rgb(${red}, ${green}, ${blue})`);

    const activeObject = this.props.canvas._activeObject;
    if (!activeObject) return;
    if (this.props.textColorOption === "fill") {
      activeObject.set("fill", `rgb(${red}, ${green}, ${blue})`);
    } else {
      activeObject.set("stroke", `rgb(${red}, ${green}, ${blue})`);
    }

    this.props.canvas.renderAll();
    this.updateCanvasState();
  };

  setTextYellowColor = (e) => {
    let { value } = e.target;
    $("#yellow_get").val(value);
    $("#yellow_set").val(value);

    const yellow = value;
    const cyan = $("#cyan_get").val();
    const black = $("#key_get").val();
    const magenta = $("#magenta_get").val();

    const red = 255 * (1 - cyan / 100) * (1 - black / 100);
    const green = 255 * (1 - magenta / 100) * (1 - black / 100);
    const blue = 255 * (1 - yellow / 100) * (1 - black / 100);

    $("#cmyk_color").css("background", `rgb(${red}, ${green}, ${blue})`);

    const activeObject = this.props.canvas._activeObject;
    if (!activeObject) return;
    if (this.props.textColorOption === "fill") {
      activeObject.set("fill", `rgb(${red}, ${green}, ${blue})`);
    } else {
      activeObject.set("stroke", `rgb(${red}, ${green}, ${blue})`);
    }

    this.props.canvas.renderAll();
    this.updateCanvasState();
  };

  setTextCyanColor = (e) => {
    let { value } = e.target;
    $("#cyan_get").val(value);
    $("#cyan_set").val(value);

    const cyan = value;
    const yellow = $("#yellow_get").val();
    const black = $("#key_get").val();
    const magenta = $("#magenta_get").val();

    const red = 255 * (1 - cyan / 100) * (1 - black / 100);
    const green = 255 * (1 - magenta / 100) * (1 - black / 100);
    const blue = 255 * (1 - yellow / 100) * (1 - black / 100);

    $("#cmyk_color").css("background", `rgb(${red}, ${green}, ${blue})`);

    const activeObject = this.props.canvas._activeObject;
    if (!activeObject) return;

    if (this.props.textColorOption === "fill") {
      activeObject.set("fill", `rgb(${red}, ${green}, ${blue})`);
    } else {
      activeObject.set("stroke", `rgb(${red}, ${green}, ${blue})`);
    }

    this.props.canvas.renderAll();
    this.updateCanvasState();
  };

  emptyClick = () => {
    if (window.innerWidth < 768) {
      document.getElementById('second-custom-right').style.display = 'none'
      document.getElementById("mySidenav1").style.width = "0"
      document.getElementById("mySidenav11") && (document.getElementById("mySidenav11").style.width = "0")
      document.getElementById("mySidenav4").style.width = "0"
    }
  }

  render() {
    const components_type = this.props.components_type;
    const flexi_config = this.props.flexi_config;
    if (!flexi_config.option_setting) return <OptionSettingDialogue />;
    const unit = this.props.flexi_config.length_unit === "FEET" ? "ft" : "m";
    return (
      <>
        <div id="second-custom-right" onClick={this.emptyClick} />
        <Sidebar />
        <div className="col-md-4 col-sm-4 text-center pad0 leftpanel">
          <SidebarItems />
        </div>
        <div id="content" className="flexi-content">
          <div className="outer">
            <div className="inner bg-light lter">
              <ExtraMenu />
              <div className="row ml0 mar">
                <div className="text-center pad0 main-content custom-main-content">
                  <div className="main-edit-content">
                    <p className="matsize">
                      <span>
                        MAT SIZE : {flexi_config.width+unit}
                        {" x "}
                        {flexi_config.height+unit}
                      </span>
                    </p>
                    <div className="addcontdiv">
                      <div className="inneraddcontdiv">
                        <div className="col-md-3">
                          <div className="row text-color-pane">
                            <img
                              className="cross-icon"
                              src={require("./../assets/img/cross.png")}
                              onClick={() => this.showEditArtWorkDialogue()}
                              alt="cross"
                              style={{ cursor: "pointer" }}
                            ></img>
                            <p
                              className="col-md-12"
                              style={{
                                marginBottom: "10px",
                                paddingBottom: "5px",
                              }}
                            >
                              EDIT TEXT COLOR
                            </p>

                            <div className="col-md-12 custom-colors">
                              <div
                                className="col-md-4 custom-color text-custom-color active"
                                data-target="custom"
                                onClick={() => this.setActiveTab("custom")}
                              >
                                <span
                                  onClick={() => this.setActiveTab("custom")}
                                >
                                  Custom
                                </span>
                              </div>
                              <div
                                className="col-md-4 rgb-color text-rgb-color"
                                data-target="rgb"
                                onClick={() => this.setActiveTab("rgb")}
                              >
                                <span onClick={() => this.setActiveTab("rgb")}>
                                  RGB
                                </span>
                              </div>
                              <div
                                className="col-md-4 cmyk-color text-cmyk-color"
                                data-target="cmyk"
                                onClick={() => this.setActiveTab("cmyk")}
                              >
                                <span onClick={() => this.setActiveTab("cmyk")}>
                                  CMYK
                                </span>
                              </div>
                            </div>
                            <div id="text-custom" className="col-md-12">
                              <div className="preset-color-row">
                                <div
                                  className="pre-clr-box clr-pr1 tooltip"
                                  onClick={() => this.setTextColor("#ffffff")}
                                  style={{ background: "white" }}
                                >
                                  <span className="tooltiptext">White</span>
                                </div>
                                <div
                                  className="pre-clr-box clr-pr2 tooltip"
                                  onClick={() => this.setTextColor(PaintColorHex.MetallicSilver)}
                                  style={{ background: PaintColorHex.MetallicSilver }}
                                >
                                  <span className="tooltiptext">Metallic Silver</span>
                                </div>
                                <div
                                  className="pre-clr-box clr-pr3 tooltip"
                                  onClick={() => this.setTextColor(PaintColorHex.LightGray)}
                                  style={{ background: PaintColorHex.LightGray }}
                                >
                                  <span className="tooltiptext">Light Gray</span>
                                </div>
                                <div
                                  className="pre-clr-box clr-pr4 tooltip"
                                  onClick={() => this.setTextColor(PaintColorHex.VegasGold)}
                                  style={{ background: PaintColorHex.VegasGoldv }}
                                >
                                  <span className="tooltiptext">Vegas Gold</span>
                                </div>
                              </div>
                              <div className="preset-color-row">
                                <div
                                  className="pre-clr-box clr-pr5 tooltip"
                                  onClick={() => this.setTextColor(PaintColorHex.Yellow)}
                                  style={{ background: PaintColorHex.Yellow }}
                                >
                                  <span className="tooltiptext">Yellow</span>
                                </div>
                                <div
                                  className="pre-clr-box clr-pr6 tooltip"
                                  onClick={() => this.setTextColor(PaintColorHex.AthleticGold)}
                                  style={{ background: PaintColorHex.AthleticGold}}
                                >
                                  <span className="tooltiptext">Athletic Gold</span>
                                </div>
                                <div
                                  className="pre-clr-box clr-pr7 tooltip"
                                  onClick={() => this.setTextColor(PaintColorHex.MatVinylGold)}
                                  style={{ background: PaintColorHex.MatVinylGold }}
                                >
                                  <span className="tooltiptext">Mat Vinyl Gold</span>
                                </div>
                                <div
                                  className="pre-clr-box clr-pr8 tooltip"
                                  onClick={() => this.setTextColor(PaintColorHex.Orange)}
                                  style={{ background: PaintColorHex.Orange}}
                                >
                                  <span className="tooltiptext">Orange</span>
                                </div>
                              </div>
                              <div className="preset-color-row">
                                <div
                                  className="pre-clr-box clr-pr9 tooltip"
                                  onClick={() => this.setTextColor(PaintColorHex.Red)}
                                  style={{ background: PaintColorHex.Red}}
                                >
                                  <span className="tooltiptext">Red</span>
                                </div>
                                <div
                                  className="pre-clr-box clr-pr10 tooltip"
                                  onClick={() => this.setTextColor(PaintColorHex.Maroon)}
                                  style={{ background: PaintColorHex.Maroon }}
                                >
                                  <span className="tooltiptext">Maroon</span>
                                </div>
                                <div
                                  className="pre-clr-box clr-pr11 tooltip"
                                  onClick={() => this.setTextColor(PaintColorHex.Purple)}
                                  style={{ background: PaintColorHex.Purple}}
                                >
                                  <span className="tooltiptext">Purple</span>
                                </div>
                                <div
                                  className="pre-clr-box clr-pr12 tooltip"
                                  onClick={() => this.setTextColor(PaintColorHex.Navy)}
                                  style={{ background: PaintColorHex.Navy }}
                                >
                                  <span className="tooltiptext">Navy</span>
                                </div>
                              </div>
                              <div className="preset-color-row">
                                <div
                                  className="pre-clr-box clr-pr13 tooltip"
                                  onClick={() => this.setTextColor(PaintColorHex.RoyalBlue)}
                                  style={{ background: PaintColorHex.RoyalBlue }}
                                >
                                  <span className="tooltiptext">Royal Blue</span>
                                </div>
                                <div
                                  className="pre-clr-box clr-pr14 tooltip"
                                  onClick={() => this.setTextColor(PaintColorHex.CarolinaBlue)}
                                  style={{ background: PaintColorHex.CarolinaBlue }}
                                >
                                  <span className="tooltiptext">Carolina Blue</span>
                                </div>
                                <div
                                  className="pre-clr-box clr-pr15 tooltip"
                                  onClick={() => this.setTextColor(PaintColorHex.Green)}
                                  style={{ background: PaintColorHex.Green }}
                                >
                                  <span className="tooltiptext">Green</span>
                                </div>
                                <div
                                  className="pre-clr-box clr-pr16 tooltip"
                                  onClick={() => this.setTextColor(PaintColorHex.DarkGreen)}
                                  style={{ background: PaintColorHex.DarkGreen }}
                                >
                                  <span className="tooltiptext">Dark Green</span>
                                </div>
                              </div>
                              <div className="preset-color-row">
                                <div
                                  className="pre-clr-box clr-pr17 tooltip"
                                  onClick={() => this.setTextColor(PaintColorHex.Brown)}
                                  style={{ background: PaintColorHex.Brown }}
                                >
                                  <span className="tooltiptext">Brown</span>
                                </div>
                                <div
                                  className="pre-clr-box clr-pr18 tooltip"
                                  onClick={() => this.setTextColor(PaintColorHex.CharcoalGray)}
                                  style={{ background: PaintColorHex.CharcoalGray}}
                                >
                                  <span className="tooltiptext">Charcoal Gray</span>
                                </div>
                                <div
                                  className="pre-clr-box clr-pr19 tooltip"
                                  onClick={() => this.setTextColor(PaintColorHex.Black)}
                                  style={{ background: PaintColorHex.Black }}
                                >
                                  <span className="tooltiptext">Black</span>
                                </div>
                                <div
                                  className="pre-clr-box clr-pr20 tooltip"
                                  onClick={() => this.setTextColor(PaintColorHex.UWWMat)}
                                  style={{ background: PaintColorHex.UWWMat }}
                                >
                                  <span className="tooltiptext">UWW Mat</span>
                                </div>
                              </div>
                            </div>
                            <div id="text-rgb" className="col-md-12">
                              <ColorPickerContainer type={"text"} />
                            </div>
                            <div id="text-cmyk" className="col-md-12">
                              <span></span>
                              <div className="row">
                                <div
                                  className="col-md-12"
                                  style={{ background: "#34405a" }}
                                >
                                  <div className="line-height-blk">
                                    <div className="line-height-label">
                                      <span>C</span>
                                    </div>
                                    <div className="line-height-label">
                                      <div className="range-slidecontainer">
                                        <input
                                          type="range"
                                          min="1"
                                          max="100"
                                          defaultValue="1"
                                          className="range-slider"
                                          id="cyan_get"
                                          onChange={this.setTextCyanColor}
                                        ></input>
                                      </div>
                                    </div>
                                    <div className="line-height-value">
                                      <input
                                        type="number"
                                        id="cyan_set"
                                        className="btn ln-ht-val-btn"
                                        defaultValue="1"
                                        min="1"
                                        max="100"
                                        onChange={this.setTextCyanColor}
                                      />
                                    </div>
                                  </div>
                                  <div className="line-height-blk">
                                    <div className="line-height-label">
                                      <span>M</span>
                                    </div>
                                    <div className="line-height-label">
                                      <div className="range-slidecontainer">
                                        <input
                                          type="range"
                                          min="1"
                                          max="100"
                                          defaultValue="1"
                                          className="range-slider"
                                          id="magenta_get"
                                          onChange={this.setTextMagentaColor}
                                        ></input>
                                      </div>
                                    </div>
                                    <div className="line-height-value">
                                      <input
                                        type="number"
                                        id="magenta_set"
                                        className="btn ln-ht-val-btn"
                                        defaultValue="1"
                                        min="1"
                                        max="100"
                                        onChange={this.setTextMagentaColor}
                                      />
                                    </div>
                                  </div>
                                  <div className="line-height-blk">
                                    <div className="line-height-label">
                                      <span>Y</span>
                                    </div>
                                    <div className="line-height-label">
                                      <div className="range-slidecontainer">
                                        <input
                                          type="range"
                                          min="1"
                                          max="100"
                                          defaultValue="1"
                                          className="range-slider"
                                          id="yellow_get"
                                          onChange={this.setTextYellowColor}
                                        ></input>
                                      </div>
                                    </div>
                                    <div className="line-height-value">
                                      <input
                                        type="number"
                                        id="yellow_set"
                                        className="btn ln-ht-val-btn"
                                        defaultValue="1"
                                        min="1"
                                        max="100"
                                        onChange={this.setTextYellowColor}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="line-height-blk"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <div className="line-height-label">
                                      <span>K</span>
                                    </div>
                                    <div className="line-height-label">
                                      <div className="range-slidecontainer">
                                        <input
                                          type="range"
                                          min="1"
                                          max="100"
                                          defaultValue="1"
                                          className="range-slider"
                                          id="key_get"
                                          onChange={this.setTextKeyColor}
                                        ></input>
                                      </div>
                                    </div>
                                    <div className="line-height-value">
                                      <input
                                        type="number"
                                        id="key_set"
                                        className="btn ln-ht-val-btn"
                                        defaultValue="1"
                                        min="1"
                                        max="100"
                                        onChange={this.setTextKeyColor}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <button
                              className="btn delete"
                              onClick={() => this.deleteSelectedMaterial()}
                            >
                              <img
                                src={require("./../assets/img/del.png")}
                                alt="del"
                              ></img>
                            </button>
                          </div>

                          <div className="row"> </div>
                        </div>
                      </div>
                      <Container fluid className="main-content-container px-4 scroll-flexi-pad">
                        <DesignCanvas>
                          {components_type.map((type, idx) => {
                            switch (type) {
                              case "Image":
                                return <Image key={idx} />;
                              default:
                                return <CurvedText key={idx} />;
                            }
                          })}
                        </DesignCanvas>
                      </Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const setSaveDesignID = (id) => {
  return {
    type: "SET_SAVED_ID",
    id: id,
  };
};

const setCanvasConfig = (_config) => {
  return {
    type: "SET_CONFIG",
    _config,
  };
};

const setSelectedGroupType = (type) => {
  return {
    type: "SET_GROUP_SELECT",
    groupType: type,
  };
};

const clearCanvas = () => {
  return {
    type: "CLAER_CANVAS",
    isCanvasCleared: true,
  };
};

const setFontColorOption = (option) => {
  return {
    type: "SET_FONT_COLOR_OPTION",
    option,
  };
};

const mapStateToProps = (state) => {
  return {
    _config: state._config,
    components_type: [...state.components_type],
    wrestling_config: state.wrestling_config,
    canvas: state.canvas,
    selectedGroupType: state.selectedGroupType,
    flexi_config: state.flexi_config,
    fontColor: state.fontColor,
    textColorOption: state.textColorOption,
    flexi_type: state.flexi_type,
    text_style: state.text_style,
    uploadedLogos: state.uploadedLogos,
    mat_color: state.mat_color,
    thickness: state.thickness,
    flexi_color: state.flexi_color,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setSaveDesignID,
      setCanvasConfig,
      setSelectedGroupType,
      clearCanvas,
      setFontColorOption,
    },
    dispatch
  );
};

const Flexi_Designer = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default Flexi_Designer;
