import React from "react";
import TextColorPaneConnect from "../../../TextColorPane";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import CustomColorPane from "../../../customColorPane";
import {TurfColorHex, TurfColorName} from "../../ColorCodeHelper";

class RubberNumberMarksToolbar extends React.Component {
	constructor() {
		super();
		this.state ={
			gridColor : '#0d0000'
		};
	}

	checkTextMarks() {
		return this.props.canvas.getObjects('text').length > 0;
	}

	setTextMarksColor(color){
		this.setState({gridColor:color});
		if(this.checkTextMarks()) {
			let allText = this.props.canvas.getObjects('text');
			allText.forEach(text => {
				if (text.custom && text.custom.type === "textMark") {
					text.set('fill',color);
				}
			});
			this.props.canvas.renderAll();
			this.updateCanvasState();
		}
	}

	setTextMarksFamily(family){
		if(this.checkTextMarks()) {
			let allText = this.props.canvas.getObjects('text');
			allText.forEach(text => {
				if (text.custom && text.custom.type === "textMark") {
					text.set('fontFamily',family);
				}
			});
			this.props.canvas.renderAll();
			this.updateCanvasState();
		}
	}

	addTextMarks() {
		// make sure grid not exist already
		if (this.checkTextMarks()) {
			// remove all grid marks first
			let allText = this.props.canvas.getObjects('text');
			allText.forEach(text => {
				if (text.custom && text.custom.type === "textMark") {
					this.props.canvas.remove(text);
				}
			});
			this.updateCanvasState();
			return;
		}
		let grid = this.props.canvas_grid,
			width = this.props.canvas.width,
			height = this.props.canvas.height;
		let {paddingTop,paddingBottom,paddingLeft,paddingRight} = this.props.rubber_turf_config;

		let top = 	grid * paddingTop,
			left = grid * paddingLeft,
			bottom = grid * paddingBottom,
			right = grid * paddingRight,
			zoom = this.props.canvas.getZoom();

		let startDistance = left; // distance from start
		let linGap = 0.8 * grid;
		let gridColor = this.state.gridColor;
		let count = 0;

		for (let i = startDistance; i < width/zoom-right; i += (grid * 15)) {
			if(count !== 0) {
				let countString = `${count}`;
				countString = countString.split("");
				countString.splice(1,0," ");
				countString = countString.join("");
				this.props.canvas.add(new window.fabric.Text(countString, {
					left: i-linGap,
					top:height/zoom - bottom - (grid * 1.5),
					fontSize:(1.7*grid),
					fontFamily:'serif',
					fontWeight:'bold',
					hasControls: false,
					hasBorder: false,
					fill:gridColor,
					selectable: false,
					custom: {
						type: 'textMark'
					}
				}));
			}
			count+=5;
		}
		this.updateCanvasState();
	}

	updateCanvasState (){
		let tempConfig = this.props._config;
		if (tempConfig.undoStatus === false && tempConfig.redoStatus === false) {
			let jsonData = this.props.canvas.toJSON(['custom','name']);
			let canvasAsJson = JSON.stringify(jsonData);
			if (tempConfig.currentStateIndex < tempConfig.canvasState.length - 1) {
				let indexToBeInserted = tempConfig.currentStateIndex + 1;
				tempConfig.canvasState[indexToBeInserted] = canvasAsJson;

				let numberOfElementsToRetain = indexToBeInserted + 1;
				tempConfig.canvasState = tempConfig.canvasState.splice(
					0,
					numberOfElementsToRetain
				);
			} else {
				tempConfig.canvasState.push(canvasAsJson);
			}

			tempConfig.currentStateIndex = tempConfig.canvasState.length - 1;

			if (
				tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
				tempConfig.currentStateIndex !== -1
			) {
				tempConfig.redoButton = "disabled";
			}
		}
		this.props.setCanvasConfig(tempConfig);
	};

	render() {
		return(
			<>
				<div className="row adddiv">
					<h2>Numbers</h2>
				</div>
				<div className="templatesmain">
					<div className="add-new-txt">
						<div className="row row-border-btm pd-15" style={{width: '100%'}}>
							<button
								className="btn btn-danger add-text-btn"
								onClick={() => {this.addTextMarks()} }
								style={{width: '100%'}}
							>
								Toggle Numbers
							</button>
						</div>
					</div>
					<div className="add-text-blk-hmenu">
						<div className="font-select">
							<div className="row  pt-15">
								<div className="col-md-4">
									<div className="form-group">Family</div>
								</div>
								<div className="col-md-8 vrtcl-dot-line">
									<div className="form-group">
										<select className="form-control slct-font-fam" onChange={(e)=>{this.setTextMarksFamily(e.target.value)}}>
											<option value={'serif'}>Serif</option>
											<option value={'sans-serif'}>Sans Serif</option>
										</select>
									</div>
								</div>
							</div>
							<div className="row pt-15 pb-15">
								<CustomColorPane activeColor={this.state.gridColor} title={'Choose Colors'} colors ={TurfColorHex} colorNames={TurfColorName} changeColor={(color) => {
									this.setTextMarksColor(color);
								}}/>
							</div>
							{/*<div className="row pt-15 pb-15">
								<TextColorPaneConnect activeColor={this.state.gridColor} type={'turf'} changeColor={(color) => {
									this.setTextMarksColor(color);
								}}/>
							</div>*/}
						</div>
					</div>
				</div>
			</>
		)
	}
}

const mapStateToPropsForNumberMarks = state => {
	return {
		_config:state._config,
		canvas: state.canvas,
		canvas_grid: state.canvas_grid,
		rubber_turf_config:state.rubber_turf_config
	}
}
const setCanvasConfig = (_config) => {
	return {
		type: "SET_CONFIG",
		_config,
	};
}
const mapDispatchToProps = dispatch => {
	return bindActionCreators({ setCanvasConfig }, dispatch);
}

const NumberMarksToolbarConnect = connect(
	mapStateToPropsForNumberMarks,mapDispatchToProps
)(RubberNumberMarksToolbar);

class RubberNumberMarksTurfToolbarSidebar extends React.Component {
	closeNav18 =() =>{
		document.getElementById("mySidenav18").style.width = "0";
	}

	render() {
		return (
			<>
				<div
					id="rubber-text-mark"
					className="h100 second-menu fordeskview"
					style={{display: "none"}}
				>
					<NumberMarksToolbarConnect/>
				</div>
				<div
					className="text-center h100 pad0 mtopneg sidenav4 formobview wo"
					id="mySidenav18"
				>
					<a
						style={{color: "#fff"}}
						href="#"
						className="closebtn"
						onClick={() => this.closeNav18()}
					>
						&times;
					</a>
					<NumberMarksToolbarConnect/>
				</div>
			</>
		);
	}
}

export default RubberNumberMarksTurfToolbarSidebar;