import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import $ from 'jquery'
import ColorPickerContainer from "../../../ColorPicker";
import {PaintColorHex} from "../../ColorCodeHelper";

class ArtWorkColorPane extends React.Component{
    state={
        activeTab:'artwork-custom'
    }
    deleteSelectedMaterial = () => {
        console.log(this.props.canvas);
        let activeObject = this.props.canvas._activeObject;
        if (activeObject) {
            this.props.canvas.remove(activeObject);
        }
        this.updateCanvasState();
    };
    setActiveTab = (id) => {
        this.setState({
            activeTab:id,
        })
        console.log(id);
        $("#custom").css("display", "none");
        $("#rgb").css("display", "none");
        $("#cmyk").css("display", "none");
        $("#text-custom").css("display", "none");
        $("#text-rgb").css("display", "none");
        $("#text-cmyk").css("display", "none");
        $("#circle-custom").css("display", "none");
        $("#circle-rgb").css("display", "none");
        $("#circle-cmyk").css("display", "none");
        $("#artwork-custom").css("display", "none");
        $("#artwork-rgb").css("display", "none");
        $("#artwork-cmyk").css("display", "none");
        $(`#${id}`).css("display", "block");
        $(".custom-colors .active").removeClass("active");
        $(`.${id}-color`).addClass("active");
    };
    setColorType = (type) => {
        console.log(type);
        this.props.setSelectedGroupType(type);
        $(".default-colors")
            .children()
            .removeClass("color-active");
        $(".default-colors")
            .children()
            .eq(type)
            .addClass("color-active");
        // console.log([0]);

        $(".custom-colors").css("display", "block");
        $("#custom").css("display", "block");
        $("#rgb").css("display", "none");
        $("#cmyk").css("display", "none");
        $("#text-custom").css("display", "block");
        $("#text-rgb").css("display", "none");
        $("#text-cmyk").css("display", "none");
        $("#artwork-custom").css("display", "block");
        $("#artwork-rgb").css("display", "none");
        $("#artwork-cmyk").css("display", "none");
        $(".advanced_span").css("display", "block");
        $(".custom-color").addClass("active");
        $(".rgb-color.active").removeClass("active");
        $(".cmyk-color.active").removeClass("active");
    };
    setBackgroundColor = (color) => {
        const activeObject = this.props.canvas._activeObject;
        if (!activeObject) return;
        if (activeObject.__proto__.type === "group") {
            switch (this.props.selectedGroupType) {
                case 0:
                    activeObject._objects[0].set("stroke", color);
                    activeObject._objects[2].set("stroke", color);
                    $(".default-color-1").css("background", color);
                    break;
                case 1:
                    activeObject._objects[1].set("stroke", color);
                    $(".default-color-2").css("background", color);
                    break;
                case 2:
                    activeObject._objects[3].set("stroke", color);
                    $(".default-color-3").css("background", color);
                    break;
                default:
                    break;
            }
        } else if (activeObject.__proto__.type === "path-group") {
            for (let i = 0; i < activeObject.paths.length; i++) {
                console.log(this.props.selectedGroupType)
                if(this.props.artworkPaths[
                    this.props.selectedGroupType
                    ]){
                    let index = this.props.artworkPaths[
                        this.props.selectedGroupType
                        ].indexOf(i);
                    console.log(index);
                    if (index >= 0) activeObject.paths[i].setFill(color);
                }
            }
            $(
                `.default-color-${this.props
                    .selectedGroupType + 1}`
            ).css("background", color);
        } else {
            if (activeObject.get("fill") === "rgba(0,0,0,0)") {
                activeObject.set("stroke", color);
                $(".default-color-2").css("background", color);
            } else {
                if (this.props.selectedGroupType === 0) {
                    activeObject.set("fill", color);
                    $(".default-color-1").css("background", color);
                } else if (this.props.selectedGroupType === 1) {
                    activeObject.set("stroke", color);
                    $(".default-color-2").css("background", color);
                }
            }
        }
        this.props.canvas.renderAll();
        this.updateCanvasState();
    };
    setCyanColor = (e) => {
        console.log("hello");
        let { value } = e.target;
        $("#cyan_artwork_get").val(value);
        $("#cyan_artwork_set").val(value);

        $('#cyan_artwork_get').css({
            background: `linear-gradient(to right, #DB2032 0%, #DB2032 ${value}%, #fff ${value}%, #fff 100%)`
        })

        const cyan = value;
        const yellow = $("#yellow_artwork_get").val();
        const black = $("#key_artwork_get").val();
        const magenta = $("#magenta_artwork_get").val();

        const red = 255 * (1 - cyan / 100) * (1 - black / 100);
        const green = 255 * (1 - magenta / 100) * (1 - black / 100);
        const blue = 255 * (1 - yellow / 100) * (1 - black / 100);

        const activeObject = this.props.canvas._activeObject;
        if (!activeObject) return;

        if (activeObject.__proto__.type === "group") {
            switch (this.props.selectedGroupType) {
                case 0:
                    activeObject._objects[0].set(
                        "stroke",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                    activeObject._objects[2].set(
                        "stroke",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                    $(".default-color-1").css(
                        "background",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                    break;
                case 1:
                    activeObject._objects[1].set(
                        "stroke",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                    $(".default-color-2").css(
                        "background",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                    break;
                case 2:
                    activeObject._objects[3].set(
                        "stroke",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                    $(".default-color-3").css(
                        "background",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                    break;
                default:
                    break;
            }
        }
        else if (activeObject.__proto__.type === "path-group") {
            for (let i = 0; i < activeObject.paths.length; i++) {
                let index = this.props.artworkPaths[
                    this.props.selectedGroupType
                    ].indexOf(i);
                if (index >= 0)
                    activeObject.paths[i].setFill(`rgb(${red}, ${green}, ${blue})`);
            }
            $(
                `.default-color-${this.props
                    .selectedGroupType + 1}`
            ).css("background", `rgb(${red}, ${green}, ${blue})`);
        } else {
            if (activeObject.get("fill") === "rgba(0,0,0,0)") {
                activeObject.set("stroke", `rgb(${red}, ${green}, ${blue})`);
                $(".default-color-2").css(
                    "background",
                    `rgb(${red}, ${green}, ${blue})`
                );
            } else {
                if (this.props.selectedGroupType === 0) {
                    activeObject.set("fill", `rgb(${red}, ${green}, ${blue})`);
                    $(".default-color-1").css(
                        "background",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                } else if (this.props.selectedGroupType === 1) {
                    activeObject.set("stroke", `rgb(${red}, ${green}, ${blue})`);
                    $(".default-color-2").css(
                        "background",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                }
            }
        }

        this.props.canvas.renderAll();
        this.updateCanvasState();
    };
    setMagentaColor = (e) => {
        let { value } = e.target;
        $("#magenta_artwork_get").val(value);
        $("#magenta_artwork_set").val(value);

        $('#magenta_artwork_get').css({
            background: `linear-gradient(to right, #DB2032 0%, #DB2032 ${value}%, #fff ${value}%, #fff 100%)`
        })

        const magenta = value;
        const cyan = $("#cyan_artwork_get").val();
        const black = $("#key_artwork_get").val();
        const yellow = $("#yellow_artwork_get").val();

        const red = 255 * (1 - cyan / 100) * (1 - black / 100);
        const green = 255 * (1 - magenta / 100) * (1 - black / 100);
        const blue = 255 * (1 - yellow / 100) * (1 - black / 100);

        const activeObject = this.props.canvas._activeObject;
        if (!activeObject) return;
        if (activeObject.__proto__.type === "group") {
            switch (this.props.selectedGroupType) {
                case 0:
                    activeObject._objects[0].set(
                        "stroke",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                    activeObject._objects[2].set(
                        "stroke",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                    $(".default-color-1").css(
                        "background",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                    break;
                case 1:
                    activeObject._objects[1].set(
                        "stroke",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                    $(".default-color-2").css(
                        "background",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                    break;
                case 2:
                    activeObject._objects[3].set(
                        "stroke",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                    $(".default-color-3").css(
                        "background",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                    break;
                default:
                    break;
            }
        }
        else if (activeObject.__proto__.type === "path-group") {
            for (let i = 0; i < activeObject.paths.length; i++) {
                let index = this.props.artworkPaths[
                    this.props.selectedGroupType
                    ].indexOf(i);
                if (index >= 0)
                    activeObject.paths[i].setFill(`rgb(${red}, ${green}, ${blue})`);
            }
            $(
                `.default-color-${this.props
                    .selectedGroupType + 1}`
            ).css("background", `rgb(${red}, ${green}, ${blue})`);
        } else {
            if (activeObject.get("fill") === "rgba(0,0,0,0)") {
                activeObject.set("stroke", `rgb(${red}, ${green}, ${blue})`);
                $(".default-color-2").css(
                    "background",
                    `rgb(${red}, ${green}, ${blue})`
                );
            } else {
                if (this.props.selectedGroupType === 0) {
                    activeObject.set("fill", `rgb(${red}, ${green}, ${blue})`);
                    $(".default-color-1").css(
                        "background",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                } else if (this.props.selectedGroupType === 1) {
                    activeObject.set("stroke", `rgb(${red}, ${green}, ${blue})`);
                    $(".default-color-2").css(
                        "background",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                }
            }
        }

        this.props.canvas.renderAll();
        this.updateCanvasState();
    };
    setYellowColor = (e) => {
        let { value } = e.target;
        $("#yellow_artwork_get").val(value);
        $("#yellow_artwork_set").val(value);
        $('#yellow_artwork_get').css({
            background: `linear-gradient(to right, #DB2032 0%, #DB2032 ${value}%, #fff ${value}%, #fff 100%)`
        })
        const yellow = value;
        const cyan = $("#cyan_artwork_get").val();
        const black = $("#key_artwork_get").val();
        const magenta = $("#magenta_artwork_get").val();

        const red = 255 * (1 - cyan / 100) * (1 - black / 100);
        const green = 255 * (1 - magenta / 100) * (1 - black / 100);
        const blue = 255 * (1 - yellow / 100) * (1 - black / 100);

        const activeObject = this.props.canvas._activeObject;
        if (!activeObject) return;
        if (activeObject.__proto__.type === "group") {
            switch (this.props.selectedGroupType) {
                case 0:
                    activeObject._objects[0].set(
                        "stroke",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                    activeObject._objects[2].set(
                        "stroke",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                    $(".default-color-1").css(
                        "background",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                    break;
                case 1:
                    activeObject._objects[1].set(
                        "stroke",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                    $(".default-color-2").css(
                        "background",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                    break;
                case 2:
                    activeObject._objects[3].set(
                        "stroke",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                    $(".default-color-3").css(
                        "background",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                    break;
                default:
                    break;
            }
        } else if (activeObject.__proto__.type === "path-group") {
            for (let i = 0; i < activeObject.paths.length; i++) {
                let index = this.props.artworkPaths[
                    this.props.selectedGroupType
                    ].indexOf(i);
                if (index >= 0)
                    activeObject.paths[i].setFill(`rgb(${red}, ${green}, ${blue})`);
            }
            $(
                `.default-color-${this.props
                    .selectedGroupType + 1}`
            ).css("background", `rgb(${red}, ${green}, ${blue})`);
        } else {
            if (activeObject.get("fill") === "rgba(0,0,0,0)") {
                activeObject.set("stroke", `rgb(${red}, ${green}, ${blue})`);
                $(".default-color-2").css(
                    "background",
                    `rgb(${red}, ${green}, ${blue})`
                );
            } else {
                if (this.props.selectedGroupType === 0) {
                    activeObject.set("fill", `rgb(${red}, ${green}, ${blue})`);
                    $(".default-color-1").css(
                        "background",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                } else if (this.props.selectedGroupType === 1) {
                    activeObject.set("stroke", `rgb(${red}, ${green}, ${blue})`);
                    $(".default-color-2").css(
                        "background",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                }
            }
        }

        this.props.canvas.renderAll();
        this.updateCanvasState();
    };
    setKeyColor = (e) => {
        let { value } = e.target;
        $("#key_artwork_get").val(value);
        $("#key_artwork_set").val(value);
        $('#key_artwork_get').css({
            background: `linear-gradient(to right, #DB2032 0%, #DB2032 ${value}%, #fff ${value}%, #fff 100%)`
        })
        const black = value;
        const cyan = $("#cyan_artwork_get").val();
        const magenta = $("#magenta_artwork_get").val();
        const yellow = $("#yellow_artwork_get").val();

        const red = 255 * (1 - cyan / 100) * (1 - black / 100);
        const green = 255 * (1 - magenta / 100) * (1 - black / 100);
        const blue = 255 * (1 - yellow / 100) * (1 - black / 100);

        const activeObject = this.props.canvas._activeObject;
        if (!activeObject) return;
        if (activeObject.__proto__.type === "group") {
            switch (this.props.selectedGroupType) {
                case 0:
                    activeObject._objects[1].set(
                        "stroke",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                    activeObject._objects[3].set(
                        "stroke",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                    $(".default-color-1").css(
                        "background",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                    break;
                case 1:
                    activeObject._objects[0].set(
                        "stroke",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                    $(".default-color-2").css(
                        "background",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                    break;
                case 2:
                    activeObject._objects[2].set(
                        "stroke",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                    $(".default-color-3").css(
                        "background",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                    break;
                default:
                    break;
            }
        } else if (activeObject.__proto__.type === "path-group") {
            for (let i = 0; i < activeObject.paths.length; i++) {
                let index = this.props.artworkPaths[
                    this.props.selectedGroupType
                    ].indexOf(i);
                if (index >= 0)
                    activeObject.paths[i].setFill(`rgb(${red}, ${green}, ${blue})`);
            }
            $(
                `.default-color-${this.props
                    .selectedGroupType + 1}`
            ).css("background", `rgb(${red}, ${green}, ${blue})`);
        } else {
            if (activeObject.get("fill") === "rgba(0,0,0,0)") {
                activeObject.set("stroke", `rgb(${red}, ${green}, ${blue})`);
                $(".default-color-2").css(
                    "background",
                    `rgb(${red}, ${green}, ${blue})`
                );
            } else {
                if (this.props.selectedGroupType === 0) {
                    activeObject.set("fill", `rgb(${red}, ${green}, ${blue})`);
                    $(".default-color-1").css(
                        "background",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                } else if (this.props.selectedGroupType === 1) {
                    activeObject.set("stroke", `rgb(${red}, ${green}, ${blue})`);
                    $(".default-color-2").css(
                        "background",
                        `rgb(${red}, ${green}, ${blue})`
                    );
                }
            }
        }

        this.props.canvas.renderAll();
        this.updateCanvasState();
    };

    updateCanvasState = () => {
        let tempConfig = this.props._config;
        if (tempConfig.undoStatus === false && tempConfig.redoStatus === false) {
            let jsonData = this.props.canvas.toJSON();
            let canvasAsJson = JSON.stringify(jsonData);
            if (tempConfig.currentStateIndex < tempConfig.canvasState.length - 1) {
                let indexToBeInserted = tempConfig.currentStateIndex + 1;
                tempConfig.canvasState[indexToBeInserted] = canvasAsJson;

                let numberOfElementsToRetain = indexToBeInserted + 1;
                tempConfig.canvasState = tempConfig.canvasState.splice(
                    0,
                    numberOfElementsToRetain
                );
            } else {
                tempConfig.canvasState.push(canvasAsJson);
            }

            tempConfig.currentStateIndex = tempConfig.canvasState.length - 1;

            if (
                tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
                tempConfig.currentStateIndex !== -1
            ) {
                tempConfig.redoButton = "disabled";
            }
        }
        this.props.setCanvasConfig(tempConfig);
    };

    deleteSelectedMaterial = () => {
        console.log(this.props.canvas);
        let activeObject = this.props.canvas._activeObject;
        if (activeObject) {
            this.props.canvas.remove(activeObject);
        }
        this.updateCanvasState();
    };

    render() {
        let styles = {cursor:'pointer'};
        return(
            <>
                <div className="adddiv">
                    <h2>EDIT ARTWORK</h2>
                </div>
                <div className="templatesmain color-lft-blk-menu">
                    <div className='row'>
                        <div className="default-colors">
                              <span
                                  className="default-color-1"
                                  style={{
                                      padding: "8px 15px",
                                      background: "black",
                                      marginRight: "8px",
                                      width: 30,
                                  }}
                                  onClick={() => this.setColorType(0)}
                              ></span>
                            {this.props.artworkPaths[1] !== undefined ? (
                                <span
                                    className="default-color-2"
                                    style={{
                                        padding: "8px 15px",
                                        background: "green",
                                        marginRight: "8px",
                                        width: 30,
                                    }}
                                    onClick={() => this.setColorType(1)}
                                ></span>
                            ) : (
                                <div />
                            )}
                            {this.props.artworkPaths[2] !== undefined ? (
                                <span
                                    className="default-color-3"
                                    style={{
                                        padding: "8px 15px",
                                        background: "red",
                                        marginRight: "8px",
                                        width: 30,
                                    }}
                                    onClick={() => this.setColorType(2)}
                                ></span>
                            ) : (
                                <div />
                            )}
                            {this.props.artworkPaths[3] !== undefined ? (
                                <span
                                    className="default-color-4"
                                    style={{
                                        padding: "8px 15px",
                                        background: "red",
                                        width: 30,
                                    }}
                                    onClick={() => this.setColorType(3)}
                                ></span>
                            ) : (
                                <div />
                            )}
                            <span className="col-md-12 advanced_span">
                                Use Advance Color
                              </span>
                        </div>
                        <div className="col-md-12 custom-colors">
                            <div
                                className="col-md-4 custom-color artwork-custom-color active"
                                data-target="custom"
                                onClick={() =>
                                    this.setActiveTab("artwork-custom")
                                }
                                style={{...styles}}
                            >
                                <span
                                    onClick={() =>
                                        this.setActiveTab("artwork-custom")
                                    }
                                >
                                  Custom
                                </span>
                            </div>

                            <div
                                className="col-md-4 rgb-color artwork-rgb-color"
                                data-target="rgb"
                                onClick={() => this.setActiveTab("artwork-rgb")}
                                style={{...styles}}
                            >
                                <span
                                    onClick={() =>
                                        this.setActiveTab("artwork-rgb")
                                    }
                                >
                                  RGB
                                </span>
                            </div>
                            <div
                                className="col-md-4 cmyk-color artwork-cmyk-color"
                                data-target="cmyk"
                                onClick={() =>
                                    this.setActiveTab("artwork-cmyk")
                                }
                                style={{...styles}}
                            >
                                <span
                                    onClick={() =>
                                        this.setActiveTab("artwork-cmyk")
                                    }
                                >
                                  CMYK
                                </span>
                            </div>
                        </div>
                        <div id="artwork-custom" style={{display:this.state.activeTab === 'artwork-custom' ? 'block':'none'}} className="col-md-12">
                            <div className="preset-color-row">
                                <div
                                    className="pre-clr-box clr-pr1 tooltip"
                                    onClick={() =>
                                        this.setBackgroundColor(PaintColorHex.White)
                                    }
                                    style={{ background: PaintColorHex.White }}
                                >
                                    <span className="tooltiptext">White</span>
                                </div>
                                <div
                                    className="pre-clr-box clr-pr2 tooltip"
                                    onClick={() =>
                                        this.setBackgroundColor(PaintColorHex.MetallicSilver)
                                    }
                                    style={{ background: PaintColorHex.MetallicSilver }}
                                >
                                  <span className="tooltiptext">
                                    Metallic Silver
                                  </span>
                                </div>
                                <div
                                    className="pre-clr-box clr-pr3 tooltip"
                                    onClick={() =>
                                        this.setBackgroundColor(PaintColorHex.LightGray)
                                    }
                                    style={{ background: PaintColorHex.LightGray }}
                                >
                                  <span className="tooltiptext">
                                    Light Gray
                                  </span>
                                </div>
                                <div
                                    className="pre-clr-box clr-pr4 tooltip"
                                    onClick={() =>
                                        this.setBackgroundColor(PaintColorHex.VegasGold)
                                    }
                                    style={{ background: PaintColorHex.VegasGold }}
                                >
                                  <span className="tooltiptext">
                                    Vegas Gold
                                  </span>
                                </div>
                            </div>
                            <div className="preset-color-row">
                                <div
                                    className="pre-clr-box clr-pr5 tooltip"
                                    onClick={() =>
                                        this.setBackgroundColor(PaintColorHex.Yellow)
                                    }
                                    style={{ background: PaintColorHex.Yellow }}
                                >
                                    <span className="tooltiptext">Yellow</span>
                                </div>
                                <div
                                    className="pre-clr-box clr-pr6 tooltip"
                                    onClick={() =>
                                        this.setBackgroundColor(PaintColorHex.AthleticGold)
                                    }
                                    style={{ background: PaintColorHex.AthleticGold }}
                                >
                                  <span className="tooltiptext">
                                    Athletic Gold
                                  </span>
                                </div>
                                <div
                                    className="pre-clr-box clr-pr7 tooltip"
                                    onClick={() =>
                                        this.setBackgroundColor(PaintColorHex.MatVinylGold)
                                    }
                                    style={{ background: PaintColorHex.MatVinylGold}}
                                >
                                  <span className="tooltiptext">
                                    Mat Vinyl Gold
                                  </span>
                                </div>
                                <div
                                    className="pre-clr-box clr-pr8 tooltip"
                                    onClick={() =>
                                        this.setBackgroundColor(PaintColorHex.Orange)
                                    }
                                    style={{ background: PaintColorHex.Orange }}
                                >
                                    <span className="tooltiptext">Orange</span>
                                </div>
                            </div>
                            <div className="preset-color-row">
                                <div
                                    className="pre-clr-box clr-pr9 tooltip"
                                    onClick={() =>
                                        this.setBackgroundColor(PaintColorHex.Red)
                                    }
                                    style={{ background: PaintColorHex.Red }}
                                >
                                    <span className="tooltiptext">Red</span>
                                </div>
                                <div
                                    className="pre-clr-box clr-pr10 tooltip"
                                    onClick={() =>
                                        this.setBackgroundColor(PaintColorHex.Maroon)
                                    }
                                    style={{ background: PaintColorHex.Maroon }}
                                >
                                    <span className="tooltiptext">Maroon</span>
                                </div>
                                <div
                                    className="pre-clr-box clr-pr11 tooltip"
                                    onClick={() =>
                                        this.setBackgroundColor(PaintColorHex.Purple)
                                    }
                                    style={{ background: PaintColorHex.Purple }}
                                >
                                    <span className="tooltiptext">Purple</span>
                                </div>
                                <div
                                    className="pre-clr-box clr-pr12 tooltip"
                                    onClick={() =>
                                        this.setBackgroundColor(PaintColorHex.Navy)
                                    }
                                    style={{ background: PaintColorHex.Navy }}
                                >
                                    <span className="tooltiptext">Navy</span>
                                </div>
                            </div>
                            <div className="preset-color-row">
                                <div
                                    className="pre-clr-box clr-pr13 tooltip"
                                    onClick={() =>
                                        this.setBackgroundColor(PaintColorHex.RoyalBlue)
                                    }
                                    style={{ background: PaintColorHex.RoyalBlue }}
                                >
                                  <span className="tooltiptext">Royal Blue</span>
                                </div>
                                <div
                                    className="pre-clr-box clr-pr14 tooltip"
                                    onClick={() =>
                                        this.setBackgroundColor(PaintColorHex.CarolinaBlue)
                                    }
                                    style={{ background: PaintColorHex.CarolinaBlue }}
                                >
                                  <span className="tooltiptext">Carolina Blue</span>
                                </div>
                                <div
                                    className="pre-clr-box clr-pr15 tooltip"
                                    onClick={() =>
                                        this.setBackgroundColor(PaintColorHex.Green)
                                    }
                                    style={{ background: PaintColorHex.Green }}
                                >
                                    <span className="tooltiptext">Green</span>
                                </div>
                                <div
                                    className="pre-clr-box clr-pr16 tooltip"
                                    onClick={() =>
                                        this.setBackgroundColor(PaintColorHex.DarkGreen)
                                    }
                                    style={{ background: PaintColorHex.DarkGreen }}
                                >
                                  <span className="tooltiptext">Dark Green</span>
                                </div>
                            </div>
                            <div className="preset-color-row">
                                <div
                                    className="pre-clr-box clr-pr17 tooltip"
                                    onClick={() =>
                                        this.setBackgroundColor(PaintColorHex.Brown)
                                    }
                                    style={{ background: PaintColorHex.Brown }}
                                >
                                    <span className="tooltiptext">Brown</span>
                                </div>
                                <div
                                    className="pre-clr-box clr-pr18 tooltip"
                                    onClick={() =>
                                        this.setBackgroundColor(PaintColorHex.CharcoalGray)
                                    }
                                    style={{ background: PaintColorHex.CharcoalGray }}
                                >
                                  <span className="tooltiptext">Charcoal Gray</span>
                                </div>
                                <div
                                    className="pre-clr-box clr-pr19 tooltip"
                                    onClick={() =>
                                        this.setBackgroundColor(PaintColorHex.Black)
                                    }
                                    style={{ background: PaintColorHex.Black }}
                                >
                                    <span className="tooltiptext">Black</span>
                                </div>
                                <div
                                    className="pre-clr-box clr-pr20 tooltip"
                                    onClick={() =>
                                        this.setBackgroundColor(PaintColorHex.UWWMat)
                                    }
                                    style={{ background: PaintColorHex.UWWMat }}
                                >
                                    <span className="tooltiptext">UWW Mat</span>
                                </div>
                            </div>
                        </div>
                        <div id="artwork-rgb" style={{display:this.state.activeTab === 'artwork-rgb' ? 'block':'none'}} className="col-md-12">
                            <ColorPickerContainer type={"rect"} />
                        </div>
                        <div id="artwork-cmyk" style={{display:this.state.activeTab === 'artwork-cmyk' ? 'block':'none'}} className="col-md-12">
                            <span></span>
                            <div className="row">
                                <div
                                    className="col-md-12"
                                    style={{ background: "#34405a" }}
                                >
                                    <div className="line-height-blk">
                                        <div className="line-height-label">
                                            <span>C</span>
                                        </div>
                                        <div className="line-height-label">
                                            <div className="range-slidecontainer">
                                                <input
                                                    type="range"
                                                    min="1"
                                                    max="100"
                                                    defaultValue="1"
                                                    className="range-slider"
                                                    id="cyan_artwork_get"
                                                    onChange={this.setCyanColor}
                                                ></input>
                                            </div>
                                        </div>
                                        <div className="line-height-value">
                                            <input
                                                type="number"
                                                id="cyan_artwork_set"
                                                className="btn ln-ht-val-btn"
                                                defaultValue="1"
                                                min="1"
                                                max="100"
                                                onChange={this.setCyanColor}
                                            />
                                        </div>
                                    </div>
                                    <div className="line-height-blk">
                                        <div className="line-height-label">
                                            <span>M</span>
                                        </div>
                                        <div className="line-height-label">
                                            <div className="range-slidecontainer">
                                                <input
                                                    type="range"
                                                    min="1"
                                                    max="100"
                                                    defaultValue="1"
                                                    className="range-slider"
                                                    id="magenta_artwork_get"
                                                    onChange={this.setMagentaColor}
                                                ></input>
                                            </div>
                                        </div>
                                        <div className="line-height-value">
                                            <input
                                                type="number"
                                                id="magenta_artwork_set"
                                                className="btn ln-ht-val-btn"
                                                defaultValue="1"
                                                min="1"
                                                max="100"
                                                onChange={this.setMagentaColor}
                                            />
                                        </div>
                                    </div>
                                    <div className="line-height-blk">
                                        <div className="line-height-label">
                                            <span>Y</span>
                                        </div>
                                        <div className="line-height-label">
                                            <div className="range-slidecontainer">
                                                <input
                                                    type="range"
                                                    min="1"
                                                    max="100"
                                                    defaultValue="1"
                                                    className="range-slider"
                                                    id="yellow_artwork_get"
                                                    onChange={this.setYellowColor}
                                                ></input>
                                            </div>
                                        </div>
                                        <div className="line-height-value">
                                            <input
                                                type="number"
                                                id="yellow_artwork_set"
                                                className="btn ln-ht-val-btn"
                                                defaultValue="1"
                                                min="1"
                                                max="100"
                                                onChange={this.setYellowColor}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="line-height-blk"
                                        style={{ marginBottom: "10px" }}
                                    >
                                        <div className="line-height-label">
                                            <span>K</span>
                                        </div>
                                        <div className="line-height-label">
                                            <div className="range-slidecontainer">
                                                <input
                                                    type="range"
                                                    min="1"
                                                    max="100"
                                                    defaultValue="1"
                                                    className="range-slider"
                                                    id="key_artwork_get"
                                                    onChange={this.setKeyColor}
                                                ></input>
                                            </div>
                                        </div>
                                        <div className="line-height-value">
                                            <input
                                                type="number"
                                                id="key_artwork_set"
                                                className="btn ln-ht-val-btn"
                                                defaultValue="1"
                                                min="1"
                                                max="100"
                                                onChange={this.setKeyColor}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center'>
                      <span className="btn col-md-2" style={{border:'1px solid white',margin:'10px 10px 10px 10px'}}>
                        <a className='custom-side-nav1' onClick={() => this.deleteSelectedMaterial()}>
                          <img src={require("./../../../../assets/img/del.png")} alt="del" style={{height:'20px',width:'18px'}} />
                        </a>
                      </span>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        components: [...state.components],
        _config: state._config,
        canvas:state.canvas,
        artworkPaths: state.artworkPaths,
        selectedGroupType: state.selectedGroupType,
    };
};
const setCanvasConfig = (_config) => {
    return {
        type: "SET_CONFIG",
        _config,
    };
};
const setSelectedGroupType = (type) => {
    return {
        type: "SET_GROUP_SELECT",
        groupType: type,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({setSelectedGroupType ,setCanvasConfig}, dispatch);
};

const ArtWorkColorPaneConnect = connect(
    mapStateToProps,
    mapDispatchToProps
)(ArtWorkColorPane);

class ArtWorkColorPaneSideBar extends React.Component {
    closeNav = () => {
        document.getElementById('mySidenav8').style.width = '0'
    }

    render() {
        return (
            <>
                <div
                    id="artwork-color-pane"
                    className="h100 second-menu fordeskview"
                    style={{ display: 'none' }}
                >
                    <ArtWorkColorPaneConnect />
                </div>
                <div
                    className="text-center h100 pad0 mtopneg sidenav5 formobview wo"
                    id="mySidenav8"
                >
                    <a
                        style={{ color: '#fff' }}
                        href="#"
                        className="closebtn"
                        onClick={() => this.closeNav()}
                    >
                        &times;
                    </a>
                    <ArtWorkColorPaneConnect />
                </div>
            </>
        )
    }
}

export default ArtWorkColorPaneSideBar;