// mats id {parent}{child}{subChild}

const RubberMats = [
	{
		id:1,
		newType:'cornerMat_0',
		title:'3/8" Rubber Mat Colors',
		padding: '0px 2px 0px 0px',
		items:[
			{id:11,type:3,title:'Black 10%',color:'cornerMatBlack10',colorCode:'cornerMatBlack10',classType:'cornerMat_0'},
			{id:12,type:3,title:'Blue 10%',color:'cornerMatBlue10',colorCode:'cornerMatBlue10',classType:'cornerMat_0'},
			{id:13,type:3,title:'Brick 10%',color:'cornerMatBrick10',colorCode:'cornerMatBrick10',classType:'cornerMat_0'},
			{id:14,type:3,title:'Gery 10%',color:'cornerMatGery10',colorCode:'cornerMatGery10',classType:'cornerMat_0'},
			{id:15,type:3,title:'Tan 10%',color:'cornerMatTan10',colorCode:'cornerMatTan10',classType:'cornerMat_0'},
			{id:16,type:3,title:'Blue 35%',color:'cornerMatBlue35',colorCode:'cornerMatBlue35',classType:'cornerMat_0'},
			{id:17,type:3,title:'Grey 35%',color:'cornerMatGery35',colorCode:'cornerMatGery35',classType:'cornerMat_0'},
			{id:18,type:3,title:'Green 35%',color:'cornerMatGreen35',colorCode:'cornerMatGreen35',classType:'cornerMat_0'},
			{id:19,type:3,title:'Red 35%',color:'cornerMatRed35',colorCode:'cornerMatRed35',classType:'cornerMat_0'},
			{id:110,type:3,title:'Tan 35%',color:'cornerMatTan35',colorCode:'cornerMatTan35',classType:'cornerMat_0'},

			/*{id:111,type:3,title:'Brown 95%',color:'cornerMatBrown95',colorCode:'cornerMatBrown95',classType:'cornerMat_0'},
			{id:112,type:3,title:'Charcoal Gery 95%',color:'cornerMatCharcoalGery95',colorCode:'cornerMatCharcoalGery95',classType:'cornerMat_0'},
			{id:113,type:3,title:'Gery 95%',color:'cornerMatGery95',colorCode:'cornerMatGery95',classType:'cornerMat_0'},
			{id:114,type:3,title:'Gery Tan 95%',color:'cornerMatGeryTan95',colorCode:'cornerMatGeryTan95',classType:'cornerMat_0'},
			{id:115,type:3,title:'Red 95%',color:'cornerMatRed95',colorCode:'cornerMatRed95',classType:'cornerMat_0'},
			{id:116,type:3,title:'Tan 95%',color:'cornerMatTan95',colorCode:'cornerMatTan95',classType:'cornerMat_0'},*/
		]
	},
	{
		id:2,
		newType:'sideMat_0',
		title:'3/8" Rubber Mat Colors',
		padding: '0px 2px 0px 0px',
		items:[
			{id:20,type:3,title:'Black 10%',color:'sideMatBlack10',colorCode:'sideMatBlack10',classType:'sideMat_0'},
			{id:21,type:3,title:'Blue 10%',color:'sideMatBlue10',colorCode:'sideMatBlue10',classType:'sideMat_0'},
			{id:22,type:3,title:'Brick 10%',color:'sideMatBrick10',colorCode:'sideMatBrick10',classType:'sideMat_0'},
			{id:23,type:3,title:'Grey 10%',color:'sideMatGery10',colorCode:'sideMatGery10',classType:'sideMat_0'},
			{id:24,type:3,title:'Tan 10%',color:'sideMatTan10',colorCode:'sideMatTan10',classType:'sideMat_0'},
			{id:25,type:3,title:'Blue 35%',color:'sideMatBlue35',colorCode:'sideMatBlue35',classType:'sideMat_0'},
			{id:26,type:3,title:'Grey 35%',color:'sideMatGery35',colorCode:'sideMatGery35',classType:'sideMat_0'},
			{id:27,type:3,title:'Green 35%',color:'sideMatGreen35',colorCode:'sideMatGreen35',classType:'sideMat_0'},
			{id:28,type:3,title:'Red 35%',color:'sideMatRed35',colorCode:'sideMatRed35',classType:'sideMat_0'},
			{id:29,type:3,title:'Tan 35%',color:'sideMatTan35',colorCode:'sideMatTan35',classType:'sideMat_0'},

			/*{id:210,type:3,title:'Tan 35%',color:'sideMatBrown95',colorCode:'sideMatBrown95',classType:'sideMat_0'},
			{id:211,type:3,title:'Tan 35%',color:'sideMatCharcoalGrey95',colorCode:'sideMatCharcoalGrey95',classType:'sideMat_0'},
			{id:212,type:3,title:'Tan 35%',color:'sideMatGrey95',colorCode:'sideMatGrey95',classType:'sideMat_0'},
			{id:213,type:3,title:'Tan 35%',color:'sideMatGreyTan95',colorCode:'sideMatGreyTan95',classType:'sideMat_0'},
			{id:214,type:3,title:'Tan 35%',color:'sideMatRed95',colorCode:'sideMatRed95',classType:'sideMat_0'},
			{id:215,type:3,title:'Tan 35%',color:'sideMatTan95',colorCode:'sideMatTan95',classType:'sideMat_0'},*/
		]
	},
	{
		id:3,
		newType:'centerMat_0',
		title:'3/8" Rubber Mat Colors',
		padding: '0px 2px 0px 0px',
		items:[
			{id:30,type:3,title:'Black 10%',color:'centerMatBlack10',colorCode:'centerMatBlack10',classType:'centerMat_0'},
			{id:31,type:3,title:'Blue 10%',color:'centerMatBlue10',colorCode:'centerMatBlue10',classType:'centerMat_0'},
			{id:32,type:3,title:'Brick 10%',color:'centerMatBrick10',colorCode:'centerMatBrick10',classType:'centerMat_0'},
			{id:33,type:3,title:'Gery 10%',color:'centerMatGery10',colorCode:'centerMatGery10',classType:'centerMat_0'},
			{id:34,type:3,title:'Tan 10%',color:'centerMatTan10',colorCode:'centerMatTan10',classType:'centerMat_0'},
			{id:35,type:3,title:'Blue 35%',color:'centerMatBlue35',colorCode:'centerMatBlue35',classType:'centerMat_0'},
			{id:36,type:3,title:'Gery 35%',color:'centerMatGery35',colorCode:'centerMatGery35',classType:'centerMat_0'},
			{id:37,type:3,title:'Green 35%',color:'centerMatGreen35',colorCode:'centerMatGreen35',classType:'centerMat_0'},
			{id:38,type:3,title:'Red 35%',color:'centerMatRed35',colorCode:'centerMatRed35',classType:'centerMat_0'},
			{id:39,type:3,title:'Tan 35%',color:'centerMatTan35',colorCode:'centerMatTan35',classType:'centerMat_0'},

			/*{id:310,type:3,title:'Brown 95%',color:'centerMatBrown95',colorCode:'centerMatBrown95',classType:'centerMat_0'},
			{id:311,type:3,title:'Charcoal Gery 95%',color:'centerMatCharcoalGery95',colorCode:'centerMatCharcoalGery95',classType:'centerMat_0'},
			{id:312,type:3,title:'Grey 95%',color:'centerMatGrey95',colorCode:'centerMatGrey95',classType:'centerMat_0'},
			{id:313,type:3,title:'Grey Tan 95%',color:'centerMatGreyTan95',colorCode:'centerMatGreyTan95',classType:'centerMat_0'},
			{id:314,type:3,title:'Red 95%',color:'centerMatRed95',colorCode:'centerMatRed95',classType:'centerMat_0'},
			{id:315,type:3,title:'Tan 95%',color:'centerMatTan95',colorCode:'centerMatTan95',classType:'centerMat_0'},*/
		]
	},
	{
		id:4,
		newType:'squareMat_0',
		title:'1" Rubber Mat Colors',
		padding: '0px 2px 0px 0px',
		items:[
			{id:41,type:3,title:'Black 10%',color:'squareMatBlack10',colorCode:'squareMatBlack10',classType:'squareMat_0'},
			{id:42,type:3,title:'Blue 10%',color:'squareMatBlue10',colorCode:'squareMatBlue10',classType:'squareMat_0'},
			{id:43,type:3,title:'Brick 10%',color:'squareMatBrick10',colorCode:'squareMatBrick10',classType:'squareMat_0'},
			{id:44,type:3,title:'Tan 10%',color:'squareMatTan10',colorCode:'squareMatTan10',classType:'squareMat_0'},
			{id:45,type:3,title:'Grey 10%',color:'squareMatGrey10',colorCode:'squareMatGrey10',classType:'squareMat_0'},
			{id:46,type:3,title:'Blue 35%',color:'squareMatBlue35',colorCode:'squareMatBlue35',classType:'squareMat_0'},
			{id:47,type:3,title:'Brick 35%',color:'squareMatBrick35',colorCode:'squareMatBrick35',classType:'squareMat_0'},
			{id:48,type:3,title:'Green 35%',color:'squareMatGreen35',colorCode:'squareMatGreen35',classType:'squareMat_0'},
			{id:49,type:3,title:'Gery 35%',color:'squareMatGrey35',colorCode:'squareMatGrey35',classType:'squareMat_0'},
			{id:410,type:3,title:'Tan 35%',color:'squareMatTan35',colorCode:'squareMatTan35',classType:'squareMat_0'},

			{id:411,type:3,title:'Brown 95%',color:'squareMatBrown95',colorCode:'squareMatBrown95',classType:'squareMat_0'},
			{id:412,type:3,title:'Charcoal Gery 95%',color:'squareMatCharcoalGery95',colorCode:'squareMatCharcoalGery95',classType:'squareMat_0'},
			{id:413,type:3,title:'Grey 95%',color:'squareMatGrey95',colorCode:'squareMatGrey95',classType:'squareMat_0'},
			{id:414,type:3,title:'Grey Tan 95%',color:'squareMatGreyTan95',colorCode:'squareMatGreyTan95',classType:'squareMat_0'},
			{id:415,type:3,title:'Red 95%',color:'squareMatRed95',colorCode:'squareMatRed95',classType:'squareMat_0'},
			{id:416,type:3,title:'Tan 95%',color:'squareMatTan95',colorCode:'squareMatTan95',classType:'squareMat_0'},
		]
	}
]
export default RubberMats;