import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

class MatsToolbar extends React.Component {
  constructor() {
    super();
    this.state={
      selectedMat:'Mat 42 x 42',
    }
  }

  setConfigWrestling = e => {
    this.setState({
      selectedMat:e.target.value,
    })
    let width = 0
    let height = 0
    let fileName = ''
    let { value } = e.target
    if (value === 'Oct30') {
      width = 30
      height = 30
      fileName = 'oct30.png'
    } else if (value === 'Oct24') {
      width = 24
      height = 24
      fileName = 'oct24.png'
    } else {
      let res = value.split(' ')
      width = res[1].replace('m', '')
      height = res[3].replace('m', '')
      fileName = `${width}x${height}.png`
    }

    const isNewMaterial = true;
    this.props.setBgColor();
    this.props.setCircleSize();
    this.props.setWrestlingConfig(width, height, isNewMaterial, fileName)
    this.deleteAllComponents()
  }

  setWrestlingConfig = (width, height, flag, file,mat) => {
    this.props.setWrestlingConfig(width, height, flag, file)
    this.deleteAllComponents()
    mat && this.setState({selectedMat:mat})
  }

  deleteAllComponents = () => {
    const curvedTexts = this.props.canvas.getObjects('curvedText')
    curvedTexts.map(text => this.props.canvas.remove(text))
    const images = this.props.canvas.getObjects('image')
    images.map(image => this.props.canvas.remove(image))
    const groups = this.props.canvas.getObjects('group')
    groups.map(group => this.props.canvas.remove(group))
    const circles = this.props.canvas.getObjects('circle')
    circles.map(circle => this.props.canvas.remove(circle))
  }

  render() {
    const {selectedMat} = this.state;
    return (
      <>
        <div className="adddiv">
          <h2>Select Mats</h2>
        </div>
        <div className="templatesmain mat-lft-blk-menu">
          <div className="row ">
            <div className="col-md-12">
              <form className="form-horizontal">
                <span className="glyphicon glyphicon-search form-control-feedback serch-icon-left"></span>
                <input
                  type="text"
                  className="form-control seacrh-field-mat"
                  id=""
                  placeholder="Seacrh in Dollamur Store Front"
                />
              </form>
            </div>
            <div className="col-md-12 mt-15">
              <div className="form-group">
                <select
                  className="form-control slct-font-fam choose-your-size-drop"
                  onChange={this.setConfigWrestling}
                  value={selectedMat}
                >
                  <option>Mat 42 x 42</option>
                  <option>Mat 42 x 40</option>
                  <option>Mat 42 x 38</option>
                  <option>Mat 40 x 40</option>
                  <option>Mat 36 x 36</option>
                  <option>Mat 30 x 30</option>
                  <option value='Mat 12m x 12m'>UWW Mat</option>
                  <option >Oct30</option>
                  <option>Oct24</option>
                </select>
              </div>
            </div>

            <div className="col-md-6 col-sm-12">
              <div className="temrows mat-lft-repeat-blk">
                <img
                  src={require('./../../../../assets/img/42x42.png')}
                  alt="Template"
                  style={selectedMat === 'Mat 42 x 42' ? {
                    borderColor: '#66afe9',
                    outline: '0',
                    boxShadow: '0 0 10px #66afe9',
                  }: {}}
                  className="img-responsive img-square"
                  onClick={() =>
                    this.setWrestlingConfig(42, 42, true, '42x42.png','Mat 42 x 42')
                  }
                />
                <span>42 x 42</span>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="temrows mat-lft-repeat-blk">
                <img
                  src={require('./../../../../assets/img/42x40.png')}
                  alt="Template"
                  style={selectedMat === 'Mat 42 x 40' ? {
                    borderColor: '#66afe9',
                    outline: '0',
                    boxShadow: '0 0 10px #66afe9',
                  }: {}}
                  className="img-responsive img-square"
                  onClick={() =>
                    this.setWrestlingConfig(42, 40, true, '42x40.png','Mat 42 x 40')
                  }
                />
                <span>42 x 40</span>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="temrows mat-lft-repeat-blk">
                <img
                  src={require('./../../../../assets/img/42x38.png')}
                  alt="Template"
                  style={selectedMat === 'Mat 42 x 38' ? {
                    borderColor: '#66afe9',
                    outline: '0',
                    boxShadow: '0 0 10px #66afe9',
                  }: {}}
                  className="img-responsive img-square"
                  onClick={() =>
                    this.setWrestlingConfig(42, 38, true, '42x38.png','Mat 42 x 38')
                  }
                />
                <span>42 x 38</span>
              </div>
            </div>

            <div className="col-md-6 col-sm-12">
              <div className="temrows mat-lft-repeat-blk">
                <img
                  src={require('./../../../../assets/img/40x40.png')}
                  alt="Template"
                  style={selectedMat === 'Mat 40 x 40' ? {
                    borderColor: '#66afe9',
                    outline: '0',
                    boxShadow: '0 0 10px #66afe9',
                  }: {}}
                  className="img-responsive img-square"
                  onClick={() =>
                    this.setWrestlingConfig(40, 40, true, '40x40.png','Mat 40 x 40')
                  }
                />
                <span>40 x 40</span>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="temrows mat-lft-repeat-blk">
                <img
                  src={require('./../../../../assets/img/36x36.png')}
                  alt="Template"
                  style={selectedMat === 'Mat 36 x 36' ? {
                    borderColor: '#66afe9',
                    outline: '0',
                    boxShadow: '0 0 10px #66afe9',
                  }: {}}
                  className="img-responsive img-square"
                  onClick={() =>
                    this.setWrestlingConfig(36, 36, true, '36x36.png','Mat 36 x 36')
                  }
                />
                <span>36 x 36</span>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="temrows mat-lft-repeat-blk">
                <img
                  src={require('./../../../../assets/img/30x30.png')}
                  alt="Template"
                  style={selectedMat === 'Mat 30 x 30' ? {
                    borderColor: '#66afe9',
                    outline: '0',
                    boxShadow: '0 0 10px #66afe9',
                  }: {}}
                  className="img-responsive img-square"
                  onClick={() =>
                    this.setWrestlingConfig(30, 30, true, '30x30.png','Mat 30 x 30')
                  }
                />
                <span>30 x 30</span>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="temrows mat-lft-repeat-blk">
                <img
                  src={require('./../../../../assets/img/12x12.png')}
                  alt="Template"
                  style={selectedMat === 'Mat 12m x 12m' ? {
                    borderColor: '#66afe9',
                    outline: '0',
                    boxShadow: '0 0 10px #66afe9',
                  }: {}}
                  className="img-responsive img-square"
                  onClick={() =>
                    this.setWrestlingConfig(12, 12, true, '12x12.png','Mat 12m x 12m')
                  }
                />
                <span>UWW Mat</span>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="temrows mat-lft-repeat-blk">
                <img
                  src={require('./../../../../assets/img/oct30.png')}
                  alt="Template"
                  style={selectedMat === 'Oct30' ? {
                    borderColor: '#66afe9',
                    outline: '0',
                    boxShadow: '0 0 10px #66afe9',
                  }: {}}
                  className="img-responsive img-square"
                  onClick={() =>
                    this.setWrestlingConfig(30, 30, true, 'oct30.png','Oct30')
                  }
                />
                <span>Oct30</span>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="temrows mat-lft-repeat-blk">
                <img
                  src={require('./../../../../assets/img/oct24.png')}
                  alt="Template"
                  style={selectedMat === 'Oct24' ? {
                    borderColor: '#66afe9',
                    outline: 'none',
                    //boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)',
                    boxShadow: '0 0 10px #66afe9',
                  }: {}}
                  className="img-responsive img-square"
                  onClick={() =>
                    this.setWrestlingConfig(24, 24, true, 'oct24.png','Oct24')
                  }
                />
                <span>Oct24</span>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const setWrestlingConfig = (width, height, isNewMaterial, file) => {
  const data = {
    width: width,
    height: height,
    length_unit: 'METER',
    isNewMaterial: isNewMaterial,
    file: file,
  }
  return {
    type: 'SET_WRESTLING_CONFIG',
    _config: data,
  }
}
const setCircleSize = circle => {
  return {
    type: "SET_CIRCLE_SIZE",
    circle_outer_diameter: 0,
    circle_inner_diameter: 0
  };
};
const setBgColor = color => {
  return {
    type: "SET_BACKGROUND_COLOR",
    color:null,
  };
};
const mapStateToProps = state => {
  return {
    canvas: state.canvas,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ setWrestlingConfig,setBgColor,setCircleSize }, dispatch)
}

const MatsToolbarConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MatsToolbar)

class MatsToolSidebar extends React.Component {
  closeNav = () => {
    document.getElementById('mySidenav1').style.width = '0'
  }

  render() {
    return (
      <>
        <div id="mats" className="h100 second-menu fordeskview">
          <MatsToolbarConnect />
        </div>
        <div
          className="text-center h100 pad0 mtopneg sidenav1 formobview wo"
          id="mySidenav1"
        >
          <a
            style={{ color: '#fff' }}
            href="#"
            className="closebtn"
            onClick={() => this.closeNav()}
          >
            &times;
          </a>
          <MatsToolbarConnect />
        </div>
      </>
    )
  }
}

export default MatsToolSidebar
