import React from 'react'
import '../assets/css/bootstrap.css'
import '../assets/css/fontawesome.css'
import '../assets/css/wrestling.css'
import '../assets/css/metisMenu.css'
import '../assets/css/onoffcanvas.css'
import '../assets/css/home.css'
import Footer from '../components/layout/Footer'

class HomePage extends React.Component {
  render() {
    return (
      <div
        className="see-instruction int-matbuild default-left wallpad home"
      >
        <div className="container">
          <div className="innerint default-left text-center">
            <h2 className="title">Interactive<br />Design Builders</h2>
            <p className="subtitle">
              Select the style mat you want to design from the menu above.
              <br />
              For odd shaped rooms, please contact one of our mat experts and we can design it for you at no charge.
            </p>
            <br />
            <br />
            <p className="subtitle no-pd-t">Wrestling Designer - Competition mats, UWW style mats, Octagons.</p>
            <p className="subtitle no-pd-t">Swain Hybrid Designer - Martial Arts mats - 1m x 1m and 1m x 2m.</p>
            <p className="subtitle no-pd-t">FLEXI-Roll and FLEXI-Connect Designer - Smooth, Tatami and Carpet Roll Mats - All Sports.</p>
            <p className="subtitle no-pd-t">Turf/Rubber Designer – Sled and Turf Tracks, Rubber Roll Flooring – All Sports.</p>
            <p className="subtitle no-pd-t">FLEXI-Wall Pads - All sports.</p>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default HomePage
