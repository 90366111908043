import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SwainColorRGB, SwainColorName } from "../layout/ColorCodeHelper";

class Template1 extends React.Component {
  state = {
    template: [
      {
        x: 0,
        y: 0,
        width: 2,
        height: 1,
        color: SwainColorRGB.Gold,
        colorName:SwainColorName.Gold
      },
      {
        x: 2,
        y: 0,
        width: 2,
        height: 1,
        color: SwainColorRGB.Gold,
        colorName:SwainColorName.Gold
      },
      {
        x: 0,
        y: 1,
        width: 2,
        height: 1,
        color: SwainColorRGB.Gold,
        colorName:SwainColorName.Gold
      },
      {
        x: 2,
        y: 1,
        width: 2,
        height: 1,
        color: SwainColorRGB.Gold,
        colorName:SwainColorName.Gold
      },
      {
        x: 0,
        y: 2,
        width: 2,
        height: 1,
        color: SwainColorRGB.Gold,
        colorName:SwainColorName.Gold
      },
      {
        x: 2,
        y: 2,
        width: 2,
        height: 1,
        color: SwainColorRGB.Gold,
        colorName:SwainColorName.Gold
      }
    ]
  };
  componentDidMount() {
    const { swain_config } = this.props;
    const canvas_grid = this.props.canvas_grid;
    const template = this.state.template;
    const rect_count = template.length;
    const unit = swain_config.length_unit === "FEET"? 3.28 : 1;
    for (let i = 0; i < rect_count; i++) {
      const rect_info = template[i];
      let width = canvas_grid * rect_info.width * unit;
      let height = canvas_grid * rect_info.height * unit;
      let top =
        Math.round(this.props.template_top / canvas_grid / unit) * canvas_grid * unit +
        rect_info.y * canvas_grid * unit;
      let left =
        Math.round(this.props.template_left / canvas_grid / unit) * canvas_grid * unit +
        rect_info.x * canvas_grid * unit;

      const rect = new window.fabric.Rect({
        left,
        top,
        width: width,
        height: height,
        fill: rect_info.color,
        hasControls: false,
        stroke: "#9aa1a2"
      });
      this.props.addRectToList({...rect, color: rect_info.colorName});
      this.props.canvas.add(rect);
    }
    this.props.canvas.renderAll();
    this.updateCanvasState();
  }

  updateCanvasState = () => {
    let tempConfig = this.props._config;
    if (tempConfig.undoStatus === false && tempConfig.redoStatus === false) {
      let jsonData = this.props.canvas.toJSON();
      let canvasAsJson = JSON.stringify(jsonData);
      if (tempConfig.currentStateIndex < tempConfig.canvasState.length - 1) {
        let indexToBeInserted = tempConfig.currentStateIndex + 1;
        tempConfig.canvasState[indexToBeInserted] = canvasAsJson;

        let numberOfElementsToRetain = indexToBeInserted + 1;
        tempConfig.canvasState = tempConfig.canvasState.splice(
          0,
          numberOfElementsToRetain
        );
      } else {
        tempConfig.canvasState.push(canvasAsJson);
      }

      tempConfig.currentStateIndex = tempConfig.canvasState.length - 1;

      if (
        tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
        tempConfig.currentStateIndex !== -1
      ) {
        tempConfig.redoButton = "disabled";
      }
    }
    this.props.setCanvasConfig(tempConfig);
  };

  render() {
    return null;
  }
}

const addRectToList = data => {
  return {
    type: "ADD_RECT_TO_LIST",
    data: data
  };
};

const setCanvasConfig = _config => {
  return {
    type: "SET_CONFIG",
    _config
  };
};

const mapStateToProps = state => {
  return {
    canvas: state.canvas,
    _config: state._config,
    swain_config: state.swain_config,
    canvas_grid: state.canvas_grid,
    template_top: state.template_top,
    template_left: state.template_left
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setCanvasConfig,
      addRectToList
    },
    dispatch
  );
};

const Template1Connect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Template1);

export default Template1Connect;
