import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Footer from '../Footer'

class OptionSettingDialogue extends React.Component {
	state = {
		width: 100,
		height: 15,
		unit: 'FEET',
		design_mode:'1',
	}
	value_limit=(val, min, max)=>{
		return val < min ? false : (val > max ? false : true);
	}

	onBuild = () => {
		console.log('building');
		if(this.state.design_mode === '1'){
			console.log(this.value_limit(this.state.width,8,100))
			console.log(this.value_limit(this.state.height,5,15))
			if (!this.value_limit(this.state.width,8,100)  || !this.value_limit(this.state.height,5,15)) {
				alert("Turf Track Only: 5’ - 15’ Wide, 8’ - 100’ Long");
			}else{
				this.props.setTurfConfig(this.state);
			}
			return;
		}
		/*if (this.state.width > 100 || this.state.height > 100) {
			return alert("Width or height should be maximum 100")
		}*/
		this.props.setTurfConfig(this.state);
	}

	_handleWidthChange = e => {
		let { value } = e.target;
		this.setState({
			width: value,
		})
	}

	_handleHeightChange = e => {
		let { value } = e.target;
		this.setState({
			height: value,
		})
	}

	_handleUnitChange = e => {
		let { value } = e.target
		if (value === 1) {
			this.setState({
				unit: 'FEET',
			})
		} else {
			this.setState({
				unit: 'METERS',
			})
		}
	}
	_handleTypeChange = e => {
		this.setState({design_mode:e.target.value })
		let category = e.target.value;
		if(category === '1') {
			// verify limit bounds
			// 5 to 15 ft height
			// 8 to 100 ft width
			let { height, width } = this.state;
			this._verifyTurfTrackLimits(height,width)
		}
	}
	_verifyTurfTrackLimits(height,width) {
		function value_limit(val, min, max) {
			return val < min ? min : (val > max ? max : val);
		}
		let newWidth = value_limit(width, 8, 100);
		let newHeight = value_limit(height, 5, 15);
		console.log({newWidth,newHeight})
		this.setState({height:newHeight,width:newWidth});
	}

	render() {
		const {width,height,unit,design_mode} = this.state;
		return (
			<>
				<div className="see-instruction int-matbuild default-left turf">
					<div className="container">
						<div className="innerint default-left text-center">
							<h2>Interactive Mat Builder</h2>
							<p>What kind of mat would you like? For mats under 20x20 please visit our Store/Martial Arts.</p>

							<div className="selectboxes default-left">
								<div className="row innerselect_box">
									<div className="col-md-3 col-sm-12 selectcol col_3">
										<div
											className="height-ft"
											style={{
											}}
										>
											Turf Options:
										</div>
									</div>
									<div className="col-md-6 col-sm-12 selectcol col_3" style={{paddingRight:'0px'}} >
										<select
											className="mdb-select md-form colorful-select dropdown-primary"
											onChange={this._handleTypeChange}
											style={{width:'100%'}}
											//value={design_mode}
										>
											<option value="1">Turf Track Only: 5’ - 15’ Wide, 8’ - 100’ Long</option>
											<option value="2">Full Room: Rubber Roll Flooring with Turf Track</option>
											<option value="3">Rubber Flooring only</option>
										</select>
									</div>
									<div className="col-md-3 col-sm-12 selectcol col_3">

									</div>
								</div>
								<div className="row innerselect_box">
									<div className="col-md-3 col-sm-12 selectcol col_1">
										<input
											type="number"
											className="mdb-select md-form colorful-select dropdown-primary"
											min="0"
											// max="100"
											value={width}
											onChange={this._handleWidthChange}
										/>
									</div>
									<div className="col-md-3 col-sm-12 selectcol col_2">
										<input
											type="number"
											className="mdb-select md-form colorful-select dropdown-primary"
											min="0"
											// max="15"
											value={height}
											onChange={this._handleHeightChange}
										/>
									</div>
									<div className="col-md-3 col-sm-12 selectcol col_3">
										<select
											className="mdb-select md-form colorful-select dropdown-primary"
									 		onChange={this._handleUnitChange}
										>
											<option value="1">Feet</option>
										</select>
									</div>
									<div className="col-md-3 col-sm-12 selectcol col_3">
										<div className="fw">
											<a className='btn btn-danger' style={{padding:'0px'}} href="#" onClick={this.onBuild}>
												<span>Build it</span>
												<img
													src={require('./../../../assets/img/fwarw.png')}
													alt="Forward Arrow"
												/>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="default-left back-fwd-btns">
							<div className="row">
								<div className="default-left entertxt text-center">
									<p className="font-oswald">
										Enter your room size. For odd shaped rooms, put in your best<br />
										guess of the area & speak to one of our mat experts for a<br />
										more accurate idea.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</>
		)
	}
}

const setTurfConfig = config => {
	const _config = {
		width: config.width,
		height: config.height,
		option_setting: true,
		length_unit: config.unit,
		design_mode: config.design_mode,
	}

	return {
		type: 'SET_TURF_CONFIG',
		_config,
	}
}

const mapStateToProps = state => {
	return {
		turf_config: state.turf_config,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ setTurfConfig }, dispatch)
}

const OptionSettingConnect = connect(
	mapStateToProps,
	mapDispatchToProps,
)(OptionSettingDialogue)

export default OptionSettingConnect
