import {SwainColorName, SwainColorRGB} from "../layout/ColorCodeHelper";


export default class RubberTemplates {
	constructor(canvas,canvas_grid,mat_type,data,puzzle_config) {
		console.log(data);
		this.data = data;
		this.canvas = canvas;
		this.canvasGrid = canvas_grid;
		this.matType = mat_type;
		this.defaultSize = 2;
		this.puzzle_config=puzzle_config;
		this.errorMessage = 'Cannot place big design on smaller design area';
	}

	/**
	 * 10ftx10ft template
	 * each mat 2ft
	 */
	addRubberTemplate1=()=>{
		const template = [
			{
				x: 0,
				y: 0,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'cornerMatGery10',
				mat_class:'cornerMat_0',
			},
			{
				x: 1,
				y: 0,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 2,
				y: 0,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 3,
				y: 0,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 4,
				y: 0,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'cornerMatGery10',
				mat_class:'cornerMat_0',
			},


			{
				x: 0,
				y: 1,
				width: 1,
				height: 1,
				angle:270,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 1,
				y: 1,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 2,
				y: 1,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 3,
				y: 1,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 4,
				y: 1,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},

			{
				x: 0,
				y: 2,
				width: 1,
				height: 1,
				angle:270,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 1,
				y: 2,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 2,
				y: 2,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 3,
				y: 2,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 4,
				y: 2,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},

			{
				x: 0,
				y: 3,
				width: 1,
				height: 1,
				angle:270,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 1,
				y: 3,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 2,
				y: 3,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 3,
				y: 3,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 4,
				y: 3,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},

			{
				x: 0,
				y: 4,
				width: 1,
				height: 1,
				angle:270,
				mat_image: 'cornerMatGery10',
				mat_class:'cornerMat_0',
			},
			{
				x: 1,
				y: 4,
				width: 1,
				height: 1,
				angle:180,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 2,
				y: 4,
				width: 1,
				height: 1,
				angle:180,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 3,
				y: 4,
				width: 1,
				height: 1,
				angle:180,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 4,
				y: 4,
				width: 1,
				height: 1,
				angle:180,
				mat_image: 'cornerMatGery10',
				mat_class:'cornerMat_0',
			},
		]
		this.addInterTemplate(template);
	}

	/**
	 * 10ftx10ft template
	 * each mat 2ft SQUARE
	 */
	addRubberTemplate2=()=>{
		let template =[]
		for(let y=0;y<5;y++) {
			for(let x=0;x<5;x++) {
				template.push({
					x,
					y,
					width: 1,
					height: 1,
					angle:0,
					mat_image: 'squareMatBlack10',
					mat_class:'squareMat_0',
				})
			}
		}
		this.addInterTemplate(template,'straight');
	}

	/**
	 * 14ftx14ft template
	 * each mat 2ft
	 */
	addRubberTemplate4=()=>{

		const template = [
			//row 1
			{
				x: 0,
				y: 0,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'cornerMatGery10',
				mat_class:'cornerMat_0',
			},
			{
				x: 1,
				y: 0,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 2,
				y: 0,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 3,
				y: 0,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 4,
				y: 0,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 5,
				y: 0,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 6,
				y: 0,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'cornerMatGery10',
				mat_class:'cornerMat_0',
			},
			// row 2
			{
				x: 0,
				y: 1,
				width: 1,
				height: 1,
				angle:270,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 1,
				y: 1,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 2,
				y: 1,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 3,
				y: 1,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 4,
				y: 1,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 5,
				y: 1,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 6,
				y: 1,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			// row 3
			{
				x: 0,
				y: 2,
				width: 1,
				height: 1,
				angle:270,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 1,
				y: 2,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 2,
				y: 2,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 3,
				y: 2,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 4,
				y: 2,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 5,
				y: 2,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 6,
				y: 2,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			// row 4
			{
				x: 0,
				y: 3,
				width: 1,
				height: 1,
				angle:270,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 1,
				y: 3,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 2,
				y: 3,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 3,
				y: 3,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 4,
				y: 3,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 5,
				y: 3,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 6,
				y: 3,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			// row 5
			{
				x: 0,
				y: 4,
				width: 1,
				height: 1,
				angle:270,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 1,
				y: 4,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 2,
				y: 4,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 3,
				y: 4,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 4,
				y: 4,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 5,
				y: 4,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 6,
				y: 4,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			// row 6
			{
				x: 0,
				y: 5,
				width: 1,
				height: 1,
				angle:270,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 1,
				y: 5,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 2,
				y: 5,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 3,
				y: 5,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 4,
				y: 5,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 5,
				y: 5,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 6,
				y: 5,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			// row 7
			{
				x: 0,
				y: 6,
				width: 1,
				height: 1,
				angle:270,
				mat_image: 'cornerMatGery10',
				mat_class:'cornerMat_0',
			},
			{
				x: 1,
				y: 6,
				width: 1,
				height: 1,
				angle:180,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 2,
				y: 6,
				width: 1,
				height: 1,
				angle:180,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 3,
				y: 6,
				width: 1,
				height: 1,
				angle:180,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 4,
				y: 6,
				width: 1,
				height: 1,
				angle:180,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 5,
				y: 6,
				width: 1,
				height: 1,
				angle:180,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 6,
				y: 6,
				width: 1,
				height: 1,
				angle:180,
				mat_image: 'cornerMatGery10',
				mat_class:'cornerMat_0',
			},

		];
		this.addInterTemplate(template);

	}

	/**
	 * 18ftx18ft template
	 * each mat 2ft
	 */
	addRubberTemplate5=()=>{
		const template = [
			//row 1
			{
				x: 0,
				y: 0,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'cornerMatGery10',
				mat_class:'cornerMat_0',
			},
			{
				x: 1,
				y: 0,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 2,
				y: 0,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 3,
				y: 0,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 4,
				y: 0,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 5,
				y: 0,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 6,
				y: 0,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 7,
				y: 0,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 8,
				y: 0,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'cornerMatGery10',
				mat_class:'cornerMat_0',
			},
			// row 2
			{
				x: 0,
				y: 1,
				width: 1,
				height: 1,
				angle:270,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 1,
				y: 1,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 2,
				y: 1,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 3,
				y: 1,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 4,
				y: 1,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 5,
				y: 1,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 6,
				y: 1,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 7,
				y: 1,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 8,
				y: 1,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			// row 3
			{
				x: 0,
				y: 2,
				width: 1,
				height: 1,
				angle:270,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 1,
				y: 2,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 2,
				y: 2,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 3,
				y: 2,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 4,
				y: 2,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 5,
				y: 2,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 6,
				y: 2,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 7,
				y: 2,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 8,
				y: 2,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			// row 4
			{
				x: 0,
				y: 3,
				width: 1,
				height: 1,
				angle:270,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 1,
				y: 3,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 2,
				y: 3,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 3,
				y: 3,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 4,
				y: 3,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 5,
				y: 3,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 6,
				y: 3,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 7,
				y: 3,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 8,
				y: 3,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			// row 5
			{
				x: 0,
				y: 4,
				width: 1,
				height: 1,
				angle:270,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 1,
				y: 4,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 2,
				y: 4,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 3,
				y: 4,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 4,
				y: 4,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 5,
				y: 4,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 6,
				y: 4,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 7,
				y: 4,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 8,
				y: 4,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			// row 6
			{
				x: 0,
				y: 5,
				width: 1,
				height: 1,
				angle:270,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 1,
				y: 5,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 2,
				y: 5,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 3,
				y: 5,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 4,
				y: 5,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 5,
				y: 5,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 6,
				y: 5,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 7,
				y: 5,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 8,
				y: 5,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},

			// row 7
			{
				x: 0,
				y: 6,
				width: 1,
				height: 1,
				angle:270,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 1,
				y: 6,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 2,
				y: 6,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 3,
				y: 6,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 4,
				y: 6,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 5,
				y: 6,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 6,
				y: 6,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 7,
				y: 6,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 8,
				y: 6,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},

			// row 7
			{
				x: 0,
				y: 7,
				width: 1,
				height: 1,
				angle:270,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 1,
				y: 7,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 2,
				y: 7,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 3,
				y: 7,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 4,
				y: 7,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 5,
				y: 7,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 6,
				y: 7,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 7,
				y: 7,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 8,
				y: 7,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},


			// row 7
			{
				x: 0,
				y: 8,
				width: 1,
				height: 1,
				angle:270,
				mat_image: 'cornerMatGery10',
				mat_class:'cornerMat_0',
			},
			{
				x: 1,
				y: 8,
				width: 1,
				height: 1,
				angle:180,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 2,
				y: 8,
				width: 1,
				height: 1,
				angle:180,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 3,
				y: 8,
				width: 1,
				height: 1,
				angle:180,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 4,
				y: 8,
				width: 1,
				height: 1,
				angle:180,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 5,
				y: 8,
				width: 1,
				height: 1,
				angle:180,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 6,
				y: 8,
				width: 1,
				height: 1,
				angle:180,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 7,
				y: 8,
				width: 1,
				height: 1,
				angle:180,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 8,
				y: 8,
				width: 1,
				height: 1,
				angle:180,
				mat_image: 'cornerMatGery10',
				mat_class:'cornerMat_0',
			},

		];
		this.addInterTemplate(template)
	}

	/**
	 * 20ftx20ft template
	 * each mat 2ft
	 */
	addRubberTemplate6=()=>{
		const template = [
			//row 1
			{
				x: 0,
				y: 0,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'cornerMatGery10',
				mat_class:'cornerMat_0',
			},
			{
				x: 1,
				y: 0,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 2,
				y: 0,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 3,
				y: 0,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 4,
				y: 0,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 5,
				y: 0,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 6,
				y: 0,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 7,
				y: 0,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 8,
				y: 0,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 9,
				y: 0,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'cornerMatGery10',
				mat_class:'cornerMat_0',
			},
			// row 2
			{
				x: 0,
				y: 1,
				width: 1,
				height: 1,
				angle:270,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 1,
				y: 1,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 2,
				y: 1,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 3,
				y: 1,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 4,
				y: 1,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 5,
				y: 1,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 6,
				y: 1,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 7,
				y: 1,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 8,
				y: 1,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 9,
				y: 1,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			// row 3
			{
				x: 0,
				y: 2,
				width: 1,
				height: 1,
				angle:270,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 1,
				y: 2,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 2,
				y: 2,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 3,
				y: 2,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 4,
				y: 2,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 5,
				y: 2,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 6,
				y: 2,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 7,
				y: 2,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 8,
				y: 2,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 9,
				y: 2,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			// row 4
			{
				x: 0,
				y: 3,
				width: 1,
				height: 1,
				angle:270,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 1,
				y: 3,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 2,
				y: 3,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 3,
				y: 3,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 4,
				y: 3,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 5,
				y: 3,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 6,
				y: 3,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 7,
				y: 3,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 8,
				y: 3,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 9,
				y: 3,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			// row 5
			{
				x: 0,
				y: 4,
				width: 1,
				height: 1,
				angle:270,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 1,
				y: 4,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 2,
				y: 4,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 3,
				y: 4,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 4,
				y: 4,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 5,
				y: 4,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 6,
				y: 4,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 7,
				y: 4,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 8,
				y: 4,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 9,
				y: 4,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			// row 6
			{
				x: 0,
				y: 5,
				width: 1,
				height: 1,
				angle:270,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 1,
				y: 5,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 2,
				y: 5,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 3,
				y: 5,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 4,
				y: 5,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 5,
				y: 5,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 6,
				y: 5,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 7,
				y: 5,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 8,
				y: 5,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 9,
				y: 5,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},

			// row 7
			{
				x: 0,
				y: 6,
				width: 1,
				height: 1,
				angle:270,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 1,
				y: 6,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 2,
				y: 6,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 3,
				y: 6,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 4,
				y: 6,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 5,
				y: 6,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 6,
				y: 6,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 7,
				y: 6,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 8,
				y: 6,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 9,
				y: 6,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},

			// row 7
			{
				x: 0,
				y: 7,
				width: 1,
				height: 1,
				angle:270,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 1,
				y: 7,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 2,
				y: 7,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 3,
				y: 7,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 4,
				y: 7,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 5,
				y: 7,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 6,
				y: 7,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 7,
				y: 7,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 8,
				y: 7,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 9,
				y: 7,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			// row 8
			{
				x: 0,
				y: 8,
				width: 1,
				height: 1,
				angle:270,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 1,
				y: 8,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 2,
				y: 8,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 3,
				y: 8,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 4,
				y: 8,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 5,
				y: 8,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 6,
				y: 8,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 7,
				y: 8,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 8,
				y: 8,
				width: 1,
				height: 1,
				angle:0,
				mat_image: 'centerMatGery10',
				mat_class:'centerMat_0',
			},
			{
				x: 9,
				y: 8,
				width: 1,
				height: 1,
				angle:90,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},

			// row 9
			{
				x: 0,
				y: 9,
				width: 1,
				height: 1,
				angle:270,
				mat_image: 'cornerMatGery10',
				mat_class:'cornerMat_0',
			},
			{
				x: 1,
				y: 9,
				width: 1,
				height: 1,
				angle:180,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 2,
				y: 9,
				width: 1,
				height: 1,
				angle:180,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 3,
				y: 9,
				width: 1,
				height: 1,
				angle:180,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 4,
				y: 9,
				width: 1,
				height: 1,
				angle:180,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 5,
				y: 9,
				width: 1,
				height: 1,
				angle:180,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 6,
				y: 9,
				width: 1,
				height: 1,
				angle:180,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 7,
				y: 9,
				width: 1,
				height: 1,
				angle:180,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 8,
				y: 9,
				width: 1,
				height: 1,
				angle:180,
				mat_image: 'sideMatGery10',
				mat_class:'sideMat_0',
			},
			{
				x: 9,
				y: 9,
				width: 1,
				height: 1,
				angle:180,
				mat_image: 'cornerMatGery10',
				mat_class:'cornerMat_0',
			},

		];
		this.addInterTemplate(template)
	}

	/**
	 * 20ftx20ft template
	 * each mat 2ft SQUARE
	 */
	addRubberTemplate3=()=>{
		let template =[]
		for(let y=0;y<10;y++) {
			for(let x=0;x<10;x++) {
				template.push({
					x,
					y,
					width: 1,
					height: 1,
					angle:0,
					mat_image: 'squareMatBlack10',
					mat_class:'squareMat_0',
				})
			}
		}
		this.addInterTemplate(template,'straight');
	}

	addInterTemplate=(template,type='inter-locking')=>{
		const rect_count = template.length;
		const canvas_grid = this.canvasGrid;
		let unitRatio = this.puzzle_config.length_unit === "FEET"? 1.9 : 1,
			unitScale = this.puzzle_config.length_unit === "FEET"? 2.07 : 1.035,
			template_top= this.data.top,
			template_left=this.data.left,
			width = canvas_grid * unitScale,
			height = canvas_grid * unitScale;
		if(type !== 'inter-locking') {
			unitScale = this.puzzle_config.length_unit === "FEET"? 2.0 : 1.0;
			unitRatio = this.puzzle_config.length_unit === "FEET"? 2 : 1;
		}
		for (let i = 0; i < rect_count; i++) {
			let img = new Image();
			const rect_info  = template[i];
			const imageSrc = require(`./../../assets/img/rubberPuzzle/${rect_info.mat_image}.png`);
			img.onload=()=>{
				let rect = new window.fabric.Image(img),
					lockRotation = true;
				let top =
					Math.round(template_top / canvas_grid / unitRatio) * canvas_grid * unitRatio +
					rect_info.y * canvas_grid * unitRatio;
				let left =
					Math.round(template_left / canvas_grid / unitRatio) * canvas_grid * unitRatio +
					rect_info.x * canvas_grid * unitRatio;
				console.log({top,left})
				rect.set({
					left:0,
					top:0,
					width,
					height,
					mat_class:rect_info.mat_class,
					mat_image:rect_info.mat_image,
				});

				let colorRect = new window.fabric.Rect({
					left:0,
					top:0,
					width,
					height,
					fill:'transparent',
					//padding:(widthRatio*0.9)-widthRatio,
					//lockRotation:lockRotation,

				});
				rect.rotate(rect_info.angle)
				let group = new window.fabric.Group([rect,colorRect],{
					top,
					left,
					lockRotation,
					rubberAngle:rect_info.angle,
					cornerSize:6,
				});
				this.canvas.add(group);
				this.canvas.renderAll();
				setTimeout(()=>{
					group.rubberAngle = rect_info.angle;
					this.canvas.fire('rubber-puzzle:rotated');
				},0)
			}
			img.src = imageSrc;
		}
	}
}