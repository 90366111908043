import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SwainColorRGB, SwainColorName } from "../layout/ColorCodeHelper";

class Template3 extends React.Component {
    state = {
        template: [
            {
                x: 0,
                y: 0,
                width: 1,
                height: 1,
                color: SwainColorRGB.Gold,
                colorName:SwainColorName.Gold
            },
            {
                x: 1,
                y: 0,
                width: 1,
                height: 1,
                color: SwainColorRGB.Gold,
                colorName:SwainColorName.Gold
            },
            {
                x: 2,
                y: 0,
                width: 1,
                height: 1,
                color: SwainColorRGB.Gold,
                colorName:SwainColorName.Gold
            },
            {
                x: 0,
                y: 1,
                width: 1,
                height: 1,
                color: SwainColorRGB.Gold,
                colorName:SwainColorName.Gold
            },
            {
                x: 1,
                y: 1,
                width: 1,
                height: 1,
                color: SwainColorRGB.Gold,
                colorName:SwainColorName.Gold
            },
            {
                x: 2,
                y: 1,
                width: 1,
                height: 1,
                color: SwainColorRGB.Gold,
                colorName:SwainColorName.Gold
            }
        ]
    };
    componentDidMount() {
        const { puzzle_config } = this.props;
        const canvas_grid = this.props.canvas_grid;
        const template = this.state.template;
        const mat_class = this.props.mat_class;
        const rect_count = template.length;
        const unit = puzzle_config.length_unit === "FEET"? 3.28 : 1;

        for (let i = 0; i < rect_count; i++) {
            let img = new Image();
            img.onload = ()=>{
                let rect = new window.fabric.Image(img);
                const rect_info = template[i];

                const unitRatio = this.props.puzzle_config.length_unit === "FEET"? 3.17 : 1;
                const unitScale = this.props.puzzle_config.length_unit === "FEET"? 3.28 : 1.035;

                let width = canvas_grid * rect_info.width * unitScale;
                let height = canvas_grid * rect_info.height * unitScale;
                let widthRatio = canvas_grid * rect_info.width * unitScale;

                let top =
                    Math.round(this.props.template_top / canvas_grid / unitRatio) * canvas_grid * unitRatio +
                    rect_info.y * canvas_grid * unitRatio;
                let left =
                    Math.round(this.props.template_left / canvas_grid / unitRatio) * canvas_grid * unitRatio +
                    rect_info.x * canvas_grid * unitRatio;

                rect.set({
                    left,
                    top,
                    width,
                    height,
                    //padding:(widthRatio*0.9)-widthRatio,
                    mat_class:mat_class,
                    mat_image:"RoyalBlue"
                });

                setTimeout( () =>{
                    rect['setControlVisible']('ml', false)
                    rect['setControlVisible']('mb', false)
                    rect['setControlVisible']('mr', false)
                    rect['setControlVisible']('mt', false)
                    rect['cornerSize'] = 6

                    this.props.canvas.add(rect)
                    this.props.canvas.renderAll()
                    this.updateCanvasState();
                },0)
            }
            img.src = require(`./../../assets/img/RoyalBlue.png`);

        }
        this.props.canvas.renderAll();

    }

    updateCanvasState = () => {
        let tempConfig = this.props._config;
        if (tempConfig.undoStatus === false && tempConfig.redoStatus === false) {
            let jsonData = this.props.canvas.toJSON(['mat_class','mat_image'])
            let canvasAsJson = JSON.stringify(jsonData);
            if (tempConfig.currentStateIndex < tempConfig.canvasState.length - 1) {
                let indexToBeInserted = tempConfig.currentStateIndex + 1;
                tempConfig.canvasState[indexToBeInserted] = canvasAsJson;

                let numberOfElementsToRetain = indexToBeInserted + 1;
                tempConfig.canvasState = tempConfig.canvasState.splice(
                    0,
                    numberOfElementsToRetain
                );
            } else {
                tempConfig.canvasState.push(canvasAsJson);
            }

            tempConfig.currentStateIndex = tempConfig.canvasState.length - 1;

            if (
                tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
                tempConfig.currentStateIndex !== -1
            ) {
                tempConfig.redoButton = "disabled";
            }
        }
        this.props.setCanvasConfig(tempConfig);
    };

    render() {
        return null;
    }
}

const addRectToList = data => {
    return {
        type: "ADD_RECT_TO_LIST",
        data: data
    };
};

const setCanvasConfig = _config => {
    return {
        type: "SET_CONFIG",
        _config
    };
};

const mapStateToProps = state => {
    return {
        canvas: state.canvas,
        _config: state._config,
        puzzle_config: state.puzzle_config,
        mat_class: state.mat_class,
        canvas_grid: state.canvas_grid,
        template_top: state.template_top,
        template_left: state.template_left
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            setCanvasConfig,
            addRectToList
        },
        dispatch
    );
};

const Template3Connect = connect(
    mapStateToProps,
    mapDispatchToProps
)(Template3);

export default Template3Connect;
