import React, {Component} from 'react';
import {connect} from 'react-redux';
import './../assets/css/customColorPane.css';
class CustomColorPane extends Component {
	constructor() {
		super();
		this.state = {
			activeColor:'#00000',
			showColorPane:false,
		}
	}
	toggleColorPane=()=>{
		this.setState({
			showColorPane:!this.state.showColorPane,
		})
	}

	render() {
		const {showColorPane} = this.state;
		const {activeColor} = this.props;
		const title = this.props.title || 'Choose Color';
		const colorNames = this.props.colorNames || {
			Red:         'Red',
			Green:       'Green',
			RoyalBlue:   'Royal Blue',
			Black:       'Black',
		}
		const colors = this.props.colors || {
			Red:        '#e4002b',
			Green:      '#67823a',
			RoyalBlue:  '#003da5',
			Black:      '#0d0000',
		};
		console.log(colors);
		let allColors = [];
		for(const color in colors) {
			allColors.push({title:colorNames[color] , colorCode:colors[color] , colorName:color});
		}
		return (
			<>
				<div className="col-md-12">
					<div className="choose-clr-blk pt-10">
						<div className="choos-clr-label"><span>{title}</span></div>
						<div className="choos-clr-btn">
							<button className="btn" style={{background: activeColor}} onClick={this.toggleColorPane}></button>
						</div>
					</div>
				</div>
				<div className="col-md-12">
					<div className={`color-pane-container ${showColorPane ? 'show-custom-colors' : 'hide-custom-colors'}`}>
						{
							allColors.map(color=>{
								return (
									<div className='custom-color-pane' style={{backgroundColor:color.colorCode}}
										onClick={()=>{this.props.changeColor(color.colorCode)}}
									>
										<span id={'color-pane-tooltip'}>{color.title}</span>
									</div>
								)
							})
						}
					</div>
					{
						showColorPane && (
							<div className='d-flex justify-content-center'>
								<span className="btn col-md-2" style={{border: '1px solid white', padding: '2px'}}>
									<a className='custom-side-nav1' onClick={() => this.toggleColorPane()}>
										<img src={require("./../assets/img/icon-collapse.png")} alt="del" style={{height: '30px', width: '30px'}}/>
									</a>
								</span>
							</div>
						)
					}

				</div>

			</>
		);
	}
}

const mapStateToProps = state => {

	return {
		canvas: state.canvas,
	};
};

const CustomColorPaneConnect = connect(
	mapStateToProps,
)(CustomColorPane);

export default CustomColorPane

const theme ={
	container:{

	}
}