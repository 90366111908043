import React from 'react'
import {
  MatsToolSidebar,
  ColorToolSidebar,
  CircleToolSidebar,
  ArtworkToolSidebar,
  ThicknessToolSidebar,
    CircleColorPaneSideBar,
    ArtWorkColorPaneSideBar,TextToolBar
} from './Items'
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

class SidebarItemsDesktop extends React.Component {
  emptyClick= () =>{
      console.log('heeree');
    document.getElementById('second-custom-right').style.display = 'none';
    document.getElementById("mySidenav1").style.width = "0";
    document.getElementById("mySidenav2").style.width = "0";
    document.getElementById("mySidenav3").style.width = "0";
      document.getElementById("mySidenav11") && (document.getElementById("mySidenav11").style.width = "0");
    document.getElementById("mySidenav5").style.width = "0";
    document.getElementById("mySidenav6").style.width = "0";
    document.getElementById("mySidenav7").style.width = "0";
    document.getElementById("mySidenav8").style.width = "0";
    setTimeout(()=>{this.props.setTextConfigPanel(false)}, 300);
  }

  render() {
    return (
        <>
            <div id="second-custom-right" onClick={this.emptyClick}/>
            <MatsToolSidebar/>
            <ColorToolSidebar/>
            <CircleToolSidebar/>
            { this.props.is_text_toolbar_active && (
                <TextToolBar/>
            )}
            <ArtworkToolSidebar/>
            <ThicknessToolSidebar/>
            <CircleColorPaneSideBar/>
            <ArtWorkColorPaneSideBar/>
        </>
    )
  }
}

const mapStateToProps = state => {
    return {
        is_text_toolbar_active: state.is_text_toolbar_active,
    }
}
const setTextConfigPanel = (status)=>{
    return {
        type: 'TEXT_TOOLBAR_CONFIG',
        is_text_toolbar_active:status,
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            setTextConfigPanel,
        },
        dispatch,
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SidebarItemsDesktop)
