import React from 'react'
import { connect } from 'react-redux'
import $ from 'jquery'
import CanvasZoomConnect from "../../zoomTool/ZoomTool";
import SaveModal from "../SaveModal";
import {bindActionCreators} from "redux";
import QuoteModal from "../QuoteModal";
import axios from "axios";
import EmailModal from "../EmailModal";
import {SwainColorHex} from "../ColorCodeHelper";

class ExtraMenu extends React.Component {

  state = {
    submittingQuote: false,
    quoteModal: false,
  }

  componentDidMount = () => {}
  download = e => {
    this.clearSelection();
    e.preventDefault()
    let download = document.createElement('a')
    download.href = this.props.canvas.toDataURL()
    download.download = 'Dollamur_mat.png'
    download.click()
  }
  undo = e => {
    e.preventDefault();
    let tempConfig = this.props._config;
    if (tempConfig.undoFinishedStatus) {
      if (tempConfig.currentStateIndex === 0) {
        tempConfig.undoStatus = false;
      } else {
        if (tempConfig.canvasState.length >= 0) {
          tempConfig.undoFinishedStatus = 0;
          if (tempConfig.currentStateIndex !== 0) {
            tempConfig.undoStatus = true;
            this.props.canvas.loadFromJSON(
                tempConfig.canvasState[tempConfig.currentStateIndex - 1],
                () => {
                  this.props.canvas.renderAll();
                  tempConfig.undoStatus = false;
                  tempConfig.currentStateIndex -= 1;
                  tempConfig.undoButton = "remove disabled";
                  if (
                      tempConfig.currentStateIndex !==
                      tempConfig.canvasState.length - 1
                  ) {
                    tempConfig.redoButton = "remove disabled";
                  }
                  tempConfig.undoFinishedStatus = 1;
                }
            );
          } else if (tempConfig.currentStateIndex === 0) {
            this.props.canvas.clear();
            tempConfig.undoFinishedStatus = 1;
            tempConfig.undoButton = "disabled";
            tempConfig.redoButton = "remove disabled";
            tempConfig.currentStateIndex -= 1;
          }
        }
      }
    }
    this.props.canvas.getObjects('line').forEach(item=>{
      item.set({selectable:false});
    })
    this.props.canvas.renderAll()
    this.props.setCanvasConfig(tempConfig);
  };

  redo = e => {
    e.preventDefault();
    let tempConfig = this.props._config;
    if (tempConfig.redoFinishedStatus) {
      if (
          tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
          tempConfig.currentStateIndex !== -1
      ) {
        tempConfig.redoButton = "disabled";
      } else {
        if (
            tempConfig.canvasState.length > tempConfig.currentStateIndex &&
            tempConfig.canvasState.length !== 0
        ) {
          tempConfig.redoFinishedStatus = 0;
          tempConfig.redoStatus = true;
          this.props.canvas.loadFromJSON(
              tempConfig.canvasState[tempConfig.currentStateIndex + 1],
              () => {
                this.props.canvas.renderAll();
                tempConfig.redoStatus = false;
                tempConfig.currentStateIndex += 1;
                if (tempConfig.currentStateIndex !== -1) {
                  tempConfig.undoButton = "remove disabled";
                }
                tempConfig.redoFinishedStatus = 1;
                if (
                    tempConfig.currentStateIndex ===
                    tempConfig.canvasState.length - 1 &&
                    tempConfig.currentStateIndex !== -1
                ) {
                  tempConfig.redoButton = "disabled";
                }
              }
          );
        }
      }
      this.props.canvas.getObjects('line').forEach(item=>{
        item.set({selectable:false});
      })
      this.props.canvas.renderAll()
    }

    this.props.setCanvasConfig(tempConfig);
  };

  save = (title, email) => {

    let jsonData = this.props.canvas.toJSON();
    jsonData.swain_config = this.props.swain_config;
    jsonData.canvas_width = this.props.canvas.width;
    jsonData.canvas_height = this.props.canvas.height;
    const data = {
      data: JSON.stringify(jsonData),
      email,
      title,
      url: `${window.location.origin}/swain_save_designer/`
    };
    axios.post("/api/save_design", data).then(res => {
      alert("Your Design Has been saved! We have sent you an email with details.");
    });
  };

  email_design = (title, email) => {
    let jsonData = this.props.canvas.toJSON();
    jsonData.swain_config = this.props.swain_config;
    jsonData.canvas_width = this.props.canvas.width;
    jsonData.canvas_height = this.props.canvas.height;
    const data = {
      data: JSON.stringify(jsonData),
      email,
      title,
      url: `${window.location.origin}/swain_save_designer/`
    };
    axios.post("/api/save_design", data)
        .then(res => {
          alert('We have sent you an email with details.');
        }).catch(res => {
      alert('An unexpected error occurred. Please contact Admin');
    })
  };

  clearCanvas = (e) => {
    e.preventDefault();
    this.props.canvas.clear();
    window.scrollTo(0, 0);
    this.updateCanvasState();
    this.props.clearCanvas();
    clearTimeout(this.popupTimer);
    this.setState({ showAlert: false, firstAlert: true });
  };

  updateCanvasState = () => {
    let tempConfig = this.props._config;
    if (tempConfig.undoStatus === false && tempConfig.redoStatus === false) {
      let jsonData = this.props.canvas.toJSON();
      let canvasAsJson = JSON.stringify(jsonData);
      if (tempConfig.currentStateIndex < tempConfig.canvasState.length - 1) {
        let indexToBeInserted = tempConfig.currentStateIndex + 1;
        tempConfig.canvasState[indexToBeInserted] = canvasAsJson;

        let numberOfElementsToRetain = indexToBeInserted + 1;
        tempConfig.canvasState = tempConfig.canvasState.splice(
            0,
            numberOfElementsToRetain
        );
      } else {
        tempConfig.canvasState.push(canvasAsJson);
      }

      tempConfig.currentStateIndex = tempConfig.canvasState.length - 1;

      if (
          tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
          tempConfig.currentStateIndex !== -1
      ) {
        tempConfig.redoButton = "disabled";
      }
    }
    this.props.setCanvasConfig(tempConfig);
  };

  clearSelection = () =>{
    this.props.canvas.deactivateAll().renderAll()
    $('.deleteBtn').css('visibility', 'hidden')
    $('.material-color-pane').css({ display: 'none' })
    $('.text-color-pane').css({ display: 'none' })

    $('#bold').removeClass('active')
    $('#italic').removeClass('active')
    $('#underline').removeClass('active')
    $('#capital').removeClass('active')
    $('#left-align').removeClass('active')
    $('#center-align').removeClass('active')
    $('#right-align').removeClass('active')
    //$('.slct-font-size').val($('.slct-font-size option:first').val())
    //$('.slct-font-fam').val($('.slct-font-fam option:first').val())
    $('#line_ht_get').val(1)
    $('#line_ht_get').css({
      background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
    })
    $('#line_ht_set').val(1)
    $('#spacing_get').val(1)
    $('#spacing_get').css({
      background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
    })
    $('#spacing_set').val(1)
    $('#text-color-bind').css({ background: 'black' })
    $('#size_get').val(0)
    $('#size_get').css({
      background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
    })
    $('#size_set').val(0)
    $('#outline-color-bind').css({ background: 'black' })
    $('#selected_text').val('sampletext')
    $('.add-text-btn').text('Add Text')
  }
  sendQuote = ({ fullName: name, email, phone, zipcode, subject, message }) => {
    this.clearSelection();
    this.setState({submittingQuote:true});
    const matData = [{}];
    const unit = this.props.swain_config.length_unit === "FEET" ? "ft" : "m";
    const unit1 = this.props.swain_config.length_unit === "FEET" ? 3.28 : 1;
    const canvas_grid = this.props.canvas_grid;
    matData[0].matSize =
        this.props.swain_config.width +
        unit +
        " x " +
        this.props.swain_config.height+unit;

    const rects = {};
    const allrects = this.props.canvas.getObjects('rect');

    console.log(allrects)
    for (let i = 0; i < allrects.length; i++) {
      //setting all horizontals as vertivals
      let size;
      if(this.onOutOfCanvas(allrects[i])){
        console.log('is out of canvas area');
        continue;
      }

      const rectWidth = Math.round(allrects[i].width / canvas_grid / unit1);
      const rectHeight = Math.round(allrects[i].height / canvas_grid / unit1);

      if (
          rectWidth > rectHeight
      ) {
        size =
            rectHeight + " x " + rectWidth;
      } else {
        size =
            rectWidth + " x " + rectHeight;
      }
      console.log(size);
      console.log(allrects[i].fill.toString());
      let color = this.reduceDesignColor(allrects[i].fill.toString());
      if (rects[size + "##" + color]) {
        rects[size + "##" + color].count += 1;
      } else {
        rects[size + "##" + color] = {
          count: 1,
        };
      }
    }
    const colors = [];
    Object.keys(rects).forEach((key, i) => {
      const strs = key.split("##");
      colors[i] = {
        count: rects[key].count,
        color: strs[1],
        size: strs[0],
      };
      console.log(colors);
    });

    matData[0].colors = colors;
    matData[0].logos = this.props.uploadedLogos;
    matData[0].svg = this.props.canvas.toSVG();
    matData[0].png = this.props.canvas.toDataURL();

    matData[0].text = this.props.text_style ? this.props.text_style.text : "";
    matData[0].font = this.props.text_style
        ? this.props.text_style.fontFamily
        : "";
    matData[0].fontColor = this.props.fontColor;

    const data = {
      name,
      email,
      phone,
      zipcode,
      subject:`${subject}`,
      message,
      matData,
    };

    axios
        .post("/api/quote", data)
        .then((res) => {
          this.setState({quoteModal: false, submittingQuote: false});
          alert("Successfully saved!");
        })
        .catch((err) => {
          this.setState({quoteModal: false, submittingQuote: false});
          alert("An unexpected error occurred. Please contact Admin");
        });
  };
  reduceDesignColor=(colorCode)=>{
    if(colorCode.indexOf('rgb') >= 0){
      let a = colorCode.split("(")[1].split(")")[0];
      a = a.split(",");
      let b = a.map(function(x){                      //For each array element
        x = parseInt(x).toString(16);      //Convert to a base16 string
        return (x.length===1) ? "0"+x : x; //Add zero if we get only one character
      });
      colorCode = "#"+b.join("");
    }
    let colorName  = colorCode,
        colorNames = Object.keys(SwainColorHex);
    colorNames.forEach(color=>{
      if(SwainColorHex[color] === colorName){
        colorName = color;
      }
    })
    return colorName;
  }
  onOutOfCanvas = (activeObject) => {
    console.log("called");
    let out = false;
    activeObject.setCoords();
    let cords = activeObject.oCoords;
    let originalHeight = this.props.canvas.height;
    let originalWidth = this.props.canvas.width;
    //if is out from top
    if (cords.tl.y < 0 && cords.tr.y < 0 && cords.br.y < 5 && cords.bl.y < 5) {
      console.log('out from top')
      out = true;
    }
    //if is out from left
    else if (cords.tl.x < 5 && cords.tr.x < 5 && cords.br.x < 5 && cords.bl.x < 5) {
      console.log('out from left')
      out = true;
    }
    //if is out from bottom
    else if (cords.tl.x > originalWidth && cords.bl.x > originalWidth) {
      console.log('out from bottom')
      out = true;
    }
    //if is out from right
    else if (cords.tr.y > originalHeight && cords.tl.y > originalHeight) {
      console.log('out from right')
      out = true;

    }
    return out;
  }

  menuToggle = () => {
    let bodyClass = $('body').attr('class')
    if (
      bodyClass === undefined ||
      bodyClass.indexOf('sidebar-left-opened') < 0
    ) {
      $('body').addClass('sidebar-left-opened')
    } else {
      $('body').removeClass('sidebar-left-opened')
    }
  }
  render() {
    return (
      <>
        <div className="row mar">
          <div className="col-md-12 thrd-nav">
            <div className="col-md-12 pl-0 pr-0 mobile-menu">
              <QuoteModal 
                toolbar={true}
                sendQuote={this.sendQuote} 
                message={'SWAIN MAT'}
                show={this.state.quoteModal}
                submitting={this.state.submittingQuote}
                toggleModal={(val) => this.setState({quoteModal: val})}  
              />
              <SaveModal toolbar={true} save={this.save} />
              <EmailModal toolbar={true} email_design={this.email_design} />
              <a className="toolbar-btn" onClick={this.download}><img
                  className="fas"
                  src={require("./../../../assets/img/download.png")}
                  style={{marginTop:'-3px'}}
                  alt="DOWNLOAD"
              /><span className="hide-on-smaller-screen">Download</span></a>
              <a className="toolbar-btn" href='/'><img
                  className="fas"
                  src={require("./../../../assets/img/info.png")}
                  style={{marginTop:'-3px'}}
                  alt="INFO"
              /><span className="hide-on-smaller-screen">Info</span></a>
              <a className="toolbar-btn" onClick={this.undo}><img
                  className="fas"
                  src={require("./../../../assets/img/undo.png")}
                  style={{marginTop:'-3px'}}
                  alt="UNDO"
              /><span className="hide-on-smaller-screen">Undo</span></a>
              <a className="toolbar-btn" onClick={this.redo}><img
                  src={require("./../../../assets/img/redo.png")}
                  style={{marginTop:'-3px'}}
                  alt="REDO"
              /><span className="hide-on-smaller-screen">Redo</span></a>
              <a className="toolbar-btn" onClick={this.clearCanvas}><img
                  src={require("./../../../assets/img/delete.png")}
                  style={{marginTop:'-3px'}}
                  alt="REDO"
              /><span className="hide-on-smaller-screen">Clear</span></a>
              <div className="nav navbar-nav navbar-right">
                <CanvasZoomConnect/>
              </div>
            </div>
            <ul className="nav navbar-nav navbar-right formobview mobul">
              <li>
                <a onClick={this.download}>
                  <img
                    src={require('./../../../assets/img/download.png')}
                    alt="DOWNLOAD"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src={require('./../../../assets/img/info.png')}
                    alt="INFO"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row mar sectogglebtn formobview">
          <a
            data-placement="bottom"
            data-original-title="Show/Hide"
            data-toggle="tooltip"
            className="btn btn-sm toggle-left"
            id="menu-toggle"
            onClick={() => this.menuToggle()}
          >
            <img src={require('./../../../assets/img/menu.png')} alt="MENU" />
          </a>
        </div>
      </>
    )
  }
}

const clearCanvas = () => {
  return {
    type: "CLAER_CANVAS",
    isCanvasCleared: true,
  };
};

const setCanvasConfig = (_config) => {
  return {
    type: "SET_CONFIG",
    _config,
  };
};


const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
      {
        clearCanvas,
        setCanvasConfig
      },
      dispatch
  );
};

const mapStateToProps = state => {
  return {
    _config: state._config,
    canvas: state.canvas,
    swain_config:state.swain_config,
    canvas_grid:state.canvas_grid
  }
}

const ExtraMenuConnect = connect(mapStateToProps,mapDispatchToProps)(ExtraMenu)

export default ExtraMenuConnect
