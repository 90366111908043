import React from 'react'
import { connect } from 'react-redux'
import $ from 'jquery'
import SaveModal from "../SaveModal";
import EmailModal from "../EmailModal";
import QuoteModal from "../QuoteModal";
import CanvasZoomConnect from "../../zoomTool/ZoomTool";
import axios from "axios";
import {bindActionCreators} from "redux";

class ExtraMenu extends React.Component {
  
  state = {
    submittingQuote: false,
    quoteModal: false,
  }

  componentDidMount = () => {}
  download = e => {
    this.clearSelection();
    e.preventDefault()
    let download = document.createElement('a')
    download.href = this.props.canvas.toDataURL()
    download.download = 'Dollamur_mat.png'
    download.click()
  }
  clearSelection = () =>{
    this.props.canvas.deactivateAll().renderAll()
    $('.deleteBtn').css('visibility', 'hidden')

    $('.text-color-pane').css({ display: 'none' })
    $('#bold').removeClass('active')
    $('#italic').removeClass('active')
    $('#underline').removeClass('active')
    $('#capital').removeClass('active')
    $('#left-align').removeClass('active')
    $('#center-align').removeClass('active')
    $('#right-align').removeClass('active')
    //$('.slct-font-size').val($('.slct-font-size option:first').val())
    //$('.slct-font-fam').val($('.slct-font-fam option:first').val())
    $('#line_ht_get').val(1)
    $('#line_ht_get').css({
      background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
    })
    $('#line_ht_set').val(1)
    $('#spacing_get').val(1)
    $('#spacing_get').css({
      background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
    })
    $('#spacing_set').val(1)
    $('#text-color-bind').css({ background: 'black' })
    $('#size_get').val(0)
    $('#size_get').css({
      background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
    })
    $('#size_set').val(0)
    $('#outline-color-bind').css({ background: 'black' })
    $('#selected_text').val('sampletext')
    $('.add-text-btn').text('Add Text')
  }

  sendQuote = ({
                 fullName: name,
                 email,
                 phone,
                 zipcode,
                 subject,
                 message,
                 sports: sport,
               }) => {
    this.clearSelection();
    this.setState({submittingQuote:true});
    const thickness = this.props.thickness;
    const matData = [{}];
    const unit = this.props.flexi_config.length_unit === "FEET" ? "ft" : "m";
    matData[0].matSize =
        this.props.flexi_config.width +
        unit +
        " x " +
        this.props.flexi_config.height +
        unit;
    matData[0].matColor = this.props.flexi_color;
    matData[0].thickness = thickness;

    let matFonts = [];
    let matShapes = [];
    if (this.props.canvas.getObjects("path-group").length > 0){
      let shapes = this.props.canvas.getObjects("path-group");
      shapes.forEach((item,index)=>{
        console.log({index,item});
        let paths = item.paths;
        let colors =[];
        paths.forEach(path=>{
          if(!colors.includes(path.fill)){
            colors.push(path.fill);
          }
        })
        matShapes.push({
          colors : colors,
        })
      })
      matData[0].shapes = matShapes;
    }
    if (this.props.canvas.getObjects("curvedText").length > 0) {
      let curvedTexts = this.props.canvas.getObjects("curvedText");
      curvedTexts.forEach((item,index)=>{
        let color = item.fill;
        if(color.indexOf('rgb') >= 0){
          let a = color.split("(")[1].split(")")[0];
          a = a.split(",");
          let b = a.map(function(x){                      //For each array element
            x = parseInt(x).toString(16);      //Convert to a base16 string
            return (x.length===1) ? "0"+x : x; //Add zero if we get only one character
          });
          color = "#"+b.join("");
        }
        if(item.text !== ''){
          matFonts.push({
            text : item.text,
            fontFamily : item.fontFamily,
            fill : item.fill,
            outline: item.stroke,
            outlineSize: item.strokeWidth,
          })
        }
      })

      matData[0].text = this.props.text_style ? this.props.text_style.text : "";
      matData[0].font = this.props.text_style
          ? this.props.text_style.fontFamily
          : "";
      matData[0].fontColor = this.props.fontColor
    }
    matData[0].curvedTexts = matFonts;
    let allLogos = [];
    if(this.props.canvas.getObjects('image').length > 0){
      let images = this.props.canvas.getObjects("image");
      images.forEach((image)=>{
        console.log(image.source)
        allLogos.push(image.source)
      })
    }
    matData[0].logos = allLogos;
    matData[0].flexiType = this.props.flexi_type;
    matData[0].sport = sport;

    matData[0].fc = this.props.mat_style_flexi_conenct ? "Yes":null ;
    matData[0].fr = this.props.mat_style_flexi_roll_mats ? "Yes":null ;
    matData[0].nonFr = this.props.mat_style_non_flexi_roll_mats ? "Yes":null ;

    //matData[0].logos = this.props.uploadedLogos;
    matData[0].svg = this.props.canvas.toSVG();
    matData[0].png = this.props.canvas.toDataURL();

    const data = {
      name,
      email,
      phone,
      zipcode,
      subject:`${subject}`,
      message,
      thickness,
      matData,
    };
    axios
        .post("/api/quote", data)
        .then((res) => {
          this.setState({quoteModal: false, submittingQuote: false});
          alert("Successfully saved!");
        })
        .catch((err) => {
          console.log(err);
          this.setState({quoteModal: false, submittingQuote: false});
          alert("An unexpected error occurred. Please contact Admin");
        });
  };
  clearCanvas = (e) => {
    e.preventDefault();
    //this.props.canvas.clear();


    let curvedText = this.props.canvas.getObjects('curvedText');
    let image = this.props.canvas.getObjects('image');
    curvedText.forEach((item)=>{
      this.props.canvas.remove(item);
    })
    image.forEach((item)=>{
      this.props.canvas.remove(item);
    })
    const t = new window.fabric.Text('', { fontFamily: 'Times New Roman',left:-10 })
    this.props.canvas.add(t);
    this.props.canvas.sendToBack(t);
    this.props.canvas.renderAll();
    this.updateCanvasState();
    /*this.props.clearCanvas();
    const t = new window.fabric.Text('', { fontFamily: 'Times New Roman',left:-10 })
    this.props.canvas.add(t);*/
  };
  undo = e => {
    e.preventDefault()
    let tempConfig = this.props._config
    if (tempConfig.undoFinishedStatus) {
      if (tempConfig.currentStateIndex === 0) {
        tempConfig.undoStatus = false
      } else {
        if (tempConfig.canvasState.length >= 2) {
          tempConfig.undoFinishedStatus = 0
          if (tempConfig.currentStateIndex !== 0) {
            tempConfig.undoStatus = true
            this.props.canvas.loadFromJSON(
                tempConfig.canvasState[tempConfig.currentStateIndex - 1],
                () => {
                  this.props.canvas.renderAll()
                  tempConfig.undoStatus = false
                  tempConfig.currentStateIndex -= 1
                  tempConfig.undoButton = 'remove disabled'
                  if (
                      tempConfig.currentStateIndex !==
                      tempConfig.canvasState.length - 1
                  ) {
                    tempConfig.redoButton = 'remove disabled'
                  }
                  tempConfig.undoFinishedStatus = 1
                },
            )
          } else if (tempConfig.currentStateIndex === 0) {
            this.props.canvas.clear()
            tempConfig.undoFinishedStatus = 1
            tempConfig.undoButton = 'disabled'
            tempConfig.redoButton = 'remove disabled'
            tempConfig.currentStateIndex -= 1
          }
        }
      }
    }

    this.props.setCanvasConfig(tempConfig)
  }

  redo = e => {
    e.preventDefault()
    let tempConfig = this.props._config
    if (tempConfig.redoFinishedStatus) {
      if (
          tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
          tempConfig.currentStateIndex !== -1
      ) {
        tempConfig.redoButton = 'disabled'
      } else {
        if (
            tempConfig.canvasState.length > tempConfig.currentStateIndex &&
            tempConfig.canvasState.length !== 0
        ) {
          tempConfig.redoFinishedStatus = 0
          tempConfig.redoStatus = true
          this.props.canvas.loadFromJSON(
              tempConfig.canvasState[tempConfig.currentStateIndex + 1],
              () => {
                this.props.canvas.renderAll()
                tempConfig.redoStatus = false
                tempConfig.currentStateIndex += 1
                if (tempConfig.currentStateIndex !== -1) {
                  tempConfig.undoButton = 'remove disabled'
                }
                tempConfig.redoFinishedStatus = 1
                if (
                    tempConfig.currentStateIndex ===
                    tempConfig.canvasState.length - 1 &&
                    tempConfig.currentStateIndex !== -1
                ) {
                  tempConfig.redoButton = 'disabled'
                }
              },
          )
        }
      }
    }

    this.props.setCanvasConfig(tempConfig)
  }

  save = (title, email) => {
    console.log(this.props.canvas.width);
    let jsonData = this.props.canvas.toJSON();
    jsonData.flexi_config = this.props.flexi_config;
    jsonData.canvas_width = this.props.canvas.width;
    jsonData.canvas_height = this.props.canvas.height;

    const data = {
      data: JSON.stringify(jsonData),
      email,
      title,
      url: `${window.location.origin}/flexi_save_designer/`
    }
    axios.post('/api/save_design', data).then(res => {
      alert("Your Design Has been saved! We have sent you an email with details.");
    })
  }

  email_design = (title, email) => {
    let jsonData = this.props.canvas.toJSON();
    jsonData.flexi_config = this.props.flexi_config;
    jsonData.canvas_width = this.props.canvas.width;
    jsonData.canvas_height = this.props.canvas.height;
    const data = {
      data: JSON.stringify(jsonData),
      email,
      title,
      url: `${window.location.origin}/flexi_save_designer/`
    }
    axios.post("/api/save_design", data)
        .then(res => {
          alert('We have sent you an email with details.');
        }).catch(res => {
      alert('An unexpected error occurred. Please contact Admin');
    })
  }
  updateCanvasState = () => {
    let tempConfig = this.props._config;
    if (tempConfig.undoStatus === false && tempConfig.redoStatus === false) {
      let jsonData = this.props.canvas.toJSON();
      let canvasAsJson = JSON.stringify(jsonData);
      if (tempConfig.currentStateIndex < tempConfig.canvasState.length - 1) {
        let indexToBeInserted = tempConfig.currentStateIndex + 1;
        tempConfig.canvasState[indexToBeInserted] = canvasAsJson;

        let numberOfElementsToRetain = indexToBeInserted + 1;
        tempConfig.canvasState = tempConfig.canvasState.splice(
            0,
            numberOfElementsToRetain
        );
      } else {
        tempConfig.canvasState.push(canvasAsJson);
      }

      tempConfig.currentStateIndex = tempConfig.canvasState.length - 1;

      if (
          tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
          tempConfig.currentStateIndex !== -1
      ) {
        tempConfig.redoButton = "disabled";
      }
    }
    this.props.setCanvasConfig(tempConfig);
  };

  menuToggle = () => {
    let bodyClass = $('body').attr('class')
    if (
      bodyClass === undefined ||
      bodyClass.indexOf('sidebar-left-opened') < 0
    ) {
      $('body').addClass('sidebar-left-opened')
    } else {
      $('body').removeClass('sidebar-left-opened')
    }
  }
  render() {
    return (
      <>
        <div className="row mar">
          <div className="col-md-12 thrd-nav">
            <div className="col-md-12 pl-0 pr-0 mobile-menu">
              <QuoteModal 
                toolbar={true} 
                designer={'flexi'} 
                sendQuote={this.sendQuote} 
                message={'FLEXI MAT'}
                show={this.state.quoteModal}
                submitting={this.state.submittingQuote}
                toggleModal={(val) => this.setState({quoteModal: val})}   
              />
              <SaveModal toolbar={true} save={this.save} />
              <EmailModal toolbar={true} email_design={this.email_design} />
              <a className="toolbar-btn" onClick={this.download}><img
                  className="fas"
                  src={require("./../../../assets/img/download.png")}
                  style={{marginTop:'-3px'}}
                  alt="DOWNLOAD"
              /><span className="hide-on-smaller-screen">Download</span></a>
              <a className="toolbar-btn" href='/'><img
                  className="fas"
                  src={require("./../../../assets/img/info.png")}
                  style={{marginTop:'-3px'}}
                  alt="INFO"
              /><span className="hide-on-smaller-screen">Info</span></a>
              <a className="toolbar-btn" onClick={this.undo}><img
                  className="fas"
                  src={require("./../../../assets/img/undo.png")}
                  style={{marginTop:'-3px'}}
                  alt="UNDO"
              /><span className="hide-on-smaller-screen">Undo</span></a>
              <a className="toolbar-btn" onClick={this.redo}><img
                  src={require("./../../../assets/img/redo.png")}
                  style={{marginTop:'-3px'}}
                  alt="REDO"
              /><span className="hide-on-smaller-screen">Redo</span></a>
              <a className="toolbar-btn" onClick={this.clearCanvas}><img
                  src={require("./../../../assets/img/delete.png")}
                  style={{marginTop:'-3px'}}
                  alt="REDO"
              /><span className="hide-on-smaller-screen">Clear</span></a>
              <div className="nav navbar-nav navbar-right">
                <CanvasZoomConnect/>
              </div>
            </div>
            <ul className="nav navbar-nav navbar-right formobview mobul">
              <li>
                <a onClick={this.download}>
                  <img
                      src={require('./../../../assets/img/download.png')}
                      alt="DOWNLOAD"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                      src={require('./../../../assets/img/info.png')}
                      alt="INFO"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row mar sectogglebtn formobview">
          <a
              data-placement="bottom"
              data-original-title="Show/Hide"
              data-toggle="tooltip"
              className="btn btn-sm toggle-left"
              id="menu-toggle"
              onClick={() => this.menuToggle()}
          >
            <img src={require('./../../../assets/img/menu.png')} alt="MENU" />
          </a>
        </div>
      </>
    )
  }
}
const setCanvasConfig = _config => {
  return {
    type: 'SET_CONFIG',
    _config,
  }
}
const mapStateToProps = state => {
  return {
    canvas: state.canvas,
    _config:state._config,
    flexi_config:state.flexi_config,
    thickness:state.thickness,
    flexi_color:state.flexi_color,
    flexi_type:state.flexi_type,
    mat_style_flexi_conenct:state.mat_style_flexi_conenct,
    mat_style_flexi_roll_mats:state.mat_style_flexi_roll_mats,
    mat_style_non_flexi_roll_mats:state.mat_style_non_flexi_roll_mats,
  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ setCanvasConfig }, dispatch)
}
const ExtraMenuConnect = connect(mapStateToProps,mapDispatchToProps)(ExtraMenu)

export default ExtraMenuConnect
